import React, { useState } from "react";
import { commonService, AppConfigData } from "../_services/common.service";
/*import { ZoomMtg } from '@zoomus/websdk';*/
import { useHistory } from "react-router-dom";
import { ZoomMtg } from '@zoom/meetingsdk';
const KJUR = require('jsrsasign')
ZoomMtg.preLoadWasm();

ZoomMtg.prepareWebSDK();
function LiveClass() {
    const history = useHistory()
    document.getElementById("main").classList.add("login")
    document.getElementById("main").classList.remove("menu-open");
    document.getElementById("main").style.marginLeft = null;
    let data = commonService.getLoginUserData();
    let liveclass = commonService.getItem("liveclass");
    let _liveclass = [];
    if (liveclass !== "") {
        _liveclass = JSON.parse(liveclass);
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Liveclass", "IsView")) {
            if (_liveclass.length !== 0 && _liveclass.details.length !== 0) {
                var authEndpoint = ''
                var sdkKey = _liveclass.details.ApiKey;
                var sdksecret = _liveclass.details.ApiSecret;
                var meetingNumber = _liveclass.details.MeetingIDNumer
                var passWord = _liveclass.details.MeetingPassword
                var role = 0
                if (_liveclass.isHost) {
                    role = 1;
                }
                var userName = data[0].FirstName + " " + data[0].LastName
                var userEmail = ''
                var registrantToken = ''
                var zakToken = ''
                var leaveUrl = '/timetableliveclass'
         

                var signature = generateSignature(sdkKey, sdksecret, meetingNumber, role);

                document.getElementById('zmmtg-root').style.display = 'block'
                ZoomMtg.init({
                    leaveUrl: leaveUrl,
                    patchJsMedia: true,
                    success: (success) => {
                        console.log(success)

                        ZoomMtg.join({
                            signature: signature,
                            sdkKey: sdkKey,
                            meetingNumber: meetingNumber,
                            passWord: passWord,
                            userName: userName,
                            userEmail: userEmail,
                            tk: registrantToken,
                            zak: zakToken,
                            success: (success) => {
                                console.log(success)
                            },
                            error: (error) => {
                                console.log(error)
                            }
                        })

                    },
                    error: (error) => {
                        console.log(error)
                    }
                })
            }
        }
    }, []);

    function generateSignature(key, secret, meetingNumber, role) {

        const iat = Math.round(new Date().getTime() / 1000) - 30
        const exp = iat + 60 * 60 * 2
        const oHeader = { alg: 'HS256', typ: 'JWT' }

        const oPayload = {
            sdkKey: key,
            appKey: key,
            mn: meetingNumber,
            role: role,
            iat: iat,
            exp: exp,
            tokenExp: exp
        }

        const sHeader = JSON.stringify(oHeader)
        const sPayload = JSON.stringify(oPayload)
        const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret)
        return sdkJWT
    }

    return (
        <>
            <h1 className="page-header my-4"> {commonService.LabelDisplayText("LiveClassHome")}</h1>
        </>
    );
}

export default LiveClass;
