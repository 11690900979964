import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";

function SiblingView() {

    const [sibling, setsibling] = useState({ IsData: false, data: [] });

    function BindSibling() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "SIBLING"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setsibling({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setsibling({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindSibling();
    }, []);

    return (
        <> <div className="container classwork-popup-detail">
            <div className="col-lg-12 student-profile ">
                {sibling.IsData ?
                    (
                        <>
                            <div className="inner-header-for-section float-left">
                                <h2 className="m-0">Sibling Details</h2>
                            </div>
                            <div className="col-lg-12 my-2 float-left">
                                {sibling.data.map((item, index) => (
                                    <>
                                        <div className="col-lg-3 float-left">
                                            <div className="col-lg-12 my-1 event-description">
                                                <b className="student-roll-no mt-1">GR/Scholar No. # : </b>{item.GRNo}
                                            </div>
                                        </div>
                                        <div className="col-lg-5 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Name # : </b>{item.StudentName}
                                            </div>
                                        </div>
                                        <div className="col-lg-2 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Standards : </b>{item.StandardName}
                                            </div>
                                        </div>
                                        <div className="col-lg-2 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Division : </b>{item.DivisionName}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <Skeleton count={1} height={30} />
                            <_Skeleton type={_SkeletonType.Table} />
                        </>
                    )}
            </div>
        </div>
        </>
    );
}

export default SiblingView;
