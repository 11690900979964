import React from "react";
import { ReactComponent as NoPostFound } from "../../src/Images/Icon/no-post-found.svg";

function _NoPostFound(prop) {
  let height = prop.height || "100%";
  let width = prop.width || "100%";
  //   let fontsize = prop.fontsize || "60px";
  let color = prop.color || "#000000";
  return (
    <div
      style={{
        height: height,
        width: width,
        // fontSize: fontsize,
        color: color,
        textAlign: "center",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <NoPostFound />
        {/* <NoPostFound className="light-mode-logo" /> */}
      </div>
      {/* <img src={NoDataFound} alt="" /> */}
    </div>
  );
}

export default _NoPostFound;
