import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

function Access() {
  return (
    <div class="col-12">
      <div class="card home-card-classwork home-card-classwork-Acces d-flex align-item-center mt-4">
        <div
          style={{
            height: "100%",
            width: "100%",
            // fontSize: fontsize,
            color: "#000000",
            textAlign: "center",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="icon-div">
            <i
              class="fa-light fa-solid fa-do-not-enter"
              style={{ fontSize: "5rem" }}
            ></i>
          </div>
          <div className="not-authorized mt-3" style={{ fontSize: "1rem" }}>
            You are not authorized to perform this operation.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Access;
