import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Select from "react-select";
import { alertService, AlertType } from "../_services/alert.service";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import Spinner from "react-bootstrap/Spinner";
import { confirmAlert } from "react-confirm-alert";

function Leave() {
    const history = useHistory();
    const [ShowEditLeavePopup, setShowEditLeavePopup] = useState(false);
    const [ShowEditLeavePopupData, setShowEditLeavePopupData] = useState(null);
    const [TeacherShowEditLeavePopup, setTeacherShowEditLeavePopup] =
        useState(false);
    const [TeacherShowEditLeavePopupData, setTeacherShowEditLeavePopupData] =
        useState(null);

    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [isloading, setisloading] = useState(false);
    const [studentleavedata, setstudentleavedata] = useState({
        isdata: false,
        data: [],
    });
    const [teacherleavedata, setteacherleavedata] = useState({
        isdata: false,
        data: [],
    });
    const [TeacherCreateLeavePopup, setTeacherCreateLeavePopup] = useState(false);
    const [teacherleavedetail, setteacherleavedetail] = useState([]);
    const [IsStudentLeaveSaveing, setIsStudentLeaveSaveing] = useState(false);
    const [IsTeacherLeaveSaveing, setIsTeacherLeaveSaveing] = useState(false);

    const [saveteacherleavedata, setsaveteacherleavedata] = useState({
        reason: "",
        startdate: new Date(),
        numberofdays: 1,
        typeofleave: "",
        daytype: "",
        errors: {
            reason: "",
            startdate: "",
            numberofdays: "",
            typeofleave: "",
            daytype: "",
        },
    });
    const [StudentCreateLeavePopup, setStudentCreateLeavePopup] = useState(false);
    const [studentleavedetail, setstudentleavedetail] = useState([]);
    const [studentteacherleavedetail, setstudentteacherleavedetail] = useState(
        []
    );
    const [savestudentleavedata, setsavestudentleavedata] = useState({
        reason: "",
        startdate: new Date(),
        numberofdays: 1,
        typeofleave: "",
        teacher: "",
        ispreapplication: false,
        studentphoto: "",
        errors: {
            reason: "",
            startdate: "",
            numberofdays: "",
            typeofleave: "",
            teacher: "",
        },
    });
    const [studentleavestatusupdate, setstudentleavestatusupdate] = useState({
        status: "",
        comment: "",
        schoolLeaveNoteID: "",
    });



    let data = commonService.getLoginUserData();

    function StudentLeaveEdit(a) {
        setShowEditLeavePopup(true);
        setShowEditLeavePopupData(a);
    }
    function TeacherLeaveEdit(a) {
        setTeacherShowEditLeavePopup(true);
        setTeacherShowEditLeavePopupData(a);
    }

    function ToggleTeacherCreateLeavePopup() {
        setTeacherCreateLeavePopup(true);
        getstudentleavedetail();
        getteacherleavedetail();
    }
    function ToggleStudentCreateLeavePopup() {
        setStudentCreateLeavePopup(true);
        getstudentleavedetail();
        getstudentteacherleavedetail();
    }
    useEffect(() => {
        if (commonService.getLoginRoleData("Leave Application", "IsView") || commonService.getLoginRoleData("Teacher Leave Application", "IsView")) {
            if (data[0].MemberType.toLowerCase() === "student") {
                getstudentleavedata();
            } else {
                getteacherleavedata();
                getstudentleavedata();
            }
        } else {
            history.push("/access");
        }
    }, []);

    function getstudentleavedata() {
        setisloading(true);
        setstudentleavedata({ isdata: false, data: [] });
        const reqstudentleavedata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: 1,
                noOfData: 0,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/getstudentleave",
            reqstudentleavedata
        )
            .then((response) => response.json())
            .then(function (json) {
                setisloading(false);
                if (json.status_code === 1) {
                    var a = json.data;
                    setstudentleavedata({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setstudentleavedata({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getteacherleavedata() {
        setisloading(true);
        setteacherleavedata({ isdata: false, data: [] });
        const reqteacherleavedata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: 1,
                noOfData: 0,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/getemployeeleave",
            reqteacherleavedata
        )
            .then((response) => response.json())

            .then(function (json) {
                setisloading(false);

                if (json.status_code === 1) {
                    var b = json.data;
                    setteacherleavedata({ isdata: true, data: b });
                } else if (json.status_code === 0) {
                    setteacherleavedata({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function setnumberofdays(Type) {
        if (Type.toUpperCase() == "FULL DAY PAY") {
            setsaveteacherleavedata({
                ...saveteacherleavedata,
                ["daytype"]: Type,
                ["numberofdays"]: 1,
            })
        }
        else if (Type.toUpperCase() == "HALF DAY PAY"){
            setsaveteacherleavedata({
                ...saveteacherleavedata,
                ["daytype"]: Type,
                ["numberofdays"]: 0.5,
            })
        }
    }

    function getteacherleavedetail() {
        setisloading(true);
        const reqteacherleavedetail = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/getemployeeleavedetails",
            reqteacherleavedetail
        )
            .then((response) => response.json())

            .then(function (json) {
                setisloading(false);
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        if (json.data[i].LeaveBalance === 0) {
                            options.push({
                                value: json.data[i].LeaveID.toLocaleUpperCase(),
                                label: `${json.data[i].LeaveDescription}(${json.data[i].LeaveBalance})`,
                                isDisabled: true,
                            });
                        } else {
                            options.push({
                                value: json.data[i].LeaveID.toLocaleUpperCase(),
                                label: `${json.data[i].LeaveDescription}(${json.data[i].LeaveBalance})`,
                                isDisabled: false,
                            });
                        }

                    }
                    setteacherleavedetail(options);
                } else if (json.status_code === 0) {
                    setteacherleavedetail([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getstudentleavedetail() {
        setisloading(true);
        const reqstudentleavedetail = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                multiCategory: "LeaveType_term",
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_projectterm_selectbymulticategory",
            reqstudentleavedetail
        )
            .then((response) => response.json())

            .then(function (json) {
                setisloading(false);
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].Term.toLocaleUpperCase(),
                            label: `${json.data[i].Term}`,
                        });
                    }
                    setstudentleavedetail(options);
                } else if (json.status_code === 0) {
                    setstudentleavedetail([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getstudentteacherleavedetail() {
        setisloading(true);
        const reqstudentleavedetail = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/getclassteacher",
            reqstudentleavedetail
        )
            .then((response) => response.json())

            .then(function (json) {
                setisloading(false);
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].TeacherID.toLocaleUpperCase(),
                            label: `${json.data[i].FullName}`,
                        });
                    }
                    setstudentteacherleavedetail(options);
                } else if (json.status_code === 0) {
                    setstudentteacherleavedetail([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function changesaveteacherleavedata(e) {
        setsaveteacherleavedata({
            ...saveteacherleavedata,
            [e.target.name]: e.target.value,
        });
    }
    function changesaveteacherleavetypedata(e) {
        setsaveteacherleavedata({ ...saveteacherleavedata, typeofleave: e.value });
    }
    function changestudentleavedata(e) {
        setsavestudentleavedata({
            ...savestudentleavedata,
            [e.target.name]: e.target.value,
        });
    }
    function changestudentleavetypedata(e) {
        setsavestudentleavedata({ ...savestudentleavedata, typeofleave: e.value });
    }
    function changestudentteacherleavetypedata(e) {
        setsavestudentleavedata({ ...savestudentleavedata, teacher: e.value });
    }
    function changestudentleaveappdata(e) {
        setsavestudentleavedata({
            ...savestudentleavedata,
            ispreapplication: e.target.checked,
        });
    }
    function submitteacherleavedata(e) {
        e.preventDefault();
        let isValid = true;
        let errors = {
            reason: "",
            numberofdays: "",
            typeofleave: "",
            startdate: "",
            daytype: "",
        };

        if (!saveteacherleavedata.reason) {
            errors.reason = "Reason is required";
            isValid = false;
        } else {
            errors.reason = "";
        }

        if (!saveteacherleavedata.numberofdays) {
            errors.numberofdays = "Number of days is required";
            isValid = false;
        } else {
            errors.numberofdays = "";
            if (saveteacherleavedata.daytype == "HALF DAY PAY") {
                if (saveteacherleavedata.numberofdays != 0.5) {
                    errors.numberofdays = "Number of days must be 0.5";
                    isValid = false;
                }
            }
            else if (saveteacherleavedata.daytype == "FULL DAY PAY") {
                if (parseInt(saveteacherleavedata.numberofdays) < 1) {
                    errors.numberofdays = "Number of days is not zero";
                    isValid = false;
                }
            }
            else {
                errors.numberofdays = "";
            }
        }

        if (!saveteacherleavedata.typeofleave) {
            errors.typeofleave = "Type of leave is required";
            isValid = false;
        } else {
            errors.typeofleave = "";
        }

        if (!saveteacherleavedata.startdate) {
            errors.startdate = "Start date is required";
            isValid = false;
        } else {
            errors.startdate = "";
        }

        if (!saveteacherleavedata.daytype) {
            errors.daytype = "Day Type is required";
            isValid = false;
        } else {
            errors.daytype = "";
        }

        setsaveteacherleavedata({
            ...saveteacherleavedata,
            ["errors"]: errors,
        });

        if (isValid) {
            setIsTeacherLeaveSaveing(true);
            let formData = new FormData();
            formData.append("LeaveRequestID", "00000000-0000-0000-0000-000000000000");
            formData.append("LeaveID", saveteacherleavedata.typeofleave);
            formData.append(
                "LeaveStartDate",
                commonService.getDateTimeInDBFormat(saveteacherleavedata.startdate)
            );
            formData.append("ReasonForLeave", saveteacherleavedata.reason);
            formData.append("TotalNoofDays", saveteacherleavedata.numberofdays);
            formData.append("LeaveType_Term", saveteacherleavedata.daytype);
            formData.append("RequestFrom", "Web");

            const SaveTeacherLeaveData1 = {
                method: "POST",
                headers: commonService.getHeadersFromData(),
                processData: false,
                contentType: false,
                body: formData,
            };
            fetch(
                AppConfigData.APIBasePath + "v1/leave/employeeleavesave",
                SaveTeacherLeaveData1
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsTeacherLeaveSaveing(false);
                    if (json.status_code === 1) {
                        setsaveteacherleavedata({
                            reason: "",
                            startdate: new Date(),
                            numberofdays: 0,
                            typeofleave: "",
                            daytype: "",
                            errors: {
                                reason: "",
                                startdate: "",
                                numberofdays: "",
                                typeofleave: "",
                                daytype: "",
                            },
                        });
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        getteacherleavedata();
                        setTeacherCreateLeavePopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    function submitsavestudentleavedata(e) {
        e.preventDefault();
        let isValid = true;
        let errors = {
            reason: "",
            numberofdays: "",
            typeofleave: "",
            teacher: "",
            startdate: "",
        };

        if (!savestudentleavedata.reason) {
            errors.reason = "Reason is required";
            isValid = false;
        } else {
            errors.reason = "";
        }

        if (!savestudentleavedata.numberofdays) {
            errors.numberofdays = "Number of days is required";
            isValid = false;
        } else {
            errors.numberofdays = "";
            if (parseInt(savestudentleavedata.numberofdays) <= 0) {
                errors.numberofdays = "Number of days is not zero";
                isValid = false;
            } else {
                errors.numberofdays = "";
            }
        }

        if (!savestudentleavedata.typeofleave) {
            errors.typeofleave = "Type of leave is required";
            isValid = false;
        } else {
            errors.typeofleave = "";
        }

        if (!savestudentleavedata.teacher) {
            errors.teacher = "Teacher is required";
            isValid = false;
        } else {
            errors.teacher = "";
        }

        if (!savestudentleavedata.startdate) {
            errors.startdate = "Start date is required";
            isValid = false;
        } else {
            errors.startdate = "";
        }

        setsavestudentleavedata({
            ...savestudentleavedata,
            ["errors"]: errors,
        });

        if (isValid) {
            setIsStudentLeaveSaveing(true);
            let formData = new FormData();
            formData.append(
                "SchoolLeaveNoteID",
                "00000000-0000-0000-0000-000000000000"
            );
            formData.append("LeaveType_Term", savestudentleavedata.typeofleave);
            formData.append(
                "StartDate",
                commonService.getDateTimeInDBFormat(savestudentleavedata.startdate)
            );
            formData.append("ApplicationToTeacherID", savestudentleavedata.teacher);
            formData.append("ReasonForLeave", savestudentleavedata.reason);
            formData.append("NoOfDays", savestudentleavedata.numberofdays);
            formData.append(
                "IsPreApplication",
                savestudentleavedata.ispreapplication
            );
            formData.append("FormFiles", savestudentleavedata.studentphoto);
            formData.append("ApplicationBy_Term", data[0].PostByType);
            formData.append("RequestFrom", "Web");

            const SaveStudentLeaveData1 = {
                method: "POST",
                headers: commonService.getHeadersFromData(),
                processData: false,
                contentType: false,
                body: formData,
            };
            fetch(
                AppConfigData.APIBasePath + "v1/leave/studentleavesave",
                SaveStudentLeaveData1
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsStudentLeaveSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        getstudentleavedata();
                        setStudentCreateLeavePopup(false);
                        setsavestudentleavedata({
                            reason: "",
                            startdate: new Date(),
                            numberofdays: 0,
                            typeofleave: "",
                            teacher: "",
                            ispreapplication: false,
                            errors: {
                                reason: "",
                                startdate: "",
                                numberofdays: "",
                                typeofleave: "",
                                teacher: "",
                            },
                        });
                        commonService.sendNotification();

                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }
    function approvestudentleave(e, a) {
        e.preventDefault();

        const SaveStudentLeaveStatus = {
            method: "POST",
            headers: commonService.getHeaders(),

            body: JSON.stringify({
                schoolLeaveNoteID: a,
                teacherComment: studentleavestatusupdate.comment,
                applicationStatus_Term: "APPROVED",
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/studentleaveapprovereject",
            SaveStudentLeaveStatus
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    getstudentleavedata();
                    commonService.sendNotification();
                    setShowEditLeavePopup(false);
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
        setstudentleavestatusupdate({
            ...setstudentleavestatusupdate,
            comment: "",
        });
    }

    function rejectstudentleave(e, a) {
        e.preventDefault();

        const SaveStudentLeaveStatus = {
            method: "POST",
            headers: commonService.getHeaders(),

            body: JSON.stringify({
                schoolLeaveNoteID: `${a}`,
                teacherComment: `${studentleavestatusupdate.comment}`,
                applicationStatus_Term: "REJECTED",
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/studentleaveapprovereject",
            SaveStudentLeaveStatus
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    getstudentleavedata();
                    commonService.sendNotification();
                    setShowEditLeavePopup(false);
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
        setstudentleavestatusupdate({
            ...setstudentleavestatusupdate,
            comment: "",
        });
    }

    function deleteleave(id, e) {
        e.preventDefault();
        const reqdeleteleave = {
            method: "POST",
            headers: commonService.getHeaders(),

            body: JSON.stringify({
                id: `${id}`,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/studentleavedeletebyid",
            reqdeleteleave
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    getstudentleavedata();
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }

    function deleteteacherleave(id, e) {
        e.preventDefault();
        const reqdeleteteacherleave = {
            method: "POST",
            headers: commonService.getHeaders(),

            body: JSON.stringify({
                id: `${id}`,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/leave/employeeleavedeletebyid",
            reqdeleteteacherleave
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    getteacherleavedata();
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    return (
        <>
            <h1 className="page-header my-4"> {commonService.LabelDisplayText("Menuleave")}</h1>
            {ShowEditLeavePopupData != null && (
                <Modal
                    show={ShowEditLeavePopup}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setShowEditLeavePopup(false)}
                    className="modal-theme-color"
                    contentClassName={"background-transparent-color"}
                    dialogClassName={"modal-full-width"}
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() => setShowEditLeavePopup(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">{commonService.LabelDisplayText("Menuleave")}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row ">
                                <Table responsive className="leave-table">
                                    <tbody>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Name")}</td>
                                            <td>{ShowEditLeavePopupData.StudentFullName}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Standard")}</td>
                                            <td>{ShowEditLeavePopupData.StandardName}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Division")}</td>
                                            <td>{ShowEditLeavePopupData.DivisionName}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Teacher")}</td>
                                            <td>{ShowEditLeavePopupData.TeacherFullName}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveApplicationDate")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    ShowEditLeavePopupData.DateOfApplication
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveFrom")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    ShowEditLeavePopupData.StartDate
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{commonService.LabelDisplayText("To")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    ShowEditLeavePopupData.EndDate
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveType")}</td>
                                            <td>{ShowEditLeavePopupData.LeaveType_Term}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Status")}</td>
                                            <td>{ShowEditLeavePopupData.ApplicationStatus_Term}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("ReasonForLeave")}</td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: ShowEditLeavePopupData.ReasonForLeave,
                                                }}
                                            ></td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("TeacherComment")}</td>
                                            <td>{ShowEditLeavePopupData.TeacherComment}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Photo")}</td>
                                            <td>
                                                {ShowEditLeavePopupData.Photo !== "" && ShowEditLeavePopupData.Photo !== null ? <>
                                                    <img
                                                        src={AppConfigData.APIFileBasePath + ShowEditLeavePopupData.Photo}
                                                        className="w-300px"
                                                    />
                                                </> : <></>}

                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                                {data[0].MemberType.toLowerCase() === "teacher" &&
                                    ShowEditLeavePopupData.ApplicationStatus_Term === "Pending" ? (
                                        <>
                                            <Table responsive className="leave-table">
                                                <tbody>
                                                    <tr>
                                                        <td>{commonService.LabelDisplayText("Comment")}</td>
                                                        <td>
                                                            <input
                                                                className={`title-textbox`}
                                                                placeholder={"Enter comment for approve / reject"}
                                                                name="comment"
                                                                value={studentleavestatusupdate.comment}
                                                                onChange={(e) =>
                                                                    setstudentleavestatusupdate({
                                                                        ...studentleavestatusupdate,
                                                                        comment: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            {
                                                commonService.getLoginRoleData("Leave Application", "IsCreate") &&
                                                <div>
                                                    <Button
                                                        variant="success"
                                                        value="Approve"
                                                        onClick={(e) =>
                                                            approvestudentleave(
                                                                e,
                                                                ShowEditLeavePopupData.SchoolLeaveNoteID
                                                            )
                                                        }
                                                    >
                                                        {commonService.LabelDisplayText("Approve")}
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        className="ml-3"
                                                        value="Reject"
                                                        onClick={(e) =>
                                                            rejectstudentleave(
                                                                e,
                                                                ShowEditLeavePopupData.SchoolLeaveNoteID
                                                            )
                                                        }
                                                    >
                                                        {commonService.LabelDisplayText("Reject")}
                                                    </Button>
                                                </div>
                                            }

                                        </>
                                    ) : (
                                        <></>
                                    )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
            {TeacherShowEditLeavePopupData != null && (
                <Modal
                    show={TeacherShowEditLeavePopup}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setTeacherShowEditLeavePopup(false)}
                    className="modal-theme-color"
                    contentClassName={"background-transparent-color"}
                    dialogClassName={"modal-full-width"}
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() => setTeacherShowEditLeavePopup(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">{commonService.LabelDisplayText("Menuleave")}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row ">
                                <Table responsive className="leave-table">
                                    <tbody>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Name")}</td>
                                            <td>{TeacherShowEditLeavePopupData.EmployeeName}</td>
                                        </tr>

                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveApplicationDate")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    TeacherShowEditLeavePopupData.DateOfLeaveApplication
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveFrom")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    TeacherShowEditLeavePopupData.LeaveStartDate
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{commonService.LabelDisplayText("To")}</td>
                                            <td>
                                                {commonService.getDateInFormat(
                                                    TeacherShowEditLeavePopupData.LeaveEndDate
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("NoofDays")}</td>
                                            <td>{TeacherShowEditLeavePopupData.TotalNoofDays}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveType")}</td>
                                            <td>{TeacherShowEditLeavePopupData.LeaveDescription}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("LeaveTypeterm")}</td>
                                            <td>{TeacherShowEditLeavePopupData.LeaveType_Term}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Status")}</td>
                                            <td>{TeacherShowEditLeavePopupData.LeaveStatus_Term}</td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("ReasonForLeave")}</td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: TeacherShowEditLeavePopupData.ReasonForLeave,
                                                }}
                                            ></td>
                                        </tr>
                                        <tr>
                                            <td>{commonService.LabelDisplayText("Note")} </td>
                                            <td>{TeacherShowEditLeavePopupData.ApprovalNote}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            <Modal
                show={TeacherCreateLeavePopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setTeacherCreateLeavePopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setTeacherCreateLeavePopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">{commonService.LabelDisplayText("ApplyForLeave")}</h1>
                                    {
                                        commonService.getLoginRoleData("Teacher Leave Application", "IsCreate") &&
                                        <div className="leave-accept-button px-3 pr-4">
                                            {IsTeacherLeaveSaveing === false ? (
                                                <button
                                                    className="btn btn-primary px-3 pr-4 popup-send-button"
                                                    type="button"
                                                    onClick={(e) => submitteacherleavedata(e)}
                                                > {commonService.LabelDisplayText("Submit")}</button>

                                            ) : (
                                                    <button
                                                        disabled="disabled"
                                                        className="approve-leave-button green-leave-button"
                                                    >
                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                    </button>
                                                )}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-9 add-leave-note ">
                                <div className="card student-leave-description mt-2">
                                    <textarea
                                        className={`title-textbox text-area-100px ${saveteacherleavedata.errors.reason && "invalid"
                                            }`}
                                        required={true}
                                        placeholder="Enter the reason"
                                        name="reason"
                                        value={saveteacherleavedata.reason}
                                        onChange={(e) => changesaveteacherleavedata(e)}
                                    />
                                    {saveteacherleavedata.errors.reason && (
                                        <span className="error-message">
                                            {saveteacherleavedata.errors.reason}
                                        </span>
                                    )}
                                </div>

                                <div></div>
                            </div>

                            <div className="col-3">
                                <div className="row mt-3 start-date-portion">
                                    <div className="col-1"> </div>
                                    <div className="col-3">{commonService.LabelDisplayText("DayType")}</div>
                                    <div className="col-8 start-date-of-leave mt-3">
                                        <Select
                                            className={`${saveteacherleavedata.errors.daytype && "invalid"
                                                }`}
                                            name="daytype"
                                            options={studentleavedetail}
                                            placeholder={"Select type"}
                                            onChange={(e) => setnumberofdays(e.value)}
                                            value={studentleavedetail.find(
                                                (obj) => obj.value === saveteacherleavedata.daytype
                                            )}
                                        />
                                        {saveteacherleavedata.errors.daytype && (
                                            <span className="error-message">
                                                {saveteacherleavedata.errors.daytype}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("From")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <div className="leave-start-date">
                                            <DatePicker
                                                required={true}
                                                className={`datepicker ${saveteacherleavedata.errors.startdate && "invalid"
                                                    }`}
                                                name="startdate"
                                                selected={saveteacherleavedata.startdate}
                                                dateFormat={data[0].DateFormat_Term}
                                                onChange={(date) =>
                                                    setsaveteacherleavedata({
                                                        ...saveteacherleavedata,
                                                        ["startdate"]: date,
                                                    })
                                                }
                                                placeholderText={data[0].DateFormat_Term}
                                            />
                                            {saveteacherleavedata.errors.startdate && (
                                                <span className="error-message">
                                                    {saveteacherleavedata.errors.startdate}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("NoofDays")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <input
                                            type="number"
                                            required
                                            className={`title-textbox ${saveteacherleavedata.errors.numberofdays && "invalid"
                                                }`}
                                            name="numberofdays"
                                            min={1}
                                            value={saveteacherleavedata.numberofdays}
                                            onChange={(e) => changesaveteacherleavedata(e)}
                                        />
                                        {saveteacherleavedata.errors.numberofdays && (
                                            <span className="error-message">
                                                {saveteacherleavedata.errors.numberofdays}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("TypeofLeave")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <Select
                                            className={`${saveteacherleavedata.errors.typeofleave && "invalid"
                                                }`}
                                            name="typeofleave"
                                            options={teacherleavedetail}
                                            placeholder={"Select type"}
                                            onChange={(e) => changesaveteacherleavetypedata(e)}
                                            // isDisabled={teacherleavedetail.find(
                                            //     (obj) =>obj.label.includes("(0)"))}
                                            value={teacherleavedetail.find(
                                                (obj) => obj.value === saveteacherleavedata.typeofleave
                                            )}
                                        />
                                        {saveteacherleavedata.errors.typeofleave && (
                                            <span className="error-message">
                                                {saveteacherleavedata.errors.typeofleave}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={StudentCreateLeavePopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setStudentCreateLeavePopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setStudentCreateLeavePopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">{commonService.LabelDisplayText("ApplyForLeave")}</h1>

                                    <div className="leave-accept-button px-3 pr-4">
                                        {IsStudentLeaveSaveing === false ? (
                                            <button
                                                onClick={(e) => submitsavestudentleavedata(e)}
                                                className="btn btn-primary px-3 pr-4 popup-send-button"
                                            >
                                                {commonService.LabelDisplayText("Submit")}
                                            </button>
                                        ) : (
                                                <button
                                                    disabled="disabled"
                                                    className="approve-leave-button green-leave-button"
                                                >
                                                    <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                </button>
                                            )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-9 add-leave-note ">
                                <div className="card student-leave-description mt-2">
                                    <textarea
                                        className={`title-textbox text-area-100px ${savestudentleavedata.errors.reason && "invalid"
                                            }`}
                                        placeholder="Enter the reason"
                                        name="reason"
                                        value={savestudentleavedata.reason}
                                        onChange={(e) => changestudentleavedata(e)}
                                    />

                                    {savestudentleavedata.errors.reason && (
                                        <span className="error-message">
                                            {savestudentleavedata.errors.reason}
                                        </span>
                                    )}
                                </div>

                            </div>
                            <div className="col-3">
                                <div className="row mt-3 start-date-portion">
                                    <div className="col-1"> </div>
                                    <div className="col-3">{commonService.LabelDisplayText("From")}</div>
                                    <div className="col-8 start-date-of-leave">
                                        <div className="leave-start-date">
                                            <DatePicker
                                                className={`datepicker ${savestudentleavedata.errors.startdate && "invalid"
                                                    }`}
                                                selected={savestudentleavedata.startdate}
                                                dateFormat={data[0].DateFormat_Term}
                                                name="startdate"
                                                onChange={(date) =>
                                                    setsavestudentleavedata({
                                                        ...savestudentleavedata,
                                                        ["startdate"]: date,
                                                    })
                                                }
                                                placeholderText={data[0].DateFormat_Term}
                                            />
                                            {savestudentleavedata.errors.startdate && (
                                                <span className="error-message">
                                                    {savestudentleavedata.errors.startdate}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("NoofDays")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <input
                                            type="number"
                                            className={`title-textbox ${savestudentleavedata.errors.numberofdays && "invalid"
                                                }`}
                                            name="numberofdays"
                                            min={1}
                                            value={savestudentleavedata.numberofdays}
                                            onChange={(e) => changestudentleavedata(e)}
                                        />
                                        {savestudentleavedata.errors.numberofdays && (
                                            <span className="error-message">
                                                {savestudentleavedata.errors.numberofdays}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("TypeofLeave")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <Select
                                            className={`${savestudentleavedata.errors.typeofleave && "invalid"
                                                }`}
                                            name="typeofleave"
                                            options={studentleavedetail}
                                            placeholder={"Select type"}
                                            onChange={(e) => changestudentleavetypedata(e)}
                                            value={studentleavedetail.find(
                                                (obj) => obj.value === savestudentleavedata.typeofleave
                                            )}
                                        />
                                        {savestudentleavedata.errors.typeofleave && (
                                            <span className="error-message">
                                                {savestudentleavedata.errors.typeofleave}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("Teacher")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <Select
                                            className={`${savestudentleavedata.errors.teacher && "invalid"
                                                }`}
                                            name="teacher"
                                            options={studentteacherleavedetail}
                                            placeholder={"Select teacher"}
                                            onChange={(e) => changestudentteacherleavetypedata(e)}
                                            value={studentteacherleavedetail.find(
                                                (obj) => obj.value === savestudentleavedata.teacher
                                            )}
                                        />
                                        {savestudentleavedata.errors.teacher && (
                                            <span className="error-message">
                                                {savestudentleavedata.errors.teacher}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-6 mt-3">{commonService.LabelDisplayText("IsPreApplication")}</div>
                                    <div className="col-5 end-date-of-leave mt-3">
                                        <input
                                            type="checkbox"
                                            name="ispreapplication"
                                            ischecked={false}
                                            onChange={(e) => changestudentleaveappdata(e)}
                                        />
                                    </div>
                                    <div className="col-1 mt-3"> </div>
                                    <div className="col-3 mt-3">{commonService.LabelDisplayText("AddPhoto")}</div>
                                    <div className="col-8 end-date-of-leave mt-3">
                                        <input
                                            type="file"
                                            className='title-textbox'
                                            accept="image/png, image/jpeg"
                                            onChange={(e) =>
                                                setsavestudentleavedata({
                                                    ...savestudentleavedata,
                                                    studentphoto: e.target.files[0],
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {data[0].MemberType.toLowerCase() === "student" ? (
                <>
                    <>
                        <Tabs
                            defaultActiveKey={"MyLeave"}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 timetable-liveclass"
                        >
                            <Tab eventKey="MyLeave" title={"My Leave"}>
                                <>
                                    <>
                                        <div className="search-top-section">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-7">
                                                    <div className="header-search-area">
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            onChange={(e) => setTxtSearch(e.target.value)}
                                                            className="ml-0"
                                                            placeholder={commonService.LabelDisplayText("SearchText")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                                                    <div className="search-right-cal d-flex align-items-center">
                                                        <div className="header-date-area w-100 mr-3">
                                                            <div className="d-flex align-items-center">
                                                                <label for="" className="from-date">
                                                                    {commonService.LabelDisplayText("From")}
                                                                </label>
                                                                <DatePicker
                                                                    className="datepicker"
                                                                    selected={txtStartDate}
                                                                    dateFormat={data[0].DateFormat_Term}
                                                                    onChange={(date) => setTxtStartDate(date)}
                                                                    placeholderText={data[0].DateFormat_Term}
                                                                />
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <label for="" className="from-date">
                                                                    {commonService.LabelDisplayText("To")}
                                                                </label>
                                                                <DatePicker
                                                                    className="datepicker"
                                                                    selected={txtEndDate}
                                                                    dateFormat={data[0].DateFormat_Term}
                                                                    onChange={(date) => setTxtEndDate(date)}
                                                                    placeholderText={data[0].DateFormat_Term}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="right-icon-cal d-flex align-items-center">
                                                            {isloading === false ? (
                                                                <div
                                                                    onClick={() => getstudentleavedata()}
                                                                    className="search-icon-img pointer-cursor"
                                                                >
                                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                                </div>
                                                            ) : (
                                                                    <div className="search-icon-img pointer-cursor">
                                                                        <Spinner
                                                                            animation="border"
                                                                            size="sm"
                                                                            variant="primary"
                                                                        />
                                                                    </div>
                                                                )}
                                                            {
                                                                commonService.getLoginRoleData("Leave Application", "IsCreate") &&
                                                                <div
                                                                    onClick={() => ToggleStudentCreateLeavePopup(true)}
                                                                    className="plus-btn pointer-cursor ml-3"
                                                                >
                                                                    <div role="group" className="dropdown btn-group">
                                                                        <i className="fa-regular fa-plus"></i>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row mt-4">
                                            {studentleavedata.isdata === true ? (
                                                <>
                                                    {
                                                        <>
                                                            {studentleavedata.data.length !== 0 ? (
                                                                <>
                                                                    {studentleavedata.data.map((a) => (
                                                                        <div className="col-xl-4 leave-status-detail mb-3">
                                                                            <div className="card leave-application">
                                                                                <div className="leave-status">
                                                                                    <div className="leave-top-col">
                                                                                        <div className="student-name-leave">
                                                                                            {/*<h3>{a.ApplicationByName}</h3>*/}
                                                                                            <span className="leaves-col">
                                                                                                {a.LeaveType_Term}
                                                                                            </span>


                                                                                        </div>
                                                                                        <div className="start-to-end-date">
                                                                                            <span class="start-date">
                                                                                                {commonService.getDateInFormat(
                                                                                                    a.StartDate
                                                                                                )}
                                                                                            </span>
                                                                                            <span class="start-to-date">
                                                                                                To
                                                                                            </span>
                                                                                            <span class="end-date">
                                                                                                {commonService.getDateInFormat(
                                                                                                    a.EndDate
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                        {a.ApplicationStatus_Term.toUpperCase() === "PENDING" ? <>
                                                                                            <div class="plus-btn-small pointer-cursor" onClick={(e) => confirmAlert({
                                                                                                title: "Delete",
                                                                                                message: "Are you sure delete ?",
                                                                                                buttons: [
                                                                                                    {
                                                                                                        label: "Yes",
                                                                                                        onClick: () =>
                                                                                                            deleteleave(a.SchoolLeaveNoteID, e),
                                                                                                    },
                                                                                                    {
                                                                                                        label: "No",
                                                                                                    },
                                                                                                ],
                                                                                            })}><i class="fa-regular fa-trash-can"></i></div>
                                                                                        </> : <></>}
                                                                                    </div>
                                                                                    <div className="leave-reason">
                                                                                        <p
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: a.ReasonForLeave,
                                                                                            }}
                                                                                        ></p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {a.ApplicationStatus_Term.toUpperCase() ===
                                                                                "APPROVED" ? (
                                                                                    <>
                                                                                        <div
                                                                                            className="leave-application-status accept-application"
                                                                                            onClick={() => StudentLeaveEdit(a)}
                                                                                        >
                                                                                            <div className="appplication-show">
                                                                                                <i className=" icon-fi-rr-check accepted-status-leave"></i>
                                                                                                <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                                            </div>
                                                                                            <div className="teacher-col">
                                                                                                <span class="icon-noun-teacher-blod"></span>{" "}
                                                                                                {a.TeacherFullName}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}

                                                                            {a.ApplicationStatus_Term.toUpperCase() ===
                                                                                "REJECTED" ? (
                                                                                    <>
                                                                                        <div
                                                                                            className="leave-application-status reject-application"
                                                                                            onClick={() => StudentLeaveEdit(a)}
                                                                                        >
                                                                                            <div className="appplication-show">
                                                                                                <i className=" icon-fi-rr-cross-small rejected-status-leave"></i>
                                                                                                <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                                            </div>
                                                                                            <div className="teacher-col">
                                                                                                <span class="icon-noun-teacher-blod"></span>{" "}
                                                                                                {a.TeacherFullName}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            {a.ApplicationStatus_Term.toUpperCase() ===
                                                                                "PENDING" ? (
                                                                                    <>
                                                                                        <div
                                                                                            className="leave-application-status pending-application"
                                                                                            onClick={() => StudentLeaveEdit(a)}
                                                                                        >
                                                                                            <div className="appplication-show">
                                                                                                <i className=" icon-fi-rr-time-quarter-to pending-status-leave"></i>
                                                                                                <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                                            </div>
                                                                                            <div className="teacher-col">
                                                                                                <span class="icon-noun-teacher-blod"></span>{" "}
                                                                                                {a.TeacherFullName}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                    <>
                                                                        <_NoDataFound fontsize={"300px"} />
                                                                    </>
                                                                )}
                                                        </>
                                                    }
                                                </>
                                            ) : (
                                                    <>
                                                        <_Skeleton
                                                            type={_SkeletonType.Table}
                                                            count={4}
                                                            lineheight={30}
                                                        />
                                                    </>
                                                )}
                                        </div>
                                    </>
                                </>
                            </Tab>
                        </Tabs>
                    </>
                </>
            ) : (
                    <></>
                )}

            {data[0].MemberType.toLowerCase() === "teacher" ? (
                <>
                    <Tabs
                        defaultActiveKey={"MyLeave"}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 timetable-liveclass"
                    >
                        <Tab eventKey="MyLeave" title={"My Leave"}>
                            <>
                                <div className="search-top-section">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-7">
                                            <div className="header-search-area">
                                                <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    onChange={(e) => setTxtSearch(e.target.value)}
                                                    className="ml-0"
                                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                                            <div className="search-right-cal d-flex align-items-center">
                                                <div className="header-date-area w-100 mr-3">
                                                    <div className="d-flex align-items-center">
                                                        <label for="" className="from-date">
                                                            {commonService.LabelDisplayText("From")}
                                                        </label>
                                                        <DatePicker
                                                            className="datepicker"
                                                            selected={txtStartDate}
                                                            dateFormat={data[0].DateFormat_Term}
                                                            onChange={(date) => setTxtStartDate(date)}
                                                            placeholderText={data[0].DateFormat_Term}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label for="" className="from-date">
                                                            {commonService.LabelDisplayText("To")}
                                                        </label>
                                                        <DatePicker
                                                            className="datepicker"
                                                            selected={txtEndDate}
                                                            dateFormat={data[0].DateFormat_Term}
                                                            onChange={(date) => setTxtEndDate(date)}
                                                            placeholderText={data[0].DateFormat_Term}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="right-icon-cal d-flex align-items-center">
                                                    {isloading === false ? (
                                                        <div
                                                            onClick={() => getteacherleavedata()}
                                                            className="search-icon-img pointer-cursor"
                                                        >
                                                            <i className="fa-regular fa-magnifying-glass"></i>
                                                        </div>
                                                    ) : (
                                                            <div className="search-icon-img pointer-cursor">
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    variant="primary"
                                                                />
                                                            </div>
                                                        )}
                                                    {
                                                        commonService.getLoginRoleData("Teacher Leave Application", "IsCreate") &&
                                                        <div
                                                            onClick={() => ToggleTeacherCreateLeavePopup()}
                                                            className="plus-btn pointer-cursor ml-3"
                                                        >
                                                            <div role="group" className="dropdown btn-group">
                                                                <i className="fa-regular fa-plus"></i>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {teacherleavedata.isdata ? (
                                    <>
                                        <>
                                            {teacherleavedata.data.length !== 0 ? (
                                                <>
                                                    <div className="row mt-4">
                                                        {teacherleavedata.data.map((a) => (
                                                            <div className="col-xl-4 leave-status-detail mb-3">
                                                                <div className="card leave-application">
                                                                    <div className="leave-status">
                                                                        <div className="leave-top-col">
                                                                            <div className="student-name-leave">
                                                                                {/*<h3>{a.EmployeeName}</h3>*/}
                                                                                <span className="leaves-col">
                                                                                    {a.LeaveDescription}
                                                                                </span>
                                                                            </div>
                                                                            <div className="start-to-end-date">
                                                                                <span class="start-date">
                                                                                    {commonService.getDateInFormat(
                                                                                        a.LeaveStartDate
                                                                                    )}
                                                                                </span>
                                                                                <span class="start-to-date">To</span>
                                                                                <span class="end-date">
                                                                                    {commonService.getDateInFormat(
                                                                                        a.LeaveEndDate
                                                                                    )}
                                                                                </span>
                                                                            </div>

                                                                            {a.LeaveStatus_Term.toUpperCase() === "PENDING" ? <>
                                                                                <div class="plus-btn-small pointer-cursor" onClick={(e) => confirmAlert({
                                                                                    title: "Delete",
                                                                                    message: "Are you sure delete ?",
                                                                                    buttons: [
                                                                                        {
                                                                                            label: "Yes",
                                                                                            onClick: () =>
                                                                                                deleteteacherleave(a.LeaveRequestID, e),
                                                                                        },
                                                                                        {
                                                                                            label: "No",
                                                                                        },
                                                                                    ],
                                                                                })}><i class="fa-regular fa-trash-can"></i></div>
                                                                            </> : <></>}
                                                                        </div>
                                                                        <div className="leave-reason">
                                                                            <p
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: a.ReasonForLeave,
                                                                                }}
                                                                            ></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {a.LeaveStatus_Term.toUpperCase() ===
                                                                    "APPROVED" ? (
                                                                        <>
                                                                            <div
                                                                                className="leave-application-status accept-application"
                                                                                onClick={() => TeacherLeaveEdit(a)}
                                                                            >
                                                                                <div className="appplication-show ">
                                                                                    <i className=" icon-fi-rr-check accepted-status-leave"></i>
                                                                                    <p>{a.LeaveStatus_Term.toUpperCase()}</p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                {a.LeaveStatus_Term.toUpperCase() ===
                                                                    "REJECTED" ||
                                                                    a.LeaveStatus_Term.toUpperCase() ===
                                                                    "CANCELLED" ? (
                                                                        <>
                                                                            <div
                                                                                className="leave-application-status reject-application"
                                                                                onClick={() => TeacherLeaveEdit(a)}
                                                                            >
                                                                                <div className="appplication-show ">
                                                                                    <i className=" icon-fi-rr-cross-small rejected-status-leave"></i>
                                                                                    <p>{a.LeaveStatus_Term.toUpperCase()}</p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                {a.LeaveStatus_Term.toUpperCase() ===
                                                                    "PENDING" ? (
                                                                        <>
                                                                            <div
                                                                                className="leave-application-status pending-application"
                                                                                onClick={() => TeacherLeaveEdit(a)}
                                                                            >
                                                                                <div className="appplication-show ">
                                                                                    <i className=" icon-fi-rr-time-quarter-to pending-status-leave"></i>
                                                                                    <p>{a.LeaveStatus_Term.toUpperCase()}</p>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                    <>
                                                        <div className="col-12">
                                                            <_NoDataFound fontsize={"300px"} />
                                                        </div>
                                                    </>
                                                )}
                                        </>
                                    </>
                                ) : (
                                        <>
                                            <_Skeleton
                                                type={_SkeletonType.Table}
                                                count={4}
                                                lineheight={30}
                                            />
                                        </>
                                    )}
                            </>
                        </Tab>
                        <Tab eventKey="StudentLeave" title={"Student Leave"}>
                            <>
                                <div className="search-top-section">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-7">
                                            <div className="header-search-area">
                                                <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    onChange={(e) => setTxtSearch(e.target.value)}
                                                    className="ml-0"
                                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                                            <div className="search-right-cal d-flex align-items-center">
                                                <div className="header-date-area w-100 mr-3">
                                                    <div className="d-flex align-items-center">
                                                        <label for="" className="from-date">
                                                            {commonService.LabelDisplayText("From")}
                                                        </label>
                                                        <DatePicker
                                                            className="datepicker"
                                                            selected={txtStartDate}
                                                            dateFormat={data[0].DateFormat_Term}
                                                            onChange={(date) => setTxtStartDate(date)}
                                                            placeholderText={data[0].DateFormat_Term}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label for="" className="from-date">
                                                            {commonService.LabelDisplayText("To")}
                                                        </label>
                                                        <DatePicker
                                                            className="datepicker"
                                                            selected={txtEndDate}
                                                            dateFormat={data[0].DateFormat_Term}
                                                            onChange={(date) => setTxtEndDate(date)}
                                                            placeholderText={data[0].DateFormat_Term}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="right-icon-cal d-flex align-items-center">
                                                    {isloading === false ? (
                                                        <div
                                                            onClick={() => getstudentleavedata()}
                                                            className="search-icon-img pointer-cursor"
                                                        >
                                                            <i className="fa-regular fa-magnifying-glass"></i>
                                                        </div>
                                                    ) : (
                                                            <div className="search-icon-img pointer-cursor">
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    variant="primary"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {studentleavedata.isdata ? (
                                    <>
                                        <div className="row mt-4">
                                            {studentleavedata.data.length !== 0 ? (
                                                <>
                                                    {studentleavedata.data.map((a) => (
                                                        <div className="col-xl-4 leave-status-detail mb-3">
                                                            <div className="card leave-application">
                                                                <div className="leave-status">
                                                                    <div className="leave-top-col">
                                                                        <div className="student-name-leave">
                                                                            <h3>{a.StudentFullName}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="leave-top-col">
                                                                        <div className="student-name-leave">
                                                                            <span className="leaves-col">
                                                                                {a.LeaveType_Term}
                                                                            </span>
                                                                        </div>
                                                                        <div className="start-to-end-date">
                                                                            <span class="start-date">
                                                                                {commonService.getDateInFormat(
                                                                                    a.StartDate
                                                                                )}
                                                                            </span>
                                                                            <span class="start-to-date">To</span>
                                                                            <span class="end-date">
                                                                                {commonService.getDateInFormat(
                                                                                    a.EndDate
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="leave-reason">
                                                                        <p
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: a.ReasonForLeave,
                                                                            }}
                                                                        ></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {a.ApplicationStatus_Term.toUpperCase() === "APPROVED" ? (
                                                                <>
                                                                    <div
                                                                        className="leave-application-status accept-application"
                                                                        onClick={() => StudentLeaveEdit(a)}
                                                                    >
                                                                        <div className="appplication-show">
                                                                            <i className=" icon-fi-rr-check accepted-status-leave"></i>
                                                                            <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                        </div>
                                                                        <div className="teacher-col">
                                                                            <span class="icon-noun-teacher-blod"></span>{" "}
                                                                            {a.TeacherFullName}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                    <></>
                                                                )}

                                                            {a.ApplicationStatus_Term.toUpperCase() === "REJECTED" ? (
                                                                <>
                                                                    <div
                                                                        className="leave-application-status reject-application"
                                                                        onClick={() => StudentLeaveEdit(a)}
                                                                    >
                                                                        <div className="appplication-show">
                                                                            <i className=" icon-fi-rr-cross-small rejected-status-leave"></i>
                                                                            <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                        </div>
                                                                        <div className="teacher-col">
                                                                            <span class="icon-noun-teacher-blod"></span>{" "}
                                                                            {a.TeacherFullName}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                    <></>
                                                                )}
                                                            {a.ApplicationStatus_Term.toUpperCase() === "PENDING" ? (
                                                                <>
                                                                    <div
                                                                        className="leave-application-status pending-application"
                                                                        onClick={() => StudentLeaveEdit(a)}
                                                                    >
                                                                        <div className="appplication-show">
                                                                            <i className=" icon-fi-rr-time-quarter-to pending-status-leave"></i>
                                                                            <p>{a.ApplicationStatus_Term.toUpperCase()}</p>
                                                                        </div>
                                                                        <div className="teacher-col">
                                                                            <span class="icon-noun-teacher-blod"></span>{" "}
                                                                            {a.TeacherFullName}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                    <></>
                                                                )}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                    <>
                                                        <_NoDataFound fontsize={"300px"} />
                                                    </>
                                                )}
                                        </div>
                                    </>
                                ) : (
                                        <>
                                            <_Skeleton
                                                type={_SkeletonType.Table}
                                                count={4}
                                                lineheight={30}
                                            />
                                        </>
                                    )}
                            </>
                        </Tab>
                    </Tabs>
                </>
            ) : (
                    <></>
                )}
        </>
    );
}

export default Leave;
