import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";


function BasicInformationView() {

    const [profile, setprofile] = useState({ IsData: false, data: [] });
    const [IsListDateLodding, setIsListDateLodding] = useState(false);

    function BindBasicInformation() {
        setIsListDateLodding(true);
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "STUDENT"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                setIsListDateLodding(false);
                if (json.status_code === 1) {
                    setprofile({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setprofile({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindBasicInformation();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {
                        profile.IsData ?
                            (
                                <>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Basic Information</h2>
                                    </div>
                                    <div className="col-lg-12 float-left mt-1">
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1 event-description">
                                                <b className="student-roll-no mt-1">Student Reg # : </b>{profile.data[0].GRNo}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Date Of Birth : </b>{commonService.getDateInFormat(profile.data[0].DateOfBirth)}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Caste : </b>{profile.data[0].Cast_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Religion : </b>{profile.data[0].Religion_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">ENR No : </b>{profile.data[0].EnrollmentNo}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Admitted Division : </b>{profile.data[0].AdmittedDivision}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Reg. Date : </b>{commonService.getDateInFormat(profile.data[0].DateOfRegistration)}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Gender : </b>{profile.data[0].GenderType_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Sub Caste : </b>{profile.data[0].SubCast_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Contact# : </b>{profile.data[0].MobileNo}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">House : </b>{profile.data[0].HouseName}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Nationality : </b>{profile.data[0].Nationality_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Category : </b>{profile.data[0].Category_Term}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Email : </b>{profile.data[0].Email}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Admitted Standard : </b>{profile.data[0].AdmittedStandard}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                </>
                            )
                    }
                    {
                        profile.IsData ?
                            (
                                <>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Address Information</h2>
                                    </div>
                                    <div className="col-lg-12 float-left mt-1">
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Address : </b>{profile.data[0].Add1}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Pincode : </b>{profile.data[0].PinCode}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">City : </b>{profile.data[0].City}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">State : </b>{profile.data[0].State}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Country : </b>{profile.data[0].Country}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">LandmarkName : </b>{profile.data[0].LandmarkName}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Longitude : </b>{profile.data[0].Longitude}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Latitude : </b>{profile.data[0].Latitude}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                </>
                            )
                    }
                    {
                        profile.IsData ?
                            (
                                <>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Previous School Details</h2>
                                    </div>
                                    <div className="col-lg-12 float-left mt-1">
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">School Name : </b>{profile.data[0].LastSchool}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Previous School Result : </b>{profile.data[0].PreviousSchoolResult}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Previous School Total Working Days: </b>{profile.data[0].PreviousSchool_TotalWorkingDays}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Reason For Leaving : </b>{profile.data[0].ReasonForLeavingLastSchool}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">GR/Scholar No. # : </b>{profile.data[0].PreviousSchoolGRNo}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Previous School Percentage : </b>{profile.data[0].PreviousSchoolPercentage}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Previous School Tota Present Days : </b>{profile.data[0].PreviousSchool_TotaPresentDays}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Date Of Leaving : </b>{commonService.getDateInFormat(profile.data[0].LastSchoolDateOfLeaving)}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Current Standard Prev School : </b>{profile.data[0].CurrentStandardPrevSchool}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                </>
                            )
                    }
                </div>
            </div>
        </>
    );
}

export default BasicInformationView;
