import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Spinner from "react-bootstrap/Spinner";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { alertService, AlertType } from "../_services/alert.service";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

function ComplainLogBook() {
    const history = useHistory();
    const [complainlogbooklist, setcomplainlogbooklist] = useState({ IsData: false, data: [] });
    const [category, setcategory] = useState([]);
    const [Type, setType] = useState([]);
    const [Item, setItem] = useState([]);
    const [vendor, setvendor] = useState([]);
    const [txtcategory, settxtcategory] = useState(null);
    const [txttype, settxttype] = useState(null);
    const [txtitem, settxtitem] = useState(null);
    const [txtstatus, settxtstatus] = useState(null);
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [status, setstatus] = useState([]);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtvendor, settxtvendor] = useState("");
    const [IsListDateLodding, setIsListDateLodding] = useState(false);
    const [showFileSelectPopup, setShowFileSelectPopup] = useState(false);
    const [TxtSearch, setTxtSearch] = useState(null);
    const [txtcomment, settxtcomment] = useState(null);
    const [AttachFiles, setAttachFiles] = useState([]);
    const [CommentFiles, setCommentFiles] = useState([]);
    const [Image, setImage] = useState([]);
    const [complainLogDetails, setcomplainLogDetails] = useState({ IsData: false, data: [] });
    const [complainTaskBookDetail, setcomplainTaskBookDetail] = useState({ IsData: false, data: [] });
    const [handleShowDetailpopup, sethandleShowDetailpopup] = useState(false);
    const [complainlogstatus, setcomplainlogstatus] = useState(null);
    const [editorDetails, seteditorDetails] = useState(() =>
        EditorState.createEmpty()
    );
    const [complainlogbook, setcomplainlogbook] = useState({
        ComplainLogBookID: "00000000-0000-0000-0000-000000000000",
        MaintenanceTypeID: "00000000-0000-0000-0000-000000000000",
        ItemId: "",
        Title: "",
        Details: "",
        MaintenanceCategoryID: "00000000-0000-0000-0000-000000000000",
        imgPath: [],
        Status: "",
        AssignTo: "00000000-0000-0000-0000-000000000000",
        createdBy: "00000000-0000-0000-0000-000000000000",
        RequestFrom: "web",
        errors: {
            title: "",
            MaintenanceCategoryID: "",
        },
    });

    let data = commonService.getLoginUserData();

    function BindComplainLogBooks() {
        setIsListDateLodding(true);
        setcomplainlogbooklist({ IsData: false, data: [] });
        let StartDate, EndDate = null;

        if (commonService.getDateInDBFormat(txtStartDate) !== "" &&
            commonService.getDateInDBFormat(txtStartDate) !== null &&
            commonService.getDateInDBFormat(txtStartDate) !== "undefined") {
            StartDate = commonService.getDateInDBFormat(txtStartDate);
        }
        if (commonService.getDateInDBFormat(txtEndDate) !== "" &&
            commonService.getDateInDBFormat(txtEndDate) !== null &&
            commonService.getDateInDBFormat(txtEndDate) !== "undefined") {
            EndDate = commonService.getDateInDBFormat(txtEndDate);
        }

        const reqTask = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                Category: txtcategory,
                Status: txtstatus,
                Searchtext: TxtSearch,
                FromDate: StartDate,
                ToDate: EndDate,
                sRow: null,
                noOfData: null,
                RequestFrom: "Web",
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/ComplainLogBook/getlistbymember", reqTask)
            .then((response) => response.json())
            .then(function (json) {
                setIsListDateLodding(false);
                if (json.status_code === 1) {
                    setcomplainlogbooklist({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setcomplainlogbooklist({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function handleShowAddEditPopup(ID) {
        
        setShowAddEditPopup(true);
        GetMaintenanceCategory();
        GetMaintenanceType();
        GetItem();

        if (ID !== "") {
            const req = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    ID: ID,
                    RequestFrom: "web"
                })
            };
            fetch(AppConfigData.APIBasePath + "v1/ComplainLogBook/getalldetailsbyid", req)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        
                        let image = [];
                        let image1 = [];
                        if (json.data.filePath !== null && json.data.filePath !== "" && json.data.filePath !== "undefined") {
                            image = json.data.filePath.split(",");
                            for (let i = 0; i < image.length; i++) {
                                if (image[i] !== "") {
                                    image1.push({ Path: image[i], IsNewFile: false, file: [] });
                                }
                            }
                        }

                        setcomplainlogbook({
                            ComplainLogBookID: json.data.complainLogBookID,
                            MaintenanceTypeID: json.data.maintenanceTypeID,
                            ItemId: json.data.itemId,
                            Title: json.data.title,
                            MaintenanceCategoryID: json.data.maintenanceCategoryID,
                            imgPath: image1,
                            Status: json.data.status,
                            AssignTo: json.data.assignTo,
                            createdBy: json.data.createBy,
                            errors: {
                                title: "",
                                maintenanceCategoryID: "",
                            }
                        });

                        seteditorDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.details)
                                        .contentBlocks
                                )
                            )
                        );
                    }
                })
        }
        else {

            setcomplainlogbook({
                ComplainLogBookID: "00000000-0000-0000-0000-000000000000",
                MaintenanceTypeID: "00000000-0000-0000-0000-000000000000",
                ItemId: "00000000-0000-0000-0000-000000000000",
                Title: "",
                MaintenanceCategoryID: "00000000-0000-0000-0000-000000000000",
                imgPath: [],
                Status: "",
                AssignTo: "00000000-0000-0000-0000-000000000000",
                createdBy: "00000000-0000-0000-0000-000000000000",
                errors: {
                    title: "",
                    maintenanceCategoryID: "",
                },
            });
            seteditorDetails(EditorState.createEmpty());
        }
    }

    function AddFileSelectInFilesDetails() {
        if (AttachFiles != null) {
            let FilesDetails = [];
            if (complainlogbook.imgPath !== null) {
                if (complainlogbook.imgPath.length > 0) {
                    for (let i = 0; i < complainlogbook.imgPath.length; i++) {
                        FilesDetails.push(complainlogbook.imgPath[i]);
                    }
                }
            }

            for (let i = 0; i < AttachFiles.length; i++) {
                if (AttachFiles[i].name !== null && AttachFiles[i].name !== "") {
                    FilesDetails.push({ Path: AttachFiles[i].name, IsNewFile: true, file: AttachFiles[i] });
                }
            }
            setcomplainlogbook({ ...complainlogbook, ["imgPath"]: FilesDetails });
            setShowFileSelectPopup(false);
        }
    }

    function GetComplainStatus() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                multiCategory: "TaskStatus",
                requestFrom: "web"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/home/get_projectterm_ComplainTaskStatus", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].Term,
                            label: `${json.data[i].Term}`,
                        });
                    }
                    setstatus(options);
                } else if (json.status_code === 0) {
                    setstatus([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function GetMaintenanceCategory() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/complainlogbook/getmaintanancecategory", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].MaintenanceCategoryID,
                            label: `${json.data[i].CategoryName}`,
                        });
                    }
                    setcategory(options);
                }
                else if (json.status_code === 0) {
                    setcategory([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function GetMaintenanceType() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                multiCategory: "MaintananceType",
                requestFrom: "web"
            })
        };

        fetch(AppConfigData.APIBasePath + "v1/home/get_projectterm_selectbymulticategory", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].TermID,
                            label: `${json.data[i].Term}`,
                        });
                    }
                    setType(options);
                }
                else if (json.status_code === 0) {
                    setType([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function GetItem() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/complainlogbook/getitemlist", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].ItemID,
                            label: `${json.data[i].ItemName}`,
                        });
                    }
                    setItem(options);
                }
                else if (json.status_code === 0) {
                    setItem([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function CreateFilesDetailsIU(FilesDetail, indexFile) {
        let Icon = "";
        let FileType = "File";
        let FilesID = "00000000-0000-0000-0000-000000000000";
        if (!FilesDetail.IsNewFile) {
            FilesID = "00000000-0000-0000-0000-000000000011";
        }

        if (FilesDetail != null) {
            if (FilesDetail.Path != "") {
                if (FilesDetail.Path.split(".").pop().toUpperCase() !== "") {
                    if (FilesDetail.Path.split(".").pop().toUpperCase() === "JPEG" || FilesDetail.Path.split(".").pop().toUpperCase() === "JPG" || FilesDetail.Path.split(".").pop().toUpperCase() === "PNG") {
                        Icon = (<i className={`file-list-icon ${AppConfigData.fileIcon["IMAGE"]}`}></i>)
                        FileType = "IMAGE"
                    }
                }
            }
        }
        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromClassworkAddPopup({
                        FilesID: FilesID,
                        Name: "",
                        FilePath: FilesDetail.Path,
                        OrderNo: indexFile,
                        FileType: FileType,
                        FileMimeType: "FILE",
                        RequestFrom: "Web",
                    })}>
                </div>
            </div>);
    }

    function ViewFileFromClassworkAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        }
        else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function AddComplainLogBook() {
        if (Validate()) {
            let formData = new FormData();

            formData.append("ComplainLogBookID", complainlogbook.ComplainLogBookID);
            formData.append("MaintenanceTypeID", complainlogbook.MaintenanceTypeID);
            formData.append("ItemId", complainlogbook.ItemId);
            formData.append("Title", complainlogbook.Title);
            formData.append("MaintenanceCategoryID", complainlogbook.MaintenanceCategoryID);
            formData.append("RequestFrom", "web");
            formData.append("Details", draftToHtml(convertToRaw(editorDetails.getCurrentContent())));

            for (let i = 0; i < complainlogbook.imgPath.length; i++) {
                if (complainlogbook.imgPath[i].IsNewFile) {
                    formData.append("FormFiles", complainlogbook.imgPath[i].file);
                }
            }

            const reqAddEditTask = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };

            fetch(AppConfigData.APIBasePath + "v1/complainlogbook/save", reqAddEditTask)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindComplainLogBooks();
                        commonService.sendNotification();
                        setShowAddEditPopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                })
        }
    }

    function fnDeletecomplain(ID) {
        if (ID !== "00000000-0000-0000-0000-000000000000" && ID !== "") {
            const reqTask = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/complainlogbook/deletebyid", reqTask)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindComplainLogBooks();
                        setShowAddEditPopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function ViewComplainDetails(ID) {
        GetVendorList();
        GetComplainStatus();
        if (ID !== "00000000-0000-0000-0000-000000000000" && ID !== "") {
            const req = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };

            fetch(AppConfigData.APIBasePath + "v1/complainlogbook/getcomplainviewdetails", req)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        let image = [];
                        setcomplainLogDetails({ IsData: true, data: json.data.complainLogDetails[0] });
                        setcomplainTaskBookDetail({ IsData: true, data: json.data.complainTaskBookDetail });
                        setcomplainlogstatus(json.data.complainLogDetails[0].Status);
                        if (json.data.complainLogDetails[0].FilePath !== "" && json.data.complainLogDetails[0].FilePath !== null) {
                            image = json.data.complainLogDetails[0].FilePath.split(',');
                        }
                        setImage(image);
                    }
                    sethandleShowDetailpopup(true);
                })
        }
    }

    function GetVendorList() {
        
        const req = {
            method: "POST",
            headers: commonService.getHeaders()
        };

        fetch(AppConfigData.APIBasePath + "v1/complainlogbook/getvendorlist", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].VendorID,
                            label: `${json.data[i].Name}`,
                        });
                    }
                    setvendor(options);
                }
                else if (json.status_code === 0) {
                    setvendor([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function SaveComment(ID) {
        
        if (txtcomment) {
            let formData = new FormData();
            let Files = [];

            formData.append("ID", ID);
            formData.append("Status", complainlogstatus);
            formData.append("VendorID", txtvendor);
            formData.append("Comment", txtcomment);

            if (CommentFiles !== "" && CommentFiles.length !== 0) {
                for (let i = 0; i < CommentFiles.length; i++) {
                    if (CommentFiles[i].name !== null && CommentFiles[i].name !== "") {
                        if (CommentFiles[i].name.split('.').pop().toUpperCase() === "JPG" || CommentFiles[i].name.split('.').pop().toUpperCase() === "JPEG" || CommentFiles[i].name.split('.').pop().toUpperCase() === "PNG") {
                            formData.append("Files", CommentFiles[i]);
                        }
                    }
                }
            }

            const reqcomment = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };

            fetch(AppConfigData.APIBasePath + "v1/complainlogbook/savecomment", reqcomment)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });

                        BindComplainLogBooks();
                        ViewComplainDetails(ID);
                        settxtcomment("");
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                })
        }
    }

    function Validate() {
        let isValid = true;
        let errors = {};

        if (!complainlogbook.Title) {
            isValid = false;
            errors.title = "Title is Required.";
        }
        else {
            errors.title = "";
        }

        if (!complainlogbook.MaintenanceCategoryID || complainlogbook.MaintenanceCategoryID === "00000000-0000-0000-0000-000000000000") {
            isValid = false;
            errors.maintenanceCategoryID = "Category is Required.";
        }
        else {
            errors.maintenanceCategoryID = "";
        }

        setcomplainlogbook({
            ...complainlogbook,
            ["errors"]: errors,
        });

        return isValid;
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Building Maintenance", "IsView")) {
            BindComplainLogBooks();
            GetComplainStatus();
        } else {
            history.push("/access");
        }
    }, []);

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("ComplainLogBook")}</h1>
            <div className="background-theme-color sticky-top">
                <div className="search-top-section">
                    <div className="row">
                        <div className="col-lg-12 col-xl-4">
                            <div className="header-search-area">
                                <input
                                    type="text"
                                    className=""
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    onChange={(e) => setTxtSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-3 my-3 my-xl-0">
                            <div className="status-select">
                                <Select
                                    placeholder="Select Status"
                                    options={status}
                                    onChange={(e) => settxtstatus(e.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-5">
                            <div className="search-right-cal d-flex align-items-center">
                                <div className="header-date-area w-100 mr-3">

                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerFrom")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtStartDate(date)}
                                            placeholderText="dd-MM-yyyy"
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerTo")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtEndDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtEndDate(date)}
                                            placeholderText="dd-MM-yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="right-icon-cal d-flex align-items-center">
                                    {IsListDateLodding === false ? (
                                        <div
                                            className="search-icon-img pointer-cursor"
                                            onClick={() => BindComplainLogBooks()}
                                        >
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </div>
                                    ) : (
                                        <div className="search-icon-img pointer-cursor">
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    )}
                                    {commonService.getLoginRoleData("Building Maintenance", "IsCreate") && (
                                        <div className="plus-btn pointer-cursor ml-3" onClick={() => handleShowAddEditPopup("")}>
                                            <i className="fa-regular fa-plus"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {complainlogbooklist.IsData ?
                    (
                        <Table className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th className="text-center w-75px">#</th>
                                    <th>Title</th>
                                    <th>Complain Date</th>
                                    <th>Maintenance Categories</th>
                                    <th>Item</th>
                                    <th>Status</th>
                                    <th className="w-150px">Complete Date</th>
                                    <th></th>
                                    <th className="w-100px">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    complainlogbooklist.data.map((item, index) => (
                                        <tr>
                                            <td className="text-center">{index + 1}</td>
                                            <td><span className="click-popup" onClick={() => ViewComplainDetails(item.ComplainLogBookID)}>{item.Title}</span></td>
                                            <td className="w-150px">{commonService.getDateInFormat(item.CreateOn)}</td>
                                            <td className="w-200px">{item.CategoryName}</td>
                                            <td>{item.ItemName}</td>
                                            <td>{item.Status}</td>
                                            <td>{commonService.getDateInFormat(item.CompleteOn)}</td>
                                            <td>{item.CreateAssingLable}</td>
                                            <td>
                                                <span className="d-flex align-items-center justify-content-center">
                                                    {item.CreateBy === data[0].UserID && (item.CreateAssingLable.toUpperCase() === "CREATED BY YOU"
                                                        && commonService.getLoginRoleData("Building Maintenance", "IsEdit") &&
                                                        (
                                                            <button className="mr-3" onClick={() => handleShowAddEditPopup(item.ComplainLogBookID)}>
                                                                <i class="fa-regular fa-pencil"></i>
                                                            </button>
                                                        )
                                                    )}
                                                    {item.CreateBy === data[0].UserID && (item.CreateAssingLable.toUpperCase() === "CREATED BY YOU"
                                                        && commonService.getLoginRoleData("Building Maintenance", "IsDelete") &&
                                                        (
                                                            <button className="" onClick={() => fnDeletecomplain(item.ComplainLogBookID)}>
                                                                <i class="fa-regular fa-trash-can"></i>
                                                            </button>
                                                        )
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    ) : (
                        <>
                            <_Skeleton type={_SkeletonType.Table} lineheight={30}  />
                        </>
                    )}
            </div>

            <Modal
                show={showFileSelectPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowFileSelectPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="file"
                                className="form-control"
                                accept="image/png, image/jpeg"
                                multiple
                                onChange={(e) => setAttachFiles(e.target.files)}
                            />
                            only image file will upload and other file will skip
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={AddFileSelectInFilesDetails}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showAddEditPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowAddEditPopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowAddEditPopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("AddComplain")}
                                    </h1>
                                    <button
                                        className="btn btn-primary px-3 pr-4 mb-3"
                                        type="button"
                                        onClick={AddComplainLogBook}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 date-section-area">
                                <input
                                    type="text"
                                    className="mt-4 title-textbox"
                                    placeholder="Title"
                                    defaultValue={complainlogbook.Title}
                                    onChange={(e) => setcomplainlogbook({
                                        ...complainlogbook,
                                        ["Title"]: e.target.value
                                    })}
                                />

                                {complainlogbook.errors.title && (<span className="error-message">
                                    {complainlogbook.errors.title}
                                </span>)}
                                <div className="rich-txt-editor-area mt-3">
                                    <Editor
                                        toolbar={AppConfigData.toolbarConfig}
                                        editorState={editorDetails}
                                        toolbar={AppConfigData.toolbarConfig}
                                        onEditorStateChange={seteditorDetails}
                                    />
                                </div>
                                <DropdownButton
                                    title="Add"
                                    className="mt-3"
                                    variant=" text-editor"
                                >
                                    <Dropdown.Item
                                        eventKey="1"
                                        className="dropdown-item" onClick={() => setShowFileSelectPopup(true)}
                                    >
                                        <i className="fa-regular fa-paperclip"></i> {commonService.LabelDisplayText("File")}
                                    </Dropdown.Item>
                                </DropdownButton>
                                <div>
                                    <div className="col-12 added-popup-data">
                                        {complainlogbook.imgPath.map((itemFile, indexFile) => (
                                            <div
                                                className="card homework-page-card mt-3"
                                                key={indexFile}
                                            >
                                                {CreateFilesDetailsIU(itemFile, indexFile)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label"> Category</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="d-flex">
                                            <div className="selectSubject-popup w-100">
                                                <Select
                                                    placeholder="Select Category"
                                                    options={category}
                                                    value={category.find(
                                                        (obj) => obj.value === complainlogbook.MaintenanceCategoryID
                                                    )}
                                                    onChange={(e) => setcomplainlogbook({
                                                        ...complainlogbook,
                                                        ["MaintenanceCategoryID"]: e.value
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        {complainlogbook.errors.maintenanceCategoryID && (<span className="error-message">
                                            {complainlogbook.errors.maintenanceCategoryID}
                                        </span>)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label"> Type</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <Select
                                            placeholder="Select Type"
                                            options={Type}
                                            value={Type.find(
                                                (obj) => obj.value === complainlogbook.MaintenanceTypeID
                                            )}
                                            onChange={(e) => setcomplainlogbook({
                                                ...complainlogbook,
                                                ["MaintenanceTypeID"]: e.value
                                            })}
                                        />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label"> Item</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <Select
                                            placeholder="Select Item"
                                            options={Item}
                                            value={Item.find(
                                                (obj) => obj.value === complainlogbook.ItemId
                                            )}
                                            onChange={(e) => setcomplainlogbook({
                                                ...complainlogbook,
                                                ["ItemId"]: e.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={handleShowDetailpopup}
                backdrop="static"
                keyboard={false}
                onHide={() => sethandleShowDetailpopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => sethandleShowDetailpopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {complainLogDetails.data.Title}
                                    </h1>
                                    <button
                                        className="btn btn-primary px-3 pr-4 mb-3"
                                        type="button"
                                        onClick={() => SaveComment(complainLogDetails.data.ComplainLogBookID)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 date-section-area">
                                <div className="upload-files mt-3">
                                    <label className="home-work-label"><b>Details</b></label>
                                    <div className="pl-2">
                                        <p
                                            className="card-body-content mt-2"
                                            dangerouslySetInnerHTML={{ __html: complainLogDetails.data.Details }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="upload-files mt-3">
                                    <label className="home-work-label"><b>Files</b></label>
                                    <div className="items-file d-flex flex-wrap">
                                        {Image.map((file) =>
                                        (file !== "" ?
                                            (file.split(".").pop().toUpperCase() === "PDF" ?
                                                (<a href={file} target="_blank"><div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["PDF"]}`}> </div></a>)
                                                : file.split(".").pop().toUpperCase() === "JPG" || file.split(".").pop().toUpperCase() === "JPEG" || file.split(".").pop().toUpperCase() === "PNG" ?
                                                    (<div className="item-file" > <a href={file} target="_blank"><img src={file} alt="" /></a></div>)
                                                    : (<a href={file} target="_blank"><div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["FILE"]}`}> </div></a>)
                                            )
                                            : ""
                                        )
                                        )}
                                    </div>
                                </div>
                                <div className="task-log-col mt-2">
                                    <label className="home-work-label">
                                        <b>Task Log Details</b></label>
                                    <div className="task-logs mt-1">
                                        {complainTaskBookDetail.IsData ? (
                                            complainTaskBookDetail.data.map((item, index) =>
                                                <div className="task-log">
                                                    <div className="top-col">
                                                        <div className="remark-date">
                                                            <b>Create / Assing Date :</b> {commonService.getDateInFormat(item.CreateOn)}
                                                        </div>
                                                        <div className="remark-by">
                                                            <b>Created By :</b> {item.createby}
                                                        </div>
                                                        <div className="top-col-status">
                                                            <b>Assing To :</b> {item.AssingTo}
                                                        </div>
                                                        <div className="top-col-status">
                                                            <b>Status  :</b> {item.Status}
                                                        </div>
                                                    </div>
                                                    <div className="task-log-comment mt-1">
                                                        <b>Comment :</b> {item.Comment}
                                                    </div>
                                                </div>
                                            )
                                        ) : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Status :</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <Select
                                            options={status}
                                            value={status.find((obj) => obj.value === complainlogstatus)}
                                            onChange={(e) => setcomplainlogstatus(e.value)}
                                        />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Image</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => setCommentFiles(e.target.files)}
                                        />
                                        only image file will upload
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Vendor</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <Select
                                            options={vendor}
                                            onChange={(e) => settxtvendor(e.value)}
                                        />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Type :</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {complainLogDetails.data.maintenancetype}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Created On :</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {commonService.getDateInFormat(complainLogDetails.data.CreateOn)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Created By :</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {complainLogDetails.data.createby}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2">
                                        <label className="home-work-label">Add Comment</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <textarea
                                            className="title-textbox"
                                            style={{ height: "100px" }}
                                            value={txtcomment}
                                            defaultValue={""}
                                            onChange={(e) => settxtcomment(e.target.value)}
                                        />
                                        {!txtcomment && (<span className="error-message">
                                            Comment is Required.
                                        </span>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ComplainLogBook;
