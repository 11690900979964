import React from "react";
import { ReactComponent as NoDataFound } from "../../src/Images/Icon/no-record-found.svg";
import { ReactComponent as NoDataFoundWhite } from "../../src/Images/Icon/no-record-found-white.svg";

export const _NoDataFoundType = {
  cloud: "cloud",
  pageandbinocular: "pageandbinocular",
};

function _NoDataFound(prop) {
  let height = prop.height || "100%";
  let width = prop.width || "100%";
  //   let fontsize = prop.fontsize || "60px";
  let color = prop.color || "#000000";
  let type = prop.type || "pageandbinocular";

  return (
    <div
      style={{
        height: height,
        width: width,
        // fontSize: fontsize,
        color: color,
        textAlign: "center",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <NoDataFound />
      </div>
    </div>
  );
}
export default _NoDataFound;
