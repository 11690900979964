import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { alertService, AlertType } from "../_services/alert.service";
import { commonService, AppConfigData } from "../_services/common.service";
import DarkModeToggle from "react-dark-mode-toggle";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Alert from 'react-bootstrap/Alert';

function SwitshStud() {
    const history = useHistory();
    const [feesdata, setfeesdata] = useState({
        FeesName: "",
        ClientID: "00000000-0000-0000-0000-000000000000",
        InstituteID: "00000000-0000-0000-0000-000000000000",
        Amount: 0,
        MemberAppPaymentID: "00000000-0000-0000-0000-000000000000",
        studentName: "",
        mobileNo: "",
        batchName: "",
        paymentByName: "",
        accesstoken: ""

    });
    const [feespopup, setfeespopup] = useState(false)
    const [IsPaying, setIsPaying] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => false);

    const [f_code, setf_code] = useState("");
    const [mmp_txn, setmmp_txn] = useState("");
    const [bank_txn, setbank_txn] = useState("");

    document.getElementById("main").classList.add("login");
    document.getElementById("main").classList.remove("menu-open");
    document.getElementById("main").style.marginLeft = null;
    let data = [];
    let dataStr = commonService.getItem("switch");
    if (dataStr !== "") {
        data = JSON.parse(dataStr);
    }
    let user = commonService.getItem("user");
    function checkuserdata(input) {
        const requestMetadata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify(input),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/user/checkuserlogindatabyuserid",
            requestMetadata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    if (json.fees !== undefined && json.fees !== null && Object.keys(json.fees).length !== 0) {
                        setfeesdata({
                            FeesName: json.fees.FeesName,
                            ClientID: json.fees.ClientID,
                            InstituteID: json.fees.InstituteID,
                            Amount: json.fees.Amount,
                            MemberAppPaymentID: json.fees.MemberAppPaymentID,
                            studentName: json.data[0].FullName,
                            mobileNo: json.data[0].UserName,
                            batchName: json.data[0].BatchName,
                            paymentByName: json.data[0].DisplayName,
                            accesstoken: json.accesstoken
                        })
                        setfeespopup(true);
                    }
                    else {
                        commonService.setItem("home", JSON.stringify(json.data));
                        commonService.setItem("accesstoken", json.accesstoken);
                        commonService.setItem("user", input.userID);
                        commonService.setItem("roledata", JSON.stringify(json.roledata));
                        commonService.setItem("fees", JSON.stringify(json.fees));
                        history.push("home");
                    }
                } else {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                }
            });
    }

    function PayNowClick() {


        setIsPaying(true);
        const req = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                accept: "*/*",
                Authorization: "Bearer " + feesdata.accesstoken,
            },
            body: JSON.stringify({
                studentName: feesdata.studentName,
                mobileNo: feesdata.mobileNo,
                batchName: feesdata.batchName,
                paymentByName: feesdata.paymentByName,
                requestFrom: "web",

            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/dashboard/creatememberpaymenturl", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    if (json.data !== null) {
                        if (json.data !== "") {
                            window.location.href = json.data;
                        }
                    }
                    setIsPaying(false);
                }
                else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                } else {
                    setIsPaying(false);
                }
            });
    }





    React.useEffect(() => {
        applyInitialTheme();
        setf_code("")
        setmmp_txn("")
        setbank_txn("")
        if (history !== null && history !== undefined) {
            if (history.location !== null && history.location !== undefined) {
                if (history.location.search !== null && history.location.search !== undefined && history.location.search !== "") {
                    const queryParams = new URLSearchParams(history.location.search);
                    setf_code(queryParams.get("f_code"))
                    setmmp_txn(queryParams.get("mmp_txn"))
                    setbank_txn(queryParams.get("bank_txn"))
                }
            }
        }
    }, []);

    function ChangeThemeMode(e) {
        setIsDarkMode(e);
        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.hasAttribute("data-theme")) {
            htmlTag.removeAttribute("data-theme");
            return window.localStorage.removeItem("site-theme");
        }
        htmlTag.setAttribute("data-theme", "dark");
        window.localStorage.setItem("site-theme", "dark");
    }
    function applyInitialTheme() {
        let theme = window.localStorage.getItem("site-theme");
        if (theme === null) {
            theme = "";
        }
        if (theme === "null") {
            theme = "";
        }
        if (theme !== null && theme !== "") {
            if (theme === "dark") {
                setIsDarkMode(true);
            }
            const htmlTag = document.getElementsByTagName("html")[0];
            htmlTag.setAttribute("data-theme", theme);
        }
    }
    function logout() {
        const theme = window.localStorage.getItem("site-theme");
        localStorage.clear();
        window.localStorage.setItem("site-theme", theme);
        history.push("/");
    }
    return (
        <>
            <div className="d-flex justify-content-end">
                <DarkModeToggle
                    onChange={(e) => ChangeThemeMode(e)}
                    checked={isDarkMode}
                    size={50}
                    className="dark-theme-toggle ml-2"
                />
            </div>
            <div className="login-main-container">
                {
                    f_code !== null && f_code !== "" && f_code !== undefined &&
                    <>
                        {
                            f_code.toUpperCase() === "SUCCESS" ?
                                <Alert key={"success"} variant={"success"} className="switch-pay-massage">
                                    Your Payment Is Success Now You Can Able To Login.
                                    {
                                        mmp_txn !== null && mmp_txn !== "" && mmp_txn !== undefined &&
                                        <><br></br>Transaction ID : {mmp_txn}</>
                                    }

                                    {
                                        bank_txn !== null && bank_txn !== "" && bank_txn !== undefined &&
                                        <><br></br>Bank Transaction ID : {bank_txn}</>
                                    }
                                </Alert>
                                :
                                <Alert key={"danger"} variant={"danger"} className="switch-pay-massage">
                                    Your Payment Is Fail Please Try After Some Time.
                                    {
                                        mmp_txn !== null && mmp_txn !== "" && mmp_txn !== undefined &&
                                        <><br></br>Transaction ID : {mmp_txn}</>
                                    }

                                    {
                                        bank_txn !== null && bank_txn !== "" && bank_txn !== undefined &&
                                        <><br></br>Bank Transaction ID : {bank_txn}</>
                                    }

                                </Alert>
                        }
                    </>
                }
                <div className="log-in-form">
                    <div className="login-main-area switchaccount">
                        <h5 className="text-center switchaccount-header">{commonService.LabelDisplayText("SwitchAccount")} <i className="fa-light fa-power-off float-right mt-2 pointer-cursor" title="Logout" onClick={logout}></i></h5>
                        <div className="body-area">
                            <div className="three-grid-section">
                                {data.map((item, index) => (
                                    <a
                                        key={index}
                                        onClick={() =>
                                            checkuserdata({
                                                userID: item.UserID,
                                                userType_Term: item.UserType_Term,
                                                memberID: item.MemberID,
                                                instituteID: item.InstituteID,
                                                clientID: item.ClientID,
                                                ctoken: item.SQLConStr,
                                                loginFrom: "Web",
                                                isCheckUserData: false,
                                                requestFrom: "Web",
                                            })
                                        }
                                    >
                                        <div
                                            className="switchaccount-select-user mt-4"
                                        >
                                            <div
                                                className={`image-area-popup ${item.UserID === user ? "active" : ""
                                                    }`}
                                                style={{
                                                    backgroundColor: commonService.CreateColor(
                                                        item.Initials
                                                    ).BGColor,
                                                    color: commonService.CreateColor(item.Initials)
                                                        .FontColor,
                                                }}
                                            >
                                                {item.Avatar !== "" ? (
                                                    <img
                                                        src={AppConfigData.ERPFileBasePath + item.Avatar}
                                                    />
                                                ) : (
                                                    item.Initials
                                                )}
                                            </div>
                                            <div className="text-area-popup">
                                                <p className="name-popup" title={item.MemberName.toUpperCase()}> {item.MemberName}</p>
                                                <p className="school-popup" title={item.InstituteName.toUpperCase()}>
                                                    <i className="fa-regular fa-school"></i>
                                                    <span>{item.InstituteName}</span>
                                                </p>
                                                <p className="role-popup"> {item.UserType_Term}</p>
                                            </div>
                                        </div>
                                    </a>
                                ))}

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <Modal
                show={feespopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setfeespopup(false)}
                className=""
                contentClassName={""}

            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("PaymentDetails")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setfeespopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">

                            <>
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>

                                        <th className="text-left">{commonService.LabelDisplayText("Name")}</th>
                                        <th className="text-right w-130px">{commonService.LabelDisplayText("Payable")}</th>

                                    </thead>
                                    <tbody>

                                        <tr>


                                            <td className="text-left">{feesdata.FeesName}</td>
                                            <td className="text-right">{feesdata.Amount}</td>

                                        </tr>

                                    </tbody>
                                </Table>
                            </>

                        </div>
                        {/* <div className="col-12 mt-3">
                            <b>{commonService.LabelDisplayText("YourUnpaidAmountIs")} {PayFeesPara.UnpaidAmount}</b>
                        </div>
                        <div className="col-12">
                            <input
                                type="number"
                                placeholder="Enter Amount"
                                onChange={(e) => checkval(e)}
                                disabled={PayFeesPara.IsTakeFullFeesByOnline}
                                value={PayFeesPara.UserAmount}
                                min={0}
                                max={PayFeesPara.UnpaidAmount}
                                className={`mt-4 title-textbox ${PayFeesPara.errors && "invalid"
                                    }`}
                            />
                            {PayFeesPara.errors && (
                                <span className="error-message">{PayFeesPara.errors}</span>
                            )}
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setfeespopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    {IsPaying === false ? (
                        <button className="post-select-btn" onClick={() => PayNowClick()}>
                            {commonService.LabelDisplayText("PayNow")}
                        </button>
                    ) : (
                        <button
                            className="post-select-btn"
                            type="button"
                            disabled="disabled"
                        >
                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Wait")}
                        </button>
                    )}
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default SwitshStud;
