import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Editor } from "react-draft-wysiwyg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Newimgs from "../Images/main-bg-image/lms-home-card.png";
import Spinner from "react-bootstrap/Spinner";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { alertService, AlertType } from "../_services/alert.service";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import { pathToFileURL } from "url";
import { isValid } from "date-fns/esm";
const path = require('path');

function ToDoTask() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [showLinkPopup, setShowLinkPopup] = useState(false);
    const [showYoutubeLinkPopup, setShowYoutubeLinkPopup] = useState(false);
    const [showFileSelectPopup, setShowFileSelectPopup] = useState(false);
    const [showTaskCategoryPopup, setShowTaskCategoryPopup] = useState(false);
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [showDoYourWorkPopup, handleShowDoYourWorkPopup] = useState(false);
    const [editorTaskDetails, seteditorTaskDetails] = useState(() =>
        EditorState.createEmpty()
    );
    const [IsListDateLodding, setIsListDateLodding] = useState(false);

    const [IsViewMyTodo, setIsViewMyTodo] = useState(false);
    const [IsViewTodo, setIsViewTodo] = useState(false);

    const [statusOption, setStatusOption] = useState([{ value: "Not Started", label: "Not Started" }, { value: "Started", label: "Started" }, { value: "Completed", label: "Completed" }]);
    const [priorityOption, setPriorityOption] = useState([{ value: "Normal", label: "Normal" }, { value: "Low", label: "Low" }, { value: "High", label: "High" }]);
    const [recurringType, setrecurringType] = useState([{ value: "Daily", label: "Daily" }, { value: "Single", label: "Single" }]);
    const [txtSearch, setTxtSearch] = useState("");
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStatus, settxtStatus] = useState("");
    const [Task, setTask] = useState({ IsData: false, data: [] })
    const [txtEmployee, setTxtEmployee] = useState("00000000-0000-0000-0000-000000000000");
    const [EmployeeList, setEmployeeList] = useState([]);
    const [AllEmployeeList, setAllEmployeeList] = useState([]);
    const [Department, setDepartment] = useState([]);
    const [TaskCategory, setTaskCategory] = useState([]);
    const [taskDetails, settaskDetails] = useState({ IsData: false, data: [] });
    const [taskComment, settaskComment] = useState({ IsData: false, data: [] });
    const [taskdetailImg, settaskdetailImg] = useState([]);
    const [taskDetailstatus, settaskDetailstatus] = useState("");
    const [taskDetailscomment, settaskDetailscomment] = useState(null);
    const [tabStatus, settabStatus] = useState(null);
    const [TaskData, setTaskData] = useState({
        TaskID: "00000000-0000-0000-0000-000000000000",
        refTaskID: "00000000-0000-0000-0000-000000000000",
        taskCategoryID: "00000000-0000-0000-0000-000000000000",
        createdBy: "00000000-0000-0000-0000-000000000000",
        assignedTo: "",
        departmentID: "00000000-0000-0000-0000-000000000000",
        priority_Term: "",
        status_Term: "",
        title: "",
        taskDetail: "",
        recurringType_Term: "",
        lastDate: "",
        taskDate: "",
        completedOn: "",
        imgPath: [],
        RequestFrom: "Web",
        errors: {
            assignedTo: "",
            departmentID: "",
            taskCategoryID: "",
            priority_Term: "",
            status_Term: "",
            title: "",
            recurringType_Term: "",
            lastDate: "",
            taskDate: "",
        },
    });

    const [AttachFiles, setAttachFiles] = useState([]);

    function BindTask() {
        setIsListDateLodding(true);
        setTask({ IsData: false, data: [] });
        const reqTask = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                status: txtStatus,
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: null,
                noOfData: null,
                requestFrom: "Web",
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/mytask/getlistbymember", reqTask)
            .then((response) => response.json())
            .then(function (json) {
                setIsListDateLodding(false);
                if (json.status_code === 1) {
                    setTask({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setTask({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function handleShowAddEditPopup(ID) {
        GetEmployee();
        GetTaskCategory();
        if (ID !== "") {
            const reqTask = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web"
                })
            };
            fetch(AppConfigData.APIBasePath + "v1/mytask/getalldetailsbyid", reqTask)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        let image = [];
                        let image1 = [];
                        if (json.data.imgPath !== null && json.data.imgPath !== "") {
                            image = json.data.imgPath.split(",");
                            for (let i = 0; i < image.length; i++) {
                                if (image[i] !== "") {
                                    image1.push({ Path: image[i], IsNewFile: false, file: [] });
                                }
                            }
                        }
                        setTaskData({
                            TaskID: json.data.taskID,
                            refTaskID: json.data.refTaskID,
                            taskCategoryID: json.data.taskCategoryID,
                            createdBy: json.data.createdBy,
                            assignedTo: json.data.assignedTo,
                            departmentID: json.data.departmentID,
                            priority_Term: json.data.priority_Term,
                            status_Term: json.data.status_Term,
                            title: json.data.title,
                            recurringType_Term: json.data.recurringType_Term,
                            lastDate: new Date(json.data.lastDate),
                            taskDate: new Date(json.data.taskDate),
                            completedOn: json.data.CompletedOn,
                            imgPath: image1,
                            errors: {
                                assignedTo: "",
                                departmentID: "",
                                taskCategoryID: "",
                                priority_Term: "",
                                status_Term: "",
                                title: "",
                                recurringType_Term: "",
                                lastDate: "",
                                taskDate: "",
                            },
                        });

                        seteditorTaskDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.taskDetail)
                                        .contentBlocks
                                )
                            )
                        );
                    }
                })
            setShowAddEditPopup(true);
        }
        else {
            setTaskData({
                TaskID: "00000000-0000-0000-0000-000000000000",
                refTaskID: "00000000-0000-0000-0000-000000000000",
                taskCategoryID: "00000000-0000-0000-0000-000000000000",
                createdBy: "00000000-0000-0000-0000-000000000000",
                assignedTo: (tabStatus === "ToDo Task") ? "00000000-0000-0000-0000-000000000000" : data[0].MemberID,
                departmentID: (tabStatus === "ToDo Task") ? "00000000-0000-0000-0000-000000000000" : data[0].DepartmentID,
                priority_Term: "",
                status_Term: "",
                title: "",
                taskDetail: "",
                recurringType_Term: "",
                lastDate: "",
                taskDate: "",
                completedOn: "",
                imgPath: [],
                RequestFrom: "Web",
                errors: {
                    assignedTo: "",
                    departmentID: "",
                    taskCategoryID: "",
                    priority_Term: "",
                    status_Term: "",
                    title: "",
                    recurringType_Term: "",
                    lastDate: "",
                    taskDate: "",
                },
            });
            seteditorTaskDetails(EditorState.createEmpty());
            setShowAddEditPopup(true);
        }
    }

    function GetEmployee() {
        const reqEmployee = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/home/getemployees", reqEmployee)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    let uniqueDept = [];
                    let department = [];
                    setAllEmployeeList(json);

                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].TeacherID,
                            label: json.data[i].FullName,
                        });
                    }
                    if (json.data.length !== 0) {
                        json.data.map(uni => {
                            if (uniqueDept.indexOf(uni.DepartmentName) === -1) {
                                uniqueDept.push(uni.DepartmentName);
                                department.push({
                                    value: uni.DepartmentID,
                                    label: uni.DepartmentName,
                                });
                            }
                        });
                    }
                    setDepartment(department);
                    setEmployeeList(options);
                } else if (json.status_code === 0) {
                    setDepartment([]);
                    setEmployeeList([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function DepartmentSelectionChange(data) {
        setTaskData({
            ...TaskData,
            ["departmentID"]: data.value,
        });
        let options = [{ label: "All", value: "*" }];
        if (data.length != 0) {
            for (let i = 0; i < AllEmployeeList.data.length; i++) {
                if (data.value === AllEmployeeList.data[i].DepartmentID) {
                    options.push({
                        value: AllEmployeeList.data[i].TeacherID,
                        label: AllEmployeeList.data[i].FullName,
                    });
                }
            }
            setEmployeeList(options);
        }
    }

    function getDropdownButtonLabel({ value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    function GetTaskCategory() {
        const reqTaskCategory = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/mytask/getcategorylist", reqTaskCategory)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];

                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].TaskCategoryID,
                            label: json.data[i].CategoryName,
                        });
                    }
                    setTaskCategory(options);
                } else if (json.status_code === 0) {
                    setTaskCategory([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            })
    }

    function AddEditTask() {
        if (validate()) {
            let formData = new FormData();
            let Assigned = [];
            if (TaskData.assignedTo !== null && TaskData.assignedTo !== "") {
                let assign = TaskData.assignedTo.replace("*,", "")
                Assigned = assign.split(",");
            }
            formData.append("TaskID", TaskData.TaskID);
            formData.append("TaskCategoryID", TaskData.taskCategoryID);
            formData.append("DepartmentID", TaskData.departmentID);
            formData.append("Priority_Term", TaskData.priority_Term);
            formData.append("Status_Term", TaskData.status_Term);
            formData.append("Title", TaskData.title);
            formData.append("TaskDetail", draftToHtml(convertToRaw(editorTaskDetails.getCurrentContent())));
            formData.append("RecurringType_Term", TaskData.recurringType_Term);
            formData.append("LastDate", commonService.getDateTimeInDBFormat(TaskData.lastDate));
            formData.append("TaskDate", commonService.getDateTimeInDBFormat(TaskData.taskDate));
            formData.append("RequestFrom", "Web");

            for (let i = 0; i < Assigned.length; i++) {
                formData.append("AssignedTo", Assigned[i]);
            }

            for (let i = 0; i < TaskData.imgPath.length; i++) {
                if (TaskData.imgPath[i].IsNewFile) {
                    formData.append("FormFiles", TaskData.imgPath[i].file);
                }
            }

            const reqAddEditTask = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };

            fetch(AppConfigData.APIBasePath + "v1/mytask/save", reqAddEditTask)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindTask();
                        commonService.sendNotification();
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                })
        }
    }

    function SaveComment(ID) {
        if (taskDetailscomment) {
            let formData = new FormData();

            formData.append("TaskID", ID);
            formData.append("Status_Term", taskDetailstatus);
            formData.append("Comment", taskDetailscomment);

            const reqcomment = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };

            fetch(AppConfigData.APIBasePath + "v1/mytask/savecomment", reqcomment)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });

                        BindTask();
                        ViewTaskDetails(ID);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                })
        }
    }
    function AddFileSelectInFilesDetails() {

        if (AttachFiles !== null) {
            let FilesDetails = [];
            if (TaskData.imgPath !== null) {
                if (TaskData.imgPath.length > 0) {
                    for (let i = 0; i < TaskData.imgPath.length; i++) {
                        FilesDetails.push(TaskData.imgPath[i]);
                    }
                }
            }

            for (let i = 0; i < AttachFiles.length; i++) {
                if (AttachFiles[i].name !== null && AttachFiles[i].name !== "") {
                    FilesDetails.push({ Path: AttachFiles[i].name, IsNewFile: true, file: AttachFiles[i] });
                }
            }
            setTaskData({ ...TaskData, ["imgPath"]: FilesDetails });
            setShowFileSelectPopup(false);
        }
    }

    function handleSelect(key) {
        if (key === "My ToDo Task")
            settabStatus("My ToDo Task");
        else
            settabStatus("ToDo Task");
    }

    function CreateFilesDetailsIU(FilesDetail, indexFile) {
        let Icon = "";
        let FileType = "File";
        let FilesID = "00000000-0000-0000-0000-000000000000";
        if (!FilesDetail.IsNewFile) {
            FilesID = "00000000-0000-0000-0000-000000000011"
        }
        if (FilesDetail.Path.split(".").pop().toUpperCase() !== "") {
            if (FilesDetail.Path.split(".").pop().toUpperCase() === "JPG" || FilesDetail.Path.split(".").pop().toUpperCase() === "JPEG" || FilesDetail.Path.split(".").pop().toUpperCase() === "PNG") {
                Icon = (<i className={`file-list-icon ${AppConfigData.fileIcon["IMAGE"]}`}></i>)
                //<img src={FilesDetail.Path} className="file-list-icon" />;

                FileType = "IMAGE"
            } else if (FilesDetail.Path.split(".").pop().toUpperCase() === "PDF") {
                Icon = (
                    <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
                );
                FileType = "PDF"
            }
            else {
                Icon = (
                    <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
                );
                FileType = "FILE"
            }


        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
            FileType = "FILE"
        }


        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromClassworkAddPopup({
                        FilesID: FilesID,
                        Name: "",
                        FilePath: FilesDetail.Path,
                        OrderNo: indexFile,
                        FileType: FileType,
                        FileMimeType: "FILE",
                        RequestFrom: "Web",
                    })}
                >

                </div>

            </div>
        );
    }

    function ViewFileFromClassworkAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function StudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setTaskData({
                ...TaskData, assignedTo: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setTaskData({
                ...TaskData, assignedTo: ""
            })
        } else if (event.action === "deselect-option") {
            setTaskData({
                ...TaskData, assignedTo: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setTaskData({
                ...TaskData, assignedTo: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setTaskData({
                ...TaskData, assignedTo: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })

        }
    }

    function fnDeleteTask(ID) {
        if (ID !== "00000000-0000-0000-0000-000000000000" && ID !== "") {
            const reqTask = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/mytask/deletebyid", reqTask)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindTask();
                        setShowAddEditPopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function ViewTaskDetails(ID) {
        const reqTask = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: ID,
                requestFrom: "web",
            })
        };
        fetch(AppConfigData.APIBasePath + "v1/mytask/gettaskviewdetails", reqTask)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let image1 = [];
                    settaskDetails({ IsData: true, data: json.data.taskDetails[0], });
                    settaskComment({ IsData: true, data: json.data.taskComment, });
                    settaskDetailstatus(json.data.taskDetails[0].Status_Term);
                    settaskDetailscomment("");
                    if (json.data.taskDetails[0].Imgpath !== "" && json.data.taskDetails[0].Imgpath !== null) {
                        image1 = json.data.taskDetails[0].Imgpath.split(",");
                    }
                    settaskdetailImg(image1);
                }
            });
        handleShowDoYourWorkPopup(true);
    }

    function validate() {
        let isValid = true;
        let errors = {};
        if (!TaskData.title) {
            errors.title = "Title is Required.";
            isValid = false;
        } else {
            errors.title = "";
        }

        if (!TaskData.assignedTo || TaskData.assignedTo === "00000000-0000-0000-0000-000000000000") {
            errors.assignedTo = "Employee is Required.";
            isValid = false;
        } else {
            errors.assignedTo = "";
        }

        if (!TaskData.departmentID || TaskData.departmentID === "00000000-0000-0000-0000-000000000000") {
            errors.departmentID = "Department is Required.";
            isValid = false;
        } else {
            errors.departmentID = "";
        }

        if (!TaskData.priority_Term) {
            errors.priority_Term = "Priority is Required.";
            isValid = false;
        } else {
            errors.priority_Term = "";
        }

        if (!TaskData.status_Term) {
            errors.status_Term = "Status is Required.";
            isValid = false;
        } else {
            errors.status_Term = "";
        }

        if (!TaskData.recurringType_Term) {
            errors.recurringType_Term = "Recurring Type is Required.";
            isValid = false;
        } else {
            errors.recurringType_Term = "";
        }

        if (!TaskData.taskCategoryID || TaskData.taskCategoryID === "00000000-0000-0000-0000-000000000000") {
            errors.taskCategoryID = "Category is Required.";
            isValid = false;
        } else {
            errors.taskCategoryID = "";
        }

        if (!TaskData.taskDate) {
            errors.taskDate = "Task Date is Required.";
            isValid = false;
        } else {
            errors.taskDate = "";
        }

        if (!TaskData.lastDate) {
            errors.lastDate = "Last Date is Required.";
            isValid = false;
        } else {
            errors.lastDate = "";
        }

        setTaskData({
            ...TaskData,
            ["errors"]: errors,
        });

        return isValid;
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("My To Do", "IsView") && commonService.getLoginRoleData("To Do", "IsView")) {
            setIsViewMyTodo(commonService.getLoginRoleData("My To Do", "IsView"))
            setIsViewTodo(commonService.getLoginRoleData("To Do", "IsView"))
            BindTask();
        }
        else {
            history.push("/access");
        }
    }, []);

    return (
        <div>
            <h1 className="page-header my-4">ToDo Task</h1>
            <div className="background-theme-color sticky-top">

                <Tabs
                    onSelect={handleSelect}
                    transition={false}
                    id="uncontrolled-tab-example"
                    className="mb-3 timetable-liveclass nav nav-tabs"
                >
                    {IsViewMyTodo &&
                        <Tab eventKey="My ToDo Task" title="My ToDo Task">
                            <div className="search-top-section">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-4">
                                        <div className="header-search-area">
                                            <input
                                                type="text"
                                                className=""
                                                placeholder={commonService.LabelDisplayText("SearchText")}
                                                onChange={(e) => setTxtSearch(e.target.value)}
                                                value={txtSearch}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-3 my-3 my-xl-0">
                                        <div className="status-select">
                                            <Select
                                                placeholder={"Select Status"}
                                                options={statusOption}
                                                value={statusOption.find(
                                                    (obj) =>
                                                        obj.value === txtStatus

                                                )}
                                                onChange={(e) => settxtStatus(e.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-5">
                                        <div className="search-right-cal d-flex align-items-center">
                                            <div className="header-date-area w-100 mr-3">
                                                <div className="d-flex align-items-center">
                                                    <label className="from-date">
                                                        {commonService.LabelDisplayText("DatepickerFrom")}
                                                    </label>
                                                    <DatePicker
                                                        className="datepicker"
                                                        selected={txtStartDate}
                                                        dateFormat={data[0].DateFormat_Term}
                                                        onChange={(date) => setTxtStartDate(date)}
                                                        placeholderText="dd-MM-yyyy"
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <label className="from-date">
                                                        {commonService.LabelDisplayText("DatepickerTo")}
                                                    </label>
                                                    <DatePicker
                                                        className="datepicker"
                                                        selected={txtEndDate}
                                                        dateFormat={data[0].DateFormat_Term}
                                                        onChange={(date) => setTxtEndDate(date)}
                                                        placeholderText="dd-MM-yyyy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="right-icon-cal d-flex align-items-center">
                                                {IsListDateLodding === false ? (
                                                    <div
                                                        className="search-icon-img pointer-cursor"
                                                        onClick={() => BindTask()}
                                                    >
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </div>
                                                ) : (
                                                    <div className="search-icon-img pointer-cursor">
                                                        <Spinner animation="border" size="sm" variant="primary" />
                                                    </div>
                                                )}
                                                {commonService.getLoginRoleData("My To Do", "IsCreate") && (
                                                    <div className="plus-btn pointer-cursor ml-3" onClick={() => handleShowAddEditPopup("")}>
                                                        <i className="fa-regular fa-plus"></i>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search-result-block">
                                <span>
                                    Result For
                                    <span>From : To :</span>
                                </span>
                            </div>

                            {Task.IsData ? (
                                <>
                                    <Table className="fees-payment-table m-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center w-75px">#</th>
                                                <th>Task</th>
                                                <th className="w-150px">{commonService.LabelDisplayText("TaskDate")}</th>
                                                <th className="w-150px">Complete Date</th>
                                                <th className="w-150px">Employee</th>
                                                <th className="w-150px">Created By</th>
                                                <th className="w-100px">Status</th>
                                                <th className="w-100px">Priority</th>
                                                <th className="text-center w-100px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Task.data.map((item, index) => (item.AssignedTo === data[0].MemberID ? (

                                                <tr>
                                                    <td className="text-center">{item.RowNo}</td>
                                                    <td><span className="click-popup" onClick={() => ViewTaskDetails(item.TaskID)}>{item.Title}</span></td>
                                                    <td>{commonService.getDateInFormat(item.TaskDate)}</td>
                                                    <td>{commonService.getDateInFormat(item.CompletedOn)}</td>
                                                    <td>{item.AssignedToName}</td>
                                                    <td>{item.CreatedByName}</td>
                                                    <td>
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            {item.Status_Term === "Not Started" ? (
                                                                <i className="fa-duotone fa-spinner-third fees-status-pending align-items-center"></i>
                                                            ) : <></>}
                                                            {item.Status_Term === "Started" ? (
                                                                <i className="fa-regular fa-circle-exclamation fees-status-error"></i>
                                                            ) : <></>}
                                                            {item.Status_Term === "Completed" ? (
                                                                <i className="fa-regular fa-circle-check fees-status-success"></i>
                                                            ) : <></>}
                                                        </span>
                                                    </td>
                                                    <td>{item.Priority_Term}</td>
                                                    <td>
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            {
                                                                item.CreatedBy === data[0].UserID && commonService.getLoginRoleData("My To Do", "ISEDIT") &&
                                                                (<button className="mr-3"
                                                                    onClick={
                                                                        commonService.getLoginRoleData("My To Do", "ISEDIT") &&
                                                                        (() => handleShowAddEditPopup(item.TaskID))
                                                                    }
                                                                >
                                                                    <i class="fa-regular fa-pencil"></i>
                                                                </button>)}
                                                            {
                                                                item.CreatedBy === data[0].UserID && commonService.getLoginRoleData("My To Do", "ISDELETE") &&
                                                                (<button className=""
                                                                    onClick={
                                                                        commonService.getLoginRoleData("My To Do", "ISDELETE") &&
                                                                        (() => fnDeleteTask(item.TaskID))
                                                                    }
                                                                >
                                                                    <i class="fa-regular fa-trash-can"></i>
                                                                </button>)}
                                                        </span>
                                                    </td>
                                                </tr>) : null)

                                            )}
                                        </tbody>
                                    </Table>
                                </>
                            ) : (
                                <_Skeleton type={_SkeletonType.Table} lineheight={30} />
                            )}
                        </Tab>}

                    {IsViewTodo && <Tab eventKey="ToDo Task" title="ToDo Task">
                        <div className="search-top-section">
                            <div className="row">
                                <div className="col-lg-12 col-xl-4">
                                    <div className="header-search-area">
                                        <input
                                            type="text"
                                            className=""
                                            placeholder={commonService.LabelDisplayText("SearchText")}
                                            onChange={(e) => setTxtSearch(e.target.value)}
                                            value={txtSearch}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-3 my-3 my-xl-0">
                                    <div className="status-select">
                                        <Select
                                            placeholder={"Select Status"}
                                            options={statusOption}
                                            selected={txtStatus}
                                            onChange={(e) => settxtStatus(e.value)}
                                            value={statusOption.find(
                                                (obj) =>
                                                    obj.value === txtStatus
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-5">
                                    <div className="search-right-cal d-flex align-items-center">
                                        <div className="header-date-area w-100 mr-3">
                                            <div className="d-flex align-items-center">
                                                <label className="from-date">
                                                    {commonService.LabelDisplayText("DatepickerFrom")}
                                                </label>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={txtStartDate}
                                                    dateFormat={data[0].DateFormat_Term}
                                                    onChange={(date) => setTxtStartDate(date)}
                                                    placeholderText="dd-MM-yyyy"
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label className="from-date">
                                                    {commonService.LabelDisplayText("DatepickerTo")}
                                                </label>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={txtEndDate}
                                                    dateFormat={data[0].DateFormat_Term}
                                                    onChange={(date) => setTxtEndDate(date)}
                                                    placeholderText="dd-MM-yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="right-icon-cal d-flex align-items-center">
                                            {IsListDateLodding === false ? (
                                                <div
                                                    className="search-icon-img pointer-cursor"
                                                    onClick={() => BindTask()}
                                                >
                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                </div>
                                            ) : (
                                                <div className="search-icon-img pointer-cursor">
                                                    <Spinner animation="border" size="sm" variant="primary" />
                                                </div>
                                            )}
                                            {commonService.getLoginRoleData("To Do", "IsCreate") && (
                                                <div className="plus-btn pointer-cursor ml-3" onClick={() => handleShowAddEditPopup("")}>
                                                    <i className="fa-regular fa-plus"></i>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search-result-block">
                            <span>
                                Result For
                                <span>From : To : </span>
                            </span>
                        </div>
                        {Task.IsData ? (
                            <>
                                <Table className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center w-75px">#</th>
                                            <th>Task</th>
                                            <th className="w-150px">{commonService.LabelDisplayText("TaskDate")}</th>
                                            <th className="w-150px">Complete Date</th>
                                            <th className="w-150px">Employee</th>
                                            <th className="w-150px">Created By</th>
                                            <th className="w-100px">Status</th>
                                            <th className="w-100px">Priority</th>
                                            <th className="text-center w-100px">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Task.data.map((item, index) => (item.AssignedTo !== data[0].MemberID ? (<tr>
                                            <td className="text-center">{item.RowNo}</td>
                                            <td><span className="click-popup" onClick={() => ViewTaskDetails(item.TaskID)}>{item.Title}</span></td>
                                            <td>{commonService.getDateInFormat(item.TaskDate)}</td>
                                            <td>{commonService.getDateInFormat(item.CompletedOn)}</td>
                                            <td>{item.AssignedToName}</td>
                                            <td>{item.CreatedByName}</td>
                                            <td>
                                                <span className="d-flex align-items-center justify-content-center">
                                                    {item.Status_Term === "Not Started" ? (
                                                        <i className="fa-duotone fa-spinner-third fees-status-pending align-items-center"></i>
                                                    ) : <></>}
                                                    {item.Status_Term === "Started" ? (
                                                        <i className="fa-regular fa-circle-exclamation fees-status-error"></i>
                                                    ) : <></>}
                                                    {item.Status_Term === "Completed" ? (
                                                        <i className="fa-regular fa-circle-exclamation fees-status-error"></i>
                                                    ) : <></>}
                                                </span>
                                            </td>
                                            <td>{item.Priority_Term}</td>
                                            <td>
                                                <span className="d-flex align-items-center justify-content-center">
                                                    {
                                                        item.CreatedBy === data[0].UserID && commonService.getLoginRoleData("To Do", "ISEDIT") &&
                                                        (<button className="mr-3"
                                                            onClick={
                                                                commonService.getLoginRoleData("To Do", "ISEDIT") &&
                                                                (() => handleShowAddEditPopup(item.TaskID))
                                                            }
                                                        >
                                                            <i class="fa-regular fa-pencil"></i>
                                                        </button>)}
                                                    {
                                                        item.CreatedBy === data[0].UserID && commonService.getLoginRoleData("To Do", "ISDELETE") &&
                                                        (<button className=""
                                                            onClick={
                                                                commonService.getLoginRoleData("To Do", "ISDELETE") &&
                                                                (() => fnDeleteTask(item.TaskID))
                                                            }
                                                        >
                                                            <i class="fa-regular fa-trash-can"></i>
                                                        </button>)}
                                                </span>
                                            </td>
                                        </tr>) : null)

                                        )}
                                    </tbody>
                                </Table>
                            </>
                        ) : (
                            <_Skeleton type={_SkeletonType.Table} />
                        )}
                    </Tab>}

                </Tabs>
            </div>

            <Modal
                show={showAddEditPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowAddEditPopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowAddEditPopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        ToDo Task
                                    </h1>
                                    <button
                                        className="btn btn-primary px-3 pr-4 mb-3"
                                        type="button"
                                        onClick={AddEditTask}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 date-section-area">
                                <input
                                    type="text"
                                    className="mt-4 title-textbox"
                                    placeholder="Task Name"
                                    defaultValue={TaskData.title}
                                    onChange={(e) => setTaskData({
                                        ...TaskData,
                                        ["title"]: e.target.value
                                    })}
                                />
                                {TaskData.errors.title && (<span className="error-message">
                                    {TaskData.errors.title}
                                </span>)
                                }
                                <div className="rich-txt-editor-area mt-3">
                                    <Editor
                                        toolbarHidden={
                                            data[0].MemberType.toLowerCase() === "student"
                                        }
                                        readOnly={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "readOnly"
                                        }
                                        editorState={editorTaskDetails}
                                        toolbar={AppConfigData.toolbarConfig}
                                        onEditorStateChange={seteditorTaskDetails}
                                    />
                                </div>
                                {TaskData.errors.taskDetail && (<span className="error-message">
                                    {TaskData.errors.taskDetail}
                                </span>)}
                                <DropdownButton
                                    title="Add"
                                    className="mt-3"
                                    variant=" text-editor"
                                >
                                    <Dropdown.Item
                                        eventKey="1"
                                        className="dropdown-item" onClick={() => setShowFileSelectPopup(true)}
                                    >
                                        <i className="fa-regular fa-paperclip"></i> {commonService.LabelDisplayText("File")}
                                    </Dropdown.Item>
                                </DropdownButton>
                                <div>
                                    <div className="col-12 added-popup-data">
                                        {
                                            TaskData.imgPath.map((itemFile, indexFile) => (
                                                <div
                                                    className="card homework-page-card mt-3"
                                                    key={indexFile}
                                                >
                                                    {CreateFilesDetailsIU(itemFile, indexFile)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 answer-section homework-popup-section">
                                <div className="row mt-4">

                                    {(tabStatus === "ToDo Task") ? (<>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Department</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                            <div className="selectSubject-popup">
                                                <Select placeholder="Select Department"
                                                    options={Department}
                                                    onChange={DepartmentSelectionChange}
                                                    value={Department.find(
                                                        (obj) =>
                                                            obj.value ===
                                                            TaskData.departmentID)}

                                                    isDisabled={TaskData.TaskID === "00000000-0000-0000-0000-000000000000" ? "" : "true"}
                                                />
                                            </div>
                                            {TaskData.errors.departmentID && (<span className="error-message">
                                                {TaskData.errors.departmentID}
                                            </span>)}
                                        </div></>) : <></>}

                                    {(tabStatus === "ToDo Task") ? (<>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Employee Name</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                            <div className={`ReactMultiSelectCheckboxes  select-student-dropdown`}>
                                                <ReactMultiSelectCheckboxes
                                                    width={"100%"}
                                                    placeholderButtonLabel={"Select Name"}
                                                    options={EmployeeList}
                                                    isMulti
                                                    onChange={StudentSelectionChange}
                                                    getDropdownButtonLabel={getDropdownButtonLabel}
                                                    closeMenuOnSelect={false}
                                                    isDisabled={TaskData.TaskID === "00000000-0000-0000-0000-000000000000" ? "" : "true"}
                                                    value={EmployeeList.filter(
                                                        (obj) =>

                                                            TaskData.assignedTo.split(",").includes(obj.value))}

                                                />
                                            </div>
                                            {TaskData.errors.assignedTo && (<span className="error-message">
                                                {TaskData.errors.assignedTo}
                                            </span>)}
                                        </div></>) : <></>}
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Task Category</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="d-flex">
                                            <div className="selectSubject-popup w-100">
                                                <Select placeholder={"Select Category"}
                                                    options={TaskCategory}
                                                    value={TaskCategory.find(
                                                        (obj) => obj.value === TaskData.taskCategoryID
                                                    )}
                                                    onChange={(e) => setTaskData({
                                                        ...TaskData,
                                                        ["taskCategoryID"]: e.value
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        {TaskData.errors.taskCategoryID && (<span className="error-message">
                                            {TaskData.errors.taskCategoryID}
                                        </span>)}
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Priority</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            <Select placeholder={"Select Priority"}
                                                options={priorityOption}
                                                value={priorityOption.find(
                                                    (obj) => obj.value === TaskData.priority_Term
                                                )}
                                                onChange={(e) => setTaskData({
                                                    ...TaskData,
                                                    ["priority_Term"]: e.value
                                                })}
                                            />
                                        </div>
                                        {TaskData.errors.priority_Term && (<span className="error-message">
                                            {TaskData.errors.priority_Term}
                                        </span>)}
                                    </div>

                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Status</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            <Select placeholder={"Select Status"}
                                                options={statusOption}
                                                value={statusOption.find(
                                                    (obj) => obj.value === TaskData.status_Term
                                                )}
                                                onChange={(e) => setTaskData({
                                                    ...TaskData,
                                                    ["status_Term"]: e.value
                                                })} />
                                        </div>
                                        {TaskData.errors.status_Term && (<span className="error-message">
                                            {TaskData.errors.status_Term}
                                        </span>)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Recurring Type</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            <Select placeholder={"Select Type"}
                                                options={recurringType}
                                                value={recurringType.find(
                                                    (obj) => obj.value === TaskData.recurringType_Term
                                                )}
                                                onChange={(e) => setTaskData({
                                                    ...TaskData,
                                                    ["recurringType_Term"]: e.value
                                                })}
                                                isDisabled={TaskData.TaskID === "00000000-0000-0000-0000-000000000000" ? "" : "true"}
                                            />
                                        </div>
                                        {TaskData.errors.recurringType_Term && (<span className="error-message">
                                            {TaskData.errors.recurringType_Term}
                                        </span>)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Task Date</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            <DatePicker
                                                className="datepicker title-textbox"
                                                placeholderText="Select Date"
                                                selected={TaskData.taskDate}
                                                dateFormat={data[0].DateFormat_Term}
                                                onChange={(date) => setTaskData({
                                                    ...TaskData,
                                                    ["taskDate"]: date
                                                })}
                                                isDisabled={TaskData.TaskID === "00000000-0000-0000-0000-000000000000" ? "" : "true"}
                                            />
                                        </div>
                                        {TaskData.errors.taskDate && (<span className="error-message">
                                            {TaskData.errors.taskDate}
                                        </span>)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Finish Date</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            <DatePicker
                                                className="datepicker title-textbox"
                                                placeholderText="Select Date"
                                                selected={TaskData.lastDate}
                                                dateFormat={data[0].DateFormat_Term}
                                                onChange={(date) => setTaskData({
                                                    ...TaskData,
                                                    ["lastDate"]: date
                                                })}
                                                isDisabled={TaskData.TaskID === "00000000-0000-0000-0000-000000000000" ? "" : "true"}
                                            />
                                        </div>
                                        {TaskData.errors.lastDate && (<span className="error-message">
                                            {TaskData.errors.lastDate}
                                        </span>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Do Your Work Popup */}
            <Modal
                show={showDoYourWorkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => handleShowDoYourWorkPopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => handleShowDoYourWorkPopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {taskDetails.data.Title}
                                    </h1>
                                    <button
                                        className="btn btn-primary px-3 pr-4 mb-3"
                                        type="button"
                                        onClick={() => SaveComment(taskDetails.data.TaskID)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 date-section-area">
                                <div className="upload-files mt-3">
                                    <label className="home-work-label"><b>Details</b></label>
                                    <div className="pl-2">
                                        <p
                                            className="card-body-content mt-2"
                                            dangerouslySetInnerHTML={{ __html: taskDetails.data.TaskDetail }}
                                        ></p>
                                    </div>
                                </div>
                                <div className="upload-files mt-3">
                                    <label className="home-work-label"><b>Files</b></label>
                                    <div className="items-file d-flex flex-wrap">
                                        {taskdetailImg.map((file) =>
                                        (file !== "" ?
                                            (file.split(".").pop().toUpperCase() === "PDF" ?
                                                (<a href={file} target="_blank"><div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["PDF"]}`}> </div></a>)
                                                : file.split(".").pop().toUpperCase() === "JPG" || file.split(".").pop().toUpperCase() === "JPEG" || file.split(".").pop().toUpperCase() === "PNG" ?
                                                    (<div className="item-file" > <a href={file} target="_blank"><img src={file} alt="" /></a></div>)
                                                    : (<a href={file} target="_blank"><div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["FILE"]}`}> </div></a>)
                                            )
                                            : ""
                                        )
                                        )}
                                    </div>
                                </div>
                                <div className="task-log-col mt-2">
                                    <label className="home-work-label">
                                        <b>Task Log Details</b></label>
                                    <div className="task-logs mt-1">
                                        {taskComment.IsData ? (
                                            taskComment.data.map((item, index) =>
                                                <div className="task-log">
                                                    <div className="top-col">
                                                        <div className="remark-date">
                                                            <b>Remark Date :</b> {commonService.getDateInFormat(item.RemarkOn)}
                                                        </div>
                                                        <div className="remark-by">
                                                            <b>Remark By :</b> {item.DisplayName}
                                                        </div>
                                                        <div className="top-col-status">
                                                            <b>Status :</b> {item.TaskStatus_Term}
                                                        </div>
                                                    </div>
                                                    <div className="task-log-comment mt-1">
                                                        <b>Comment :</b> {item.Remark}
                                                    </div>
                                                </div>
                                            )
                                        ) : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Created On</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {commonService.getDateInFormat(taskDetails.data.CreatedOn)}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Created By</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {taskDetails.data.CreatedBy}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Assign To</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        {taskDetails.data.Employee}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">Status</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <div className="selectSubject-popup">
                                            {taskDetails.data.AssignedTo === data[0].MemberID ? (<Select placeholder={"Select Status"}
                                                options={statusOption}
                                                value={statusOption.find((obj) => obj.value === taskDetailstatus)}
                                                onChange={(e) => settaskDetailstatus(e.value)}
                                            />) : taskDetails.data.Status_Term}
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4 mb-2">
                                        <label className="home-work-label">Add Comment</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-7 mb-2 select-student-dropdown">
                                        <textarea
                                            className="title-textbox"
                                            style={{ height: "100px" }}
                                            value={taskDetailscomment}
                                            defaultValue={""}
                                            onChange={(e) => settaskDetailscomment(e.target.value)}
                                        />
                                        {!taskDetailscomment && (<span className="error-message">
                                            Comment is Required.
                                        </span>)
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Do Your Work Popup */}


            <Modal
                show={showFileSelectPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowFileSelectPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="file"
                                className="form-control"
                                multiple
                                onChange={(e) => setAttachFiles(e.target.files)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={AddFileSelectInFilesDetails}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showTaskCategoryPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowTaskCategoryPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>Task Category</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowTaskCategoryPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                className="form-control mb-2"
                                placeholder="Name"
                            />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="title-textbox"
                                style={{ height: "100px" }}
                                placeholder="Detail"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowTaskCategoryPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                    >
                        {commonService.LabelDisplayText("Save")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ToDoTask;
