import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useHistory } from "react-router-dom";
import { viewFilePopupService } from "../_services/viewFilePopup.service";

function Exams() {
    const history = useHistory();
    let data = commonService.getLoginUserData();

    const [WeeklySubjectwiseResult, setWeeklySubjectwiseResult] = useState({ IsData: false, data: [] });
    const [WeeklyExamwiseResult, setWeeklyExamwiseResult] = useState({ IsData: false, data: [] });
    const [CBSEExamWiseResult, setCBSEExamWiseResult] = useState({ IsData: false, data: [] });
    const [CBSEFinalResult, setCBSEFinalResult] = useState({ IsData: false, data: [] });
    const [TermExamWiseResult, setTermExamWiseResult] = useState({ IsData: false, data: [] });
    const [TermFinalResult, setTermFinalResult] = useState({ IsData: false, data: [] });
    const [SemesterExamWiseResult, setSemesterExamWiseResult] = useState({ IsData: false, data: [] });
    const [SemesterFinalResult, setSemesterFinalResult] = useState({ IsData: false, data: [] });

    function BindExams() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "EXAM"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setWeeklySubjectwiseResult({ IsData: true, data: json.data.weeklySubjectwiseResult });
                    setWeeklyExamwiseResult({ IsData: true, data: json.data.weeklyExamwiseResult });
                    setCBSEExamWiseResult({ IsData: true, data: json.data.cbseExamWiseResult });
                    setCBSEFinalResult({ IsData: true, data: json.data.cbseFinalResult });
                    setTermExamWiseResult({ IsData: true, data: json.data.termExamWiseResult });
                    setTermFinalResult({ IsData: true, data: json.data.termFinalResult });
                    setSemesterExamWiseResult({ IsData: true, data: json.data.semesterExamWiseResult });
                    setSemesterFinalResult({ IsData: true, data: json.data.semesterFinalResult });
                } else if (json.status_code === 0) {
                    setWeeklySubjectwiseResult({ IsData: true, data: [] });
                    setWeeklyExamwiseResult({ IsData: true, data: [] });
                    setCBSEExamWiseResult({ IsData: true, data: [] });
                    setCBSEFinalResult({ IsData: true, data: [] });
                    setTermExamWiseResult({ IsData: true, data: [] });
                    setTermFinalResult({ IsData: true, data: [] });
                    setSemesterExamWiseResult({ IsData: true, data: [] });
                    setSemesterFinalResult({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        BindExams();
    }, []);

    return (
        <> <div className="container classwork-popup-detail">
            <div className="col-lg-12 student-profile">
                <Tabs
                    transition={false}
                    id="uncontrolled-tab-example"
                    className="mb-3 timetable-liveclass"
                >
                    <Tab eventKey="Weekly Exam" title="Weekly Exam">
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-0 container-xl">Exam Details</h2>
                        </div>
                        {
                            WeeklySubjectwiseResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam Date</th>
                                            <th>Subject</th>
                                            <th>Exam</th>
                                            <th>Exam Type</th>
                                            <th>Total Marks</th>
                                            <th>Obt Marks</th>
                                            <th>Percentage</th>
                                            <th>DIV. Rank</th>
                                            <th>DIV. Highest</th>
                                            <th>DIV. Lowest</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            WeeklySubjectwiseResult.data.length != 0 &&
                                            (
                                                WeeklySubjectwiseResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>{commonService.getDateInFormat(item.DateOfExam)}</td>
                                                        <td>{item.SubjectName}</td>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.ExamTypeName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.NetObtainedMarks}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.DivisionRank}</td>
                                                        <td>{item.DivisionHighest}</td>
                                                        <td>{item.DivsionLowest}</td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {WeeklySubjectwiseResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-2">Final Exam Details</h2>
                        </div>
                        {
                            WeeklyExamwiseResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam </th>
                                            <th>Exam Type</th>
                                            <th>Total Marks</th>
                                            <th>Obt Marks</th>
                                            <th>Percentage</th>
                                            <th>DIV. Rank</th>
                                            <th>DIV. Highest</th>
                                            <th>DIV. Lowest</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            WeeklyExamwiseResult.data.length != 0 &&
                                            (
                                                WeeklyExamwiseResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.ExamTypeName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.NetObtainedMarks}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.DivisionRank}</td>
                                                        <td>{item.DivisionHighest}</td>
                                                        <td>{item.DivsionLowest}</td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {WeeklyExamwiseResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                    </Tab>
                    <Tab eventKey="CBSE Exam" title="CBSE Exam">
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-0 container-xl">Exam Details</h2>
                        </div>
                        {
                            CBSEExamWiseResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam Date</th>
                                            <th>Exam</th>
                                            <th>Total Marks</th>
                                            <th>Obt Marks</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            CBSEExamWiseResult.data.length != 0 &&
                                            (
                                                CBSEExamWiseResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>
                                                            {item.StartDate != "" && commonService.getDateInFormat(item.StartDate)} - {item.EndDate != "" && commonService.getDateInFormat(item.EndDate)}
                                                        </td>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.Obtained_FRS}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.ResultType_Term}</td>
                                                        <td>{item.DivisionRank}</td>
                                                        <td className="text-center w-100px">
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {CBSEExamWiseResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-2">Final Exam Details</h2>
                        </div>
                        {
                            CBSEFinalResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam </th>
                                            <th>Total Marks</th>
                                            <th>Total Obtained</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th>STD. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            CBSEFinalResult.data.length != 0 &&
                                            (
                                                CBSEFinalResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.TotalObtained}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.Result_Term}</td>
                                                        <td>{item.DivRank}</td>
                                                        <td>{item.Rank}</td>
                                                        <td className="text-center w-100px">
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {CBSEFinalResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                    </Tab>
                    <Tab eventKey="Term Exam" title="Term Exam">
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-0 container-xl">Exam Details</h2>
                        </div>
                        {
                            TermExamWiseResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam Date</th>
                                            <th>Exam</th>
                                            <th>Total Marks</th>
                                            <th>Obt Marks</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            TermExamWiseResult.data.length != 0 &&
                                            (
                                                TermExamWiseResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>
                                                            {item.StartDate != "" && commonService.getDateInFormat(item.StartDate)} - {item.EndDate != "" && commonService.getDateInFormat(item.EndDate)}
                                                        </td>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.Obtained_FRS}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.ResultType_Term}</td>
                                                        <td>{item.DivisionRank}</td>
                                                        <td className="text-center w-100px">
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {TermExamWiseResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-2">Final Exam Details</h2>
                        </div>
                        {
                            TermFinalResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam </th>
                                            <th>Total Marks</th>
                                            <th>Total Obtained</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th>STD. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            TermFinalResult.data.length != 0 &&
                                            (
                                                TermFinalResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.ExamType_Term}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.TotalObtained}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.Result_Term}</td>
                                                        <td>{item.DivRank}</td>
                                                        <td>{item.Rank}</td>
                                                        <td className="text-center w-100px">
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {TermFinalResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                    </Tab>
                    <Tab eventKey="Semester Exam" title="Semester Exam">
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-0 container-xl">Exam Details</h2>
                        </div>
                        {
                            SemesterExamWiseResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam Date</th>
                                            <th>Exam</th>
                                            <th>Total Marks</th>
                                            <th>Obt Marks</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SemesterExamWiseResult.data.length != 0 &&
                                            (
                                                SemesterExamWiseResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>
                                                            {item.StartDate != "" && commonService.getDateInFormat(item.StartDate)} - {item.EndDate != "" && commonService.getDateInFormat(item.EndDate)}
                                                        </td>
                                                        <td>{item.ExamName}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.Obtained_FRS}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.ResultType_Term}</td>
                                                        <td>{item.DivisionRank}</td>
                                                        <td>
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {SemesterExamWiseResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                        <div className="inner-header-for-section float-left">
                            <h2 className="m-2">Final Exam Details</h2>
                        </div>
                        {
                            SemesterFinalResult.IsData ? (
                                <Table responsive className="fees-payment-table m-0">
                                    <thead>
                                        <tr>
                                            <th>Exam </th>
                                            <th>Total Marks</th>
                                            <th>Total Obtained</th>
                                            <th>Percentage</th>
                                            <th>Result</th>
                                            <th>DIV. Rank</th>
                                            <th>STD. Rank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SemesterFinalResult.data.length != 0 &&
                                            (
                                                SemesterFinalResult.data.map((item, index) => (
                                                    <tr>
                                                        <td>{item.ExamType_Term}</td>
                                                        <td>{item.TotalMarks}</td>
                                                        <td>{item.TotalObtained}</td>
                                                        <td>{item.Percentage}</td>
                                                        <td>{item.Result_Term}</td>
                                                        <td>{item.DivRank}</td>
                                                        <td>{item.Rank}</td>
                                                        <td>
                                                            <a
                                                                onClick={(e) =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: [
                                                                            {
                                                                                Name: item.ExamName,
                                                                                FilePath:
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    data[0].ClientID +
                                                                                    "/" +
                                                                                    data[0].InstituteID +
                                                                                    "/MarkSheets/" +
                                                                                    item.ID +
                                                                                    ".pdf",
                                                                                OrderNo: 1,
                                                                                FileType: "PDF",
                                                                            },
                                                                        ],
                                                                    })
                                                                }
                                                            >
                                                                <button className="small-btn d-flex align-items-center justify-content-center">
                                                                    {commonService.LabelDisplayText("View")}
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            ) : (<_Skeleton type={_SkeletonType.Table} />)
                        }
                        {SemesterFinalResult.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                    </Tab>
                </Tabs>
            </div>
        </div>
        </>
    );
}

export default Exams;
