import React, { useState } from "react";
import { commonService, AppConfigData } from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { alertService, AlertType } from "../_services/alert.service";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useEffect } from "react";

function Communication() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [openaddpopup, setopenaddpopup] = useState(false);
    const [openresultpopup, setopenresultpopup] = useState(false);
    const [openanswerpopup, setopenanswerpopup] = useState(false);
    const [teacherlistingdata, setteacherlistingdata] = useState({ isdata: false, data: [] });
    const [isteacherpollsaveloading, setisteacherpollsaveloading] = useState(false)
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [isloading, setisloading] = useState(false);
    const params = new URLSearchParams(window.location.search);

    const [editorClassworksDetails, setEditorClassworksDetails] = useState(() =>
        EditorState.createEmpty()
    );
    const [addoptionarray, setaddoptionarray] = useState([""]);
    const [teacherpollsave, setteacherpollsave] = useState({
        PollID: "00000000-0000-0000-0000-000000000000",
        StartDate: new Date(),
        EndDate: new Date(),
        Title: "",
        Details: "",
        IsMultiChoice: false,
        TagMemberIDs: "",
        teachertagid: "",
        mergetagid: "",
        Options: [""],
        ClientID: "00000000-0000-0000-0000-000000000000",
        CreatedOn: new Date(),
        CreateBy: "00000000-0000-0000-0000-000000000000",
        studentData: [],
        teacherData: [],
        RequestFrom: "Web",
        errors: {
            Title: "",
            option: "",
        },
    });




    const [TeacherStdDiv, setTeacherStdDiv] = useState([]);
    const [SelectedStdDiv, setSelectedStdDiv] = useState("");
    const [StudentByStdDiv, setStudentByStdDiv] = useState([]);
    const [TeacherSelect, setTeacherSelect] = useState([]);
    const [isteacherviewpopup, setisteacherviewpopup] = useState(false);

    useEffect(() => {
        if (commonService.getLoginRoleData("Poll", "IsView") && data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "teacher") {
            if (data[0].MemberType.toLowerCase() === "teacher") {
                getteacherlistingdata();
            }
            GetStandardDivisionData();
            get_student_listbystudentsubjectenrollment();
        } else {
            history.push("/access");
        }

    }, [])

    function getteacherlistingdata() {
        setisloading(true);
        setteacherlistingdata({ isdata: false, data: [] });
        const reqteacherlistingdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: 0,
                noOfData: 0,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/poll/getlistbymember",
            reqteacherlistingdata
        )
            .then((response) => response.json())
            .then(function (json) {
                setisloading(false);
                if (json.status_code === 1) {
                    var a = json.data;
                    setteacherlistingdata({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setisloading(false);
                    setteacherlistingdata({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    setisloading(false);
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function toaddoptionarray(e) {
        setaddoptionarray([...addoptionarray, ""])
        setteacherpollsave({ ...teacherpollsave, ["Options"]: [...teacherpollsave.Options, ""] });
    }



    function GetStandardDivisionData() {
        setTeacherStdDiv([]);

        const reqNotice = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/get_teacher_standarddivision",
            reqNotice
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        Options.push({
                            value: json.data[i].IDs.toLocaleUpperCase(),
                            label: json.data[i].Text,
                        });
                    }
                    setTeacherStdDiv(Options);
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function StandardDivisionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setSelectedStdDiv(Array.isArray(this.options) ? this.options.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            );
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setSelectedStdDiv("")
            GetStudentDataByStandardDivision(
                ""
            );
        } else if (event.action === "deselect-option") {
            setSelectedStdDiv(Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            );
        } else if (value.length === this.options.length - 1) {
            setSelectedStdDiv(Array.isArray(this.options) ? this.options.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            );
        } else {
            setSelectedStdDiv(Array.isArray(value) ? value.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(value) ? value.map((x) => x.value).join() : ""
            );
        }
    }

    function GetStudentDataByStandardDivision(stddivids) {
        if (stddivids === "") {
            setteacherpollsave({ ...teacherpollsave, ["TagMemberIDs"]: "" });
        }
        setStudentByStdDiv([]);
        const reqNotice = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                stddivids: stddivids.replace("*,", ""),
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_student_listbystudentmultistandarddivision",
            reqNotice
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        Options.push({
                            value: json.data[i].StudentID.toLocaleUpperCase(),
                            label:
                                json.data[i].StandardName +
                                " - " +
                                json.data[i].DivisionName +
                                " - " +
                                json.data[i].RollNo +
                                " - " +
                                json.data[i].StudentFullName,
                        });
                    }
                    setStudentByStdDiv(Options);
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function get_student_listbystudentsubjectenrollment() {
        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/getemployees",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].TeacherID.toLocaleUpperCase(),
                            label: json.data[i].FullName,
                        });
                    }
                    setTeacherSelect(options);
                } else if (json.status_code === 0) {
                    setTeacherSelect([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function adddata(e, index) {
        e.preventDefault();
        var newoption2 = [...teacherpollsave.Options]
        newoption2[e.target.id] = e.target.value
        setteacherpollsave({ ...teacherpollsave, ["Options"]: newoption2 })
    }

    function saveteacherpoll(e) {
        
        e.preventDefault();
        let isValid = true;
        let errors = {
            Title: "",
            option: "",
        };
        for (let i = 0; i <= teacherpollsave.Options.length; i++) {
            if (teacherpollsave.Options[i] === "" || teacherpollsave.Options[i] === null) {

                errors.option = "Text is required";
                isValid = false;
                break;
            } else {
                errors.option = "";
            }
        }
        if (!teacherpollsave.Title) {
            errors.Title = "Title is required";
            isValid = false;
        } else {
            errors.Title = "";
        }
        setteacherpollsave({
            ...teacherpollsave,
            ["errors"]: errors,
        });

        if (isValid) {

            setisteacherpollsaveloading(true);
            let MemberTagIDs = "";
            if (teacherpollsave.TagMemberIDs !== null && teacherpollsave.TagMemberIDs !== "") {
                MemberTagIDs = teacherpollsave.TagMemberIDs;
            }
            else {
                StudentByStdDiv.map((item) => (
                    MemberTagIDs = MemberTagIDs + "," + item.value
                ))
            }

            if (teacherpollsave.teachertagid !== null && teacherpollsave.teachertagid !== "") {
                MemberTagIDs = MemberTagIDs + "," + teacherpollsave.teachertagid;
            }
            else {
                if (MemberTagIDs !== "") {
                    TeacherSelect.map((item) => (
                        MemberTagIDs = MemberTagIDs + "," + item.value
                    ))
                }
            }



            let formData = new FormData();

            formData.append("PollID", teacherpollsave.PollID);
            formData.append("StartDate", commonService.getDateTimeInDBFormat(teacherpollsave.StartDate));
            formData.append("EndDate", commonService.getDateTimeInDBFormat(teacherpollsave.EndDate));
            formData.append("IsMultiChoice", teacherpollsave.IsMultiChoice);
            formData.append("Title", teacherpollsave.Title);
            formData.append("Details", draftToHtml(convertToRaw(editorClassworksDetails.getCurrentContent())));
            formData.append("TagMemberIDs", MemberTagIDs.replaceAll("*,", ""));
            formData.append("RequestFrom", "Web");
            for (let i = 0; i < teacherpollsave.Options.length; i++) {
                formData.append(
                    "Options[" + i + "]",
                    teacherpollsave.Options[i]
                );
            }

            const reqNotice = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/poll/save", reqNotice)
                .then((response) => response.json())
                .then(function (json) {
                    setisteacherpollsaveloading(false)
                    if (json.status_code === 1) {
                        setteacherpollsave({
                            PollID: "00000000-0000-0000-0000-000000000000",
                            StartDate: new Date(),
                            EndDate: new Date(),
                            Title: "",
                            Details: "",
                            IsMultiChoice: false,
                            TagMemberIDs: "",
                            teachertagid: "",
                            mergetagid: "",
                            Options: [""],
                            RequestFrom: "Web",
                            errors: {
                                Title: "",
                                option: "",
                            }
                        })
                        setaddoptionarray([""]);
                        setEditorClassworksDetails(EditorState.createEmpty());
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        getteacherlistingdata();
                        setopenaddpopup(false)

                        commonService.sendNotification();

                    } else if (json.status_code === 0) {
                        setisteacherpollsaveloading(false)
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        setisteacherpollsaveloading(false)
                        localStorage.clear();
                        history.push("/");
                    }


                });
        }

    }




    function seeteacherpolldatabyid(id) {

        if (id !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/poll/getalldetailsbyid",
                reqClasswork
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        let TagMemberIDs = "";
                        json.data.studentData.map((item) => (
                            TagMemberIDs = TagMemberIDs + "," + item.StudentID
                        ))
                        let teachertagid = "";
                        json.data.teacherData.map((item) => (
                            teachertagid = teachertagid + "," + item.TeacherID
                        ))
                        let Options = [];
                        if (json.data.optionData.length !== 0) {
                            json.data.optionData.map((item) =>
                                Options.push(item.Option)
                            )
                        }
                        else {
                            Options.push("");
                        }
                        setteacherpollsave({
                            PollID: json.data.detailsData[0].PollID,
                            StartDate: new Date(
                                json.data.detailsData[0].StartDate
                            ),
                            EndDate: new Date(
                                json.data.detailsData[0].EndDate
                            ),
                            ClientID: json.data.detailsData[0].ClientID,
                            CreatedOn: new Date(
                                json.data.detailsData[0].CreatedOn
                            ),
                            CreateBy: json.data.detailsData[0].CreateBy,
                            Title: json.data.detailsData[0].Title,
                            Details: json.data.detailsData[0].Details,
                            IsMultiChoice: json.data.detailsData[0].IsMultiChoice,
                            TagMemberIDs: TagMemberIDs,
                            Options: Options,
                            // optionData: json.data.optionData,
                            studentData: json.data.studentData,
                            teacherData: json.data.teacherData,

                            teachertagid: teachertagid,
                            mergetagid: "",
                            RequestFrom: "Web",
                            errors: {
                                Title: "",
                                option: "",
                            }
                        });
                        if (json.data.studentData.length !== 0) {
                            let STDDIV = "";
                            for (let i = 0; i < json.data.studentData.length; i++) {
                                if (
                                    STDDIV.toUpperCase().indexOf(
                                        json.data.studentData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.studentData[i].DivisionID.toUpperCase()
                                    ) < 0
                                ) {
                                    STDDIV =
                                        STDDIV +
                                        "," +
                                        json.data.studentData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.studentData[i].DivisionID.toUpperCase();
                                }
                            }
                            setSelectedStdDiv(STDDIV);
                            GetStudentDataByStandardDivision(STDDIV);
                        }

                        setEditorClassworksDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].Details)
                                        .contentBlocks
                                )
                            )
                        );

                        get_student_listbystudentsubjectenrollment(
                            json.data.detailsData[0].GradeID,
                            json.data.detailsData[0].DivisionID,
                            json.data.detailsData[0].SubjectID
                        );
                        setopenaddpopup(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
        else {
            setteacherpollsave({
                PollID: "00000000-0000-0000-0000-000000000000",
                StartDate: new Date(),
                EndDate: new Date(),
                Title: "",
                Details: "",
                IsMultiChoice: false,
                TagMemberIDs: "",
                teachertagid: "",
                mergetagid: "",
                Options: [""],
                ClientID: "00000000-0000-0000-0000-000000000000",
                CreatedOn: new Date(),
                CreateBy: "00000000-0000-0000-0000-000000000000",
                studentData: [],
                teacherData: [],
                RequestFrom: "Web",
                errors: {
                    Title: "",
                    option: "",
                },
            });
            setSelectedStdDiv("")
            setEditorClassworksDetails(EditorState.createEmpty());
            setopenaddpopup(true);
        }

    }

    function deleteteacherpoll(id) {
        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: id,
                requestFrom: "Web",
            }),

        };
        fetch(
            AppConfigData.APIBasePath + "v1/poll/deletebyid",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    getteacherlistingdata();
                    setopenaddpopup(false);
                }
                else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const [resultdata, setresultdata] = useState({
        detailsData: [],
        optionData: [],
        memberData: [],
        isdata: false,
    })

    function getresultdetail(id) {
        setopenresultpopup(true);
        if (id !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),

            };
            fetch(
                AppConfigData.APIBasePath + "v1/poll/getallvotedetailsbyid",
                reqClasswork
            ).then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setresultdata({
                            detailsData: json.data.detailsData,
                            optionData: json.data.optionData,
                            memberData: json.data.memberData,
                            isdata: true,
                        })
                        setopenresultpopup(true);
                    }
                    // else if (json.status_code === 0) {
                    //   }
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setresultdata({
                detailsData: [],
                optionData: [],
                memberData: [],
                isdata: false,
            })
            setopenresultpopup(false);
        }
    }

    function getDropdownButtonLabel({ value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    function StudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setteacherpollsave({
                ...teacherpollsave, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setteacherpollsave({
                ...teacherpollsave, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setteacherpollsave({
                ...teacherpollsave, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setteacherpollsave({
                ...teacherpollsave, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setteacherpollsave({
                ...teacherpollsave, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })

        }
    }

    function TeacherSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setteacherpollsave({
                ...teacherpollsave, teachertagid: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setteacherpollsave({
                ...teacherpollsave, teachertagid: ""
            })
        } else if (event.action === "deselect-option") {
            setteacherpollsave({
                ...teacherpollsave, teachertagid: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setteacherpollsave({
                ...teacherpollsave, teachertagid: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setteacherpollsave({
                ...teacherpollsave, teachertagid: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })
        }
    }
    return (
        <>

            <div className="row">
                <div className="col-md-12">
                    <h1 className="page-header my-4">
                        {commonService.LabelDisplayText("Poll")}
                    </h1>
                    <div className="background-theme-color sticky-top homework-searchBar">
                        <div className="search-top-section">
                            <div className="row">
                                <div className="col-lg-12 col-xl-7">
                                    <div className="header-search-area">
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            onChange={(e) => setTxtSearch(e.target.value)}
                                            className="ml-0"
                                            placeholder={commonService.LabelDisplayText("SearchText")}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                                    <div className="search-right-cal d-flex align-items-center">
                                        <div className="header-date-area w-100 mr-3">
                                            <div className="d-flex align-items-center">
                                                <label className="from-date">
                                                    {commonService.LabelDisplayText("DatepickerFrom")}
                                                </label>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={txtStartDate}
                                                    dateFormat={data[0].DateFormat_Term}
                                                    onChange={(date) => setTxtStartDate(date)}
                                                    placeholderText={data[0].DateFormat_Term}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <label className="from-date">
                                                    {commonService.LabelDisplayText("DatepickerTo")}
                                                </label>
                                                <DatePicker
                                                    className="datepicker"
                                                    selected={txtEndDate}
                                                    dateFormat={data[0].DateFormat_Term}
                                                    onChange={(date) => setTxtEndDate(date)}
                                                    placeholderText={data[0].DateFormat_Term}
                                                />
                                            </div>
                                        </div>
                                        <div className="right-icon-cal d-flex align-items-center">
                                            {isloading === false ? (
                                                <div
                                                    onClick={() => getteacherlistingdata()}
                                                    className="search-icon-img pointer-cursor"
                                                >
                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                </div>
                                            ) : (
                                                <div className="search-icon-img pointer-cursor">
                                                    <Spinner
                                                        animation="border"
                                                        size="sm"
                                                        variant="primary"
                                                    />
                                                </div>
                                            )}
                                            {
                                                commonService.getLoginRoleData("Poll", "IsCreate") &&
                                                <div
                                                    className="plus-btn pointer-cursor ml-3"
                                                    onClick={() => seeteacherpolldatabyid("")}
                                                >
                                                    <i className="fa-regular fa-plus"></i>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="search-result-block">
                            <span>
                                {commonService.LabelDisplayText("ResultFor")}
                                <span>  {txtSearch +
                                    " From : " +
                                    commonService.getDateInFormat(txtStartDate) +
                                    " To : " +
                                    commonService.getDateInFormat(txtEndDate)}</span>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        {data[0].MemberType.toLowerCase() === "teacher" ? <>
                            {teacherlistingdata.isdata !== false ? <>
                                {teacherlistingdata.data.length !== 0 ? <>
                                    {teacherlistingdata.data.map((a) => (

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                            <div className="card home-card-homework">
                                                <div className="card-body" >
                                                    <div className="d-flex justify-content-between align-items-center pointer-cursor" onClick={() => seeteacherpolldatabyid(a.PollID)}>
                                                        <h2 className="card-body-header mb-2 m-0" title="Title" >
                                                            {/* onClick={() => setopenanswerpopup(true)} */}
                                                            {a.Title}
                                                        </h2>
                                                        <i className="fa-regular fa-square-arrow-up-right expand-classwork-icon"></i>
                                                    </div>


                                                    <p
                                                        className="card-body-content mt-2"
                                                        dangerouslySetInnerHTML={{
                                                            __html: a.Details,
                                                        }}
                                                    ></p>

                                                    <div className="mt-3">
                                                        <label></label>
                                                    </div>
                                                    <div className="section-for-footer-card">
                                                        <div className="left-section">
                                                            <i className="fa-light fa-calendar-lines calendar-icons"></i>
                                                            <p>{commonService.getDateInFormat(a.StartDate)} to {commonService.getDateInFormat(a.EndDate)}</p>
                                                        </div>
                                                        <div className="right-section myrightclass">
                                                            <div className="d-flex align-items-center">
                                                                <div className="button mr-3">
                                                                    <button
                                                                        onClick={() => getresultdetail(a.PollID)}
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                    >
                                                                        {commonService.LabelDisplayText("Result")}
                                                                    </button>
                                                                </div>
                                                                <div className="user-pic">
                                                                    <div
                                                                        className="user-pic"
                                                                        title={a.CreateByName}
                                                                        style={{
                                                                            backgroundColor: commonService.CreateColor(
                                                                                a.Initials
                                                                            ).BGColor,
                                                                            color: commonService.CreateColor(a.Initials)
                                                                                .FontColor,
                                                                        }}
                                                                    >
                                                                        {a.Avatar !== "" ? (
                                                                            <img
                                                                                alt="User Profile"
                                                                                src={AppConfigData.ERPFileBasePath + a.Avatar}
                                                                            />
                                                                        ) : (
                                                                            a.Initials
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    ))}
                                </> : <>
                                    <_NoDataFound fontsize={"300px"} />
                                </>}
                            </> :
                                <> <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                    <_Skeleton
                                        type={_SkeletonType.ClassWork}

                                        lineheight={30}
                                    /></div> <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                        <_Skeleton
                                            type={_SkeletonType.ClassWork}

                                            lineheight={30}
                                        /></div> <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                        <_Skeleton
                                            type={_SkeletonType.ClassWork}

                                            lineheight={30}
                                        /></div></>}

                        </> : <></>}



                    </div>

                </div>
            </div>

            <Modal
                show={openaddpopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setopenaddpopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => { setopenaddpopup(false) }}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("AddPoll")}

                                    </h1>
                                    {isteacherpollsaveloading === false ? <>
                                        {teacherpollsave.PollID === "00000000-0000-0000-0000-000000000000" ? <>
                                            <button
                                                className="btn btn-primary px-3 pr-4 popup-send-button"
                                                type="button"
                                                onClick={(e) => saveteacherpoll(e)}
                                            > {commonService.LabelDisplayText("Save")}</button>
                                        </> : <>
                                            {teacherpollsave.PollID !== "00000000-0000-0000-0000-000000000000" && teacherpollsave.StartDate > new Date() && data[0].UserID.toUpperCase() === teacherpollsave.CreateBy.toUpperCase() ? <>

                                                <ButtonGroup className="mb-3">
                                                    <button
                                                        className="btn btn-primary px-3 pr-4"
                                                        type="button"
                                                        onClick={(e) => saveteacherpoll(e)}
                                                    >
                                                        {commonService.LabelDisplayText("Save")}
                                                    </button>
                                                    <DropdownButton
                                                        as={ButtonGroup}
                                                        title=""
                                                        className="btn-transparent send-dropdown-button"
                                                    >
                                                        <Dropdown.Item
                                                            eventKey="1"
                                                            onClick={(e) => saveteacherpoll(e)}
                                                        >
                                                            {commonService.LabelDisplayText("Edit")}
                                                        </Dropdown.Item>
                                                        {
                                                            commonService.getLoginRoleData("Poll", "IsDelete") &&
                                                            <Dropdown.Item
                                                                eventKey="2"
                                                                onClick={() => confirmAlert({
                                                                    title: "Delete",
                                                                    message: "Are you sure delete ?",
                                                                    buttons: [
                                                                        {
                                                                            label: "Yes",
                                                                            onClick: () =>
                                                                                deleteteacherpoll(teacherpollsave.PollID),
                                                                        },
                                                                        {
                                                                            label: "No",
                                                                        },
                                                                    ],
                                                                })}
                                                            >
                                                                {commonService.LabelDisplayText("Delete")}
                                                            </Dropdown.Item>
                                                        }
                                                    </DropdownButton>
                                                </ButtonGroup>
                                            </> :
                                                <>{teacherpollsave.PollID !== "00000000-0000-0000-0000-000000000000" && data[0].UserID.toUpperCase() === teacherpollsave.CreateBy.toUpperCase() ? <>
                                                    <button
                                                        className="btn btn-primary px-3 pr-4 popup-send-button"
                                                        type="button"
                                                        onClick={() => confirmAlert({
                                                            title: "Delete",
                                                            message: "Are you sure delete ?",
                                                            buttons: [
                                                                {
                                                                    label: "Yes",
                                                                    onClick: () =>
                                                                        deleteteacherpoll(teacherpollsave.PollID),
                                                                },
                                                                {
                                                                    label: "No",
                                                                },
                                                            ],
                                                        })}
                                                    > {commonService.LabelDisplayText("Delete")}</button>
                                                </> : <></>}
                                                </>}
                                        </>}

                                    </> :
                                        <>
                                            <button
                                                className="btn btn-primary px-3 pr-5"
                                                type="button"
                                                disabled="disabled"
                                            >
                                                <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                            </button>
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 date-section-area">
                                <input
                                    type="text"
                                    className={`mt-4 title-textbox ${teacherpollsave.errors.Title && "invalid"
                                        }`}
                                    placeholder="Poll Title"
                                    onChange={(e) =>
                                        setteacherpollsave({
                                            ...teacherpollsave,
                                            ["Title"]: e.target.value,
                                        })
                                    }

                                    value={teacherpollsave.Title}
                                />
                                {teacherpollsave.errors.Title && (
                                    <span className="error-message">
                                        {teacherpollsave.errors.Title}
                                    </span>
                                )}

                                <div className="rich-txt-editor-area mt-3">
                                    <Editor
                                        toolbarHidden={
                                            data[0].MemberType.toLowerCase() === "student"
                                        }
                                        readOnly={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "readOnly"
                                        }
                                        editorState={editorClassworksDetails}
                                        toolbar={AppConfigData.toolbarConfig}
                                        onEditorStateChange={setEditorClassworksDetails}

                                    />
                                </div>
                                {teacherpollsave.Options.map((a, index) => (
                                    <input
                                        id={index}
                                        type="text"
                                        className={`mt-3 title-textbox ${teacherpollsave.errors.option && "invalid"
                                            }`}
                                        placeholder="add option"
                                        value={a}
                                        onChange={(e) => adddata(e, index)}
                                    />

                                ))}

                                {teacherpollsave.errors.option && (
                                    <span className="error-message">
                                        {teacherpollsave.errors.option}
                                    </span>
                                )}
                                <div className={`pointer-cursor mychoice mt-3 `} onClick={(e) => toaddoptionarray(e)} >
                                    <div className="dosplychan">
                                        <i className="fa-regular fa-plus" ></i>
                                    </div>
                                    <span >{commonService.LabelDisplayText("AddMoreOptions")}</span>

                                </div>

                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-4 d-flex align-items-center">
                                        <label className="home-work-label">
                                            {commonService.LabelDisplayText("StartDate")}
                                        </label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  mb-4">
                                        <DatePicker
                                            className={`datepicker homework-input-date `}
                                            disabled={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "disabled"
                                            }
                                            selected={teacherpollsave.StartDate}
                                            minDate={new Date()}
                                            onChange={(date) => { setteacherpollsave({ ...teacherpollsave, ["StartDate"]: date, ["EndDate"]: date }) }
                                            }
                                            dateFormat={data[0].DateFormat_Term}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-4 d-flex align-items-center">
                                        <label className="home-work-label">
                                            {commonService.LabelDisplayText("EndDate")}
                                        </label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  mb-4">
                                        <DatePicker
                                            className={`datepicker homework-input-date `}
                                            disabled={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "disabled"
                                            }
                                            selected={teacherpollsave.EndDate}
                                            minDate={teacherpollsave.StartDate}
                                            onChange={(date) =>
                                                setteacherpollsave({ ...teacherpollsave, ["EndDate"]: date })
                                            }
                                            dateFormat={data[0].DateFormat_Term}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">For</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 select-student-subject">
                                        <div className="ReactMultiSelectCheckboxes selectSubject-popup">
                                            <ReactMultiSelectCheckboxes
                                                width={"100%"}
                                                placeholderButtonLabel={
                                                    "Select Standard Division"
                                                }
                                                options={TeacherStdDiv}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                onChange={StandardDivisionChange}
                                                value={TeacherStdDiv.filter((obj) =>
                                                    SelectedStdDiv.split(",").includes(obj.value)
                                                )}
                                                getDropdownButtonLabel={getDropdownButtonLabel}
                                            />
                                        </div>
                                        <div className="ReactMultiSelectCheckboxes mt-2 select-student-dropdown">
                                            <ReactMultiSelectCheckboxes
                                                width={"100%"}
                                                placeholderButtonLabel={"Select Student"}
                                                options={StudentByStdDiv}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                onChange={StudentSelectionChange}

                                                getDropdownButtonLabel={getDropdownButtonLabel}
                                                value={StudentByStdDiv.filter((obj) =>
                                                    teacherpollsave.TagMemberIDs.toUpperCase().split(",").includes(
                                                        obj.value.toUpperCase()
                                                    )
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                        <label className="home-work-label"> {commonService.LabelDisplayText("Teacher")}</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 select-student-subject">
                                        <div className="ReactMultiSelectCheckboxes selectSubject-popup">
                                            <ReactMultiSelectCheckboxes
                                                width={"100%"}
                                                placeholderButtonLabel={
                                                    "Select Standard Division"
                                                }
                                                options={TeacherSelect}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                onChange={TeacherSelectionChange}
                                                getDropdownButtonLabel={getDropdownButtonLabel}
                                                value={TeacherSelect.filter((obj) =>
                                                    teacherpollsave.teachertagid.toUpperCase().split(",").includes(
                                                        obj.value.toUpperCase()
                                                    )
                                                )} />
                                        </div>
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-2 d-flex align-items-center">

                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  mb-2">

                                        <div className="mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={teacherpollsave.IsMultiChoice}
                                                id="flexCheckDefault"
                                                onChange={(e) => setteacherpollsave({ ...teacherpollsave, ["IsMultiChoice"]: e.target.checked })}
                                            />
                                            <label
                                                className="form-check-label"
                                                for="flexCheckDefault"

                                            >
                                                {commonService.LabelDisplayText("IsMultiChoice")}?
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>




            <Modal
                show={openresultpopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setopenresultpopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => getresultdetail("")}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("PollResult")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {resultdata.isdata === true ? <>
                        {resultdata.detailsData.length !== 0 ? <>
                            <div className="container classwork-popup-detail">
                                <div className="row mt-3 pollresultpop">
                                    <div className="col-md-12">
                                        <h2>
                                            <span className="text-muted">{commonService.LabelDisplayText("Title")}:</span>
                                            {resultdata.detailsData[0].Title}
                                        </h2>

                                        <p
                                            className="card-body-content mt-2 text-muted"
                                            dangerouslySetInnerHTML={{
                                                __html: resultdata.detailsData[0].Details,
                                            }}
                                        ></p>
                                        <h2 className="mt-3">
                                            <span className="text-muted"> {commonService.LabelDisplayText("Votes")}:</span>
                                        </h2>
                                    </div>

                                    {resultdata.optionData.map((a) => (
                                        <div className="col-md-12 mb-3 mt-2">
                                            <div className="mycustomcard">
                                                <div className="d-flex justify-content-between">
                                                    <div className="align-self-center">

                                                        <p
                                                            className="card-body-content mt-2"
                                                            dangerouslySetInnerHTML={{
                                                                __html: a.Option,
                                                            }}
                                                        ></p>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="pollbadge mr-4">
                                                            <span>{a.TotalVotes}</span>
                                                        </div>
                                                        <span className="persent">{a.TotalVotes !== 0 && a.PollTotalVotes !== 0 ? (((a.TotalVotes) / a.PollTotalVotes) * 100).toFixed(1) : 0} %</span>
                                                    </div>
                                                </div>
                                                <div className="newprocess">
                                                    <ProgressBar now={a.TotalVotes !== 0 && a.PollTotalVotes !== 0 ? (((a.TotalVotes) / a.PollTotalVotes) * 100) : 0} />
                                                </div>
                                            </div>
                                        </div>

                                    ))}


                                    <div className="col-md-12">
                                        <h2 className="mt-3">
                                            <span className="text-muted">{commonService.LabelDisplayText("VoterList")} :</span>
                                        </h2>
                                        <div className="table-responsive">
                                            <>{resultdata.memberData.length !== 0 ? <>
                                                <table className="mark-entry-table table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("Name")}</b>
                                                            </th>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("ContactNo")}</b>
                                                            </th>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("STDDIVDEPT")}</b>
                                                            </th>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("Date")}</b>
                                                            </th>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("VoteBy")}</b>
                                                            </th>
                                                            <th>
                                                                <b>{commonService.LabelDisplayText("Option")}</b>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {resultdata.memberData.map((a) => (
                                                            <tr>
                                                                <td>{a.FullName}</td>
                                                                <td>{a.MobileNo}</td>
                                                                <td>{a.STDDIVDEPT}</td>
                                                                <td>{commonService.getDateInFormat(a.DateOfPoll)}</td>
                                                                <td>{a.DisplayName} ({a.UserType})</td>
                                                                <td>{a.SelectedOption}</td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </> : <><_NoDataFound fontsize={"250px"} /></>}</>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <><_NoDataFound fontsize={"250px"} /></>}

                    </> : <> <_Skeleton type={_SkeletonType.Line} count={2} /></>}

                </Modal.Body>
            </Modal>




        </>
    );
}

export default Communication;
