import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import Table from "react-bootstrap/Table";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";

function TeacherChargePatrak() {
    const history = useHistory();
    const [IsListDateLodding, setIsListDateLodding] = useState(false);
    const [TeacherChargePatrak, setTeacherChargePatrak] = useState({ IsData: false, data: [] });

    function BindTeacherChargePatrak() {
        setIsListDateLodding(true);
        const reqTeacherChargePatrak = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/teacherchargepatraks/getlistbymember", reqTeacherChargePatrak)
            .then((response) => response.json())
            .then(function (json) {
                setIsListDateLodding(false);
                if (json.status_code === 1) {
                    setTeacherChargePatrak({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setTeacherChargePatrak({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        BindTeacherChargePatrak();
    }, []);

    return (
        <div>
            <h1 className="page-header my-4">Teacher Charge Patrak</h1>
            <div className="background-theme-color sticky-top">
                {TeacherChargePatrak.IsData ? (
                    <>
                        <Table className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th className="text-center w-75px">#</th>
                                    <th className="w-200px">{commonService.LabelDisplayText("ItemName")}</th>
                                    <th className="w-200px">{commonService.LabelDisplayText("ChargeDate")}</th>
                                    <th className="w-200px">{commonService.LabelDisplayText("ReturnDate")}</th>
                                    <th className="w-200px">{commonService.LabelDisplayText("Status")}</th>
                                    <th className="w-200px">Record Enter By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TeacherChargePatrak.data.map((item,index) => (
                                    <tr>
                                        <td className="text-center">{index + 1}</td>
                                        <td>{item.ItemName}</td>
                                        <td>{commonService.getDateInFormat(item.ChargeDate)}</td>
                                        <td>{commonService.getDateInFormat(item.ReturnDate)}</td>
                                        <td>{item.Term}</td>
                                        <td>{item.DisplayName}</td>
                                    </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </>
                ) : (
                        <_Skeleton type={_SkeletonType.Table} />
                    )}
            </div>
        </div>
    );
}

export default TeacherChargePatrak;
