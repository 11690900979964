import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";
import Table from "react-bootstrap/Table";

function HostelDetailView() {

    const [transactionDetail, settransactionDetail] = useState({ IsData: false, data: [] });
    const [hostelDetail, sethostelDetail] = useState({ IsData: false, data: [] });
    var closingvalue = 0;

    function BindHostelDetail() {
        closingvalue = 0;
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "HOSTELDETAILS"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    
                    if (json.data.hostelDetails.OpeningBalance !== null) {
                        if (json.data.hostelDetails.OpeningBalance !== "") {
                            closingvalue = json.data.hostelDetails.OpeningBalance;
                        }
                    }
                    settransactionDetail({ IsData: true, data: json.data.transactionData });
                    sethostelDetail({ IsData: true, data: json.data.hostelDetails });
                } else if (json.status_code === 0) {
                    settransactionDetail({ IsData: true, data: [] });
                    sethostelDetail({ IsData: true, data: [] });
                }
            });
    }
    function CalClosingValue(type, amount) {
        if (type === "DEPOSIT") {
            closingvalue = closingvalue + amount;
        }
        else if (type === "WITHDRAW") {
            closingvalue = closingvalue - amount;
        }
    }

    React.useEffect(() => {
        BindHostelDetail();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {hostelDetail.IsData &&
                        (
                            <>
                                <div className="col-lg-12 my-2">
                                    <div className="col-lg-6 float-left">
                                        <div className="col-lg-12 my-1 event-description">
                                            <b className="student-roll-no mt-1">Hostel : </b>{hostelDetail.data[0].HostelName}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Account No. : </b>{hostelDetail.data[0].AcctNo}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">HR No. : </b>{hostelDetail.data[0].HRNo}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Class In Hostel : </b>{hostelDetail.data[0].StandardDivision}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Opening Balance : </b>{hostelDetail.data[0].OpeningBalance}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 float-left">
                                        <div className="col-lg-12 my-1 event-description">
                                            <b className="student-roll-no mt-1">Building : </b>{hostelDetail.data[0].Building}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Floor : </b>{hostelDetail.data[0].BuildingFloor}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Room : </b>{hostelDetail.data[0].BuildingFloorRoom}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Bed : </b>{hostelDetail.data[0].BuildingRoomBed}
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Closing Balance : </b>{closingvalue}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    {transactionDetail.IsData ? (
                        <Table className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th>Seq No.</th>
                                    <th>Date</th>
                                    <th>Detail</th>
                                    <th>Deposit</th>
                                    <th>Withdraw</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionDetail.data.length !== 0 && (
                                    transactionDetail.data.map((item) => (
                                        <>
                                            {CalClosingValue(item.TransactionType_Term.toUpperCase(), item.Amount)}
                                            <tr>
                                                <td>{item.SeqNo}</td>
                                                <td>{commonService.getDateInFormat(item.DateOfTransaction)}</td>
                                                <td>{item.Narration}</td>
                                                <td>{item.TransactionType_Term.toUpperCase() === "DEPOSIT" ?
                                                    (
                                                        <span>{item.Amount}</span>
                                                    ) : ("")}
                                                </td>
                                                <td>{item.TransactionType_Term.toUpperCase() === "WITHDRAW" ?
                                                    (
                                                        <span>{item.Amount}</span>
                                                    ) : ("")}
                                                </td>
                                                <td>{closingvalue}</td>
                                            </tr>
                                        </>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    ) : (<_Skeleton type={_SkeletonType.Table} />)}
                    {transactionDetail.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                </div>
            </div>
        </>
    );
}

export default HostelDetailView;
