import React, { useState, useRef } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory } from "react-router-dom";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { alertService, AlertType } from "../_services/alert.service";
import Spinner from "react-bootstrap/Spinner";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Select from "react-select";

let SearchingText = "";

function Wall() {
    // Function To getdata from API :
    const [WallList, setWallList] = useState([]);

    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [WallListSelectedVal, setWallListSelectedVal] = useState(
        data[0].WallID
    );
    const [Post, setPost] = useState({ IsData: false, data: [] });
    const [BirthDay, setBirthDay] = useState({ IsData: false, data: [] });

    const [FilterAssociationType, setFilterAssociationType] = useState("");

    const [showCreatePost, setshowCreatePost] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const [showLinkPopup, setShowLinkPopup] = useState(false);
    const [showYoutubeLinkPopup, setShowYoutubeLinkPopup] = useState(false);
    const [showFileSelectPopup, setShowFileSelectPopup] = useState(false);
    const [IsPostDataLodding, setIsPostDataLodding] = useState(false);

    const [AttachLink, setAttachLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachYoutubeLink, setAttachYoutubeLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachFiles, setAttachFiles] = useState({
        name: "",
        link: null,
        errors: "",
    });
    const [editorPostDetails, seteditorPostDetails] = useState(() =>
        EditorState.createEmpty()
    );

    const [IsPostSaveing, setIsPostSaveing] = useState(false);

    const [PostData, setPostData] = useState({
        FilesDetails: [],
        errors: {
            FileText: "",
        },
    });

    // Function to get api reponce :
    function get_WallList_data() {
        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: "",
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/getmemberwalllist",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].WallID,
                            label: json.data[i].WallName,
                        });
                    }
                    setWallList(options);
                } else if (json.status_code === 0) {
                    setWallList([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    // Function to get api reponce (End of Function) :

    function getcommonwallpost(_sRow, _filterAssociationType) {
        let noOfData = 10;
        setIsPostDataLodding(true);
        if (_sRow === 1) {
            setPost({ IsData: false, data: [] });
        }
        setFilterAssociationType(_filterAssociationType);
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                wallID: data[0].WallID,
                sRow: _sRow,
                noOfData: noOfData,
                filterAssociationType: _filterAssociationType,
                startDate: null,
                endDate: null,
                searchingText: SearchingText,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/getcommonwallpost",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                setIsPostDataLodding(false);
                if (json.status_code === 1) {
                    if (_sRow === 1) {
                        setPost({ IsData: true, data: json.data });
                        //if (json.data.length % 10 !== 0) {
                        //    document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                        //}
                    } else {
                        setPost((oldData) => ({
                            IsData: true,
                            data: [...oldData.data, ...json.data],
                        }));
                        //if (json.data.length % 10 !== 0) {
                        //    document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                        //}
                    }

                    if (json.data.length < noOfData) {
                        document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    }
                } else if (json.status_code === 0) {
                    setPost((oldData) => ({
                        IsData: true,
                        data: oldData.data,
                    }));
                    //if (_sRow === 1) {
                    //    setPost({ IsData: true, data: [] });
                    //}
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getbirthdaylist() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(AppConfigData.APIBasePath + "v1/home/upcommingbirthdaylist", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setBirthDay({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setBirthDay({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function LikePost(Input, index) {
        let element = document.getElementById("post_" + Input.postCommentID);
        element.style.pointerEvents = "none";

        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify(Input),
        };
        fetch(AppConfigData.APIBasePath + "v1/postcomments/likepost", reqPost)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    PostDataChange(index, Input.postCommentID);
                }
            });
    }

    function DisLikePost(Input, index) {
        let element = document.getElementById("post_" + Input.postCommentID);
        element.style.pointerEvents = "none";

        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify(Input),
        };
        fetch(AppConfigData.APIBasePath + "v1/postcomments/dislikepost", reqPost)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    PostDataChange(index, Input.postCommentID);
                }
            });
    }

    function PostDataChange(index, PostCommentID) {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: PostCommentID,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/getcommonwallpost_byid",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Data = Post.data;
                    Data[index].DateOfPost = json.data[0].DateOfPost;
                    Data[index].PostCommentNote = json.data[0].PostCommentNote;
                    Data[index].TotalLikes = json.data[0].TotalLikes;
                    Data[index].TotalDislike = json.data[0].TotalDislike;
                    Data[index].IsDislike = json.data[0].IsDislike;
                    Data[index].IsLike = json.data[0].IsLike;
                    setPost({ IsData: true, data: Data });
                }
                let element = document.getElementById("post_" + PostCommentID);
                element.style.pointerEvents = "unset";
            });
    }

    function ViewPostFilePopup(Files, index) {
        let PassedFiles = [];
        Files.map((itemFile, indexFile) => PassedFiles.push(itemFile));

        if (index !== 0) {
            const IndexFileObj = PassedFiles[index];
            PassedFiles.splice(index, 1);
            PassedFiles = [IndexFileObj].concat(PassedFiles);
        }
        viewFilePopupService.viewFilePopup({ data: PassedFiles });
    }

    function WindowScrolling(event) {
        if (
            event.srcElement.scrollTop + window.innerHeight - 20 >=
            event.srcElement.scrollHeight - 1 &&
            document.getElementById("FilterAssociationType_Active") !== null
        ) {
            getcommonwallpost(
                document.getElementsByClassName("card-for-wall").length + 1,
                document
                    .getElementById("FilterAssociationType_Active")
                    .getAttribute("attr-type")
            );
        }

        let AllPost = document.getElementsByClassName("callisreadapprove");
        if (AllPost != null && AllPost.length != 0) {
            for (let i = 0; i < AllPost.length; i++) {
                if (isInViewport(AllPost[i])) {

                    let AID = AllPost[i].getAttribute("aid")
                    let AT = AllPost[i].getAttribute("at")
                    approvereadpost(AID, AT);
                    AllPost[i].classList.remove("callisreadapprove");

                }
            }

        }
    }

    function isInViewport(e) {

        const rect = e.getBoundingClientRect();

        return (
            rect.top <= ((window.innerHeight || document.documentElement.clientHeight))

        );
    }


    function PostSearching() {
        SearchingText = "";
        if (document.getElementById("txtPostSearch").value !== "") {
            SearchingText = document.getElementById("txtPostSearch").value;
        }
        getcommonwallpost(
            1,
            document
                .getElementById("FilterAssociationType_Active")
                .getAttribute("attr-type")
        );
    }

    function AddLinkInFilesDetails() {
        setAttachLink({ ...AttachLink, ["errors"]: "" });
        if (AttachLink.link !== "") {
            var res = AttachLink.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLink({ ...AttachLink, ["errors"]: "Link is not valid" });
                return false;
            } else {
                let FilesDetails = PostData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLink.name,
                    FilePath: AttachLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setPostData({ ...PostData, ["FilesDetails"]: FilesDetails });
                setShowLinkPopup(false);
                return true;
            }
        } else {
            setAttachLink({ ...AttachLink, ["errors"]: "Link is required" });
        }
    }

    function AddYoutubeLinkInFilesDetails() {
        setAttachYoutubeLink({ ...AttachYoutubeLink, ["errors"]: "" });
        if (AttachYoutubeLink.link !== "") {
            var res = AttachYoutubeLink.link.match(AppConfigData.regex.YoutubeEmbed);
            if (res == null) {
                setAttachYoutubeLink({
                    ...AttachYoutubeLink,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = PostData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLink.name,
                    FilePath: AttachYoutubeLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setPostData({ ...PostData, ["FilesDetails"]: FilesDetails });
                setShowYoutubeLinkPopup(false);
                return true;
            }
        } else {
            setAttachYoutubeLink({
                ...AttachYoutubeLink,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetails() {
        setAttachFiles({ ...AttachFiles, ["errors"]: "" });
        if (AttachFiles.link !== null) {
            let FilesDetails = PostData.FilesDetails;
            for (let i = 0; i < AttachFiles.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFiles.link[i].name,
                    FilePath: AttachFiles.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFiles.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setPostData({ ...PostData, ["FilesDetails"]: FilesDetails });
            setShowFileSelectPopup(false);
        } else {
            setAttachFiles({
                ...AttachFiles,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIU(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }

        let handleChange = (e) => { };

        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor upload-file-text"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromPostAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {
                        <i
                            className="fa-regular fa-xmark pointer-cursor"
                            onClick={() =>
                                confirmAlert({
                                    title: "Delete",
                                    message: "Are you sure delete ?",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () =>
                                                fnDeleteFilesDetails(FilesDetail.FilesID, indexFile),
                                        },
                                        {
                                            label: "No",
                                        },
                                    ],
                                })
                            }
                        ></i>
                    }
                </div>
            </div>
        );
    }

    function fnDeleteFilesDetails(id, key) {
        let FilesDetails = PostData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setPostData({ ...PostData, ["FilesDetails"]: FilesDetails });
        }
    }

    function ViewFileFromPostAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    React.useEffect(() => {
        if (data[0].MemberType.toLowerCase() !== "student") {
            get_WallList_data(); // Function Call to get Api data.
        }
        document.getElementById("root").addEventListener("scroll", WindowScrolling);
        getcommonwallpost(1, "");
        getbirthdaylist();

        return () => {
            document
                .getElementById("root")
                .removeEventListener("scroll", WindowScrolling);
        };
    }, []);

    function OpenPostOverlay(e) {
        setPostData({
            FilesDetails: [],
            errors: {
                FileText: "",
            },
        });

        seteditorPostDetails(EditorState.createEmpty());
        setshowCreatePost(true);
        setTarget(e.target.parentNode.parentNode);
    }

    function validate() {
        let isValid = true;
        let errors = {};
        let HTMl = draftToHtml(convertToRaw(editorPostDetails.getCurrentContent()));
        if (PostData.FilesDetails.length === 0 && HTMl === "<p></p>\n") {
            errors.FileText = "Text or Files is required";
            isValid = false;
        } else {
            errors.FileText = "";
        }

        setPostData({
            ...PostData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function CreatePostSave() {
        if (validate()) {
            setIsPostSaveing(true);

            let formData = new FormData();

            formData.append(
                "PostCommentNote",
                draftToHtml(convertToRaw(editorPostDetails.getCurrentContent()))
            );
            formData.append("TagMemberIDs", "");
            formData.append("PostByType", data[0].PostByType);
            formData.append("WallID", WallListSelectedVal); // Change Wall ID Here , Pass As Per DDL Select

            formData.append("SaveType", "Post");
            formData.append("RequestFrom", "Web");
            let NewIndex = 0;
            for (let i = 0; i < PostData.FilesDetails.length; i++) {
                if (
                    PostData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    PostData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        PostData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        PostData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        PostData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        PostData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        PostData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        PostData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    PostData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    PostData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", PostData.FilesDetails[i].FilePath);
                }
            }
            const reqClasswork = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/postcomments/save", reqClasswork)
                .then((response) => response.json())
                .then(function (json) {
                    setIsPostSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        PostDataAdd(json.data.postCommentID);
                        commonService.sendNotification();
                        /*BindClassworkList();*/
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                    setshowCreatePost(false);
                });
        }
    }

    function PostDataAdd(PostCommentID) {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: PostCommentID,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/getcommonwallpost_byid",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Data = [json.data[0]].concat(Post.data);
                    setPost({ IsData: true, data: Data });
                }
            });
    }

    function CreatePostFileUI(Files) {
        let ImageFiles = [];
        let OtherFiles = [];
        for (let i = 0; i < Files.length; i++) {
            if (Files[i].FileType.toUpperCase() === "IMAGE") {
                ImageFiles.push({ File: Files[i], Index: i });
            } else {
                OtherFiles.push({ File: Files[i], Index: i });
            }
        }

        if (ImageFiles.length === 1) {
            return (
                <>
                    {
                        <div className="single-grid-container">
                            <div className="single-grid-one grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[0].File.FilePath
                                    }
                                    title={ImageFiles[0].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[0].Index)}
                                />
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //  className="document-area pointer-cursor"
                        //  key={indexFile}
                        //  onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //  <i
                        //    className={`icon ${
                        //      AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //    }`}
                        //  ></i>
                        //  <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else if (ImageFiles.length === 2) {
            return (
                <>
                    {
                        <div className="two-grid-container">
                            <div className="two-grid-one grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[0].File.FilePath
                                    }
                                    title={ImageFiles[0].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[0].Index)}
                                />
                            </div>
                            <div className="two-grid-two grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[1].File.FilePath
                                    }
                                    title={ImageFiles[1].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[1].Index)}
                                />
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //  className="document-area pointer-cursor"
                        //  key={indexFile}
                        //  onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //  <i
                        //    className={`icon ${
                        //      AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //    }`}
                        //  ></i>
                        //  <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else if (ImageFiles.length === 3) {
            return (
                <>
                    {
                        <div className="three-grid-container">
                            <div className="Three-grid-one grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[0].File.FilePath
                                    }
                                    title={ImageFiles[0].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[0].Index)}
                                />
                            </div>
                            <div className="Three-grid-two grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[1].File.FilePath
                                    }
                                    title={ImageFiles[1].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[1].Index)}
                                />
                            </div>
                            <div className="Three-grid-three grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[2].File.FilePath
                                    }
                                    title={ImageFiles[2].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[2].Index)}
                                />
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //    className="document-area pointer-cursor"
                        //    key={indexFile}
                        //    onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //    <i
                        //        className={`icon ${AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //            }`}
                        //    ></i>
                        //    <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else if (ImageFiles.length === 4) {
            return (
                <>
                    {
                        <div className="four-grid-container">
                            <div className="Four-grid-one grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[0].File.FilePath
                                    }
                                    title={ImageFiles[0].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[0].Index)}
                                />
                            </div>
                            <div className="Four-grid-Two grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[1].File.FilePath
                                    }
                                    title={ImageFiles[1].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[1].Index)}
                                />
                            </div>
                            <div className="Four-grid-three grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[2].File.FilePath
                                    }
                                    title={ImageFiles[2].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[2].Index)}
                                />
                            </div>
                            <div className="Four-grid-Four grid-image-responsive">
                                <img
                                    src={
                                        AppConfigData.APIFileBasePath + ImageFiles[3].File.FilePath
                                    }
                                    title={ImageFiles[3].File.Name}
                                    onClick={() => ViewPostFilePopup(Files, ImageFiles[3].Index)}
                                />
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //    className="document-area pointer-cursor"
                        //    key={indexFile}
                        //    onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //    <i
                        //        className={`icon ${AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //            }`}
                        //    ></i>
                        //    <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else if (ImageFiles.length === 5) {
            return (
                <>
                    {
                        <div className="five-image-area mb-3">
                            <div className="five-image-container">
                                <div className="five-image-one grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[0].File.FilePath
                                        }
                                        title={ImageFiles[0].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[0].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-two grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[1].File.FilePath
                                        }
                                        title={ImageFiles[1].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[1].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-Three grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[2].File.FilePath
                                        }
                                        title={ImageFiles[2].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[2].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-four grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[3].File.FilePath
                                        }
                                        title={ImageFiles[3].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[3].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image--five grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[4].File.FilePath
                                        }
                                        title={ImageFiles[4].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[4].Index)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //    className="document-area pointer-cursor"
                        //    key={indexFile}
                        //    onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //    <i
                        //        className={`icon ${AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //            }`}
                        //    ></i>
                        //    <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else if (ImageFiles.length > 5) {
            return (
                <>
                    {
                        <div className="five-image-area">
                            <div className="five-image-container">
                                <div className="five-image-one grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[0].File.FilePath
                                        }
                                        title={ImageFiles[0].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[0].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-two grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[1].File.FilePath
                                        }
                                        title={ImageFiles[1].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[1].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-Three grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[2].File.FilePath
                                        }
                                        title={ImageFiles[2].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[2].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image-four grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[3].File.FilePath
                                        }
                                        title={ImageFiles[3].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[3].Index)
                                        }
                                    />
                                </div>
                                <div className="five-image--five grid-image-responsive">
                                    <img
                                        src={
                                            AppConfigData.APIFileBasePath +
                                            ImageFiles[4].File.FilePath
                                        }
                                        title={ImageFiles[4].File.Name}
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[4].Index)
                                        }
                                    />
                                    <div
                                        className="five-plus-more"
                                        onClick={() =>
                                            ViewPostFilePopup(Files, ImageFiles[4].Index)
                                        }
                                    >
                                        +{ImageFiles.length - 5} More
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {OtherFiles.map((itemFile, indexFile) => (
                        <label
                            title={itemFile.File.Name}
                            onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                            key={indexFile}
                            className={`${itemFile.File.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                        >
                            {itemFile.File.FileType}
                        </label>
                        //<div
                        //    className="document-area pointer-cursor"
                        //    key={indexFile}
                        //    onClick={() => ViewPostFilePopup(Files, itemFile.Index)}
                        //>
                        //    <i
                        //        className={`icon ${AppConfigData.fileIcon[itemFile.File.FileType.toUpperCase()]
                        //            }`}
                        //    ></i>
                        //    <span>{itemFile.File.Name}</span>
                        //</div>
                    ))}
                </>
            );
        } else {
            return Files.map((itemFile, indexFile) => (
                <label
                    title={itemFile.Name}
                    onClick={() => ViewPostFilePopup(Files, indexFile)}
                    key={indexFile}
                    className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                >
                    {itemFile.FileType}
                </label>
                //<div
                //    className="document-area pointer-cursor"
                //    key={indexFile}
                //    onClick={() => ViewPostFilePopup(Files, indexFile)}
                //>
                //    <i
                //        className={`icon ${AppConfigData.fileIcon[itemFile.FileType.toUpperCase()]
                //            }`}
                //    ></i>
                //    <span>{itemFile.Name}</span>
                //</div>
            ));
        }
    }

    function RedirectOnMenuPageForAddMode(type) {
        history.push(type);
    }

    function approvereadpost(AID, AT) {
        const reqreadapprovepostdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associatinID: AID,
                associationType: AT
            })
        }
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readapprovepost",
            reqreadapprovepostdata
        ).then((response) => response.json())

            .then(function (json) {
                if (json.status_code === 1) {

                }

                else if (json.status_code === 0) {


                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }

    function deletepostdata(postcommentid, associationid, associationtype) {
        const reqdeletepostdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                postCommentID: postcommentid,
                associatinID: associationid,
                associationType: associationtype
            })
        }
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/deletepost",
            reqdeletepostdata
        ).then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    document.getElementById("post_" + postcommentid).remove();
                }
                else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });

                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnAssociationTypeLable(item) {
        let IsAbleToDelete = false;
        if (data[0].MemberID === item.MemberID) {
            if (item.AssociationType.toLowerCase() === "circular") {
                IsAbleToDelete = commonService.getLoginRoleData("Circular", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "post") {
                IsAbleToDelete = commonService.getLoginRoleData("Wall", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "event") {
                IsAbleToDelete = commonService.getLoginRoleData("Event", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "homework") {
                IsAbleToDelete = commonService.getLoginRoleData("Homework", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "classwork") {
                IsAbleToDelete = commonService.getLoginRoleData("Classwork", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "note") {
                IsAbleToDelete = commonService.getLoginRoleData("Notice", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "teacherscommunication") {
                IsAbleToDelete = commonService.getLoginRoleData("Teachers Communication", "IsDelete")
            }
            else if (item.AssociationType.toLowerCase() === "poll") {
                IsAbleToDelete = commonService.getLoginRoleData("Poll", "IsDelete")
            }
        }

        if (IsAbleToDelete) {
            return (
                <label
                    className={`wall-label ${item.AssociationType.toLowerCase()}-label pointer-cursor`}
                    onClick={() => confirmAlert({
                        title: "Delete",
                        message: "Are you sure delete ?",
                        buttons: [
                            {
                                label: "Yes",
                                onClick: () =>
                                    deletepostdata(item.PostCommentID, item.AssociationID, item.AssociationType),
                            },
                            {
                                label: "No",
                            },
                        ],
                    })
                    }
                >
                    {item.AssociationType}
                    <i class="fa-regular fa-trash-can  ml-2"></i>

                </label>
            )
        }
        else {
            return (
                <label className={`wall-label ${item.AssociationType.toLowerCase()}-label`}> {item.AssociationType}</label>
            )
        }
    }

    return (
        <>
            <div
                className="background-theme-color sticky-top mt-3 wall-page-top-section"
                id="wall-page-header"
            >
                <nav className="navbar navbar-expand-sm wall-page-navbar row">
                    <ul className="navbar-nav header-content col-md-12 col-xl-8 col-xl-8 pl-0 justify-content-between">
                        <li className="nav-item active">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === ""
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "" ? "active" : ""
                                    }`}
                                attr-type=""
                                onClick={(e) => getcommonwallpost(1, "")}
                            >
                                {commonService.LabelDisplayText("All")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "POST"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "POST" ? "active" : ""
                                    }`}
                                attr-type="POST"
                                onClick={(e) => getcommonwallpost(1, "POST")}
                            >
                                {commonService.LabelDisplayText("Post")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "HOMEWORK"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "HOMEWORK"
                                    ? "active"
                                    : ""
                                    }`}
                                attr-type="HOMEWORK"
                                onClick={(e) => getcommonwallpost(1, "HomeWork")}
                            >
                                {commonService.LabelDisplayText("Homework")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "CLASSWORK"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "CLASSWORK"
                                    ? "active"
                                    : ""
                                    }`}
                                attr-type="CLASSWORK"
                                onClick={(e) => getcommonwallpost(1, "ClassWork")}
                            >
                                {commonService.LabelDisplayText("ClassworkTitle")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "CIRCULAR"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "CIRCULAR"
                                    ? "active"
                                    : ""
                                    }`}
                                attr-type="CIRCULAR"
                                onClick={(e) => getcommonwallpost(1, "Circular")}
                            >
                                {commonService.LabelDisplayText("Circular")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "EVENTS"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "EVENTS"
                                    ? "active"
                                    : ""
                                    }`}
                                attr-type="EVENTS"
                                onClick={(e) => getcommonwallpost(1, "Events")}
                            >
                                {commonService.LabelDisplayText("Events")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                id={
                                    FilterAssociationType.toUpperCase() === "NOTE"
                                        ? "FilterAssociationType_Active"
                                        : ""
                                }
                                className={`nav-link ${FilterAssociationType.toUpperCase() === "NOTE" ? "active" : ""
                                    }`}
                                attr-type="NOTE"
                                onClick={(e) => getcommonwallpost(1, "Note")}
                            >
                                {commonService.LabelDisplayText("Notice")}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-8">
                    <div
                        className="sticky-top sticky-top-90 margin-adjustmnet-b3 wall-page-search-section"
                        id="wall-page-search"
                    >
                        <div
                            className="posting-section d-flex align-items-center justify-content-between"
                            ref={ref}
                        >
                            <div className="user-img">
                                <div
                                    className="profile-area search-section-area wall-page-profile-area"
                                    style={{
                                        backgroundColor: commonService.CreateColor(data[0].Initials)
                                            .BGColor,
                                        color: commonService.CreateColor(data[0].Initials)
                                            .FontColor,
                                    }}
                                >
                                    {data[0].Avatar != "" ? (
                                        <img src={AppConfigData.ERPFileBasePath + data[0].Avatar} />
                                    ) : (
                                        data[0].Initials
                                    )}
                                </div>
                            </div>
                            <div className="text-box-for-post wall-page-post-search">
                                <input
                                    type="text"
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    name={"txtPostSearch"}
                                    id={"txtPostSearch"}
                                />
                            </div>

                            {IsPostDataLodding === false ? (
                                <div
                                    className="search-icon-img pointer-cursor mr-2"
                                    onClick={() => PostSearching()}
                                >
                                    <i className="fa-regular fa-magnifying-glass"></i>
                                </div>
                            ) : (
                                <div className="search-icon-img pointer-cursor mr-2">
                                    <Spinner animation="border" size="sm" variant="primary" />
                                </div>
                            )}

                            {data[0].MemberType.toLowerCase() !== "student" && (
                                <div className="plus-btn pointer-cursor custom-plus-btn floating-button">
                                    <DropdownButton className="overlay" as={ButtonGroup}>
                                        {
                                            commonService.getLoginRoleData("Wall", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="1"
                                                className="dropdown-post-section"
                                                onClick={(e) => OpenPostOverlay(e)}
                                            >
                                                <i className="fa-light fa-circle-exclamation"></i>
                                                <p>{commonService.LabelDisplayText("Post")}</p>
                                            </Dropdown.Item>
                                        }

                                        {
                                            commonService.getLoginRoleData("Homework", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="2"
                                                className="dropdown-homework-section"
                                                onClick={() =>
                                                    RedirectOnMenuPageForAddMode("homework?add=homework")
                                                }
                                            >
                                                <i className="fa-light fa-book-open-cover"></i>{" "}
                                                <p>{commonService.LabelDisplayText("Homework")}</p>
                                            </Dropdown.Item>
                                        }

                                        {
                                            commonService.getLoginRoleData("Classwork", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="3"
                                                className="dropdown-classwork-section"
                                                onClick={() =>
                                                    RedirectOnMenuPageForAddMode("classwork?add=classwork")
                                                }
                                            >
                                                <i className="fa-light fa-book-open-reader"></i>{" "}
                                                <p>{commonService.LabelDisplayText("ClassworkTitle")}</p>
                                            </Dropdown.Item>
                                        }

                                        {
                                            commonService.getLoginRoleData("Circular", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="4"
                                                className="dropdown-circular-section"
                                                onClick={() =>
                                                    RedirectOnMenuPageForAddMode(
                                                        "notecircualreevents?add=circular"
                                                    )
                                                }
                                            >
                                                <i className="fa-light fa-arrows-rotate"></i>{" "}
                                                <p>{commonService.LabelDisplayText("Circular")}</p>
                                            </Dropdown.Item>
                                        }

                                        {
                                            commonService.getLoginRoleData("Event", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="5"
                                                className="dropdown-event-section"
                                                onClick={() =>
                                                    RedirectOnMenuPageForAddMode(
                                                        "notecircualreevents?add=event"
                                                    )
                                                }
                                            >
                                                <i className="fa-light fa-calendar-days"></i>{" "}
                                                <p>{commonService.LabelDisplayText("Events")}</p>
                                            </Dropdown.Item>
                                        }
                                        {
                                            commonService.getLoginRoleData("Notice", "IsCreate") &&
                                            <Dropdown.Item
                                                eventKey="6"
                                                className="dropdown-note-section"
                                                onClick={() =>
                                                    RedirectOnMenuPageForAddMode(
                                                        "notecircualreevents?add=note"
                                                    )
                                                }
                                            >
                                                <i className="fa-light fa-hexagon-exclamation"></i>{" "}
                                                <p>{commonService.LabelDisplayText("Notice")}</p>
                                            </Dropdown.Item>
                                        }
                                    </DropdownButton>
                                </div>
                            )}

                            <Overlay
                                show={showCreatePost}
                                target={target}
                                placement="bottom"
                                container={ref}
                                containerPadding={20}
                                className="post-portion"
                            >
                                <Popover id="popover-contained" className="post-body">
                                    <Popover.Body>
                                        <div className="select-dropdown-wall mb-3">
                                            <Select
                                                placeholder={"Select Wall"}
                                                options={WallList}
                                                onChange={(e) => setWallListSelectedVal(e.value)}
                                                value={WallList.find(
                                                    (obj) => obj.value === WallListSelectedVal
                                                )}
                                            />
                                        </div>
                                        <Editor
                                            toolbarHidden={
                                                data[0].MemberType.toLowerCase() === "student"
                                            }
                                            readOnly={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "readOnly"
                                            }
                                            toolbar={AppConfigData.toolbarConfig}
                                            editorState={editorPostDetails}
                                            onEditorStateChange={seteditorPostDetails}
                                        />

                                        {/*Add Wall Selection DDL Here*/}

                                        <div className="file-seleciton-post-section">
                                            <div>
                                                <div className="mt-3 dropdown">
                                                    <DropdownButton
                                                        title="Add"
                                                        className="mt-3"
                                                        variant=" text-editor "
                                                    >
                                                        <Dropdown.Item
                                                            eventKey="1"
                                                            className="dropdown-item"
                                                            onClick={() => setShowLinkPopup(true)}
                                                        >
                                                            <i className="fa-solid fa-link-simple"></i> Link
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            eventKey="2"
                                                            className="dropdown-item"
                                                            onClick={() => setShowFileSelectPopup(true)}
                                                        >
                                                            <i className="fa-regular fa-paperclip"></i> File
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            eventKey="3"
                                                            className="dropdown-item"
                                                            onClick={() => setShowYoutubeLinkPopup(true)}
                                                        >
                                                            <i className="fa-brands fa-youtube"></i> YouTube
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </div>

                                            <div className="post-button-section mt-3">
                                                {IsPostSaveing === false ? (
                                                    <button
                                                        className="post-select-btn mr-2"
                                                        onClick={() => CreatePostSave()}
                                                    >
                                                        {commonService.LabelDisplayText("Post")}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="post-select-btn mr-2"
                                                        type="button"
                                                        disabled="disabled"
                                                    >
                                                        <Spinner animation="border" size="sm" /> Posting...
                                                    </button>
                                                )}

                                                <button
                                                    className="post-select-btn cancel-selection-button"
                                                    onClick={() => setshowCreatePost(false)}
                                                >
                                                    {commonService.LabelDisplayText("Cancel")}
                                                </button>
                                            </div>
                                        </div>
                                        {PostData.errors.FileText && (
                                            <span className="error-message">
                                                {PostData.errors.FileText}
                                            </span>
                                        )}
                                        <div className="row">
                                            <div className="col-12 selected-files-area">
                                                <div className="row">
                                                    {PostData.FilesDetails.map((itemFile, indexFile) => (
                                                        <div
                                                            className="card homework-page-card mt-3 post-selected-files"
                                                            key={indexFile}
                                                        >
                                                            {CreateFilesDetailsIU(itemFile, indexFile)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                        </div>
                    </div>
                    <div>

                        {!Post.IsData ? (
                            <_Skeleton type={_SkeletonType.Post} count={4} />
                        ) : Post.data.length === 0 ? (
                            <_NoDataFound fontsize={"200px"} />
                        ) : (
                            <>
                                {
                                    Post.data.map((item, index) => (
                                        <div
                                            className={`card-for-wall mb-3 ${item.IsRead == 0 ? 'callisreadapprove' : ''}`}
                                            key={index}
                                            id={"post_" + item.PostCommentID}
                                            aid={item.AssociationID}
                                            at={item.AssociationType}

                                        >
                                            <div className="upper-section-area">
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            className="wall-post user-pic mr-3 "
                                                            style={{
                                                                backgroundColor: commonService.CreateColor(
                                                                    item.Initials
                                                                ).BGColor,
                                                                color: commonService.CreateColor(item.Initials)
                                                                    .FontColor,
                                                            }}
                                                        >
                                                            {item.Avatar !== "" ? (
                                                                <img
                                                                    src={AppConfigData.ERPFileBasePath + item.Avatar}
                                                                />
                                                            ) : (
                                                                item.Initials
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div className="birthday-name">
                                                                {item.DisplayName}
                                                            </div>
                                                            <div>
                                                                <span className="birthday-details">
                                                                    {commonService.getDateTimeInFormat(
                                                                        item.DateOfPost
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {fnAssociationTypeLable(item)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body-section-area">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.PostCommentNote,
                                                    }}
                                                ></p>
                                                {item.Files && CreatePostFileUI(JSON.parse(item.Files))}
                                            </div>
                                            <div className="like-section-area">
                                                <div className="reaction">
                                                    <div
                                                        className={`pointer-cursor like-section ${item.IsLike ? "active" : ""
                                                            }`}
                                                        onClick={() =>
                                                            LikePost(
                                                                {
                                                                    postCommentID: item.PostCommentID,
                                                                    postType: item.AssociationType,
                                                                    isLike: !item.IsLike,
                                                                    wallID: data[0].WallID,
                                                                },
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-light fa-thumbs-up"></i>
                                                        <i className="fa-solid fa-thumbs-up"></i>
                                                        <span>
                                                            <span>{item.TotalLikes}</span> {commonService.LabelDisplayText("Likes")}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={`pointer-cursor dislike-section ${item.IsDislike ? "active" : ""
                                                            }`}
                                                        onClick={() =>
                                                            DisLikePost(
                                                                {
                                                                    postCommentID: item.PostCommentID,
                                                                    postType: item.AssociationType,
                                                                    isDisLike: !item.IsDislike,
                                                                    wallID: data[0].WallID,
                                                                },
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <i className="fa-light fa-thumbs-down"></i>
                                                        <i className="fa-solid fa-thumbs-down"></i>
                                                        <span>
                                                            <span>{item.TotalDislike}</span> {commonService.LabelDisplayText("Dislike")}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div title="Message Posted On">
                                                    {" "}
                                                    <span className="message-posted-by">
                                                        {" "}
                                                        <i class="fa-regular fa-message-dots mr-2"></i>
                                                        {item.PostedOn}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    Post.data.length !== 0 && IsPostDataLodding &&
                                    <_Skeleton type={_SkeletonType.Post} count={4} />
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-4">
                    <div className="card sticky-top sticky-top-90">
                        <div className="card-header bg-transparent">
                            <h3 className="m-0">{commonService.LabelDisplayText("Birthdays")}</h3>
                        </div>
                        <div className="card-body padding-bottom-150px birthday-column-section">
                            {!BirthDay.IsData ? (
                                <div>
                                    <_Skeleton type={_SkeletonType.BirthDay} />
                                    <_Skeleton type={_SkeletonType.BirthDay} />
                                    <_Skeleton type={_SkeletonType.BirthDay} />
                                    <_Skeleton type={_SkeletonType.BirthDay} />
                                    <_Skeleton type={_SkeletonType.BirthDay} />
                                </div>
                            ) : BirthDay.data.length === 0 ? (
                                <_NoDataFound fontsize={"100px"} />
                            ) : (
                                BirthDay.data.map((item, index) => (
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="wall-post user-pic mr-3"
                                                style={{
                                                    backgroundColor: commonService.CreateColor(
                                                        item.Initials
                                                    ).BGColor,
                                                    color: commonService.CreateColor(item.Initials)
                                                        .FontColor,
                                                }}
                                            >
                                                {item.Avatar !== "" ? (
                                                    <img
                                                        src={AppConfigData.ERPFileBasePath + item.Avatar}
                                                    />
                                                ) : (
                                                    item.Initials
                                                )}
                                            </div>
                                            <div>
                                                <div className="birthday-name">{item.Name}</div>
                                                <div>
                                                    <span className="birthday-details extra-dot">
                                                        {item.MemberType}
                                                    </span>
                                                    <span className="birthday-details">
                                                        <label className="std-homework-label ml-2">
                                                            {item.STDDIVDEPT}
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowLinkPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddLink")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["name"]: e.target.value })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["link"]: e.target.value })
                                }
                                className={`form-control ${AttachLink.errors && "invalid"}`}
                                placeholder="Add link here"
                            />
                            {AttachLink.errors && (
                                <span className="error-message">{AttachLink.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddLinkInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showYoutubeLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowYoutubeLinkPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddYoutube")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["name"]: e.target.value,
                                    })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["link"]: e.target.value,
                                    })
                                }
                                className={`form-control  ${AttachYoutubeLink.errors && "invalid"
                                    }`}
                                placeholder="Add youtube embed link here"
                            />
                            {AttachYoutubeLink.errors && (
                                <span className="error-message">
                                    {AttachYoutubeLink.errors}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddYoutubeLinkInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showFileSelectPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowFileSelectPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 choose-file-input">
                            <input
                                type="file"
                                className={`form-control  ${AttachFiles.errors && "invalid"}`}
                                multiple
                                onChange={(e) =>
                                    setAttachFiles({ ...AttachFiles, ["link"]: e.target.files })
                                }
                            />
                            {AttachFiles.errors && (
                                <span className="error-message">{AttachFiles.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddFileSelectInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Wall;
