import React, { useState, useRef } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { Link, useHistory } from "react-router-dom";
import ScienecImage from "../Images/main-bg-image/science-card-bg.svg";
import MathsImage from "../Images/main-bg-image/maths-card-bg.svg";
import EnglishImage from "../Images/main-bg-image/english-card-bg.svg";
import SocialScienceImage from "../Images/main-bg-image/socialScience-card-bg.svg";
import GujratiImage from "../Images/main-bg-image/gujarati-card-bg.svg";
import hindiImage from "../Images/main-bg-image/hindi-card-bg.svg";
import HealthImage from "../Images/main-bg-image/health-card-bg.svg";
import ComputerLabImage from "../Images/main-bg-image/lab-card-bg.svg";
import DrawingImage from "../Images/main-bg-image/drawing-card-bg.svg";
import ChemistryImage from "../Images/main-bg-image/chemistry-card-bg.svg";
import MusicImage from "../Images/main-bg-image/music-card-bg.svg";
import BiologyImage from "../Images/main-bg-image/biology-card-bg.svg";
import SportImage from "../Images/main-bg-image/sports-card-bg.svg";
import ForeignIanguageImage from "../Images/main-bg-image/foreign-card-bg.svg";
import PhysicsImage from "../Images/main-bg-image/physics-card-bg.svg";
import PtImage from "../Images/main-bg-image/pt-card-bg.svg";
import ArtImage from "../Images/main-bg-image/art-card-bg.svg";
import { alertService, AlertType } from "../_services/alert.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";

function SubjectListing() {
    const history = useHistory();
    const [SubjectList, setSubjectList] = useState({ IsData: false, data: [] });

    React.useEffect(() => {
        if (commonService.getLoginRoleData("LMS Subject List", "IsView")) {
            GetSubjectList();
        }
        else {
            history.push("/access");
        }
    }, []);

    function ViewSubjectDetailPage(item) {
        /* history.push("/examsyllabus");*/
        history.push({
            pathname: "/subjectdetails",
            state: item,
        });
    }

    function GetSubjectList() {
        const reqbody = {
            method: "POST",
            headers: commonService.getHeaders(),
        };

        fetch(AppConfigData.APIBasePath + "v1/lms/getsubjectlist", reqbody)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setSubjectList({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setSubjectList({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    // history.push("/");
                }
            });
    }
    const RandomClassName = ["pink-card", "purple-card", "blue-card", "lightBlue-card", "yellow-card", "orange-card"];
    function fnGetRandomClassName(index) {
        return RandomClassName[index%6];
    }
    return (
        <div>
            <div className="container p-0 mt-4">
                <div className="row">
                    <div className="col-12">
                        <div className="card lms-home-main-card">
                            <div className="card-body">
                                <h2>
                                    {commonService.LabelDisplayText("Whatdoyouwanttolearn")} <br />
                                    {commonService.LabelDisplayText("today")}?
                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="lms-subject-header">{commonService.LabelDisplayText("YourSubjectstostudy")}</h3>
                <div className="row">
                    {!SubjectList.IsData ? (
                        <_Skeleton type={_SkeletonType.SubjectList} lineheight={30}/>
                    ) : SubjectList.data.length === 0 ? (
                        <_NoDataFound fontsize={"200px"} />
                    ) : (
                        SubjectList.data.map((item, index) => (
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <a onClick={(e) => ViewSubjectDetailPage(item)}>
                                    <div className={`card subject-card ${fnGetRandomClassName(index)}`}>
                                        <div className="card-body">

                                            <h4>{item.SubjectName}</h4>
                                            <h6 className="standarname">{commonService.LabelDisplayText("STD")} : {item.StandardName}</h6>
                                            <div className="subject-image">
                                                <img
                                                    src={
                                                        AppConfigData.ERPFileBasePathLMS + item.ImagePath
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default SubjectListing;
