import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { alertService, AlertType } from "../_services/alert.service";
import { commonService, AppConfigData } from "../_services/common.service";
import Spinner from "react-bootstrap/Spinner";
import DarkModeToggle from "react-dark-mode-toggle";
import img3 from "../Images/logo/3.png"
import img4 from "../Images/logo/4.png"

function Login() {

    const [isDarkMode, setIsDarkMode] = useState(() => false);
    document.getElementById("main").classList.add("login");
    document.getElementById("main").classList.remove("menu-open");
    document.getElementById("main").style.marginLeft = null;

    const history = useHistory();
    const [input, setInput] = useState({
        mobileNumber: "",
        otpNumber: "",
        requestFrom: "WEB",
        errors: {
            mobileNumber: "",
            otpNumber: "",
        },
    });

    const [ViewMode, setViewMode] = useState("");
    const [ShowPassword, setShowPassword] = useState(false);

    const [ForgotPassInput, setForgotPassInput] = useState({
        mobileNumber: "",
        otpNumber: "",
        newPassword: "",
        confirmPassword: "",
        requestFrom: "WEB",
        errors: {
            mobileNumber: "",
            otpNumber: "",
            newPassword: "",
            confirmPassword: "",
        },
    });

    const [IsloginClick, setIsloginClick] = useState(false);

    const [IsforgotPasswordSendOTPClick, setIsforgotPasswordSendOTPClick] =
        useState(false);

    const [
        IsforgotPasswordVerifyAndUpdatePasswordClick,
        setIsforgotPasswordVerifyAndUpdatePasswordClick,
    ] = useState(false);

    function fnLoginPageMode(Mode) {
        setViewMode(Mode);
        if (Mode === "Login") {
            setForgotPassInput({
                mobileNumber: "",
                otpNumber: "",
                newPassword: "",
                confirmPassword: "",
                requestFrom: "WEB",
                errors: {
                    mobileNumber: "",
                    otpNumber: "",
                    newPassword: "",
                    confirmPassword: "",
                },
            });
        }
    }

    function loginClick() {
        if (validate()) {
            setIsloginClick(true);
            const requestMetadata = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify(input),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/user/checklogindetailsverifiypassword",
                requestMetadata
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsloginClick(false);
                    if (json.status_code === 1) {
                        commonService.setItem("switch", JSON.stringify(json.data));
                        history.push("switch");
                    } else {
                        alertService.alert({
                            type: AlertType.Error,
                            message: "Invalid Login Details",
                        });
                    }
                });
        }
    }

    function validate() {
        let isValid = true;
        let errors = {};
        if (!input.mobileNumber) {
            errors.mobileNumber = "Mobile No. or GR No. is required";
            isValid = false;
        } else {
            errors.mobileNumber = "";
        }

        if (!input.otpNumber) {
            errors.otpNumber = "Password is required";
            isValid = false;
        } else {
            errors.otpNumber = "";
        }
        setInput({
            ...input,
            ["errors"]: errors,
        });

        return isValid;
    }

    function forgotPasswordSendOTPClick() {
        if (forgotPasswordSendOTPValidate()) {
            setIsforgotPasswordSendOTPClick(true);
            const requestMetadata = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    mobileNumber: ForgotPassInput.mobileNumber,
                    otpNumber: "",
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/user/forgotpasswordcheckdetail",
                requestMetadata
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsforgotPasswordSendOTPClick(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        fnLoginPageMode("Set New Password");
                    } else {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    }
                });
        }
    }

    function forgotPasswordSendOTPValidate() {
        let isValid = true;
        let errors = {
            mobileNumber: "",
            otpNumber: "",
            newPassword: "",
            confirmPassword: "",
        };
        if (!ForgotPassInput.mobileNumber) {
            errors.mobileNumber = "Mobile No. or GR No. is required";
            isValid = false;
        } else {
            errors.mobileNumber = "";
        }

        setForgotPassInput({
            ...ForgotPassInput,
            ["errors"]: errors,
        });

        return isValid;
    }

    function forgotPasswordVerifyAndUpdatePasswordClick() {
        if (forgotPasswordVerifyAndUpdatePasswordValidate()) {
            setIsforgotPasswordVerifyAndUpdatePasswordClick(true);
            const requestMetadata = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    mobileNumber: ForgotPassInput.mobileNumber,
                    otpNumber: ForgotPassInput.otpNumber,
                    newPassword: ForgotPassInput.newPassword,
                    requestFrom: "web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/user/forgotpasswordverifydetail",
                requestMetadata
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });

                        const requestMetadata = {
                            method: "POST",
                            headers: commonService.getHeaders(),
                            body: JSON.stringify({
                                mobileNumber: ForgotPassInput.mobileNumber,
                                otpNumber: ForgotPassInput.newPassword,
                                requestFrom: "WEB",
                                errors: {
                                    mobileNumber: "",
                                    otpNumber: "",
                                },
                            }),
                        };
                        fetch(
                            AppConfigData.APIBasePath +
                            "v1/user/checklogindetailsverifiypassword",
                            requestMetadata
                        )
                            .then((response) => response.json())
                            .then(function (json) {
                                setIsloginClick(false);
                                if (json.status_code === 1) {
                                    commonService.setItem("switch", JSON.stringify(json.data));
                                    history.push("switch");
                                } else {
                                    alertService.alert({
                                        type: AlertType.Error,
                                        message: "Invalid Login Details",
                                    });
                                }
                            });
                    } else {
                        setIsforgotPasswordVerifyAndUpdatePasswordClick(false);
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    }
                });
        }
    }

    function forgotPasswordVerifyAndUpdatePasswordValidate() {
        let isValid = true;
        let errors = {
            mobileNumber: "",
            otpNumber: "",
            newPassword: "",
            confirmPassword: "",
        };
        if (!ForgotPassInput.otpNumber) {
            errors.otpNumber = "OTP number is required";
            isValid = false;
        } else {
            errors.otpNumber = "";
        }

        if (!ForgotPassInput.newPassword) {
            errors.newPassword = "New password is required";
            isValid = false;
        } else {
            errors.newPassword = "";
        }

        if (!ForgotPassInput.confirmPassword) {
            errors.confirmPassword = "Confirm password is required";
            isValid = false;
        } else {
            errors.confirmPassword = "";
        }

        if (
            ForgotPassInput.newPassword !== "" &&
            ForgotPassInput.confirmPassword !== ""
        ) {
            if (ForgotPassInput.confirmPassword !== ForgotPassInput.newPassword) {
                errors.confirmPassword = "New password and confirm password not match";
                isValid = false;
            } else {
                errors.confirmPassword = "";
            }
        }

        setForgotPassInput({
            ...ForgotPassInput,
            ["errors"]: errors,
        });

        return isValid;
    }

    function ChangeThemeMode(e) {
        
        setIsDarkMode(e);
        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.hasAttribute("data-theme")) {
            htmlTag.removeAttribute("data-theme");
            return window.localStorage.removeItem("site-theme");
        }
        htmlTag.setAttribute("data-theme", "dark");
        window.localStorage.setItem("site-theme", "dark");
    }

    function applyInitialTheme() {
        
        let theme = window.localStorage.getItem("site-theme");
        if (theme === null) {
            theme = "";
        }
        if (theme === "null") {
            theme = "";
        }
        if (theme !== null && theme !== "") {
            if (theme === "dark" && theme !== "null") {
                setIsDarkMode(true);
            }
            const htmlTag = document.getElementsByTagName("html")[0];
            htmlTag.setAttribute("data-theme", theme);
        }
    }

    React.useEffect(() => {
        applyInitialTheme();
        if (history !== null && history !== undefined) {
            if (history.location !== null && history.location !== undefined) {
                if (history.location.search !== null && history.location.search !== undefined && history.location.search !== "") {
                    const queryParams = new URLSearchParams(history.location.search);
                    const a = queryParams.get("a")
                    const b = queryParams.get("b")
                    if (a !== null && b !== null) {
                        if (a !== undefined && b !== undefined) {
                            if (a !== "" && b !== "") {
                                setViewMode("");
                                setIsloginClick(true);
                                const requestMetadata = {
                                    method: "POST",
                                    headers: commonService.getHeaders(),
                                    body: JSON.stringify({
                                        mobileNumber: a,
                                        otpNumber: b,
                                        requestFrom: "WEB",
                                        errors: {
                                            mobileNumber: "",
                                            otpNumber: "",
                                        },
                                    }),
                                };
                                fetch(
                                    AppConfigData.APIBasePath + "v1/user/checklogindetailsverifiypasswordbyerp",
                                    requestMetadata
                                )
                                    .then((response) => response.json())
                                    .then(function (json) {
                                        setIsloginClick(false);
                                        setViewMode("Login");
                                        if (json.status_code === 1) {
                                            commonService.setItem("switch", JSON.stringify(json.data));
                                            history.push("switch");
                                        } else {
                                            alertService.alert({
                                                type: AlertType.Error,
                                                message: "Invalid Login Details",
                                            });
                                        }
                                    });
                            }
                        }
                    }
                }
                else {
                    setViewMode("Login");
                }
            }
            else {
                setViewMode("Login");
            }
        }
        else {
            setViewMode("Login");
        }
    }, []);

    function showPassword() {
        setShowPassword(!ShowPassword);
    }

    return (
        ViewMode &&
        <>
            <div className="d-flex justify-content-end">
                <DarkModeToggle
                    onChange={(e) => ChangeThemeMode(e)}
                    checked={isDarkMode}
                    size={50}
                    className="dark-theme-toggle ml-2"
                />
            </div>
            <div className="login-main-container">
                <div className="log-in-form loginpage">
                    <div className="login-main-area ">
                        <div className="logo-area">
                            <img src={img3} className="main-logo main-logo-light" />
                            <img src={img4} className="main-logo main-logo-dark" />
                            {/*<span className="icon-light-logo">*/}
                            {/*    <span className="path1"></span>*/}
                            {/*    <span className="path2"></span>*/}
                            {/*    <span className="path3"></span>*/}
                            {/*    <span className="path4"></span>*/}
                            {/*    <span className="path5"></span>*/}
                            {/*    <span className="path6"></span>*/}
                            {/*    <span className="path7"></span>*/}
                            {/*    <span className="path8"></span>*/}
                            {/*    <span className="path9"></span>*/}
                            {/*    <span className="path10"></span>*/}
                            {/*</span>*/}
                        </div>
                        <div className="body-area">
                            {ViewMode === "Login" && (
                                <form action="#" className="form">
                                    <div className="form-group">
                                        <i className="fa-regular fa-mobile-screen-button form-group-icon"></i>

                                        <input
                                            value={input.mobileNumber}
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }
                                            type="text"
                                            name="mobileNumber"
                                            className={`form-control mobile-gr-number${input.errors.mobileNumber && "invalid"
                                                }`}
                                            placeholder="Mobile No. or GR No."
                                        />
                                        {input.errors.mobileNumber && (
                                            <span className="error-message">
                                                {input.errors.mobileNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <i className="fa-regular fa-lock-keyhole form-group-icon"></i>
                                        {ShowPassword ? (
                                            <input
                                                value={input.otpNumber}
                                                onChange={(e) =>
                                                    setInput({
                                                        ...input,
                                                        [e.target.name]: e.target.value,
                                                    })
                                                }
                                                type="text"
                                                name="otpNumber"
                                                className={`form-control txtpassword ${input.errors.otpNumber && "invalid"
                                                    }`}
                                                placeholder="Password"
                                            />
                                        ) : (
                                            <input
                                                value={input.otpNumber}
                                                onChange={(e) =>
                                                    setInput({
                                                        ...input,
                                                        [e.target.name]: e.target.value,
                                                    })
                                                }
                                                type="password"
                                                name="otpNumber"
                                                className={`form-control txtpassword ${input.errors.otpNumber && "invalid"
                                                    }`}
                                                placeholder="Password"
                                            />
                                        )}
                                        {ShowPassword ? (
                                            <a onClick={showPassword}>{commonService.LabelDisplayText("Hide")}</a>
                                        ) : (
                                            <a onClick={showPassword}>{commonService.LabelDisplayText("Show")}</a>
                                        )}

                                        {input.errors.otpNumber && (
                                            <span className="error-message">
                                                {input.errors.otpNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-check login-selection-data-area">
                                        <label
                                            htmlFor="rememberMe"
                                            className="form-check-label"
                                        ></label>
                                        <label htmlFor="forgot-pass" className="forgot-pass">
                                            <a onClick={(e) => fnLoginPageMode("Forgot Password")}>
                                                {commonService.LabelDisplayText("ForgetPassword")} ?
                          </a>
                                        </label>
                                    </div>
                                    <div className="login-btn-class">
                                        {IsloginClick === false ? (
                                            <a
                                                className="btn-for-login-classroom"
                                                onClick={loginClick}
                                            >
                                                {commonService.LabelDisplayText("Login")}
                                            </a>
                                        ) : (
                                            <a className="btn-for-login-classroom">
                                                <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Login")}...
                                            </a>
                                        )}
                                    </div>
                                </form>
                            )}
                            {ViewMode === "Forgot Password" && (
                                <form action="#" className="form">
                                    <div className="form-group">
                                        <i className="fa-regular fa-lock-keyhole form-group-icon"></i>
                                        <input
                                            value={ForgotPassInput.mobileNumber}
                                            onChange={(e) =>
                                                setForgotPassInput({
                                                    ...ForgotPassInput,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }
                                            type="text"
                                            name="mobileNumber"
                                            className={`form-control mobile-gr-number${ForgotPassInput.errors.mobileNumber && "invalid"
                                                }`}
                                            placeholder="Mobile No. or GR No."
                                        />
                                        {ForgotPassInput.errors.mobileNumber && (
                                            <span className="error-message">
                                                {ForgotPassInput.errors.mobileNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-check login-selection-data-area">
                                        <label
                                            htmlFor="rememberMe"
                                            className="form-check-label"
                                        ></label>
                                        <label htmlFor="forgot-pass" className="forgot-pass">
                                            <a onClick={(e) => fnLoginPageMode("Login")}>
                                                {commonService.LabelDisplayText("BackToLogin")}
                                            </a>
                                        </label>
                                    </div>
                                    <div className="login-btn-class">
                                        {IsforgotPasswordSendOTPClick === false ? (
                                            <a
                                                className="btn btn-for-login"
                                                onClick={forgotPasswordSendOTPClick}
                                            >
                                                {commonService.LabelDisplayText("Next")}
                                            </a>
                                        ) : (
                                            <a className="btn btn-for-login">
                                                <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Next")}...
                                            </a>
                                        )}
                                    </div>
                                </form>
                            )}
                            {ViewMode === "Set New Password" && (
                                <form action="#" className="form">
                                    <div className="form-group">
                                        <span className="icon-fi-rr-lock form-group-icon"></span>
                                        <input
                                            value={ForgotPassInput.otpNumber}
                                            maxLength={6}
                                            onChange={(e) =>
                                                setForgotPassInput({
                                                    ...ForgotPassInput,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }
                                            type="password"
                                            name="otpNumber"
                                            className={`form-control txtpassword ${ForgotPassInput.errors.otpNumber && "invalid"
                                                }`}
                                            placeholder="Enter OTP"
                                        />

                                        {ForgotPassInput.errors.otpNumber && (
                                            <span className="error-message">
                                                {ForgotPassInput.errors.otpNumber}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <span className="icon-fi-rr-lock form-group-icon"></span>
                                        <input
                                            value={ForgotPassInput.newPassword}
                                            onChange={(e) =>
                                                setForgotPassInput({
                                                    ...ForgotPassInput,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }
                                            type="password"
                                            name="newPassword"
                                            className={`form-control txtpassword ${ForgotPassInput.errors.newPassword && "invalid"
                                                }`}
                                            placeholder="Enter New Password"
                                        />

                                        {ForgotPassInput.errors.newPassword && (
                                            <span className="error-message">
                                                {ForgotPassInput.errors.newPassword}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <span className="icon-fi-rr-lock form-group-icon"></span>
                                        <input
                                            value={ForgotPassInput.confirmPassword}
                                            onChange={(e) =>
                                                setForgotPassInput({
                                                    ...ForgotPassInput,
                                                    [e.target.name]: e.target.value,
                                                })
                                            }
                                            type="password"
                                            name="confirmPassword"
                                            className={`form-control txtpassword ${ForgotPassInput.errors.confirmPassword && "invalid"
                                                }`}
                                            placeholder="Confirm Password"
                                        />

                                        {ForgotPassInput.errors.confirmPassword && (
                                            <span className="error-message">
                                                {ForgotPassInput.errors.confirmPassword}
                                            </span>
                                        )}
                                    </div>
                                    <div className="form-check login-selection-data-area">
                                        <label
                                            htmlFor="rememberMe"
                                            className="form-check-label"
                                        ></label>
                                        <label htmlFor="forgot-pass" className="forgot-pass">
                                            <a onClick={(e) => fnLoginPageMode("Login")}>
                                                {commonService.LabelDisplayText("BackToLogin")}
                                            </a>
                                        </label>
                                    </div>
                                    <div className="login-btn-class">
                                        {IsforgotPasswordVerifyAndUpdatePasswordClick === false ? (
                                            <a
                                                className="btn btn-for-login"
                                                onClick={forgotPasswordVerifyAndUpdatePasswordClick}
                                            >
                                                {commonService.LabelDisplayText("VerifyAndUpdate")}
                                            </a>
                                        ) : (
                                            <a className="btn btn-for-login">
                                                <Spinner animation="border" size="sm" />  {commonService.LabelDisplayText("VerifyAndUpdate")}...
                                            </a>
                                        )}
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                    <p className="note-for-login mt-4">
                        <b>Note : </b> Only authorised members are permitted to log into
                this portal. Any unauthorised access or attempt to access will be a
                breach of our data security and liable for prosecution under IT Act
                2015 of India.
              </p>
                </div>
            </div>
        </>
    );
}

export default Login;
