import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory } from "react-router-dom";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { alertService, AlertType } from "../_services/alert.service";
import { MathComponent } from 'mathjax-react'
import { confirmAlert } from "react-confirm-alert";

function ExamQuestion() {
    document.getElementById("main").classList.add("login");
    const history = useHistory();
    let data = commonService.getLoginUserData();
    let duration = 0;
    let SecondRunning = 0;

    const [ExamDetails, setExamDetails] = useState({ IsData: false, data: [] });
    const [ExamHeader, setExamHeader] = useState("");
    const [RemainingTime, setRemainingTime] = useState("00:00:00");
    const [QustionIndex, setQustionIndex] = useState(0);
    const [ValidationMessage, setValidationMessage] = useState("");
    const [FilesDetails, setFilesDetails] = useState([]);
    const [IsSubjectiveExamSaveing, setIsSubjectiveExamSaveing] = useState(false);
    const [TotalSecondGone, setTotalSecondGone] = useState(0);
    const [ResultScreenPara, setResultScreenPara] = useState({
        SubjectName: "",
        StdExamSubScheduleID: "",
        ExamSubjectStudentID: "",
        ResultData: []
    });

    function BindExamData(exam) {
        setExamHeader(exam.StandardName + " - " + exam.SubjectName)
        setExamDetails({ IsData: false, data: [] });
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                "stdExamSubScheduleID": exam.StdExamSubScheduleID,
                "standardID": exam.StandardID,
                "requestFrom": "web"
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/startexam", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExamDetails({ IsData: true, data: json });
                    duration = json.data.duration * 60;
                    setResultScreenPara({
                        SubjectName: exam.SubjectName,
                        StdExamSubScheduleID: exam.StdExamSubScheduleID,
                        ExamSubjectStudentID: json.data.examSubjectStudentID
                    });

                    if (json.status_code === 1) {
                        let timeloop = setInterval(() => {
                            if (duration !== 0) {
                                duration = duration - 1;
                                SecondRunning = SecondRunning + 1;
                                var dateObj = new Date(duration * 1000);
                                var timeString = dateObj.getUTCHours() + ':' + dateObj.getUTCMinutes() + ':' + dateObj.getSeconds();
                                setRemainingTime(timeString);
                                setTotalSecondGone(SecondRunning);
                            }
                            else {
                                clearInterval(timeloop);
                                if (json.data.paperType.toUpperCase() === "OBJECTIVE") {
                                    fnFinishObjectiveExam();
                                }
                                else {
                                    fnFinishSubjectiveExam();
                                }
                            }
                        }, 1000);

                    }

                } else if (json.status_code === 0) {
                    setExamDetails({ IsData: true, data: json });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }

            });
    }

    function GoToExamListing() {
        history.push("/exam")
    }

    function fnSelectAns(CurrentQustionIndex, questionOptionID) {
        ExamDetails.data.data.objectiveData[CurrentQustionIndex].selectedOption = questionOptionID;
        setExamDetails({
            ...ExamDetails,
            ExamDetails,
        });
    }

    function fnAction(CurrentQustionIndex, Type) {
        setValidationMessage("");
        let IsValid = true;
        if (Type.toUpperCase() === "PREV") {
            if (QustionIndex != 0) {
                CurrentQustionIndex = CurrentQustionIndex - 1;
                setQustionIndex(CurrentQustionIndex);
            }
        }
        else if (Type.toUpperCase() === "SAVE & FINISH" || Type.toUpperCase() === "SKIP & FINISH" || Type.toUpperCase() === "SAVE & NEXT" || Type.toUpperCase() === "SKIP") {
            if (Type.toUpperCase() === "SKIP" || Type.toUpperCase() === "SKIP & FINISH") {
                ExamDetails.data.data.objectiveData[CurrentQustionIndex].questionAttendType = "SKIP";
                ExamDetails.data.data.objectiveData[CurrentQustionIndex].takenTimeInSeconds = ExamDetails.data.data.objectiveData[CurrentQustionIndex].takenTimeInSeconds + TotalSecondGone;
                setExamDetails({
                    ...ExamDetails,
                    ExamDetails,
                });
                SaveExamQuestionAnswer(ExamDetails.data.data.objectiveData[CurrentQustionIndex], ExamDetails.data.data.examSubjectStudentID, CurrentQustionIndex);
                if (QustionIndex + 1 < ExamDetails.data.data.objectiveData.length) {
                    CurrentQustionIndex = CurrentQustionIndex + 1;
                    setQustionIndex(CurrentQustionIndex);
                }
            }
            else if (Type.toUpperCase() === "SAVE & NEXT" || Type.toUpperCase() === "SAVE & FINISH") {
                if (ExamDetails.data.data.objectiveData[CurrentQustionIndex].selectedOption === "") {
                    IsValid = false;
                }

                if (!IsValid) {
                    setValidationMessage("Please select any answer");
                }
                else {
                    ExamDetails.data.data.objectiveData[CurrentQustionIndex].questionAttendType = "SAVE & NEXT";
                    ExamDetails.data.data.objectiveData[CurrentQustionIndex].takenTimeInSeconds = ExamDetails.data.data.objectiveData[CurrentQustionIndex].takenTimeInSeconds + TotalSecondGone;

                    SaveExamQuestionAnswer(ExamDetails.data.data.objectiveData[CurrentQustionIndex], ExamDetails.data.data.examSubjectStudentID, CurrentQustionIndex);
                    setExamDetails({
                        ...ExamDetails,
                        ExamDetails,
                    });

                    if (QustionIndex + 1 < ExamDetails.data.data.objectiveData.length) {
                        CurrentQustionIndex = CurrentQustionIndex + 1;
                        setQustionIndex(CurrentQustionIndex);
                    }
                }
            }
            if ((Type.toUpperCase() === "SKIP & FINISH" || Type.toUpperCase() === "SAVE & FINISH") && IsValid) {
                confirmAlert({
                    title: "Alert Message",
                    message: "Do you want to sure finish your exam",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () =>
                                fnFinishObjectiveExam(),
                        },
                        {
                            label: "No",
                        },
                    ],
                })
            }
        }
    }

    function SaveExamQuestionAnswer(item, examSubjectStudentID, CurrentQustionIndex) {
        let takenTimeInSeconds = item.takenTimeInSeconds;
        if (CurrentQustionIndex !== 0 && CurrentQustionIndex > 0) {
            takenTimeInSeconds = item.takenTimeInSeconds - ExamDetails.data.data.objectiveData[CurrentQustionIndex - 1].takenTimeInSeconds
        }
        let QA = {
            "examSubStdQueAnsList": [
                {
                    "examSubStdQueAnsID": item.examSubStdQueAnsID,
                    "stdExamSubSecQuestionID": item.stdExamSubSecQuestionID,
                    "studentID": data[0].MemberID,
                    "subjectID": item.subjectID,
                    "questionID": item.questionID,
                    "selectedOptionIDs": item.selectedOption,
                    "isSkipped": item.questionAttendType === "SKIP" ? true : false,
                    "isCorrectAns": true,  //questionAttendType
                    "isWrongAns": true,  //questionAttendType
                    "marksObtained": 0,
                    "timeTokenInSeconds": takenTimeInSeconds,
                    "ansWritten": "",
                    "marksForEachQuestion": item.marksForEachQuestion,
                    "negativeMarksOnWrongAns": item.negativeMarksOnWrongAns,
                    "attemptedOn": new Date(),
                    "coids": item.coids,
                }
            ],
            "examSubjectStudentID": examSubjectStudentID
        }

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify(QA),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/startexamquestionanswer", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnFinishObjectiveExam() {

        let examSubStdQueAnsList = [];
        let QAData = ExamDetails.data.data.objectiveData;
        for (let i = 0; i < QAData.length; i++) {
            let takenTimeInSeconds = QAData[i].takenTimeInSeconds;
            if (i !== 0 && i > 0) {
                takenTimeInSeconds = QAData[i].takenTimeInSeconds - QAData[i - 1].takenTimeInSeconds
            }

            examSubStdQueAnsList.push({
                "examSubStdQueAnsID": QAData[i].examSubStdQueAnsID,
                "stdExamSubSecQuestionID": QAData[i].stdExamSubSecQuestionID,
                "studentID": data[0].MemberID,
                "subjectID": QAData[i].subjectID,
                "questionID": QAData[i].questionID,
                "selectedOptionIDs": QAData[i].selectedOption,
                "isSkipped": QAData[i].questionAttendType === "SKIP" ? true : false,
                "isCorrectAns": true,  //questionAttendType
                "isWrongAns": true,  //questionAttendType
                "marksObtained": 0,
                "timeTokenInSeconds": takenTimeInSeconds,
                "ansWritten": "",
                "marksForEachQuestion": QAData[i].marksForEachQuestion,
                "negativeMarksOnWrongAns": QAData[i].negativeMarksOnWrongAns,
                "attemptedOn": new Date(),
                "coids": QAData[i].coids,
            })
        }


        let QA = {
            "examSubStdQueAnsList": examSubStdQueAnsList,
            "examSubjectStudentID": ExamDetails.data.data.examSubjectStudentID
        }

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify(QA),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/startexamquestionanswer", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    const reqExamRes = {
                        method: "POST",
                        headers: commonService.getHeaders(),
                        body: JSON.stringify({
                            "stdExamSubScheduleID": ResultScreenPara.StdExamSubScheduleID,
                            "examSubjectStudentID": ResultScreenPara.ExamSubjectStudentID
                        }),
                    };
                    fetch(AppConfigData.APIBasePath + "v1/exam/startexamresultdata", reqExamRes)
                        .then((response) => response.json())
                        .then(function (json) {

                            if (json.status_code === 1) {
                                if (json.data[0].IsShowSummary) {
                                    let New_ResultScreenPara = ResultScreenPara;
                                    New_ResultScreenPara.ResultData = json.data;
                                    history.push({
                                        pathname: '/overallresult',
                                        state: New_ResultScreenPara
                                    })
                                }
                                else {
                                    history.push("/exam");
                                }
                            } else if (json.status_code === 0) {
                                history.push("/exam");
                            } else if (json.status_code === 2) {
                                localStorage.clear();
                                history.push("/");
                            }
                        });
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnFinishSubjectiveExam() {
        let ImageCount = 0;
        let PDFCount = 0;
        let ItsValid = true;

        for (let i = 0; i < FilesDetails.length; i++) {
            let FileType = commonService.getFileType(FilesDetails[i]).toUpperCase();

            if (FileType === "IMAGE") {
                ImageCount = ImageCount + 1
            }

            if (FileType === "PDF") {
                PDFCount = PDFCount + 1
            }
        }

        if (ImageCount > 0 && PDFCount > 0) {
            ItsValid = false;
            alertService.alert({
                type: AlertType.Error,
                message: "Image Or PDF Both Are Not Valid, Select Ithere 1 PDF OR Ithere Multiple Image",
            });
        }
        else if (PDFCount > 1) {
            ItsValid = false;
            alertService.alert({
                type: AlertType.Error,
                message: "More Then 1 PDF Are Not Valid",
            });
        }

        if (ItsValid) {
            confirmAlert({
                title: "Alert Message",
                message: "Do you want to sure finish your exam",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () =>
                            ExamSnsSheetInsert(),
                    },
                    {
                        label: "No",
                    },
                ],
            })
        }
    }

    function ExamSnsSheetInsert() {

        setIsSubjectiveExamSaveing(true);
        let formData = new FormData();
        formData.append("StdExamSubScheduleID", ExamDetails.data.data.subjectiveData[0].StdExamSubjectScheduleID);
        formData.append("SubjectID", ExamDetails.data.data.subjectiveData[0].SubjectID);
        formData.append("StdExamSubPassingMarkID", ExamDetails.data.data.subjectiveData[0].StdExamSubPassingMarkID);
        formData.append("ExamID", ExamDetails.data.data.subjectiveData[0].ExamID);
        formData.append("StandardID", ExamDetails.data.data.subjectiveData[0].StandardID);
        formData.append("TimeTakenInMinute", parseInt(TotalSecondGone / 60));

        for (let i = 0; i < FilesDetails.length; i++) {
            formData.append("FormFiles", FilesDetails[i]);
        }
        const reqExam = {
            method: "POST",
            enctype: "multipart/form-data",
            processData: false,
            contentType: false,
            headers: commonService.getHeadersFromData(),
            body: formData,
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/startexamanssheetinsert", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                setIsSubjectiveExamSaveing(false);
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                    history.push("/exam");
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnSelectedFiles(Files) {

        let OldFiles = FilesDetails;
        for (let i = 0; i < Files.length; i++) {
            let FileType = commonService.getFileType(Files[i]).toUpperCase();
            if (FileType === "IMAGE" || FileType === "PDF") {
                OldFiles.push(Files[i]);
            }
        }
        setFilesDetails(OldFiles);
    }

    function fnDeleteFilesDetails(key) {
        let OldFiles = FilesDetails;
        OldFiles.splice(key, 1);
        setFilesDetails(OldFiles);
    }

    React.useEffect(() => {
        if(commonService.getLoginRoleData("Exam","IsView")){
        if (history.location.state !== undefined) {
            BindExamData(history.location.state);
        }
        else {
            history.push("/exam");
        }
    }
    else{
        history.push("/access");
      }
    }, []);

    return (
        <>
            <div className="exam-main-container">
                <div className="exam-div-container">
                    <div className="container-fluid border-bottom-exam position-relative">
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <h1 className="page-header">{
                                                ExamDetails.IsData &&
                                                <span>{ExamHeader}</span>
                                            }</h1>
                                        </div>
                                        <div className="blue-text">
                                            {
                                                ExamDetails.IsData &&
                                                <><i className=" icon-fi-rr-time-quarter-to mr-2"></i>
                                                    <span>{RemainingTime}</span></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid position-relative">
                        <div className="container mt-3">
                            {
                                !ExamDetails.IsData ?
                                    <_Skeleton type={_SkeletonType.ExamQuestion} />
                                    :
                                    ExamDetails.data.status_code === 1 ?
                                        <div className="row">

                                            {
                                                ExamDetails.data.data.paperType.toUpperCase() === "OBJECTIVE" ?
                                                    <>
                                                        <div className="col-9 question-setion">
                                                            <div className="d-flex  justify-content-between mb-1">
                                                                <div className=" ">
                                                                    <span className="blue-text question-text">{commonService.LabelDisplayText("Question")} {QustionIndex +   1}.</span>
                                                                    
                                                                </div>
                                                                <div>
                                                                    <label className="mark-label">
                                                                        {ExamDetails.data.data.objectiveData[QustionIndex].marksForEachQuestion} {commonService.LabelDisplayText("Mark")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            <div>
                                                                {
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].questionTitle.indexOf("$$") == 0 ?
                                                                        <span className="question-title"><MathComponent tex={String.raw`${ExamDetails.data.data.objectiveData[QustionIndex].questionTitle.replaceAll("$$", "")}`} /></span>
                                                                        :
                                                                        <span className="question-title">{ExamDetails.data.data.objectiveData[QustionIndex].questionTitle}</span>

                                                                }
                                                            </div>
                                                            <div className={"question-img"}>
                                                                {
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].image1 &&
                                                                    <img src={AppConfigData.ERPFileBasePath + ExamDetails.data.data.objectiveData[QustionIndex].image1} />
                                                                }
                                                            </div>
                                                            <div className={"question-img"}>
                                                                {
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].image2 &&
                                                                    <img src={AppConfigData.ERPFileBasePath + ExamDetails.data.data.objectiveData[QustionIndex].image1} />
                                                                }
                                                            </div>
                                                            <div className={"question-img"}>
                                                                {
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].image3 &&
                                                                    <img src={AppConfigData.ERPFileBasePath + ExamDetails.data.data.objectiveData[QustionIndex].image1} />
                                                                }
                                                                </div>
                                                            </div>
                                                                
                                                               

                                                           
                                                            
                                                            <div className="mb-4">
                                                                <p className="question-main">
                                                                    {ExamDetails.data.data.objectiveData[QustionIndex].questionUnderstanding}
                                                                </p>
                                                            </div>
                                                            <div className="option-section">
                                                                {
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].questionOptionList != undefined &&
                                                                    ExamDetails.data.data.objectiveData[QustionIndex].questionOptionList.map((q, i) =>
                                                                        <div key={i}>
                                                                            <input
                                                                                className="option-radio"
                                                                                type="radio"
                                                                                name={q.questionID}
                                                                                id={q.questionOptionID}
                                                                                checked={q.questionOptionID.toUpperCase() === ExamDetails.data.data.objectiveData[QustionIndex].selectedOption}
                                                                                onClick={(e) => fnSelectAns(QustionIndex, q.questionOptionID.toUpperCase())}
                                                                            />
                                                                            <label htmlFor={q.questionOptionID} className="option-selection">
                                                                                <div>
                                                                                <span className="option-mcq">{q.questionText} </span>
                                                                                </div>
                                                                                <div>
                                                                                {
                                                                                    q.questionDetail.indexOf("$$") == 0 ?
                                                                                        <span className="option-discription"><MathComponent tex={String.raw`${q.questionDetail.replaceAll("$$", "")}`} /></span>
                                                                                        :
                                                                                        <span className="option-discription">{q.questionDetail}</span>
                                                                                }

                                                                                <div className={"option-img"}>
                                                                                    {
                                                                                        q.image1 &&
                                                                                        <img src={AppConfigData.ERPFileBasePath + q.image1} />
                                                                                    }
                                                                                </div>
                                                                                <div className={"option-img"}>
                                                                                    {
                                                                                        q.image2 &&
                                                                                        <img src={AppConfigData.ERPFileBasePath + q.image2} />
                                                                                    }
                                                                                </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                }
                                                                {ValidationMessage && (
                                                                    <span className="error-message">
                                                                        {ValidationMessage}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <a className="btn btn-disabled w-100" onClick={(e) => fnAction(QustionIndex, "PREV")}>PREV</a>
                                                                </div>
                                                                {
                                                                    (QustionIndex + 1) === ExamDetails.data.data.objectiveData.length ?
                                                                        <>
                                                                            <div className="col-4">
                                                                                <a className="btn btn-primary w-100" onClick={(e) => fnAction(QustionIndex, "SAVE & FINISH")}>{commonService.LabelDisplayText("SAVEANDFINISH")}</a>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <a className="btn btn-secondary w-100" onClick={(e) => fnAction(QustionIndex, "SKIP & FINISH")}>{commonService.LabelDisplayText("SKIPANDFINISH")}</a>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="col-4">
                                                                                <a className="btn btn-primary w-100" onClick={(e) => fnAction(QustionIndex, "SAVE & NEXT")}>{commonService.LabelDisplayText("SAVEANDNEXT")}</a>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <a className="btn btn-secondary w-100" onClick={(e) => fnAction(QustionIndex, "SKIP")}>{commonService.LabelDisplayText("SKIP")}</a>
                                                                            </div>
                                                                        </>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="col-3 answer-section">
                                                            <div className="col-12">
                                                                <div className="three-grid-sections">
                                                                    <div className="status-colors">
                                                                        <div className="status-answered color-identification-circle"></div>
                                                                        <span>{commonService.LabelDisplayText("Answered")}</span>
                                                                    </div>
                                                                    <div className="status-colors">
                                                                        <div className="status-skipped grey-circle"></div>
                                                                        <span>{commonService.LabelDisplayText("Skipped")}</span>
                                                                    </div>
                                                                    <div className="status-colors">
                                                                        <div className="status-notVisited grey-circle"></div>
                                                                        <span>{commonService.LabelDisplayText("NotVisited")}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="question-status-section">
                                                                    {
                                                                        ExamDetails.data.data.objectiveData.map((item, index) =>
                                                                            <button name={"view_" + item.examSubStdQueAnsID} className={`question-status pointer-cursor ${item.questionAttendType === "" && "status-notVisited"} ${item.questionAttendType.toUpperCase() === "SKIP" && "status-skipped"} ${item.questionAttendType.toUpperCase() === "SAVE & NEXT" && "status-answered"}`}>
                                                                                {index + 1}
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-9 question-setion">
                                                            <iframe width={"100%"} height={"1000px"} src={AppConfigData.ERPFileBasePath.replace("Files", "") + ExamDetails.data.data.subjectiveData[0].PaperFilePath}></iframe>
                                                        </div>
                                                        <div className="col-3 answer-section">

                                                            <div className="col-12">
                                                                {
                                                                    ExamDetails.data.data.subjectiveData[0].FilePath &&
                                                                    <a className="btn btn-primary w-100 mb-2" target={"_blank"} href={(ExamDetails.data.data.subjectiveData[0].FilePath.toUpperCase().indexOf("HTTP") == 0 ? "" : "http://") + ExamDetails.data.data.subjectiveData[0].FilePath}>View Your Uploaded Ans Sheet</a>
                                                                }

                                                                {commonService.LabelDisplayText("SelectYourAnswerSheet")}
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    className={`form-control`}
                                                                    onChange={(e) =>
                                                                        fnSelectedFiles(e.target.files)
                                                                    }
                                                                    accept="application/pdf, image/jpeg, image/jpg, image/png"
                                                                />
                                                                <sub >{commonService.LabelDisplayText("OnlyImageAndPDFFileAreValid")}</sub>
                                                                <br />
                                                                <sub className="error-message">{commonService.LabelDisplayText("NotesYouCanChooseIthereMultipleImageOrIthereOnePDF")}</sub>
                                                            </div>
                                                            <div className="col-12">
                                                                {FilesDetails.map((itemFile, indexFile) => (
                                                                    <div
                                                                        className="card homework-page-card mt-3"
                                                                        key={indexFile}
                                                                    >
                                                                        {
                                                                            <div className="card-body p-0 card-home-work-grid">
                                                                                {
                                                                                    commonService.getFileType(itemFile).toUpperCase() === "IMAGE" ?
                                                                                        <img
                                                                                            src={URL.createObjectURL(itemFile)}
                                                                                            className="file-list-icon"
                                                                                        />
                                                                                        :
                                                                                        <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
                                                                                }
                                                                                <div className="card-text-inner pointer-cursor">
                                                                                    <p>{itemFile.name}</p>
                                                                                </div>
                                                                                <div className="close-btn-area">
                                                                                    <i
                                                                                        className="fa-regular fa-xmark pointer-cursor"
                                                                                        onClick={() =>
                                                                                            confirmAlert({
                                                                                                title: "Delete",
                                                                                                message: "Are you sure delete ?",
                                                                                                buttons: [
                                                                                                    {
                                                                                                        label: "Yes",
                                                                                                        onClick: () =>
                                                                                                            fnDeleteFilesDetails(indexFile),
                                                                                                    },
                                                                                                    {
                                                                                                        label: "No",
                                                                                                    },
                                                                                                ],
                                                                                            })
                                                                                        }
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="col-12 mt-2">
                                                                {
                                                                    IsSubjectiveExamSaveing === false ?
                                                                        <a className="btn btn-primary w-100" onClick={(e) => fnFinishSubjectiveExam()}>{commonService.LabelDisplayText("FINISH")}</a>
                                                                        :
                                                                        <a className="btn btn-primary w-100" > <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}</a>
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        :
                                        <div className={"text-center"}>
                                            {ExamDetails.data.message}
                                            <a className="btn btn-primary w-100 mt-5" onClick={(e) => GoToExamListing()}>{commonService.LabelDisplayText("GoToBack")}</a>
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default ExamQuestion;
