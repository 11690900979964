/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "react-datepicker/dist/react-datepicker.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { alertService, AlertType } from "../_services/alert.service";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

function Homework() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [Homework, setHomework] = useState({ IsData: false, data: [] });
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [TeacherSubject, setTeacherSubject] = useState([]);
    const [SubjectEnrollStudent, setSubjectEnrollStudent] = useState([]);
    const [AttachLink, setAttachLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachYoutubeLink, setAttachYoutubeLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachFiles, setAttachFiles] = useState({
        name: "",
        link: null,
        errors: "",
    });
    const [showLinkPopup, setShowLinkPopup] = useState(false);
    const [showYoutubeLinkPopup, setShowYoutubeLinkPopup] = useState(false);
    const [showFileSelectPopup, setShowFileSelectPopup] = useState(false);
    const [showScheduleDatePopup, setShowScheduleDatePopup] = useState(false);
    const [showCheckHomeworkPopup, setShowCheckHomeworkPopup] = useState(false);
    const [IsHomeworkSaveing, setIsHomeworkSaveing] = useState(false);
    const [IsHomeworkListDateLodding, setIsHomeworkListDateLodding] =
        useState(false);

    const [UploadHomeWorkAttachFiles, setUploadHomeWorkAttachFiles] = useState(
        []
    );

    const [HomeWorkData, setHomeWorkData] = useState({
        AssignmentID: "00000000-0000-0000-0000-000000000000",
        GradeID: "00000000-0000-0000-0000-000000000000",
        DivisionID: "00000000-0000-0000-0000-000000000000",
        SubjectID: "00000000-0000-0000-0000-000000000000",
        DateOfHomeWork: new Date(),
        Title: "",
        HomeWorksDetails: "",
        DateOfFinish: new Date(),
        SaveType: "",
        TagMemberIDs: "",
        FilesDetails: [],
        IsForAll: true,
        RequestFrom: "Web",
        StandardName: "",
        DivisionName: "",
        SubjectName: "",
        errors: {
            Title: "",
            DateOfFinish: "",
            SubjectID: "",
            DateOfHomeWork: "",
        },
    });
    const [editorHomeWorksDetails, setEditorHomeWorksDetails] = useState(() =>
        EditorState.createEmpty()
    );
    const [editorUploadHomeWorksDetails, setEditorUploadHomeWorksDetails] =
        useState(() => EditorState.createEmpty());
    const [UploadStudentHomeworkError, setUploadStudentHomeworkError] =
        useState("");
    const [IsStudentUploadHomeworkSaveing, setIsStudentUploadHomeworkSaveing] =
        useState(false);
    const [UploadStudentHomeworkOpenID, setUploadStudentHomeworkOpenID] =
        useState("");
    const [UploadHomeworkList, setUploadHomeworkList] = useState({
        IsData: false,
        data: [],
    });
    const [readstatus, setreadstatus] = useState({ isdata: false, data: [] })
    const [studentreadstatuspopup, setstudentreadstatuspopup] = useState(false)

    const params = new URLSearchParams(window.location.search);
    function BindHomeWorkList(_sRow) {
        let noOfData = 21;
        document
            .getElementById("root")
            .removeEventListener("scroll", WindowScrolling);

        setIsHomeworkListDateLodding(true);

        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: data[0].MemberType.toLowerCase() === "student" ? "Send" : "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: _sRow,
                noOfData: noOfData,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/homework/getlistbymember",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                document.getElementById("root").addEventListener("scroll", WindowScrolling);
                setIsHomeworkListDateLodding(false);
                
                if (json.status_code === 1) {
                    if (_sRow === 1) {
                        setHomework({ IsData: true, data: json.data });
                        json.data.map((item, index) => {
                            if (item.IsRead === 0) { approvereadpost(item.AssignmentID, "HomeWork") }
                        });
                       
                    } else {
                        setHomework((oldData) => ({
                            IsData: true,
                            data: [...oldData.data, ...json.data],
                        }));
                       
                    }

                    if (json.data.length < noOfData) {
                        document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    }

                } else if (json.status_code === 0) {
                    document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    setHomework((oldData) => ({
                        IsData: true,
                        data: oldData.data,
                    }));
                    /* setHomework({ IsData: true, data: [] });*/
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function StandardDivisionSubjectChange(IDs) {
        setSubjectEnrollStudent([]);
        if (IDs !== "") {
            setHomeWorkData({
                ...HomeWorkData,
                ["GradeID"]: IDs.split("|")[0],
                ["DivisionID"]: IDs.split("|")[1],
                ["SubjectID"]: IDs.split("|")[2],
            });
            get_student_listbystudentsubjectenrollment(
                IDs.split("|")[0],
                IDs.split("|")[1],
                IDs.split("|")[2]
            );
        } else {
            setHomeWorkData({
                ...HomeWorkData,
                ["GradeID"]: "00000000-0000-0000-0000-000000000000",
                ["DivisionID"]: "00000000-0000-0000-0000-000000000000",
                ["SubjectID"]: "00000000-0000-0000-0000-000000000000",
            });
        }
    }

    function handleShowAddEditPopup(ID, StandardName, DivisionName, SubjectName) {
        get_teacher_standarddivisionsubject();
        if (ID !== "") {
            const reqHomework = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/homework/getalldetailsbyid",
                reqHomework
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setHomeWorkData({
                            AssignmentID: json.data.detailsData[0].AssignmentID,
                            GradeID: json.data.detailsData[0].GradeID,
                            DivisionID: json.data.detailsData[0].DivisionID,
                            SubjectID: json.data.detailsData[0].SubjectID,
                            DateOfHomeWork: new Date(json.data.detailsData[0].DateOfHomeWork),
                            Title: json.data.detailsData[0].Title,
                            HomeWorksDetails: json.data.detailsData[0].HomeWorksDetails,
                            DateOfFinish: new Date(json.data.detailsData[0].DateOfFinish),
                            SaveType: json.data.detailsData[0].SaveType,
                            //TagMemberIDs: json.data.detailsData[0].IsForAll
                            //    ? ""
                            //    : json.data.detailsData[0].TagMemberIDs,
                            TagMemberIDs: json.data.detailsData[0].TagMemberIDs,
                            FilesDetails: json.data.fileData,
                            IsForAll: json.data.detailsData[0].IsForAll,
                            RequestFrom: "Web",
                            StandardName: StandardName,
                            DivisionName: DivisionName,
                            SubjectName: SubjectName,
                            errors: {
                                Title: "",
                                DateOfFinish: "",
                                SubjectID: "",
                                DateOfHomeWork: "",
                            },
                        });

                        setEditorHomeWorksDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].HomeWorksDetails)
                                        .contentBlocks
                                )
                            )
                        );

                        get_student_listbystudentsubjectenrollment(
                            json.data.detailsData[0].GradeID,
                            json.data.detailsData[0].DivisionID,
                            json.data.detailsData[0].SubjectID
                        );
                        setShowAddEditPopup(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setHomeWorkData({
                AssignmentID: "00000000-0000-0000-0000-000000000000",
                GradeID: "00000000-0000-0000-0000-000000000000",
                DivisionID: "00000000-0000-0000-0000-000000000000",
                SubjectID: "00000000-0000-0000-0000-000000000000",
                DateOfHomeWork: new Date(),
                Title: "",
                HomeWorksDetails: "",
                DateOfFinish: new Date(),
                SaveType: "",
                TagMemberIDs: "",
                FilesDetails: [],
                IsForAll: true,
                RequestFrom: "Web",
                StandardName: "",
                DivisionName: "",
                SubjectName: "",
                errors: {
                    Title: "",
                    DateOfFinish: "",
                    SubjectID: "",
                    DateOfHomeWork: "",
                },
            });
            setEditorHomeWorksDetails(EditorState.createEmpty());
            setShowAddEditPopup(true);
        }
    }

    function get_teacher_standarddivisionsubject() {
        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/get_teacher_standarddivisionsubject",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].IDs.toLocaleUpperCase(),
                            label: json.data[i].Text,
                        });
                    }
                    setTeacherSubject(options);
                } else if (json.status_code === 0) {
                    setTeacherSubject([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function get_student_listbystudentsubjectenrollment(
        standardID,
        divisionID,
        subjectID
    ) {
        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                standardID: standardID,
                divisionID: divisionID,
                subjectID: subjectID,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_student_listbystudentsubjectenrollment",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].StudentID.toLocaleUpperCase(),
                            label: json.data[i].RollNo + " - " + json.data[i].StudentFullName,
                        });
                    }
                    setSubjectEnrollStudent(options);
                } else if (json.status_code === 0) {
                    setSubjectEnrollStudent([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function ButtonClickForSave(SaveType) {
        if (SaveType.toUpperCase() === "SCHEDULE") {
            setShowScheduleDatePopup(true);
        } else if (
            SaveType.toUpperCase() === "SEND" ||
            SaveType.toUpperCase() === "DRAFT"
        ) {
            if (SaveType.toUpperCase() === "SEND") {
                setHomeWorkData({
                    ...HomeWorkData,
                    ["DateOfHomeWork"]: new Date(),
                });
            }
            AddEditHomeWork(SaveType);
        }
    }

    function AddEditHomeWork(SaveType) {
        if (validate()) {
            setIsHomeworkSaveing(true);
            setShowScheduleDatePopup(false);
            let formData = new FormData();
            formData.append("AssignmentID", HomeWorkData.AssignmentID);
            formData.append("GradeID", HomeWorkData.GradeID);
            formData.append("DivisionID", HomeWorkData.DivisionID);
            formData.append("SubjectID", HomeWorkData.SubjectID);
            formData.append(
                "DateOfHomeWork",
                commonService.getDateTimeInDBFormat(HomeWorkData.DateOfHomeWork)
            );
            formData.append("Title", HomeWorkData.Title);
            formData.append(
                "HomeWorksDetails",
                draftToHtml(convertToRaw(editorHomeWorksDetails.getCurrentContent()))
            );
            formData.append(
                "DateOfFinish",
                commonService.getDateTimeInDBFormat(HomeWorkData.DateOfFinish)
            );
            formData.append("SaveType", SaveType);
            formData.append("TagMemberIDs", HomeWorkData.TagMemberIDs.replace("*,", ""));
            formData.append(
                "IsForAll",
                HomeWorkData.TagMemberIDs === "" ? true : false
            );
            formData.append("RequestFrom", "Web");
            let NewIndex = 0;
            for (let i = 0; i < HomeWorkData.FilesDetails.length; i++) {
                if (
                    HomeWorkData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    HomeWorkData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        HomeWorkData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        HomeWorkData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        HomeWorkData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        HomeWorkData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        HomeWorkData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        HomeWorkData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    HomeWorkData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    HomeWorkData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", HomeWorkData.FilesDetails[i].FilePath);
                }
            }
            const reqHomework = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/homework/save", reqHomework)
                .then((response) => response.json())
                .then(function (json) {
                    setIsHomeworkSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindHomeWorkList(1);
                        if (SaveType.toUpperCase() === "SEND") {
                            commonService.sendNotification();
                        }
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                    if (params.has("add")) {
                        if (params.get("add") === "homework") {
                            history.push("wall");
                        } else {
                            setShowAddEditPopup(false);
                        }
                    } else {
                        setShowAddEditPopup(false);
                    }
                });
        }
    }

    function validate() {
        let isValid = true;
        let errors = {};
        if (!HomeWorkData.Title) {
            errors.Title = "Title is required";
            isValid = false;
        } else {
            errors.Title = "";
        }

        if (!HomeWorkData.DateOfFinish) {
            errors.DateOfFinish = "Due date is required";
            isValid = false;
        } else {
            errors.DateOfFinish = "";
        }

        if (!HomeWorkData.DateOfHomeWork) {
            errors.DateOfHomeWork = "Schedule date is required";
            isValid = false;
        } else {
            errors.DateOfHomeWork = "";
        }

        if (
            !HomeWorkData.SubjectID ||
            HomeWorkData.SubjectID === "00000000-0000-0000-0000-000000000000"
        ) {
            errors.SubjectID = "Subject is required";
            isValid = false;
        } else {
            errors.SubjectID = "";
        }

        setHomeWorkData({
            ...HomeWorkData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function AddLinkInFilesDetails() {
        setAttachLink({ ...AttachLink, ["errors"]: "" });
        if (AttachLink.link !== "") {
            var res = AttachLink.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLink({ ...AttachLink, ["errors"]: "Link is not valid" });
                return false;
            } else {
                let FilesDetails = HomeWorkData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLink.name,
                    FilePath: AttachLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setHomeWorkData({ ...HomeWorkData, ["FilesDetails"]: FilesDetails });
                setShowLinkPopup(false);
                return true;
            }
        } else {
            setAttachLink({ ...AttachLink, ["errors"]: "Link is required" });
        }
    }

    function AddYoutubeLinkInFilesDetails() {
        setAttachYoutubeLink({ ...AttachYoutubeLink, ["errors"]: "" });
        if (AttachYoutubeLink.link !== "") {
            var res = AttachYoutubeLink.link.match(AppConfigData.regex.YoutubeEmbed);
            if (res == null) {
                setAttachYoutubeLink({
                    ...AttachYoutubeLink,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = HomeWorkData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLink.name,
                    FilePath: AttachYoutubeLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setHomeWorkData({ ...HomeWorkData, ["FilesDetails"]: FilesDetails });
                setShowYoutubeLinkPopup(false);
                return true;
            }
        } else {
            setAttachYoutubeLink({
                ...AttachYoutubeLink,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetails() {
        setAttachFiles({ ...AttachFiles, ["errors"]: "" });
        if (AttachFiles.link !== null) {
            let FilesDetails = HomeWorkData.FilesDetails;
            for (let i = 0; i < AttachFiles.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFiles.link[i].name,
                    FilePath: AttachFiles.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFiles.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setHomeWorkData({ ...HomeWorkData, ["FilesDetails"]: FilesDetails });
            setShowFileSelectPopup(false);
        } else {
            setAttachFiles({
                ...AttachFiles,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIU(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }

        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromHomeworkAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {data[0].MemberType.toLowerCase() !== "student" &&
                        HomeWorkData.SaveType.toUpperCase() !== "SEND" && (
                            <i
                                className="fa-regular fa-xmark pointer-cursor"
                                onClick={() =>
                                    confirmAlert({
                                        title: "Delete",
                                        message: "Are you sure delete ?",
                                        buttons: [
                                            {
                                                label: "Yes",
                                                onClick: () =>
                                                    fnDeleteFilesDetails(FilesDetail.FilesID, indexFile),
                                            },
                                            {
                                                label: "No",
                                            },
                                        ],
                                    })
                                }
                            ></i>
                        )}
                </div>
            </div>
        );
    }

    function ViewFileFromHomeworkAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function fnDeleteFilesDetails(id, key) {
        let FilesDetails = HomeWorkData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setHomeWorkData({ ...HomeWorkData, ["FilesDetails"]: FilesDetails });
        } else if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqHomework = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/home/file_deletebyid", reqHomework)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        FilesDetails.splice(key, 1);
                        setHomeWorkData({
                            ...HomeWorkData,
                            ["FilesDetails"]: FilesDetails,
                        });
                    } else if (json.status_code === 0) {
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function fnDeleteHomeWork(id) {
        if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqHomework = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/homework/deletebyid", reqHomework)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindHomeWorkList(1);
                        setShowAddEditPopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function StudentTeacherUploadHomeworkOption(ID, SID, DID) {
        BindUploadHomeworkList(ID, SID, DID);
        setEditorUploadHomeWorksDetails(EditorState.createEmpty());
        setUploadStudentHomeworkOpenID(ID);
        setUploadHomeWorkAttachFiles([]);
        setShowCheckHomeworkPopup(true);
    }
    function studentreadstatus(aid) {
        getstudentreadstatus(aid)
        setstudentreadstatuspopup(true)
    }
    function getstudentreadstatus(aid) {
        setreadstatus({ isdata: false, data: [] });
        const reqstudentreadstatusdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associationID: aid,
                associationType: "HomeWork",
                searchText: "",
                sRow: 0,
                noOfData: 0,
                requestFrom: "web"
            }),
        }; fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readunreadlist",
            reqstudentreadstatusdata
        ).then((response) => response.json())
            .then(function (json) {

                if (json.status_code === 1) {
                    var a = json.data;
                    setreadstatus({ isdata: true, data: a });
                }

                else if (json.status_code === 0) {
                    setreadstatus({ isdata: true, data: [] });
                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function FnUploadHomeWorkAttachFiles(Files) {
        let FD = [];
        for (let i = 0; i < Files.length; i++) {
            FD.push({
                FilesID: "00000000-0000-0000-0000-000000000000",
                Name: Files[i].name,
                FilePath: Files[i],
                OrderNo: i,
                FileType: commonService.getFileType(Files[i]),
                FileMimeType: "FILE",
                RequestFrom: "Web",
            });
        }
        setUploadHomeWorkAttachFiles(FD);
    }

    function UploadStudentHomework() {
        if (UploadStudentHomeworkValidate()) {
            setIsStudentUploadHomeworkSaveing(true);
            let formData = new FormData();
            //formData.append('HomeWorksDetails', draftToHtml(convertToRaw(editorHomeWorksDetails.getCurrentContent())));
            formData.append(
                "TextContaine",
                draftToHtml(
                    convertToRaw(editorUploadHomeWorksDetails.getCurrentContent())
                )
            );
            formData.append("AssociationID", UploadStudentHomeworkOpenID);
            formData.append("RequestFrom", "Web");
            let NewIndex = 0;
            for (let i = 0; i < UploadHomeWorkAttachFiles.length; i++) {
                if (
                    UploadHomeWorkAttachFiles[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    UploadHomeWorkAttachFiles[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        UploadHomeWorkAttachFiles[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        UploadHomeWorkAttachFiles[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        UploadHomeWorkAttachFiles[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        UploadHomeWorkAttachFiles[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        UploadHomeWorkAttachFiles[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        UploadHomeWorkAttachFiles[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    UploadHomeWorkAttachFiles[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    UploadHomeWorkAttachFiles[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", UploadHomeWorkAttachFiles[i].FilePath);
                }
            }
            const reqHomework = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(
                AppConfigData.APIBasePath + "v1/homework/homeworkupload_save",
                reqHomework
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsStudentUploadHomeworkSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                    setShowCheckHomeworkPopup(false);
                });
        }
    }

    function UploadStudentHomeworkValidate() {
        let isValid = true;
        let HTMl = draftToHtml(
            convertToRaw(editorUploadHomeWorksDetails.getCurrentContent())
        );
        if (UploadHomeWorkAttachFiles.length === 0 && HTMl === "<p></p>\n") {
            setUploadStudentHomeworkError("Text or Files is required");
            isValid = false;
        } else {
            setUploadStudentHomeworkError("");
        }
        return isValid;
    }

    function BindUploadHomeworkList(ID, SID, DID) {
        setUploadHomeworkList({ IsData: false, data: [] });
        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associationID: ID,
                associationType: "Homework",
                standardID: SID,
                divisionID: DID,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/homework/homeworkupload_getlistbymember",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setUploadHomeworkList({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setUploadHomeworkList({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnCreateViewButtion(item) {
        let FileData = [];
        if (item.Files !== "") {
            FileData = JSON.parse(item.Files);
        }
        if (
            item.TextContaine !== "<p></p>\n" &&
            item.TextContaine !== "" &&
            item.TextContaine
        ) {
            FileData.push({
                Name: "Text Containe",
                FilePath: item.TextContaine,
                FileType: "TEXT",
                FileMimeType: "TEXT",
            });
        }
        if (FileData.length !== 0) {
            return (
                <button
                    className="small-btn"
                    onClick={() => viewFilePopupService.viewFilePopup({ data: FileData })}
                >
                    View
                </button>
            );
        } else {
            return <></>;
        }
    }

    function TeacherCommentOnUploadHomework(index) {
        let ListData = UploadHomeworkList;
        //ListData.data[index]["IsSaving"] = true;
        //setUploadHomeworkList(ListData);

        let formData = new FormData();
        formData.append("StudentReplayID", ListData.data[index].StudentReplayID);
        formData.append("IsApprove", ListData.data[index].IsApprove);
        formData.append("Note", ListData.data[index].Note);
        formData.append("RequestFrom", "Web");

        const reqHomework = {
            method: "POST",
            enctype: "multipart/form-data",
            processData: false,
            contentType: false,
            headers: commonService.getHeadersFromData(),
            body: formData,
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/homework/homeworkupload_saveteachercomment",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                //let ListData = UploadHomeworkList;
                //let item = ListData.data[index];
                //ListData.data[index]["IsSaving"] = false
                //setUploadHomeworkList(ListData);
                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function UpdateUploadHomeworkListItem(value, field, index) {
        let ListData = UploadHomeworkList;
        ListData.data[index][field] = value;
        setUploadHomeworkList(ListData);
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Homework", "IsView")) {

            document.getElementById("root").addEventListener("scroll", WindowScrolling);
            BindHomeWorkList(1);

            if (data[0].MemberType.toLowerCase() !== "student") {
                if (params.has("add")) {
                    if (params.get("add") === "homework") {
                        handleShowAddEditPopup("", "", "", "");
                    }
                }
            }

            return () => {
                document
                    .getElementById("root")
                    .removeEventListener("scroll", WindowScrolling);
            };
        }
        else {
            history.push("/access");
        }
    }, []);

    function WindowScrolling(event) {

        if (event.srcElement.scrollTop + window.innerHeight >= (event.srcElement.scrollHeight - 100)) {
            BindHomeWorkList(document.getElementsByClassName("homeworkdata").length+1);
        }

        let AllPost = document.getElementsByClassName("callisreadapprove");
        if (AllPost != null && AllPost.length != 0) {
            for (let i = 0; i < AllPost.length; i++) {
                if (isInViewport(AllPost[i])) {
                    let AID = AllPost[i].getAttribute("aid")
                    let AT = AllPost[i].getAttribute("at")
                    approvereadpost(AID, AT);
                    AllPost[i].classList.remove("callisreadapprove");
                }
            }
        }
    }

    function approvereadpost(AID, AT) {

        const reqreadapprovepostdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associatinID: AID,
                associationType: AT
            })
        }
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readapprovepost",
            reqreadapprovepostdata
        ).then((response) => response.json())

            .then(function (json) {
                if (json.status_code === 1) {

                }

                else if (json.status_code === 0) {

                }
                else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }

    function isInViewport(e) {
        const rect = e.getBoundingClientRect();
        return (
            rect.top <= ((window.innerHeight || document.documentElement.clientHeight))
        );
    }

    function getDropdownButtonLabel({ value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    function StudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setHomeWorkData({
                ...HomeWorkData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setHomeWorkData({
                ...HomeWorkData, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setHomeWorkData({
                ...HomeWorkData, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setHomeWorkData({
                ...HomeWorkData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setHomeWorkData({
                ...HomeWorkData, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })

        }
    }


    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("MenuHomework")}</h1>
            <div className="background-theme-color sticky-top homework-searchBar">
                <div className="search-top-section">
                    <div className="row">
                        <div className="col-lg-12 col-xl-7">
                            <div className="header-search-area">
                                <input
                                    type="text"
                                    className=""
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    onChange={(e) => setTxtSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                            <div className="search-right-cal d-flex align-items-center">
                                <div className="header-date-area w-100 mr-3">
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerFrom")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtStartDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {" "}
                                            {commonService.LabelDisplayText("DatepickerTo")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtEndDate}
                                            minDate={txtStartDate && txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtEndDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                </div>
                                <div className="right-icon-cal d-flex align-items-center">
                                    {IsHomeworkListDateLodding === false ? (
                                        <div
                                            className="search-icon-img pointer-cursor"
                                            onClick={() => BindHomeWorkList(1)}
                                        >
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </div>
                                    ) : (
                                        <div className="search-icon-img pointer-cursor">
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    )}
                                    {data[0].MemberType.toLowerCase() !== "student" && commonService.getLoginRoleData("Homework", "IsCreate") && (
                                        <div
                                            className="plus-btn pointer-cursor ml-3"
                                            onClick={() => handleShowAddEditPopup("", "", "", "")}
                                        >
                                            <i className="fa-regular fa-plus"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-result-block">
                    <span>
                        {commonService.LabelDisplayText("ResultFor")}{" "}
                        <span>
                            {txtSearch +
                                " From : " +
                                commonService.getDateInFormat(txtStartDate) +
                                " To : " +
                                commonService.getDateInFormat(txtEndDate)}
                        </span>
                    </span>
                </div>
            </div>

            {!Homework.IsData ? (
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                    </div>
                </div>
            ) : Homework.data.length === 0 ? (
                <div className="col-12">
                    <_NoDataFound fontsize={"300px"} />
                </div>
            ) : (
                <>
                    <div className="row">
                        {Homework.data.map((item, index) => (
                            <div
                                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 homeworkdata ${(item.IsRead == 0 && item.SaveType == 'Send') ? 'callisreadapprove' : ''}`}
                                key={index}
                                aid={item.AssignmentID}
                                at="HomeWork"
                            >
                                <div className="card">
                                    <div className="card-body">
                                        <div
                                            className="card-homework-header pointer-cursor"
                                            onClick={() =>
                                                handleShowAddEditPopup(
                                                    item.AssignmentID,
                                                    item.StandardName,
                                                    item.DivisionName,
                                                    item.SubjectName
                                                )
                                            }
                                        >
                                            <div
                                                className="user-pic"
                                                title={item.CreateByName}
                                                style={{
                                                    backgroundColor: commonService.CreateColor(
                                                        item.Initials
                                                    ).BGColor,
                                                    color: commonService.CreateColor(item.Initials)
                                                        .FontColor,
                                                }}
                                            >
                                                {item.Avatar !== "" ? (
                                                    <img
                                                        src={AppConfigData.ERPFileBasePath + item.Avatar}
                                                    />
                                                ) : (
                                                    item.Initials
                                                )}
                                            </div>
                                            <h2 title={item.Title} className="card-body-header">
                                                {item.Title}
                                            </h2>
                                            <i className="fa-regular fa-square-arrow-up-right expand-classwork-icon"></i>
                                        </div>
                                        <label className="language-label mr-1">
                                            {item.SubjectName}
                                        </label>
                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                            <label className="std-homework-label">
                                                {item.StandardName + " - " + item.DivisionName}
                                            </label>
                                        )}
                                        {data[0].MemberType.toLowerCase() === "teacher" ? <>
                                            <label className={`pdf-label ml-1`}>
                                                {item.SaveType}
                                            </label>
                                        </> : <></>}

                                        <p
                                            className="card-body-content mt-2"
                                            dangerouslySetInnerHTML={{ __html: item.HomeWorksDetails }}
                                        ></p>

                                        <div className="mt-3">
                                            {item.Files ? (
                                                JSON.parse(item.Files).map(
                                                    (itemFile, indexFile) =>
                                                        indexFile < 3 && (
                                                            <label
                                                                onClick={() =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: JSON.parse(item.Files),
                                                                    })
                                                                }
                                                                key={indexFile}
                                                                className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                                            >
                                                                {itemFile.FileType}
                                                            </label>
                                                        )
                                                )
                                            ) : (
                                                <label></label>
                                            )}
                                        </div>
                                        <div className="section-for-footer-card">
                                            <div className="left-section">
                                                <span className="fa-light fa-calendar-lines calendar-icons"></span>
                                                <p>
                                                    {commonService.getDateInFormat(item.DateOfHomeWork)}
                                                </p>
                                            </div>

                                            {data[0].MemberType.toLowerCase() === "teacher" ? <>
                                                <div
                                                    className="upload-section pointer-cursor"
                                                    onClick={() =>
                                                        studentreadstatus(
                                                            item.AssignmentID,

                                                        )
                                                    }
                                                >
                                                    <i className=" mr-1 fa-regular fa-check-double"></i>
                                                    <span>{commonService.LabelDisplayText("ReadStatus")}</span>
                                                </div>
                                            </> : <></>}

                                            <div className="right-section">
                                                {data[0].MemberType.toLowerCase() === "student" ? (
                                                    <div
                                                        className="upload-section pointer-cursor"
                                                        onClick={() =>
                                                            StudentTeacherUploadHomeworkOption(
                                                                item.AssignmentID,
                                                                item.GradeID,
                                                                item.DivisionID
                                                            )
                                                        }
                                                    >
                                                        {item.TotalIsApprove > 0 ? (
                                                            <i className=" mr-2 fa-regular fa-check-double"></i>
                                                        ) : (
                                                            <i className="fa-regular fa-arrow-up-from-bracket mr-2"></i>
                                                        )}
                                                        <span>{commonService.LabelDisplayText("HomeworkUpload")}</span>
                                                    </div>
                                                ) : (<>

                                                    <div
                                                        className="upload-section pointer-cursor"
                                                        onClick={() =>
                                                            StudentTeacherUploadHomeworkOption(
                                                                item.AssignmentID,
                                                                item.GradeID,
                                                                item.DivisionID
                                                            )
                                                        }
                                                    >
                                                        <i className=" mr-1 fa-regular fa-check-double"></i>
                                                        <span>{commonService.LabelDisplayText("HomeworkCheck")}</span>
                                                    </div>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {
                        Homework.data.length !== 0 && IsHomeworkListDateLodding &&
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.HomeWork} count={4} />
                            </div>
                        </div>
                    }

                </>
            )}

            <Modal
                show={showAddEditPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowAddEditPopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() =>
                            data[0].MemberType.toLowerCase() !== "student"
                                ? params.has("add") === true
                                    ? params.get("add") === "homework"
                                        ? history.push("wall")
                                        : setShowAddEditPopup(false)
                                    : setShowAddEditPopup(false)
                                : setShowAddEditPopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("MenuHomework")}
                                    </h1>
                                    {
                                        data[0].MemberType.toLowerCase() !== "student" ? (
                                            IsHomeworkSaveing === false ? (
                                                <>{HomeWorkData.SaveType.toUpperCase() === "SEND" && commonService.getLoginRoleData("Homework", "IsDelete") ? <>
                                                    <button
                                                        className="btn btn-primary px-3 pr-4 mb-3"
                                                        type="button"
                                                        onClick={() =>
                                                            confirmAlert({
                                                                title: "Delete",
                                                                message: "Are you sure delete ?",
                                                                buttons: [
                                                                    {
                                                                        label: "Yes",
                                                                        onClick: () =>
                                                                            fnDeleteHomeWork(
                                                                                HomeWorkData.AssignmentID
                                                                            ),
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                    },
                                                                ],
                                                            })
                                                        }
                                                    >
                                                        {commonService.LabelDisplayText("Delete")}
                                                    </button>
                                                </> : <>
                                                    <ButtonGroup className="mb-3">
                                                        <button
                                                            className="btn btn-primary px-3 pr-4"
                                                            type="button"
                                                            onClick={() => ButtonClickForSave("Send")}
                                                        >
                                                            {commonService.LabelDisplayText("Send")}
                                                        </button>
                                                        <DropdownButton
                                                            as={ButtonGroup}
                                                            title=""
                                                            className="btn-transparent send-dropdown-button"
                                                        >
                                                            <Dropdown.Item
                                                                eventKey="1"
                                                                onClick={() => ButtonClickForSave("Send")}
                                                            >
                                                                {commonService.LabelDisplayText("Send")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                eventKey="2"
                                                                onClick={() => ButtonClickForSave("Draft")}
                                                            >
                                                                {commonService.LabelDisplayText("Draft")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                eventKey="3"
                                                                onClick={() => ButtonClickForSave("Schedule")}
                                                            >
                                                                {commonService.LabelDisplayText("Schedule")}
                                                            </Dropdown.Item>
                                                            {HomeWorkData.SaveType.toUpperCase() !== "SEND" &&
                                                                HomeWorkData.SaveType.toUpperCase() !== "" && commonService.getLoginRoleData("Homework", "IsDelete") && (
                                                                    <div>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            eventKey="4"
                                                                            onClick={() =>
                                                                                confirmAlert({
                                                                                    title: "Delete",
                                                                                    message: "Are you sure delete ?",
                                                                                    buttons: [
                                                                                        {
                                                                                            label: "Yes",
                                                                                            onClick: () =>
                                                                                                fnDeleteHomeWork(
                                                                                                    HomeWorkData.AssignmentID
                                                                                                ),
                                                                                        },
                                                                                        {
                                                                                            label: "No",
                                                                                        },
                                                                                    ],
                                                                                })
                                                                            }
                                                                        >
                                                                            {commonService.LabelDisplayText("DeleteDraft")}
                                                                        </Dropdown.Item>
                                                                    </div>
                                                                )}
                                                        </DropdownButton>
                                                    </ButtonGroup>
                                                </>}</>
                                            ) : (
                                                <button
                                                    className="btn btn-primary px-3 pr-5"
                                                    type="button"
                                                    disabled="disabled"
                                                >
                                                    <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                </button>
                                            )
                                        ) : (
                                            ""
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 date-section-area">
                                <input
                                    type="text"
                                    className={`mt-4 title-textbox ${HomeWorkData.errors.Title && "invalid"
                                        }`}
                                    disabled={
                                        data[0].MemberType.toLowerCase() === "student" && "disabled"
                                    }
                                    placeholder="Homework / Assignment Title"
                                    onChange={(e) =>
                                        setHomeWorkData({
                                            ...HomeWorkData,
                                            ["Title"]: e.target.value,
                                        })
                                    }
                                    defaultValue={HomeWorkData.Title}
                                />
                                {HomeWorkData.errors.Title && (
                                    <span className="error-message">
                                        {HomeWorkData.errors.Title}
                                    </span>
                                )}
                                <div className="rich-txt-editor-area mt-3">
                                    <Editor
                                        toolbarHidden={
                                            data[0].MemberType.toLowerCase() === "student"
                                        }
                                        readOnly={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "readOnly"
                                        }
                                        editorState={editorHomeWorksDetails}
                                        toolbar={AppConfigData.toolbarConfig}
                                        onEditorStateChange={setEditorHomeWorksDetails}
                                    />
                                </div>
                                {data[0].MemberType.toLowerCase() !== "student" && (
                                    <DropdownButton
                                        title="Add"
                                        className="mt-3"
                                        variant=" text-editor "
                                    >
                                        <Dropdown.Item
                                            eventKey="1"
                                            className="dropdown-item"
                                            onClick={() => setShowLinkPopup(true)}
                                        >
                                            <i className="fa-solid fa-link-simple"></i> {commonService.LabelDisplayText("Link")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="2"
                                            className="dropdown-item"
                                            onClick={() => setShowFileSelectPopup(true)}
                                        >
                                            <i className="fa-regular fa-paperclip"></i> {commonService.LabelDisplayText("File")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="3"
                                            className="dropdown-item"
                                            onClick={() => setShowYoutubeLinkPopup(true)}
                                        >
                                            <i className="fa-brands fa-youtube"></i> {commonService.LabelDisplayText("Youtube")}
                                        </Dropdown.Item>
                                    </DropdownButton>
                                )}

                                <div className="row">
                                    <div className="col-12 added-popup-data">
                                        {HomeWorkData.FilesDetails.map((itemFile, indexFile) => (
                                            <div
                                                className="card homework-page-card mt-3"
                                                key={indexFile}
                                            >
                                                {CreateFilesDetailsIU(itemFile, indexFile)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">{commonService.LabelDisplayText("DueDate")}</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  mb-2">
                                        <DatePicker
                                            className={`datepicker homework-input-date ${HomeWorkData.errors.DateOfFinish && "invalid"
                                                }`}
                                            disabled={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "disabled"
                                            }
                                            selected={HomeWorkData.DateOfFinish}
                                            minDate={HomeWorkData.DateOfFinish}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) =>
                                                setHomeWorkData({
                                                    ...HomeWorkData,
                                                    ["DateOfFinish"]: date,
                                                })
                                            }
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                        {HomeWorkData.errors.DateOfFinish && (
                                            <span className="error-message">
                                                {HomeWorkData.errors.DateOfFinish}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-2 d-flex align-items-center">
                                        <label className="home-work-label">For</label>
                                    </div>
                                    {data[0].MemberType.toLowerCase() !== "student" ? (
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 mb-2 select-student-subject">
                                            <div className="selectSubject-popup">
                                                <Select
                                                    className={`${HomeWorkData.errors.SubjectID && "invalid"
                                                        }`}
                                                    placeholder={"Select Subject"}
                                                    options={TeacherSubject}
                                                    onChange={(e) =>
                                                        StandardDivisionSubjectChange(e.value)
                                                    }
                                                    value={TeacherSubject.find(
                                                        (obj) =>
                                                            obj.value ===
                                                            HomeWorkData.GradeID.toLocaleUpperCase() +
                                                            "|" +
                                                            HomeWorkData.DivisionID.toLocaleUpperCase() +
                                                            "|" +
                                                            HomeWorkData.SubjectID.toLocaleUpperCase()
                                                    )}
                                                />
                                                {HomeWorkData.errors.SubjectID && (
                                                    <span className="error-message">
                                                        {HomeWorkData.errors.SubjectID}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="ReactMultiSelectCheckboxes mt-2 select-student-dropdown">
                                                <ReactMultiSelectCheckboxes
                                                    width={"100%"}
                                                    placeholderButtonLabel={"Select Student"}
                                                    options={SubjectEnrollStudent}
                                                    isMulti
                                                    onChange={StudentSelectionChange}
                                                    getDropdownButtonLabel={getDropdownButtonLabel}
                                                    closeMenuOnSelect={false}
                                                    value={SubjectEnrollStudent.filter((obj) =>
                                                        HomeWorkData.TagMemberIDs.split(",").includes(
                                                            obj.value
                                                        )
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  mb-2">
                                            <input
                                                type="text"
                                                className={`title-textbox`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                defaultValue={HomeWorkData.SubjectName}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowLinkPopup(false)}
                className="add-file-popup mt-5"
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddLink")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["name"]: e.target.value })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["link"]: e.target.value })
                                }
                                className={`form-control ${AttachLink.errors && "invalid"}`}
                                placeholder="Add link here"
                            />
                            {AttachLink.errors && (
                                <span className="error-message">{AttachLink.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddLinkInFilesDetails()}
                    >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showYoutubeLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowYoutubeLinkPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddYoutube")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["name"]: e.target.value,
                                    })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["link"]: e.target.value,
                                    })
                                }
                                className={`form-control  ${AttachYoutubeLink.errors && "invalid"
                                    }`}
                                placeholder="Add youtube embed link here"
                            />
                            {AttachYoutubeLink.errors && (
                                <span className="error-message">
                                    {AttachYoutubeLink.errors}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddYoutubeLinkInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showFileSelectPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowFileSelectPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="file"
                                className={`form-control  ${AttachFiles.errors && "invalid"}`}
                                multiple
                                onChange={(e) =>
                                    setAttachFiles({ ...AttachFiles, ["link"]: e.target.files })
                                }
                            />
                            {AttachFiles.errors && (
                                <span className="error-message">{AttachFiles.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddFileSelectInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showScheduleDatePopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowScheduleDatePopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectScheduleDate")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowScheduleDatePopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                showTimeSelect
                                className={`datepicker homework-input-date form-control  ${HomeWorkData.errors.DateOfHomeWork && "invalid"
                                    }`}
                                selected={HomeWorkData.DateOfHomeWork}
                                dateFormat={data[0].DateFormat_Term + " " + data[0].TimeFormat_Term}
                                onChange={(date) =>
                                    setHomeWorkData({ ...HomeWorkData, ["DateOfHomeWork"]: date })
                                }
                                placeholderText={data[0].DateFormat_Term + " " + data[0].TimeFormat_Term}
                            />
                            {HomeWorkData.errors.DateOfHomeWork && (
                                <span className="error-message">
                                    {HomeWorkData.errors.DateOfHomeWork}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowScheduleDatePopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddEditHomeWork("Schedule")}
                    >
                        {commonService.LabelDisplayText("Schedule")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showCheckHomeworkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowCheckHomeworkPopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowCheckHomeworkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("HomeworkUpload")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    {data[0].MemberType.toLowerCase() === "student" && (
                        <div className="container upload-homework-section mt-4">
                            <div className="rich-txt-editor-area">
                                <Editor
                                    editorState={editorUploadHomeWorksDetails}
                                    toolbar={AppConfigData.toolbarConfig}
                                    onEditorStateChange={setEditorUploadHomeWorksDetails}
                                />
                            </div>
                            <div className="file-seleciton-post-section">
                                <div>
                                    <div className="mt-3 dropdown">
                                        <input
                                            type="file"
                                            className={`form-control`}
                                            multiple
                                            onChange={(e) =>
                                                FnUploadHomeWorkAttachFiles(e.target.files)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="post-button-section mt-3">
                                    {IsStudentUploadHomeworkSaveing === false ? (
                                        <button
                                            className="post-select-btn"
                                            onClick={() => UploadStudentHomework()}
                                        >
                                            {commonService.LabelDisplayText("Submit")}
                                        </button>
                                    ) : (
                                        <button
                                            className="post-select-btn"
                                            type="button"
                                            disabled="disabled"
                                        >
                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            {UploadStudentHomeworkError && (
                                <span className="error-message">
                                    {UploadStudentHomeworkError}
                                </span>
                            )}
                            {UploadHomeWorkAttachFiles.map((itemFile, indexFile) => (
                                <div className="card homework-page-card mt-3" key={indexFile}>
                                    {CreateFilesDetailsIU(itemFile, indexFile)}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="container uploaded-homework-table mt-4">
                        {!UploadHomeworkList.IsData ? (
                            <div className="row col-12">
                                <_Skeleton
                                    type={_SkeletonType.Table}
                                    count={4}
                                    lineheight={30}
                                />
                            </div>
                        ) : UploadHomeworkList.data.length === 0 ? (
                            <div className="col-12">
                                <_NoDataFound fontsize={"150px"} />
                            </div>
                        ) : (
                            <Table
                                striped
                                bordered
                                hover
                                className=" table table-dashboard upload-homework-table"
                            >
                                <thead>
                                    <tr>
                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                            <>
                                                <th class="w-75px">{commonService.LabelDisplayText("Roll")}</th>
                                                <th >{commonService.LabelDisplayText("Name")}</th>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <th class="w-200px">{commonService.LabelDisplayText("Uploadedby")}</th>
                                        <th class="w-200px">{commonService.LabelDisplayText("Uploadedon")}</th>
                                        <th class="w-75px">{commonService.LabelDisplayText("Approve")}</th>
                                        <th>{commonService.LabelDisplayText("Note")}</th>
                                        <th class="w-180px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UploadHomeworkList.data.map((item, index) => (
                                        <tr key={index}>
                                            {data[0].MemberType.toLowerCase() !== "student" ? (
                                                <>
                                                    <td className="text-center">{item.RollNo}</td>
                                                    <td>{item.StudentFullName}</td>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <td>{item.DisplayName}</td>
                                            <td>
                                                {commonService.getDateTimeInFormat(item.CreatedOn)}
                                            </td>
                                            <td className="text-center">
                                                {data[0].MemberType.toLowerCase() !== "student" ? (
                                                    item.StudentReplayID &&
                                                        item.StudentReplayID !== "" ? (
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={item.IsApprove}
                                                            onChange={(e) =>
                                                                UpdateUploadHomeworkListItem(
                                                                    e.target.checked,
                                                                    "IsApprove",
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                ) : item.IsApprove !== null ? (
                                                    item.IsApprove === true ? (
                                                        <span>{commonService.LabelDisplayText("Yes")}</span>
                                                    ) : (
                                                        <span>{commonService.LabelDisplayText("No")}</span>
                                                    )
                                                ) : (
                                                    <span></span>
                                                )}
                                            </td>
                                            <td>
                                                {data[0].MemberType.toLowerCase() !== "student" ? (
                                                    item.StudentReplayID &&
                                                        item.StudentReplayID !== "" ? (
                                                        <input
                                                            type="text"
                                                            onChange={(e) =>
                                                                UpdateUploadHomeworkListItem(
                                                                    e.target.value,
                                                                    "Note",
                                                                    index
                                                                )
                                                            }
                                                            className="title-textbox"
                                                            placeholder="Teacher Note"
                                                            defaultValue={item.Note}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                ) : (
                                                    <>{item.Note}</>
                                                )}
                                            </td>
                                            <td>
                                                {fnCreateViewButtion(item)}
                                                {data[0].MemberType.toLowerCase() !== "student" &&
                                                    item.StudentReplayID &&
                                                    item.StudentReplayID !== "" ? (
                                                    <button
                                                        className="small-btn ml-2"
                                                        onClick={() =>
                                                            TeacherCommentOnUploadHomework(index)
                                                        }
                                                    >
                                                        {commonService.LabelDisplayText("Save")}
                                                    </button>
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={studentreadstatuspopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setstudentreadstatuspopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setstudentreadstatuspopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">
                                            {" "}
                                            {commonService.LabelDisplayText("ReadStatus")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {readstatus.isdata === true ? <>
                            {readstatus.data.length !== 0 ? <>
                                <Table
                                    striped
                                    bordered
                                    hover
                                    className="check-homework-table table table-dashboard upload-homework-table"
                                >
                                    <thead>
                                        <th>{commonService.LabelDisplayText("GR")}</th>
                                        <th>{commonService.LabelDisplayText("StudentName")}</th>
                                        <th>{commonService.LabelDisplayText("STDDIV")}</th>
                                        <th>{commonService.LabelDisplayText("FatherContact")}</th>
                                        <th>{commonService.LabelDisplayText("MotherContact")}</th>
                                        <th>{commonService.LabelDisplayText("Status")}</th>
                                    </thead>

                                    <tbody>
                                        {readstatus.data.map((a) => (
                                            <>
                                                <tr>
                                                    <td>
                                                        {a.GRNo}
                                                    </td>
                                                    <td>
                                                        {a.StudentFullName}
                                                    </td>
                                                    <td>
                                                        {a.STDDIV}
                                                    </td>
                                                    <td>
                                                        {a.FatherContactNo}
                                                    </td>
                                                    <td>
                                                        {a.MotherContactNo}
                                                    </td>
                                                    <td>

                                                        {a.StatusType === "Readed" ? <>
                                                            <label className={`link-label pointer-cursor ml-1`}>
                                                                {a.StatusType}
                                                            </label>
                                                        </> : <>
                                                            <label className={`file-label pointer-cursor ml-1`}>
                                                                {a.StatusType}
                                                            </label>
                                                        </>}
                                                    </td>


                                                </tr>
                                            </>
                                        ))}
                                    </tbody>

                                </Table>
                            </> : <>
                                <_NoDataFound fontsize={'150px'} />
                            </>}

                        </> : <>
                            <div className="row col-12">
                                <_Skeleton
                                    type={_SkeletonType.Table}
                                    count={4}
                                    lineheight={30}
                                />
                            </div>
                        </>}

                    </>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Homework;
