import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import DarkModeToggle from "react-dark-mode-toggle";
// import useDarkMode from "use-dark-mode";
import img3 from "../Images/logo/3.png"
import img4 from "../Images/logo/4.png"

function _Header() {
    document.getElementById("main").classList.remove("login");
    const history = useHistory();
    const [isDarkMode, setIsDarkMode] = useState(() => false);
    // const darkMode = useDarkMode(false);

    let dataAccessToken = commonService.getItem("accesstoken") || "";
    if (dataAccessToken === "") {
        localStorage.clear();
        history.push("/");
    }

    let data = commonService.getLoginUserData();

    function gotoSwitch() {
        history.push("switch");
    }

    function ChangeThemeMode(e) {
        setIsDarkMode(e);
        const htmlTag = document.getElementsByTagName("html")[0];
        if (htmlTag.hasAttribute("data-theme")) {
            htmlTag.removeAttribute("data-theme");
            return window.localStorage.removeItem("site-theme");
        }
        htmlTag.setAttribute("data-theme", "dark");
        window.localStorage.setItem("site-theme", "dark");
    }
    function applyInitialTheme() {
        let theme = window.localStorage.getItem("site-theme");
        if (theme === null) {
            theme = "";
        }
        if (theme === "null") {
            theme = "";
        }
        if (theme !== null && theme !== "") {
            if (theme === "dark") {
                setIsDarkMode(true);
            }
            const htmlTag = document.getElementsByTagName("html")[0];
            htmlTag.setAttribute("data-theme", theme);
        }
    }

    React.useEffect(() => {
        applyInitialTheme();
    }, []);

    if (data.length !== 0) {
        return (
            <header id="Main-header">
                <div className="main-header d-flex justify-content-between align-items-center">
                    <div className="logo-section">
                        <img src={img3} className="main-logo main-logo-light" />
                        <img src={img4} className="main-logo main-logo-dark" />
                        {/*<span className="icon-light-logo">*/}
                        {/*    <span className="path1"></span>*/}
                        {/*    <span className="path2"></span>*/}
                        {/*    <span className="path3"></span>*/}
                        {/*    <span className="path4"></span>*/}
                        {/*    <span className="path5"></span>*/}
                        {/*    <span className="path6"></span>*/}
                        {/*    <span className="path7"></span>*/}
                        {/*    <span className="path8"></span>*/}
                        {/*    <span className="path9"></span>*/}
                        {/*    <span className="path10"></span>*/}
                        {/*</span>*/}
                    </div>
                    <div className="personal-section">
                        {/*<div className="header-search-largeScreen">*/}
                        {/*  <i className="fa-regular fa-magnifying-glass search-icon"></i>*/}
                        {/*  <input*/}
                        {/*    className="header-search"*/}
                        {/*    type="search"*/}
                        {/*    name="search"*/}
                        {/*    id=""*/}
                        {/*    placeholder="Search"*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <div className="d-flex align-items-center">
                            <div className="inner-personal-section" onClick={gotoSwitch}>
                                <div className="name-area">
                                    <p>
                                        <i>{commonService.LabelDisplayText("Welcome")}</i>
                                    </p>
                                    <p>
                                        <b className="uppercase">
                                            {data[0].FirstName} {data[0].LastName}
                                        </b>
                                    </p>
                                    <p>
                                        <i>{data[0].InstituteName}</i>
                                    </p>
                                </div>
                                <div
                                    className="profile-area header-profile-area"
                                    style={{
                                        backgroundColor: commonService.CreateColor(data[0].Initials)
                                            .BGColor,
                                        color: commonService.CreateColor(data[0].Initials)
                                            .FontColor,
                                    }}
                                >
                                    {data[0].Avatar !== "" ? (
                                        <img src={AppConfigData.ERPFileBasePath + data[0].Avatar} />
                                    ) : (
                                        data[0].Initials
                                    )}
                                </div>
                            </div>
                            <div>
                                <DarkModeToggle
                                    onChange={(e) => ChangeThemeMode(e)}
                                    checked={isDarkMode}
                                    size={50}
                                    className="dark-theme-toggle ml-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="small-screen-search-bar mt-4">
          <i className="fa-regular fa-magnifying-glass search-icon"></i>
          <input
            className="header-search small-header-search"
            type="search"
            name="search"
            id=""
          placeholder="Search"
          />
        </div>*/}
            </header>
        );
    } else {
        return "<div></div>";
    }
}
export default _Header;
