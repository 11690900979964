import React from "react";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import { commonService, AppConfigData } from "../_services/common.service";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import _NoDataFound from "./_NoDataFound";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import Access from "./Access";

function HostelTransactionDetail() {
    let data = commonService.getLoginUserData();
    const [studentpocketmoney, setstudentpocketmoney] = useState({
        isdata: false,
        data: [],
    });
    const [studentpocketmoneydetail, setstudentpocketmoneydetail] = useState({
        isdata: false,
        data: [],
    });
    const history = useHistory();
    const [isloading, setisloading] = useState(false);
    useEffect(() => {
        if (commonService.getLoginRoleData("Hostel", "IsView") && data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "student") {
            getstudentpocketmoney();
        } else {
            history.push("/access");
        }
    }, []);

    function getstudentpocketmoney() {
        const reqstudentpocketmoney = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/hostel/getstudentpocketmoneytransactiondetails",
            reqstudentpocketmoney
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    var a = json.data.transaction;
                    var b = json.data.hostelAccounts;
                    setstudentpocketmoney({ isdata: true, data: a.reverse() });
                    setstudentpocketmoneydetail({ isdata: true, data: b });
                } else if (json.status_code === 0) {
                    setstudentpocketmoney({ isdata: true, data: [] });
                    setstudentpocketmoneydetail({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    var balance = 0;
    function getnetbalance(thismonthbalance, thismonthtype) {
        var type = thismonthtype;
        if (balance === 0) {
            balance = studentpocketmoneydetail.data[0].CurrentBalance;
            return balance;
        } else {
            if (type === "withdraw") {
                balance = balance + thismonthbalance;
                return balance;
            } else {
                balance = balance - thismonthbalance;
                return balance;
            }
        }
    }

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("HostelTransactionDetail")}</h1>
            {data[0].MemberType.toLowerCase() === "student" ? (
                <>
                    {studentpocketmoney.isdata && studentpocketmoneydetail.isdata ? (
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3  col-xl-3">
                                {studentpocketmoneydetail.data.length !== 0 ? (
                                    <>
                                        <Card border="light">
                                            <Card.Header as="h5">
                                                <div className="account-holder-section">
                                                    <span className="account-holder-name">
                                                        {studentpocketmoneydetail.data[0].StudentName}
                                                    </span>
                                                    <label class="youtube-label float-right">
                                                        {studentpocketmoneydetail.data[0].StandardDivision}
                                                    </label>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("Account")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <label class="pdf-label">
                                                            {studentpocketmoneydetail.data[0].AcctNo}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("HR")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <label class="link-label">
                                                            {studentpocketmoneydetail.data[0].HRNo}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("Building")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>{studentpocketmoneydetail.data[0].Building}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("Floor")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {studentpocketmoneydetail.data[0].BuildingFloor}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("Room")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {
                                                                studentpocketmoneydetail.data[0]
                                                                    .BuildingFloorRoom
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        <b>{commonService.LabelDisplayText("Bed")} : </b>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>
                                                            {studentpocketmoneydetail.data[0].BuildingRoomBed}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </>
                                ) : (
                                        <Card border="light">
                                            <Card.Body>
                                                <_NoDataFound fontsize={"300px"} />
                                            </Card.Body>
                                        </Card>
                                    )}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                {studentpocketmoney.data.length !== 0 ? (
                                    <Table responsive className="pocketmoney-table">
                                        <thead>
                                            <th className="w-100px">{commonService.LabelDisplayText("Date")}</th>
                                            <th className="w-150px text-right">{commonService.LabelDisplayText("Deposit")}</th>
                                            <th className="w-150px text-right">{commonService.LabelDisplayText("Withdraw")}</th>
                                            <th className="w-150px text-right">{commonService.LabelDisplayText("Balance")}</th>
                                            <th>{commonService.LabelDisplayText("Detail")}</th>
                                        </thead>
                                        <tbody>
                                            {studentpocketmoney.data.map((a, index) => (
                                                <tr>
                                                    <td>
                                                        {commonService.getDateInFormat(a.DateOfTransaction)}
                                                    </td>
                                                    <td className="text-right">
                                                        {a.TransactionType_Term === "deposit" ? (
                                                            <>{a.Amount}</>
                                                        ) : (
                                                                <>-</>
                                                            )}
                                                    </td>
                                                    <td className="text-right">
                                                        {a.TransactionType_Term === "withdraw" ? (
                                                            <>{a.Amount}</>
                                                        ) : (
                                                                <>-</>
                                                            )}
                                                    </td>
                                                    <td className="text-right">
                                                        {index == 0
                                                            ? getnetbalance(
                                                                studentpocketmoney.data[index].Amount,
                                                                studentpocketmoney.data[index]
                                                                    .TransactionType_Term
                                                            )
                                                            : getnetbalance(
                                                                studentpocketmoney.data[index - 1].Amount,
                                                                studentpocketmoney.data[index - 1]
                                                                    .TransactionType_Term
                                                            )}
                                                    </td>
                                                    <td>{a.Narration}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                        <_NoDataFound fontsize={"300px"} />
                                    )}
                            </div>
                        </div>
                    ) : (
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        )}
                </>
            ) : (
                    <></>
                )}
        </>
    );
}
export default HostelTransactionDetail;
