import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory } from "react-router-dom";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";

function OverallResult() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [ExamDetails, setExamDetails] = useState({ IsData: false, data: [] });
    const [ExamHeader, setExamHeader] = useState("");
    const [ExamTime, setExamTime] = useState("0 hrs 0 mins 0 sec");
    const [QAvgTime, setQAvgTime] = useState("0h 0m 0s");

    function ExamResultData(data) {
        setExamHeader(data.SubjectName)
        setExamDetails({ IsData: true, data: data.ResultData });


        var h = Math.floor(data.ResultData[0].TimeTokenInSeconds / 3600);
        var m = Math.floor(data.ResultData[0].TimeTokenInSeconds % 3600 / 60);
        var s = Math.floor(data.ResultData[0].TimeTokenInSeconds % 3600 % 60);
        setExamTime(h + ' hrs ' + m + ' mins ' + s + ' sec');

        var h1 = Math.floor((data.ResultData[0].TimeTokenInSeconds / data.ResultData[0].TotalQuestions) / 3600);
        var m1 = Math.floor((data.ResultData[0].TimeTokenInSeconds / data.ResultData[0].TotalQuestions) % 3600 / 60);
        var s1 = Math.floor((data.ResultData[0].TimeTokenInSeconds / data.ResultData[0].TotalQuestions) % 3600 % 60);
        setQAvgTime(h1 + 'h ' + m1 + 'm ' + s1 + 's');
    }

    React.useEffect(() => {
        if(commonService.getLoginRoleData("Exam","IsView")){
        if (history.location.state !== undefined) {
            ExamResultData(history.location.state);
        }
        else {
            history.push("/exam");
        }
    }
    else{
        history.push("/access");
      }
    }, []);


    return (
        ExamDetails.IsData &&
        <>
            <h1 className="page-header my-4">  {commonService.LabelDisplayText("OverallResult")} ({ExamHeader})</h1>
            <div className="row result-grid">
                <div className="col-7">
                    <div className="card blue-card-result">
                        <div className="card-body">
                            <p>{commonService.LabelDisplayText("congratulations")} !</p>
                            <p className="answered-text-card">
                                <span>{ExamDetails.data[0].TotalRightQuestions}</span><span>/{ExamDetails.data[0].TotalQuestions}</span
                                ><span>{commonService.LabelDisplayText("CorrectAnswered")}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    <div className="card card-with-medal">
                        <div className="card-body">
                            <h2>{commonService.LabelDisplayText("Leaderboard")}</h2>
                            <p>{commonService.LabelDisplayText("Checkthetopperformingstudents")}</p>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card result-normal-card">
                        <div className="card-body">
                            <p>
                                <i class="fa-regular fa-memo-circle-check"></i>
                                <span>{commonService.LabelDisplayText("Score")}</span>
                            </p>
                            <p>
                                <span>{ExamDetails.data[0].TotalMarksObtained}</span>
                                <span>/{ExamDetails.data[0].TotalMarks}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card result-normal-card">
                        <div className="card-body">
                            <p>
                                <i class="fa-solid fa-chevrons-right"></i>
                                <span>{commonService.LabelDisplayText("Skip")}</span>
                            </p>
                            <p>
                                <span>{ExamDetails.data[0].TotalSkipQuestions} </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card result-normal-card">
                        <div className="card-body">
                            <p>
                                <i class="fa-regular fa-circle-xmark"></i>
                                <span>{commonService.LabelDisplayText("Wrong")}</span>
                            </p>
                            <p>
                                <span>{ExamDetails.data[0].TotalWrongQuestions}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card result-normal-card">
                        <div className="card-body">
                            <p>
                                <i className=" icon-fi-rr-dashboard
                       "></i>
                                <span>{commonService.LabelDisplayText("Percentage")}</span>
                            </p>
                            <p>
                                <span>{(ExamDetails.data[0].TotalMarksObtained * 100) / ExamDetails.data[0].TotalMarks}%</span>
                                <i className=" icon-fi-rr-signal-alt ml-2"></i>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card result-normal-card ">
                        <div className="card-body">
                            <p>
                                <i className="icon-fi-rr-time-quarter-to"></i>
                                <span>{commonService.LabelDisplayText("Time")}</span>
                            </p>
                            <p className="last-card-of-result">
                                <span className="d-block time-status-czard"
                                >{ExamTime}</span
                                >
                                <span className="d-block time-average-card"
                                >{QAvgTime} {commonService.LabelDisplayText("Avgtime")} <span> / {commonService.LabelDisplayText("Q")}</span></span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverallResult;