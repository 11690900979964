import Moment from "moment";
const cryptoKey = "SqT_cL@SsRoOm_S@T!Sh_393987";

export const commonService = {
    setItem,
    getItem,
    getHeaders,
    getHeadersFromData,
    getLoginUserData,
    getDateInFormat,
    getDateTimeInFormat,
    getFileType,
    getDateTimeInDBFormat,
    getDateInDBFormat,
    getDateInDateMonthFormat,
    getTimeInFormat,
    CreateColor,
    getDateInMonthYearFormat,
    sendNotification,
    groupArrayOfObjects,
    getLoginRoleData,
    //getLoginRoleDataByURL,
    LabelDisplayText,

};

export const AppConfigData = {
    CryptoKey: cryptoKey,
    APIBasePath: "https://apiv2.orataro.com/api/classroom/",
    /*APIBasePath: "http://localhost:51919/api/classroom/",*/
    APIFileBasePath: "https://apiv2.orataro.com/",
    //ERPBasePath: "http://erp.orataro.com/",
    //ERPFileBasePath: "http://erp.orataro.com/Files/",
    //ERPFileBasePathLMS: "http://erp.orataro.com",

    ERPBasePath: getLoginUserData()[0].ClientSystermType === "SCHOOL" ? "https://erp.orataro.com/" : "https://college.orataro.com/",
    ERPFileBasePath: getLoginUserData()[0].ClientSystermType === "SCHOOL" ? "https://erp.orataro.com/Files/" : "https://college.orataro.com/Files/",
    ERPFileBasePathLMS: getLoginUserData()[0].ClientSystermType === "SCHOOL" ? "https://erp.orataro.com" : "https://college.orataro.com",

    VimeoVideo: "https://player.vimeo.com/video/",
    YoutubeVideo: "https://www.youtube.com/embed/",
    RoleNameByURL: {
        attendance: "Attendance",
        chapterdetails: "LMS Subject List",
        classwork: "Classwork",
        exam: "Exam",
        leave: "Leave Application",
        examquestion: "Exam",
        examquestionone: "Exam",
        examsyllabus: "Exam",
        fees: "Fees",
        pocketmoney: "Hostel",
        homework: "Homework",
        liveclass: "Liveclass",
        notecircualreevents: "Notice",
        notecircualreevents_notice: "Notice",
        overallresult: "Exam",
        profile: "Profile",
        subjectdetails: "LMS Subject List",
        subjectlisting: "LMS Subject List",
        wall: "Wall",
        timetableliveclass: "Timetable",
        communication: "Teachers Communication",
        poll: "Poll"
    },
    toolbarConfig: {
        options: ["inline", "list", "history"],
        //options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline", "strikethrough"],
            //options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
            //className: "",
            //component: "",
            //dropdownClassName: "",
            //bold: { icon: bold, className: "" },
            //italic: { icon: italic, className: "" },
            //underline: { icon: underline, className: "" },
            //strikethrough: { icon: strikethrough, className: "" },
            //monospace: { icon: monospace, className: "" },
            //superscript: { icon: superscript, className: "" },
            //subscript: { icon: subscript, className: "" },
        },
        //blockType: {
        //    inDropdown: true,
        //    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        //    //className: "",
        //    //component: "",
        //    //dropdownClassName: "",
        //},
        //fontSize: {
        //    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        //    //icon: fontSize,
        //    //className: "",
        //    //component: "",
        //    //dropdownClassName: "",
        //},
        //fontFamily: {
        //    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
        //    //className: "",
        //    //component: "",
        //    //dropdownClassName: "",
        //},
        list: {
            inDropdown: false,
            options: ["unordered", "ordered"],
            //options: ['unordered', 'ordered', 'indent', 'outdent'],
            //className: "",
            //component: "",
            //dropdownClassName: "",
            //unordered: { icon: unordered, className: "" },
            //ordered: { icon: ordered, className: "" },
            //indent: { icon: indent, className: "" },
            //outdent: { icon: outdent, className: "" },
        },
        //textAlign: {
        //    inDropdown: false,
        //    options: ['left', 'center', 'right', 'justify'],
        //    //className: "",
        //    //component: "",
        //    //dropdownClassName: "",
        //    //left: { icon: left, className: "" },
        //    //center: { icon: center, className: "" },
        //    //right: { icon: right, className: "" },
        //    //justify: { icon: justify, className: "" },
        //},
        //colorPicker: {
        //    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        //        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        //        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        //        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        //        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        //        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
        //    //icon: color,
        //    //className: "",
        //    //component: "",
        //    //popupClassName: "",
        //},
        //link: {
        //    inDropdown: false,
        //    options: ['link', 'unlink'],
        //    //className: "",
        //    //component: "",
        //    //popupClassName: "",
        //    //dropdownClassName: "",
        //    //showOpenOptionOnHover: true,
        //    //defaultTargetOption: '_self',
        //    //link: { icon: link, className: "" },
        //    //unlink: { icon: unlink, className: "" },
        //    //linkCallback: ""
        //},
        //emoji: {
        //    emojis: [
        //        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        //        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        //        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        //        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        //        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        //        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        //        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        //        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        //        '✅', '❎', '💯',
        //    ],
        //    //icon: emoji,
        //    //className: "",
        //    //component: "",
        //    //popupClassName: "",
        //},
        //embedded: {
        //    defaultSize: {
        //        height: 'auto',
        //        width: 'auto',
        //    },
        //    //icon: embedded,
        //    //className: "",
        //    //component: "",
        //    //popupClassName: "",
        //    //embedCallback: "",
        //},
        //image: {
        //    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        //    alt: { present: false, mandatory: false },
        //    defaultSize: {
        //        height: 'auto',
        //        width: 'auto',
        //    },
        //    //icon: image,
        //    //className: "",
        //    //component: "",
        //    //popupClassName: "",
        //    //urlEnabled: true,
        //    //uploadEnabled: true,
        //    //alignmentEnabled: true,
        //    //uploadCallback: "",
        //    //previewImage: false,
        //},
        //remove: {
        //    //icon: eraser,
        //    //className: "",
        //    //component: ""
        //},
        history: {
            inDropdown: false,
            options: ["undo", "redo"],
            //className: "",
            //component: "",
            //dropdownClassName: "",
            //undo: { icon: undo, className: "" },
            //redo: { icon: redo, className: "" },
        },
    },
    regex: {
        URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        YoutubeEmbed:
            /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    },
    fileIcon: {
        LINK: "fa-regular fa-link-simple",
        // <i class="fa-regular fa-paperclip-vertical"></i>
        YOUTUBE: "fa-brands fa-youtube",
        IMAGE: "fa-regular fa-image",
        AUDIO: "fa-regular fa-music",
        VIDEO: "fa-regular fa-play",
        PDF: "fa-regular fa-file-pdf",
        FILE: "fa-regular fa-file-lines",
    },
};

const DisplayText = {
    BrowserNotSupportVideo: {
        SCHOOL: "Your browser does not support the video tag.",
        COLLEGE: "Your browser does not support the video tag.",
    },
    BrowserNotSupportAudio: {
        SCHOOL: "Your browser does not support the audio tag.",
        COLLEGE: "Your browser does not support the audio tag.",
    },
    UnableToLoadContent: {
        SCHOOL: "Sorry we are unable to load this content in current page.<br /> Click here for view in new tab",
        COLLEGE: "Sorry we are unable to load this content in current page.<br /> Click here for view in new tab",
    },
    Of: {
        SCHOOL: "of",
        COLLEGE: "of",
    },
    Welcome: {
        SCHOOL: "Welcome",
        COLLEGE: "Welcome",
    },
    MenuHome: {
        SCHOOL: "Home",
        COLLEGE: "Home",
    },
    MenuWall: {
        SCHOOL: "Wall",
        COLLEGE: "Wall",
    },
    MenuHomework: {
        SCHOOL: "Homework / Assignment",
        COLLEGE: "Assignment",
    },
    MenuClasswork: {
        SCHOOL: "Classwork",
        COLLEGE: "Classwork",
    },
    MenuNoteCircularEvents: {
        SCHOOL: "Notice / Circular / Events",
        COLLEGE: "Notice / Circular / Events",
    },
    MenuTimetableLiveclass: {
        SCHOOL: "Timetable / Liveclass",
        COLLEGE: "Timetable / Liveclass",
    },
    MenuExam: {
        SCHOOL: "Exam",
        COLLEGE: "Exam",
    },
    MenuFees: {
        SCHOOL: "Fees",
        COLLEGE: "Fees",
    },
    MenuAttendance: {
        SCHOOL: "Attendance",
        COLLEGE: "Attendance",
    },
    MenuSubjectList: {
        SCHOOL: "Subject List",
        COLLEGE: "Subject List",
    },
    Menuleave: {
        SCHOOL: "Leave Application",
        COLLEGE: "Leave Application",
    },
    MenuProfile: {
        SCHOOL: "Profile",
        COLLEGE: "Profile",
    },

    MenuLogout: {
        SCHOOL: "Logout",
        COLLEGE: "Logout",
    },
    AttendenceTitle: {
        SCHOOL: "Attendance",
        COLLEGE: "Attendance",
    },
    SelectAttendanceFrom: {
        SCHOOL: "Select Attendance From",
        COLLEGE: "Select Attendance From",
    },
    SelectAttendenceTo: {
        SCHOOL: "Select Attendance To",
        COLLEGE: "Select Attendance To",
    },
    SelectStandardDivision: {
        SCHOOL: "Select Standard / Division",
        COLLEGE: "Select Course / Division",
    },
    SelectStdDiv: {
        SCHOOL: "Select Std / Div",
        COLLEGE: "Select Course / Div",
    },
    SelectGrade: {
        SCHOOL: "Choose Grade",
        COLLEGE: "Choose Grade",
    },
    SelectMonthYear: {
        SCHOOL: "Select Month / Year",
        COLLEGE: "Select Month / Year",
    },
    AttendencePresent: {
        SCHOOL: "Present",
        COLLEGE: "Present",
    },
    AttendenceCancel: {
        SCHOOL: "Cancel",
        COLLEGE: "Cancel",
    },
    AttendenceAbsent: {
        SCHOOL: "Absent",
        COLLEGE: "Absent",
    },
    AttendenceLeave: {
        SCHOOL: "Leave",
        COLLEGE: "Leave",
    },
    AttendenceSickLeave: {
        SCHOOL: "Sick Leave",
        COLLEGE: "Sick Leave",
    },
    AttendenceWorkingDays: {
        SCHOOL: "Only working Days",
        COLLEGE: "Only working Days",
    },
    ClassworkTitle: {
        SCHOOL: "Classwork",
        COLLEGE: "Classwork",
    },
    Homework: {
        SCHOOL: "Homework",
        COLLEGE: "Assignment",
    },
    HomeworkCheck: {
        SCHOOL: "Check Homework",
        COLLEGE: "Check Assignment",
    },
    HomeworkUpload: {
        SCHOOL: "Upload Homework",
        COLLEGE: "Upload Assignment",
    },
    TimetableLiveclass: {
        SCHOOL: "Timetable / Liveclass",
        COLLEGE: "Timetable / Liveclass",
    },
    TimetableWeekly: {
        SCHOOL: "Weekly",
        COLLEGE: "Weekly",
    },
    Timetable: {
        SCHOOL: "Timetable",
        COLLEGE: "Timetable",
    },
    Liveclass: {
        SCHOOL: "Liveclass",
        COLLEGE: "Liveclass",
    },
    LiveClassHome: {
        SCHOOL: "Live Class",
        COLLEGE: "Live Class",
    },
    SearchText: {
        SCHOOL: "Searching text here...",
        COLLEGE: "Searching text here...",
    },
    DatepickerFrom: {
        SCHOOL: "From",
        COLLEGE: "From",
    },
    DatepickerTo: {
        SCHOOL: "To",
        COLLEGE: "To",
    },
    Events: {
        SCHOOL: "Events",
        COLLEGE: "Events",
    },
    Circular: {
        SCHOOL: "Circular",
        COLLEGE: "Circular",
    },
    Notice: {
        SCHOOL: "Notice",
        COLLEGE: "Notice",
    },
    All: {
        SCHOOL: "All",
        COLLEGE: "All",
    },
    Post: {
        SCHOOL: "Post",
        COLLEGE: "Post",
    },
    Birthdays: {
        SCHOOL: "Birthdays",
        COLLEGE: "Birthdays",
    },
    FeesReceipt: {
        SCHOOL: "Fees Receipt Details",
        COLLEGE: "Fees Receipt Details",
    },
    TransactionDetail: {
        SCHOOL: "Online transaction details",
        COLLEGE: "Online transaction details",
    },
    UpcomingExam: {
        SCHOOL: "Up-Coming",
        COLLEGE: "Up-Coming",
    },
    CompletedExam: {
        SCHOOL: "Completed",
        COLLEGE: "Completed",
    },
    ExamResult: {
        SCHOOL: "Exam Result",
        COLLEGE: "Exam Result",
    },
    WeeklyResult: {
        SCHOOL: "Weekly",
        COLLEGE: "Weekly",
    },
    TermsResult: {
        SCHOOL: "Terms",
        COLLEGE: "Terms",
    },
    SemResult: {
        SCHOOL: "Semester",
        COLLEGE: "Semester",
    },
    CBSEResult: {
        SCHOOL: "ICSE/CBSE",
        COLLEGE: "ICSE/CBSE",
    },
    ViewAll: {
        SCHOOL: "View All",
        COLLEGE: "View All",
    },
    FinishExam: {
        SCHOOL: "Finish Exam",
        COLLEGE: "Finish Exam",
    },
    Transport: {
        SCHOOL: "Transport",
        COLLEGE: "Transport",
    },
    PickupDetail: {
        SCHOOL: "Pickup Details",
        COLLEGE: "Pickup Details",
    },
    DropDetail: {
        SCHOOL: "Drop Details",
        COLLEGE: "Drop Details",
    },
    Unpaid: {
        SCHOOL: "Unpaid",
        COLLEGE: "Unpaid",
    },
    paid: {
        SCHOOL: "Paid",
        COLLEGE: "Paid",
    },
    Point: {
        SCHOOL: "Point",
        COLLEGE: "Point",
    },
    BusNum: {
        SCHOOL: "Bus#",
        COLLEGE: "Bus#",
    },
    BusDriver: {
        SCHOOL: "Driver",
        COLLEGE: "Driver",
    },
    BusHelper: {
        SCHOOL: "Helper",
        COLLEGE: "Helper",
    },
    Hostel: {
        SCHOOL: "Hostel",
        COLLEGE: "Hostel",
    },
    BedHostel: {
        SCHOOL: "Bed",
        COLLEGE: "Bed",
    },
    RoomHostel: {
        SCHOOL: "Room",
        COLLEGE: "Room",
    },
    FloorHostel: {
        SCHOOL: "Floor",
        COLLEGE: "Floor",
    },
    BuildingHostel: {
        SCHOOL: "Building",
        COLLEGE: "Building",
    },
    Leaderboard: {
        SCHOOL: "Leaderboard",
        COLLEGE: "Leaderboard",
    },
    HostelTransactionDetail: {
        SCHOOL: "Pocket Money",
        COLLEGE: "Pocket Money",
    },
    ReadStatus: {
        SCHOOL: "Student Read Status",
        COLLEGE: "Student Read Status",
    },
    Leave: {
        SCHOOL: "Leave",
        COLLEGE: "Leave",
    },
    Settings: {
        SCHOOL: "Settings",
        COLLEGE: "Settings",
    },
    AddLink: {
        SCHOOL: "Add Link",
        COLLEGE: "Add Link",
    },
    AddYoutube: {
        SCHOOL: "Add Youtube",
        COLLEGE: "Add Youtube",
    },
    SelectFile: {
        SCHOOL: "Select File",
        COLLEGE: "Select File",
    },
    SelectScheduleDate: {
        SCHOOL: "Select Schedule Date",
        COLLEGE: "Select Schedule Date",
    },
    TotalMark: {
        SCHOOL: "Total Mark",
        COLLEGE: "Total Mark",
    },
    STDDIV: {
        SCHOOL: "STD-DIV",
        COLLEGE: "COURSE-DIV",
    },
    Year: {
        SCHOOL: "Year",
        COLLEGE: "Year",
    },
    StudentListWithAnswerSheet: {
        SCHOOL: "Student List With Answer Sheet",
        COLLEGE: "Student List With Answer Sheet",
    },
    StudentMarkEntry: {
        SCHOOL: "Student Mark Entry",
        COLLEGE: "Student Mark Entry",
    },
    PaymentDetails: {
        SCHOOL: "Payment Details",
        COLLEGE: "Payment Details",
    },
    STD: {
        SCHOOL: "STD",
        COLLEGE: "Course",
    },
    ApplyForLeave: {
        SCHOOL: "Apply For Leave",
        COLLEGE: "Apply For Leave",
    },
    OverallResult: {
        SCHOOL: "Overall Result",
        COLLEGE: "Overall Result",
    },
    Leaderboard: {
        SCHOOL: "Leaderboard",
        COLLEGE: "Leaderboard",
    },
    Whatdoyouwanttolearn: {
        SCHOOL: "What do you want to learn",
        COLLEGE: "What do you want to learn",
    },
    today: {
        SCHOOL: "Today",
        COLLEGE: "Today",
    },
    YourSubjectstostudy: {
        SCHOOL: "Your Subjects to study",
        COLLEGE: "Your Subjects to study",
    },
    SwitchAccount: {
        SCHOOL: "Switch Account",
        COLLEGE: "Switch Account",
    },
    GenerateReport: {
        SCHOOL: "Generate Report",
        COLLEGE: "Generate Report",
    },
    GeneratingReport: {
        SCHOOL: "Generating Report...",
        COLLEGE: "Generating Report...",
    },
    Save: {
        SCHOOL: "Save",
        COLLEGE: "Save",
    },
    Saving: {
        SCHOOL: "Saving...",
        COLLEGE: "Saving...",
    },
    ReadStatus: {
        SCHOOL: "Read Status",
        COLLEGE: "Read Status",
    },
    DeleteDraft: {
        SCHOOL: "Delete Draft",
        COLLEGE: "Delete Draft",
    },
    DueDate: {
        SCHOOL: "Due Date",
        COLLEGE: "Due Date",
    },
    Close: {
        SCHOOL: "Close",
        COLLEGE: "Close",
    },
    Add: {
        SCHOOL: "Add",
        COLLEGE: "Add",
    },
    Schedule: {
        SCHOOL: "Schedule",
        COLLEGE: "Schedule",
    },
    GR: {
        SCHOOL: "GR",
        COLLEGE: "GR",
    },
    StudentName: {
        SCHOOL: "Student Name",
        COLLEGE: "Student Name",
    },
    FatherContact: {
        SCHOOL: "Father Contact",
        COLLEGE: "Father Contact",
    },
    MotherContact: {
        SCHOOL: "Mother Contact",
        COLLEGE: "Mother Contact",
    },
    Status: {
        SCHOOL: "Status",
        COLLEGE: "Status",
    },
    Section: {
        SCHOOL: "Section",
        COLLEGE: "Section",
    },
    Question: {
        SCHOOL: "Question",
        COLLEGE: "Question",
    },
    Mark: {
        SCHOOL: "Mark",
        COLLEGE: "Mark",
    },
    SAVEANDFINISH: {
        SCHOOL: "SAVE & FINISH",
        COLLEGE: "SAVE & FINISH",
    },
    SKIPANDFINISH: {
        SCHOOL: "SKIP & FINISH",
        COLLEGE: "SKIP & FINISH",
    },
    SAVEANDNEXT: {
        SCHOOL: "SAVE & NEXT",
        COLLEGE: "SAVE & NEXT",
    },
    SKIP: {
        SCHOOL: "SKIP",
        COLLEGE: "SKIP",
    },
    Answered: {
        SCHOOL: "Answered",
        COLLEGE: "Answered",
    },
    Skipped: {
        SCHOOL: "Skipped",
        COLLEGE: "Skipped",
    },
    NotVisited: {
        SCHOOL: "Not Visited",
        COLLEGE: "Not Visited",
    },
    SelectYourAnswerSheet: {
        SCHOOL: "Select Your Answer Sheet",
        COLLEGE: "Select Your Answer Sheet",
    },
    OnlyImageAndPDFFileAreValid: {
        SCHOOL: "Only Image And PDF File Are Valid",
        COLLEGE: "Only Image And PDF File Are Valid",
    },
    NotesYouCanChooseIthereMultipleImageOrIthereOnePDF: {
        SCHOOL: "Notes : You Can Choose Ithere Multiple Image Or Ithere One PDF",
        COLLEGE: "Notes : You Can Choose Ithere Multiple Image Or Ithere One PDF",
    },
    FINISH: {
        SCHOOL: "FINISH",
        COLLEGE: "FINISH",
    },
    GoToBack: {
        SCHOOL: "Go To Back",
        COLLEGE: "Go To Back",
    },
    Duration: {
        SCHOOL: "Duration",
        COLLEGE: "Duration",
    },
    min: {
        SCHOOL: "Min",
        COLLEGE: "Min",
    },
    Marks: {
        SCHOOL: "Marks",
        COLLEGE: "Marks",
    },
    Loading: {
        SCHOOL: "Loading...",
        COLLEGE: "Loading...",
    },
    StartExam: {
        SCHOOL: "Start Exam",
        COLLEGE: "Start Exam",
    },
    GoToMarkEntry: {
        SCHOOL: "Go To Mark Entry",
        COLLEGE: "Go To Mark Entry",
    },
    ViewStudentAnswerSheet: {
        SCHOOL: "View Student Answer Sheet",
        COLLEGE: "View Student Answer Sheet",
    },
    ViewPaper: {
        SCHOOL: "View Paper",
        COLLEGE: "View Paper",
    },
    Roll: {
        SCHOOL: "Roll#",
        COLLEGE: "Roll#",
    },
    Seat: {
        SCHOOL: "Seat#",
        COLLEGE: "Seat#",
    },
    AnsSheet: {
        SCHOOL: "Ans Sheet",
        COLLEGE: "Ans Sheet",
    },
    UploadOn: {
        SCHOOL: "Upload On",
        COLLEGE: "Upload On",
    },
    Checked: {
        SCHOOL: "Checked",
        COLLEGE: "Checked",
    },
    TeacherNote: {
        SCHOOL: "Teacher Note",
        COLLEGE: "Teacher Note",
    },
    Action: {
        SCHOOL: "Action",
        COLLEGE: "Action",
    },
    View: {
        SCHOOL: "View",
        COLLEGE: "View",
    },
    SubmitedWithFile: {
        SCHOOL: "Submited With File",
        COLLEGE: "Submited With File",
    },
    SubmitedWithoutFile: {
        SCHOOL: "Submited Without File",
        COLLEGE: "Submited Without File",
    },
    Appear: {
        SCHOOL: "Appear",
        COLLEGE: "Appear",
    },
    NotAppear: {
        SCHOOL: "Not Appear",
        COLLEGE: "Not Appear",
    },
    PleaseEnterMarkForAbsent10001CopyCase10002: {
        SCHOOL: "Please Enter Mark For Absent : 10001 | Copy Case : 10002",
        COLLEGE: "Please Enter Mark For Absent : 10001 | Copy Case : 10002",
    },
    NetObtainedMarks: {
        SCHOOL: "Obt. Marks",
        COLLEGE: "Obt. Marks",
    },
    NoOfCorrectAnswer: {
        SCHOOL: "Correct Ans.",
        COLLEGE: "Correct Ans.",
    },
    NoOfInCorrectAnswer: {
        SCHOOL: "In Correct Ans.",
        COLLEGE: "In Correct Ans.",
    },
    Grade: {
        SCHOOL: "Grade",
        COLLEGE: "Grade",
    },
    ConvertedMarks: {
        SCHOOL: "Converted Marks",
        COLLEGE: "Converted Marks",
    },
    FeesName: {
        SCHOOL: "Fees Name",
        COLLEGE: "Fees Name",
    },
    Payableamount: {
        SCHOOL: "Payable amount",
        COLLEGE: "Payable amount",
    },
    Paidamount: {
        SCHOOL: "Paid amount",
        COLLEGE: "Paid amount",
    },
    Unpaidamount: {
        SCHOOL: "Un-paid amount",
        COLLEGE: "Un-paid amount",
    },
    Pay: {
        SCHOOL: "Pay",
        COLLEGE: "Pay",
    },
    Receipt: {
        SCHOOL: "Receipt",
        COLLEGE: "Receipt",
    },
    Date: {
        SCHOOL: "Date",
        COLLEGE: "Date",
    },
    Amount: {
        SCHOOL: "Amount",
        COLLEGE: "Amount",
    },
    paymentId: {
        SCHOOL: "Payment Id",
        COLLEGE: "Payment Id",
    },
    Paidby: {
        SCHOOL: "Paid by",
        COLLEGE: "Paid by",
    },
    From: {
        SCHOOL: "From",
        COLLEGE: "From",
    },
    Check: {
        SCHOOL: "Check",
        COLLEGE: "Check",
    },
    Name: {
        SCHOOL: "Name",
        COLLEGE: "Name",
    },
    Payable: {
        SCHOOL: "Payable",
        COLLEGE: "Payable",
    },
    Discount: {
        SCHOOL: "Discount",
        COLLEGE: "Discount",
    },
    Paid: {
        SCHOOL: "Paid",
        COLLEGE: "Paid",
    },
    YourUnpaidAmountIs: {
        SCHOOL: "Your Unpaid Amount Is",
        COLLEGE: "Your Unpaid Amount Is",
    },
    PayNow: {
        SCHOOL: "Pay Now",
        COLLEGE: "Pay Now",
    },
    Wait: {
        SCHOOL: "Wait...",
        COLLEGE: "Wait...",
    },
    From: {
        SCHOOL: "From",
        COLLEGE: "From",
    },
    To: {
        SCHOOL: "To",
        COLLEGE: "To",
    },
    ResultFor: {
        SCHOOL: "Result For",
        COLLEGE: "Result For",
    },
    Link: {
        SCHOOL: "Link",
        COLLEGE: "Link",
    },
    File: {
        SCHOOL: "File",
        COLLEGE: "File",
    },
    Youtube: {
        SCHOOL: "YouTube",
        COLLEGE: "YouTube",
    },
    Uploadedby: {
        SCHOOL: "Uploaded by",
        COLLEGE: "Uploaded by",
    },
    Uploadedon: {
        SCHOOL: "Uploaded on",
        COLLEGE: "Uploaded on",
    },
    Approve: {
        SCHOOL: "Approve",
        COLLEGE: "Approve",
    },
    Note: {
        SCHOOL: "Note",
        COLLEGE: "Note",
    },
    Yes: {
        SCHOOL: "Yes",
        COLLEGE: "Yes",
    },
    No: {
        SCHOOL: "No",
        COLLEGE: "No",
    },
    Account: {
        SCHOOL: "Account#",
        COLLEGE: "Account#",
    },
    HR: {
        SCHOOL: "HR#",
        COLLEGE: "HR#",
    },
    Building: {
        SCHOOL: "Building",
        COLLEGE: "Building",
    },
    Floor: {
        SCHOOL: "Floor",
        COLLEGE: "Floor",
    },
    Room: {
        SCHOOL: "Room",
        COLLEGE: "Room",
    },
    Bed: {
        SCHOOL: "Bed",
        COLLEGE: "Bed",
    },
    Deposit: {
        SCHOOL: "Deposit",
        COLLEGE: "Deposit",
    },
    Withdraw: {
        SCHOOL: "Withdraw",
        COLLEGE: "Withdraw",
    },
    Balance: {
        SCHOOL: "Balance",
        COLLEGE: "Balance",
    },
    Detail: {
        SCHOOL: "Detail",
        COLLEGE: "Detail",
    },
    Standard: {
        SCHOOL: "Standard",
        COLLEGE: "Course",
    },
    Division: {
        SCHOOL: "Division",
        COLLEGE: "Division",
    },
    Teacher: {
        SCHOOL: "Teacher",
        COLLEGE: "Teacher",
    },
    LeaveApplicationDate: {
        SCHOOL: "Leave Application Date",
        COLLEGE: "Leave Application Date",
    },
    LeaveFrom: {
        SCHOOL: "Leave From",
        COLLEGE: "Leave From",
    },
    LeaveType: {
        SCHOOL: "Leave Type",
        COLLEGE: "Leave Type",
    },
    ReasonForLeave: {
        SCHOOL: "Reason For Leave",
        COLLEGE: "Reason For Leave",
    },
    Comment: {
        SCHOOL: "Comment",
        COLLEGE: "Comment",
    },
    Reject: {
        SCHOOL: "Reject",
        COLLEGE: "Reject",
    },
    NoofDays: {
        SCHOOL: "No. of Days",
        COLLEGE: "No. of Days",
    },
    LeaveTypeterm: {
        SCHOOL: "Leave Type Term",
        COLLEGE: "Leave Type Term",
    },
    Submit: {
        SCHOOL: "Submit",
        COLLEGE: "Submit",
    },
    TypeofLeave: {
        SCHOOL: "Type of Leave",
        COLLEGE: "Type of Leave",
    },
    Teacher: {
        SCHOOL: "Teacher",
        COLLEGE: "Teacher",
    },
    IsPreApplication: {
        SCHOOL: "Is Pre-Application",
        COLLEGE: "Is Pre-Application",
    },
    BackToLogin: {
        SCHOOL: "Back To Login",
        COLLEGE: "Back To Login",
    },
    VerifyAndUpdate: {
        SCHOOL: "Verify And Update",
        COLLEGE: "Verify And Update",
    },
    Send: {
        SCHOOL: "Send",
        COLLEGE: "Send",
    },
    Draft: {
        SCHOOL: "Draft",
        COLLEGE: "Draft",
    },
    Location: {
        SCHOOL: "Location",
        COLLEGE: "Location",
    },
    ReminderDate: {
        SCHOOL: "Reminder Date",
        COLLEGE: "Reminder Date",
    },
    StartDate: {
        SCHOOL: "Start Date",
        COLLEGE: "Start Date",
    },
    EndDate: {
        SCHOOL: "End Date",
        COLLEGE: "End Date",
    },
    Type: {
        SCHOOL: "Type",
        COLLEGE: "Type",
    },
    congratulations: {
        SCHOOL: "congratulations",
        COLLEGE: "congratulations",
    },
    CorrectAnswered: {
        SCHOOL: "Correct Ans.",
        COLLEGE: "Correct Ans.",
    },
    Checkthetopperformingstudents: {
        SCHOOL: "Check the top performing students",
        COLLEGE: "Check the top performing students",
    },
    Score: {
        SCHOOL: "Score",
        COLLEGE: "Score",
    },
    Skip: {
        SCHOOL: "Skip",
        COLLEGE: "Skip",
    },
    Wrong: {
        SCHOOL: "Wrong",
        COLLEGE: "Wrong",
    },
    Percentage: {
        SCHOOL: "Percentage",
        COLLEGE: "Percentage",
    },
    Time: {
        SCHOOL: "Time",
        COLLEGE: "Time",
    },
    Avgtime: {
        SCHOOL: "Avg time",
        COLLEGE: "Avg time",
    },
    Q: {
        SCHOOL: "Q",
        COLLEGE: "Q",
    },
    NoAddressDetails: {
        SCHOOL: "No Address Details",
        COLLEGE: "No Address Details",
    },
    Days: {
        SCHOOL: "Days",
        COLLEGE: "Days",
    },
    EMPCode: {
        SCHOOL: "EMP Code#",
        COLLEGE: "EMP Code#",
    },
    GRScholar: {
        SCHOOL: "GR/Scholar#",
        COLLEGE: "GR/Scholar#",
    },
    Topics: {
        SCHOOL: "Topics",
        COLLEGE: "Topics",
    },
    Time: {
        SCHOOL: "Time",
        COLLEGE: "Time",
    },
    Mon: {
        SCHOOL: "Mon",
        COLLEGE: "Mon",
    },
    Tue: {
        SCHOOL: "Tue",
        COLLEGE: "Tue",
    },
    Wed: {
        SCHOOL: "Wed",
        COLLEGE: "Wed",
    },
    Thu: {
        SCHOOL: "Thu",
        COLLEGE: "Thu",
    },
    Fri: {
        SCHOOL: "Fri",
        COLLEGE: "Fri",
    },
    Sat: {
        SCHOOL: "Sat",
        COLLEGE: "Sat",
    },
    Sun: {
        SCHOOL: "Sun",
        COLLEGE: "Sun",
    },
    JoinClass: {
        SCHOOL: "Join Class",
        COLLEGE: "Join Class",
    },
    Likes: {
        SCHOOL: "Like",
        COLLEGE: "Like",
    },
    Dislike: {
        SCHOOL: "Dislike",
        COLLEGE: "Dislike",
    },
    AttendenceForIn: {
        SCHOOL: "Attendence For In",
        COLLEGE: "Attendence For In",
    },
    AttendenceForOut: {
        SCHOOL: "Attendence For Out",
        COLLEGE: "Attendence For Out",
    },
    ExamTime: {
        SCHOOL: "Exam Time",
        COLLEGE: "Exam Time",
    },
    Hide: {
        SCHOOL: "Hide",
        COLLEGE: "Hide",
    },
    ForgetPassword: {
        SCHOOL: "Forget Password",
        COLLEGE: "Forget Password",
    },
    Login: {
        SCHOOL: "Login",
        COLLEGE: "Login",
    },
    Next: {
        SCHOOL: "Next",
        COLLEGE: "Next",
    },
    Cancel: {
        SCHOOL: "Cancel",
        COLLEGE: "Cancel",
    },
    Show: {
        SCHOOL: "Show",
        COLLEGE: "Show",
    },
    LectureWiseAttendanceDetails: {
        SCHOOL: "Lecture Wise Attendance Details",
        COLLEGE: "Lecture Wise Attendance Details",
    },
    Subject: {
        SCHOOL: "Subject",
        COLLEGE: "Subject",
    },
    Delete: {
        SCHOOL: "Delete",
        COLLEGE: "Delete",
    },
    Unpaid: {
        SCHOOL: "Unpaid",
        COLLEGE: "Unpaid",
    },
    TeacherAttendanceDetails: {
        SCHOOL: "Teacher Attendance Details",
        COLLEGE: "Teacher Attendance Details",
    },
    InTime: {
        SCHOOL: "In Time",
        COLLEGE: "In Time",
    },
    OutTime: {
        SCHOOL: "Out Time",
        COLLEGE: "Out Time",
    },
    Reason: {
        SCHOOL: "Reason",
        COLLEGE: "Reason",
    },
    Photo: {
        SCHOOL: "Photo",
        COLLEGE: "Photo",
    },
    AddPhoto: {
        SCHOOL: "Add Photo",
        COLLEGE: "Add Photo",
    },
    Communication: {
        SCHOOL: "Communication",
        COLLEGE: "Communication",
    },
    TeacherCommunication: {
        SCHOOL: "Teacher Communication",
        COLLEGE: "Teacher Communication",
    },
    ID: {
        SCHOOL: "ID",
        COLLEGE: "ID",
    },
    TeacherName: {
        SCHOOL: "Teacher Name",
        COLLEGE: "Teacher Name",
    },
    TeacherPhoneNo: {
        SCHOOL: "Teacher Phone No.",
        COLLEGE: "Teacher Phone No.",
    },
    Poll: {
        SCHOOL: "Feedback And Review",
        COLLEGE: "Feedback And Review",
    },
    AddPoll: {
        SCHOOL: "Add / Edit Poll",
        COLLEGE: "Add / Edit Poll",
    },
    PollResult: {
        SCHOOL: "Poll Result",
        COLLEGE: "Poll Result",
    },
    Edit: {
        SCHOOL: "Edit",
        COLLEGE: "Edit",
    },
    Result: {
        SCHOOL: "Result",
        COLLEGE: "Result",
    },
    AddMoreOptions: {
        SCHOOL: "Add More Options",
        COLLEGE: "Add More Options",
    },
    Teacher: {
        SCHOOL: "Teacher",
        COLLEGE: "Teacher",
    },
    IsMultiChoice: {
        SCHOOL: "Is Multi Choice",
        COLLEGE: "Is Multi Choice",
    },
    Votes: {
        SCHOOL: "Votes",
        COLLEGE: "Votes",
    },
    VoterList: {
        SCHOOL: "Voter List",
        COLLEGE: "Voter List",
    },
    ContactNo: {
        SCHOOL: "Contact No",
        COLLEGE: "Contact No",
    },
    STDDIVDEPT: {
        SCHOOL: "STD-DIV-DEPT",
        COLLEGE: "STD-DIV-DEPT",
    },
    VoteBy: {
        SCHOOL: "Vote By",
        COLLEGE: "Vote By",
    },
    Option: {
        SCHOOL: "Option",
        COLLEGE: "Option",
    },
    Title: {
        SCHOOL: "Title",
        COLLEGE: "Title",
    },
    Cancel: {
        SCHOOL: "Cancel",
        COLLEGE: "Cancel",
    },
    Note: {
        SCHOOL: "Note",
        COLLEGE: "Note",
    },
    TeacherComment: {
        SCHOOL: "Teacher Comment",
        COLLEGE: "Teacher Comment",
    },
    TopicIsPublishClickForUnpublish: {
        SCHOOL: "Topic Is Publish, Click For Un-publish",
        COLLEGE: "Topic Is Publish, Click For Un-publish",
    }
    ,
    TopicIsPublishClickForPublish: {
        SCHOOL: "Topic Is Un-publish, Click For Publish",
        COLLEGE: "Topic Is Un-publish, Click For Publish",
    },
    UpdateInfo: {
        SCHOOL: "Update Information",
        COLLEGE: "Update Information",
    },
    MenuUpdateInfo: {
        SCHOOL: "Update Information",
        COLLEGE: "Update Information",
    },
    ToDoTask: {
        SCHOOL: "To-Do Task",
        COLLEGE: "To-Do Task",
    },
    TaskDate: {
        SCHOOL: "Task Date",
        COLLEGE: "Task Date",
    },
    TeacherChargePatraks: {
        SCHOOL: "Teacher Charge Patraks",
        COLLEGE: "Teacher Charge Patraks",
    },
    ChargeDate: {
        SCHOOL: "Charge Date",
        COLLEGE: "Charge Date",
    },
    ReturnDate: {
        SCHOOL: "Return Date",
        COLLEGE: "Return Date",
    },
    ItemName: {
        SCHOOL: "Item Name",
        COLLEGE: "Item Name",
    },
    DayType: {
        SCHOOL: "Day Type",
        COLLEGE: "Day Type",
    },
    ComplainLogBook: {
        SCHOOL: "Building Maintenance",
        COLLEGE: "Building Maintenance",
    },
    ComplainDate: {
        SCHOOL: "Complain Date",
        COLLEGE: "Complain Date",
    },
    TodayExam: {
        SCHOOL: "Today",
        COLLEGE: "Today",
    },
    Holiday: {
        SCHOOL: "Holiday",
        COLLEGE: "Holiday",
    },
    HolidayDetails: {
        SCHOOL: "Holiday Details",
        COLLEGE: "Holiday Details",
    },
    AddComplain: {
        SCHOOL: "Add Complain",
        COLLEGE: "Add Complain",
    },
    InstitutePage: {
        SCHOOL: "Institute Page",
        COLLEGE: "Institute Page",
    },
    ResultSheet: {
        SCHOOL: "Result Sheet",
        COLLEGE: "Result Sheet",
    }
};

function LabelDisplayText(lable) {
    let data = getLoginUserData()[0].ClientSystermType;
    return DisplayText[lable][data];
}

function setItem(key, strString) {
    let CryptoJS = require("crypto-js");
    localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {
    let CryptoJS = require("crypto-js");
    let dataValues = localStorage.getItem(key) || "";
    let dataStr = "";
    if (dataValues !== "") {
        var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
        dataStr = bytes.toString(CryptoJS.enc.Utf8);
    }
    return dataStr;
}

function getHeaders() {
    return {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: "Bearer " + getItem("accesstoken"),
    };
}

function getHeadersFromData() {
    return {
        //'Content-Type': 'multipart/form-data; boundary=classroom',
        //'accept': '*/*',
        Authorization: "Bearer " + getItem("accesstoken"),
    };
}


function getLoginUserData() {
    var data = [
        {
            Avatar: "",
            BGColor: "",
            BatchID: "00000000-0000-0000-0000-000000000000",
            ClientID: "00000000-0000-0000-0000-000000000000",
            CurrentDivisionID: "00000000-0000-0000-0000-000000000000",
            CurrentStandardID: "00000000-0000-0000-0000-000000000000",
            DateFormat_Term: "",
            DepartmentID: "00000000-0000-0000-0000-000000000000",
            DisplayName: "",
            EndDate: "",
            FontColor: "",
            FullName: "",
            Initials: "",
            InstituteCode: "",
            InstituteID: "00000000-0000-0000-0000-000000000000",
            InstituteLogo: "",
            InstituteName: "",
            InstituteType_Term: "",
            MediumOfEducation_Term: "",
            MemberID: "00000000-0000-0000-0000-000000000000",
            MemberType: "",
            PostByType: "",
            PrimaryName: "00000000-0000-0000-0000-000000000000",
            RoleID: "00000000-0000-0000-0000-000000000000",
            RoleName: "",
            RollEMPNo: "",
            StartDate: "",
            StdDivDept: "",
            StudentBusRegMasterID: "00000000-0000-0000-0000-000000000000",
            StudentCode: "",
            StudentHostelRegID: "00000000-0000-0000-0000-000000000000",
            TimeFormat_Term: "",
            UserID: "00000000-0000-0000-0000-000000000000",
            UserName: "",
            UserType_Term: "",
            WallID: "00000000-0000-0000-0000-000000000000",
            FirstName: "",
            LastName: "",
            AttendenceType_Term: "",
            ClientSystermType: "SCHOOL",
        },
    ];
    let dataStr = getItem("home");
    if (dataStr !== "") {
        data = JSON.parse(dataStr);
    }

    return data;
}

function getLoginRoleData(PageName, ActionType) {
    let IsValidAction = false;
    var roledata = [
    ];
    let roledataStr = getItem("roledata");
    if (roledataStr !== "") {
        roledata = JSON.parse(roledataStr);
        for (let i = 0; i < roledata.length; i++) {
            if (roledata[i].RightName.toUpperCase() == PageName.toUpperCase()) {
                if (ActionType.toUpperCase() == "ISVIEW") {
                    return roledata[i].IsView;
                }
                else if (ActionType.toUpperCase() == "ISCREATE") {
                    return roledata[i].IsCreate;
                }
                else if (ActionType.toUpperCase() == "ISDELETE") {
                    return roledata[i].IsDelete;
                }
                else if (ActionType.toUpperCase() == "ISDOWNLOAD") {
                    return roledata[i].IsDownload;
                }
                else if (ActionType.toUpperCase() == "ISEDIT") {
                    return roledata[i].IsEdit;
                }
                else if (ActionType.toUpperCase() == "ISPRINT") {
                    return roledata[i].IsPrint;
                }
            }
        }
    }

    return IsValidAction;
}

//function getLoginRoleDataByURL(ByURL) {
//    if (ByURL.toUpperCase() === "HOME" || ByURL.toUpperCase() === "PROFILE" || ByURL.toUpperCase() === "ACCESS") {
//        return true;
//    }

//    let IsValidAction = false;
//    let PageName = AppConfigData.RoleNameByURL[ByURL];

//    var roledata = [
//    ];
//    let roledataStr = getItem("roledata");
//    if (roledataStr !== "" && PageName !== undefined && PageName !== "") {
//        roledata = JSON.parse(roledataStr);
//        for (let i = 0; i < roledata.length; i++) {
//            if (roledata[i].RightName.toUpperCase() === PageName.toUpperCase()) {
//                return roledata[i].IsView;
//            }
//        }
//    }

//    return IsValidAction;
//}

function getDateInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format(
            getLoginUserData()[0].DateFormat_Term.toUpperCase()
        );
    else return "";
}

function getDateTimeInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format(
            getLoginUserData()[0].DateFormat_Term.toUpperCase() +
            " " +
            getLoginUserData()[0].TimeFormat_Term.replace("aa", "a")
        );
    else return "";
}

function getDateTimeInDBFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("YYYY-MM-DD HH:mm:ss");
    else return "";
}

function getDateInDBFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("YYYY-MM-DD");
    else return "";
}

function getDateInDateMonthFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("DD MMM");
    else return "";
}

function getDateInMonthYearFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format("MMM YYYY");
    else return "";
}

function getTimeInFormat(date) {
    if (date !== null && date !== "" && date !== undefined)
        return Moment(date).format(
            getLoginUserData()[0].TimeFormat_Term.replace("aa", "a")
        );
    else return "";
}

function getFileType(file) {
    let FileType = "FILE";
    if (file !== null && file !== undefined) {
        if (file.type !== null && file.type !== "" && file.type !== undefined) {
            if (file.type.toUpperCase().indexOf("IMAGE") >= 0) {
                FileType = "IMAGE";
            } else if (file.type.toUpperCase().indexOf("AUDIO") >= 0) {
                FileType = "AUDIO";
            } else if (file.type.toUpperCase().indexOf("VIDEO") >= 0) {
                FileType = "VIDEO";
            } else if (file.type.toUpperCase().indexOf("PDF") >= 0) {
                FileType = "PDF";
            }
        }
    }
    return FileType;
}

function CreateColor(str) {
    let BGRGB = "000000";
    let FRGB = "FFFFFF";
    if (str !== "") {
        str = str + "000000";
        let hash = hashCode(str);
        BGRGB = intToRGB(hash);
        FRGB = invertColor(BGRGB);
    }
    return { BGColor: BGRGB, FontColor: FRGB };
}

function hashCode(str) {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "000000".substring(0, 6 - c.length) + c;
}

function invertColor(hex) {
    if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
    }
    //var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    //    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    //    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

    var r = (255).toString(16),
        g = (255).toString(16),
        b = (255).toString(16);
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
}

function sendNotification() {
    const reqHomework = {
        method: "POST",
        headers: getHeaders(),
        body: "",
    };
    fetch(
        AppConfigData.APIBasePath + "v1/postcomments/sendpushnotification",
        reqHomework
    ).then((response) => response.json());
}

function groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}
