import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";

function RewardView() {

    const [reward, setreward] = useState({ IsData: false, data: [] });
    let data = commonService.getLoginUserData();

    function BindReward() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "REWARD"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setreward({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setreward({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindReward();
    }, []);

    return (
        <> <div className="container classwork-popup-detail">
            <div className="col-lg-12 student-profile">
                {reward.IsData ?
                    (
                        <>
                            <div className="inner-header-for-section float-left">
                                <h2 className="m-0">Reward Details</h2>
                            </div>
                            <Table className="fees-payment-table m-0" >
                                <thead>
                                    <tr>
                                        <th className="text-center w-75px">#</th>
                                        <th>Earned</th>
                                        <th>Badge Type</th>
                                        <th>Earned On</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reward.data.length != 0 && (
                                            reward.data.map((item, index) => (
                                                <tr>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{item.CreditEarned}</td>
                                                    <td>{item.BadgeType_Term}</td>
                                                    <td>{commonService.getDateInFormat(item.CreditedOn)}</td>
                                                    <td>{item.CurrentBalance}</td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <>
                            <Skeleton count={1} height={30} />
                            <_Skeleton type={_SkeletonType.Table} />
                        </>
                    )}
                {reward.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
            </div>
        </div>
        </>
    );
}

export default RewardView;
