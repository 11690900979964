import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";


function Healthview() {

    const [profile, setprofile] = useState({ IsData: false, data: [] });

    function BindHealth() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "HEALTH"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setprofile({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setprofile({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindHealth();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {profile.IsData ?
                        (
                            <>
                                <div className="inner-header-for-section float-left">
                                    <h2 className="m-0">Health Details</h2>
                                </div>
                                <div className="col-lg-12 float-left mt-1">
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1 event-description">
                                            <b className="student-roll-no mt-1">Doctor Name : {profile.data[0].FamilyDoctorName}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Blood Group : {profile.data[0].BloodGroup_Term}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Specs : {profile.data[0].IsWearingSpecs && (<span className="student-roll-no mt-1">Left# : {profile.data[0].LeftNumbers}| Right# : {profile.data[0].RightNumbers}</span>)}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Contact# : {profile.data[0].ContactNo}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Body Temperature : {profile.data[0].BodyTempreture}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Feet Size : {profile.data[0].SizeOfFeet}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Teeth Status : {profile.data[0].TeethStatus_Term}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="inner-header-for-section float-left">
                                    <h2 className="m-0">Other Details</h2>
                                </div>
                                <div className="col-lg-12 float-left mt-1">
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Height  : {profile.data[0].Height}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Eye Color : {profile.data[0].EyeColor}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Physical Disability : {profile.data[0].IsAnyPhysicalDisability && (<span className="student-roll-no mt-1">{profile.data[0].DetailOfPhysicalDisability}</span>)}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Mark on Face : {profile.data[0].MarkOnFace}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Weight  : {profile.data[0].Weight}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Hair Color : {profile.data[0].HairColor}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Chest : {profile.data[0].Chest}</b>
                                        </div>
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Skin Color : {profile.data[0].SkinColor}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="inner-header-for-section float-left">
                                    <h2 className="m-0">Vaccination Detail</h2>
                                </div>
                                <div className="col-lg-12 float-left mt-1">
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Date : {commonService.getDateInFormat(profile.data[0].VaccinationDetailDate)}</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 float-left">
                                        <div className="col-lg-12 my-1">
                                            <b className="student-roll-no mt-1">Detail : {profile.data[0].VaccinationDetail}</b>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                            </>
                        )}
                </div>
            </div>
        </>
    );
}

export default Healthview;
