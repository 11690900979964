import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
function Holiday() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [holidaydata, setholidaydata] = useState({ IsData: true, data: [] });
    const [holidaydetails, setholidaydetails] = useState([]);
    function BindCalData() {

        setholidaydata({ IsData: false, data: [] });

        const reqAttendanceData = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/holidaylist",
            reqAttendanceData
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setholidaydata({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setholidaydata({ IsData: false, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Holiday", "IsView")) {
            BindCalData();
        } else {
            history.push("/access");
        }
    }, []);


    function BindHolidayCal(caldata) {
        if (
            data[0].StartDate !== null &&
            data[0].StartDate !== "" &&
            data[0].EndDate !== null &&
            data[0].EndDate !== ""
        ) {

            let dt = new Date(data[0].StartDate);

            let NoofMonth = [];

            while (dt <= new Date(data[0].EndDate)) {
                let StartDate = new Date(dt);
                let EndDate = new Date(dt);

                EndDate = new Date(EndDate.setMonth(EndDate.getMonth() + 1));
                EndDate = new Date(EndDate.setDate(EndDate.getDate() - 1));

                let Highlight_present = [];

                let FilterData = caldata.filter(i => new Date(i.DateValue) >= StartDate && new Date(i.DateValue) <= EndDate)
                
                if (FilterData !== null && FilterData.length !== 0) {
                    FilterData.map((item, index) => {
                        Highlight_present.push(new Date(item.DateValue));
                    });
                }

                NoofMonth.push({
                    StartDate: commonService.getDateInDBFormat(StartDate),
                    EndDate: commonService.getDateInDBFormat(EndDate),
                    HighlightWithRanges: [
                        { "present-cal-indicator": Highlight_present },
                    ],
                });
                dt = new Date(dt.setMonth(dt.getMonth() + 1));
            }

            if (NoofMonth.length !== 0) {
                return NoofMonth.map((item, index) => (
                    <div
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 attendence-calendar-student"
                        key={index}
                    >
                        <div className="stud-attendance-view">
                            <DatePicker
                                selected={""}
                                highlightDates={item.HighlightWithRanges}
                                inline
                                minDate={new Date(item.StartDate)}
                                maxDate={new Date(item.EndDate)}
                                onChange={(date) => fnShowHolidayDetails(date)}
                            />
                        </div>
                    </div>
                ));
            } else {
                return (
                    <div className="col-12 m-2">
                        <_NoDataFound fontsize={"150px"} />
                    </div>
                );
            }
        }
        else {
            return (
                <div className="col-12 m-2">
                    <_NoDataFound fontsize={"150px"} />
                </div>
            );
        }
    }

    function fnShowHolidayDetails(date) {
        setholidaydetails([]);
        if (holidaydata !== null && holidaydata.data !== null && holidaydata.data.length !== 0) {
            let FilterData = holidaydata.data.filter(i => commonService.getDateInDBFormat(i.DateValue) === commonService.getDateInDBFormat(date))
            if (FilterData !== null && FilterData.length !== 0) {
                setholidaydetails(FilterData);
            }
        }

    }
    return (
        <>
            <div className="d-flex my-4 align-items-center justify-content-between">
                <div>
                    <h1 className="page-header mr-1">{commonService.LabelDisplayText("Holiday")}</h1>
                </div>
                <div>
                    <span className="attendence-indicator attendence-present">
                        {commonService.LabelDisplayText("Holiday")}
                    </span>
                </div>
            </div>
            {!holidaydata.IsData ? (
                <div className="row col-12">
                    <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                </div>
            ) : holidaydata.data.length === 0 ? (
                <div className="col-12">
                    <_NoDataFound fontsize={"150px"} />
                </div>
            ) : (
                <div className="row">{BindHolidayCal(holidaydata.data)}</div>
            )}

            {
                holidaydetails !== null && holidaydetails.length !== 0 &&
                <Modal
                    show={true}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setholidaydetails([])}
                    className=""
                    contentClassName={""}
                    dialogClassName={"width-40per"}
                >
                    <Modal.Header className="">
                        <h5> {commonService.getDateInFormat(holidaydetails[0].DateValue)} {commonService.LabelDisplayText("HolidayDetails")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setholidaydetails([])}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            holidaydetails.map((item, index) =>
                                <div class="col-12 date-time-detail">
                                    <div class="event-date-time-detail ">
                                        <div class="event-title pointer-cursor"><h6>{item.Title}</h6></div>
                                        <label class="mark-label">{commonService.getDateInFormat(item.StartDate)} To {commonService.getDateInFormat(item.EndDate)} </label>
                                        <div class="event-description" title={item.Details}>
                                            <p>{item.Details}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default Holiday;
