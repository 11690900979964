import React from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";

function Settings(props) {
    let data = commonService.getLoginUserData();
    return (
        <h1 className="page-header my-4">{commonService.LabelDisplayText("Settings")}</h1>
    );
}

export default Settings;