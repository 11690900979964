import React from "react";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import { commonService, AppConfigData } from "../_services/common.service";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import _NoDataFound from "./_NoDataFound";
import _Skeleton, { _SkeletonType } from "./_Skeleton";

function UpdateInfo() {
    let data = commonService.getLoginUserData();
    const [UpdateInfo, setUpdateInfo] = useState({
        isdata: false,
        data: null,
    });
    useEffect(() => {
        setUpdateInfo({
            isdata: false,
            data: null,
        });

        const requestMetadata = {
            method: "POST",
            headers: commonService.getHeaders(),
            
        };

        fetch(
            AppConfigData.APIBasePath + "v1/home/institutepages",
            requestMetadata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setUpdateInfo({ isdata: true, data: json.data });
                } else if (json.status_code === 0) {
                    setUpdateInfo({ isdata: true, data: null });
                }
            });
    }, []);

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("InstitutePage")}</h1>
            <div className="row">
                {
                    UpdateInfo.isdata ?
                        <>
                            <div className="col-md-12">
                                {
                                    UpdateInfo.data !== null ?
                                        <>
                                            <div
                                                className="card-body-content mt-2"
                                                dangerouslySetInnerHTML={{ __html: UpdateInfo.data.PageDetails }}
                                            ></div>
                                        </> : <_NoDataFound fontsize={"300px"} />
                                }
                            </div>
                        </> :
                        <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                }
            </div>
        </>
    );
}
export default UpdateInfo;
