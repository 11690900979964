import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";

function OtherInfoView() {

    const [profile, setprofile] = useState({ IsData: false, data: [] });

    function BindOtherInfo() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "OTHERINFO"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setprofile({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setprofile({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindOtherInfo();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    <div className="card student-main-profile">
                        {profile.IsData ?
                            (
                                <>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Other Information</h2>
                                    </div>
                                    <div className="col-lg-12 mt-1 float-left">
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1 event-description">
                                                <b className="student-roll-no mt-1">Dise Code : {profile.data[0].DiseCode}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Aadhar # : {profile.data[0].AdharNo}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">BPL No # : {profile.data[0].BPLNo}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Remark : {profile.data[0].Remark}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Credit Star : {profile.data[0].CreditStar}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Passport # : {profile.data[0].PassportNo}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Samagar Family ID : {profile.data[0].SamagraFamilyID}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Reference : {profile.data[0].Reference}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Overall Rating : {profile.data[0].OverallRating}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Licence # : {profile.data[0].DrivingLicenseNo}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Samagar Member ID : {profile.data[0].SamagraMemberID}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Board ID No : {profile.data[0].BoardIDNo}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Bank Information</h2>
                                    </div>
                                    <div className="col-lg-12 mt-1 float-left">
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Bank Account No #  : {profile.data[0].BankAccountNo}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Branch Name # : {profile.data[0].BranchName}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Bank Name # : {profile.data[0].BankName}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Bank Holder Name #  : {profile.data[0].BankHolderName}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Branch City # : {profile.data[0].BranchCity}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">IFSC Code # : {profile.data[0].IFSCCode}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Branch Code # : {profile.data[0].BankBranchCode}</b>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                </>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtherInfoView;
