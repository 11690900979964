import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";

function FeeView() {

    const [fee, setfee] = useState({ IsData: false, data: [] });
    let data = commonService.getLoginUserData();

    function BindFee() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "FEE"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setfee({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setfee({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindFee();
    }, []);

    return (
        <> <div className="container classwork-popup-detail">
            <div className="col-lg-12 student-profile">
                {fee.IsData ?
                    (
                        <>
                            <div className="inner-header-for-section float-left">
                                <h2 className="m-0">Fee Details</h2>
                            </div>
                            <Table className="fees-payment-table m-0" >
                                <thead>
                                    <tr>
                                        <th className="text-center w-75px">#</th>
                                        <th>BatchName</th>
                                        <th>Structure Name</th>
                                        <th>Amount</th>
                                        <th>Paid</th>
                                        <th>Due</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fee.data.map((item, index) => (
                                            <tr>
                                                <td className="text-center">{index + 1}</td>
                                                <td>{item.BatchName}</td>
                                                <td>{item.StructuteName}</td>
                                                <td>{item.PayableAmount}</td>
                                                <td>{item.PaidAmounts}</td>
                                                <td>{item.TotalDue}</td>
                                                <td className="text-center w-100px">
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            "http://erp.orataro.com/mobile_api/FeesReceipt?ClientID=" +
                                                            data[0].ClientID +
                                                            "&InstituteID=" +
                                                            data[0].InstituteID +
                                                            "&BatchID=" +
                                                            item.BatchID +
                                                            "&StudentID=" +
                                                            item.StudentID +
                                                            "&FeesStructureID=" +
                                                            item.FeesStructuteID +
                                                            "&ctoken="
                                                        }
                                                    >
                                                        <button className="small-btn d-flex align-items-center justify-content-center">
                                                            {commonService.LabelDisplayText("View")}
                                                        </button>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <>
                            <Skeleton count={1} height={30} />
                            <_Skeleton type={_SkeletonType.Table} />
                        </>
                    )}
            </div>
        </div>
        </>
    );
}

export default FeeView;
