import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import { alertService, AlertType } from "../_services/alert.service";

function TimetableLiveclass() {
    const history = useHistory();
    let data = commonService.getLoginUserData();

    const [TimeTable, setTimeTable] = useState({ IsData: false, data: [] });
    const [TimeTableToday, setTimeTableToday] = useState({
        IsData: false,
        data: [],
    });

    const [LiveClass, setLiveClass] = useState({ IsData: false, data: [] });
    const [LiveClassToday, setLiveClassToday] = useState({
        IsData: false,
        data: [],
    });

    function BindList() {
        setTimeTable({ IsData: false, data: [] });
        setTimeTableToday({ IsData: false, data: [] });
        setLiveClass({ IsData: false, data: [] });
        setLiveClassToday({ IsData: false, data: [] });

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/timetableliveclass/getlistbymember",
            reqExam
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let varTodayTimeTable = [];
                    let varTodayLiveClass = [];

                    let varWeekTimeTable = [];
                    let varWeekLiveClass = [];

                    if (json.data.liveClass.length !== 0) {
                        for (let i = 0; i < json.data.liveClass.length; i++) {
                            if (json.data.liveClass[i].IsTodayClass) {
                                varTodayLiveClass.push(json.data.liveClass[i]);
                            }
                        }

                        for (let i = 0; i < json.data.liveClassUniqTime.length; i++) {
                            var MONDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "MONDAY"
                                );
                            });
                            var TUESDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "TUESDAY"
                                );
                            });
                            var WEDNESDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "WEDNESDAY"
                                );
                            });
                            var THURSDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "THURSDAY"
                                );
                            });
                            var FRIDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "FRIDAY"
                                );
                            });
                            var SATURDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "SATURDAY"
                                );
                            });
                            var SUNDAY = json.data.liveClass.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.liveClassUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "SUNDAY"
                                );
                            });
                            if (data[0].MemberType.toLowerCase() === "student") {
                                varWeekLiveClass.push({
                                    TIME: (
                                        <label className="starttimeendtime">
                                            {json.data.liveClassUniqTime[i].StartTimeEndTime}
                                        </label>
                                    ),
                                    MONDAY:
                                        MONDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {MONDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {MONDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    TUESDAY:
                                        TUESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {TUESDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {TUESDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    WEDNESDAY:
                                        WEDNESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {WEDNESDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {WEDNESDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    THURSDAY:
                                        THURSDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {THURSDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {THURSDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    FRIDAY:
                                        FRIDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {FRIDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {FRIDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SATURDAY:
                                        SATURDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SATURDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {SATURDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SUNDAY:
                                        SUNDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SUNDAY[0]["SubjectName"]}
                                                </label>{" "}
                                                <label className="details-sub-header">
                                                    {SUNDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                });
                            } else {
                                varWeekLiveClass.push({
                                    TIME: (
                                        <label className="starttimeendtime">
                                            {json.data.liveClassUniqTime[i].StartTimeEndTime}
                                        </label>
                                    ),
                                    MONDAY:
                                        MONDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {MONDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {MONDAY[0]["StandardName"] +
                                                        " - " +
                                                        MONDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    TUESDAY:
                                        TUESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {TUESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {TUESDAY[0]["StandardName"] +
                                                        " - " +
                                                        TUESDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    WEDNESDAY:
                                        WEDNESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {WEDNESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {WEDNESDAY[0]["StandardName"] +
                                                        " - " +
                                                        WEDNESDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    THURSDAY:
                                        THURSDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {THURSDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {THURSDAY[0]["StandardName"] +
                                                        " - " +
                                                        THURSDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    FRIDAY:
                                        FRIDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {FRIDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {FRIDAY[0]["StandardName"] +
                                                        " - " +
                                                        FRIDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SATURDAY:
                                        SATURDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SATURDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SATURDAY[0]["StandardName"] +
                                                        " - " +
                                                        SATURDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SUNDAY:
                                        SUNDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SUNDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SUNDAY[0]["StandardName"] +
                                                        " - " +
                                                        SUNDAY[0]["DivisionName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                });
                            }
                        }
                    }

                    if (json.data.timeTable.length !== 0) {
                        for (let i = 0; i < json.data.timeTable.length; i++) {
                            if (json.data.timeTable[i].IsTodayClass) {
                                varTodayTimeTable.push(json.data.timeTable[i]);
                            }
                        }

                        for (let i = 0; i < json.data.timeTableUniqTime.length; i++) {
                            var MONDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "MONDAY"
                                );
                            });
                            var TUESDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "TUESDAY"
                                );
                            });
                            var WEDNESDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "WEDNESDAY"
                                );
                            });
                            var THURSDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "THURSDAY"
                                );
                            });
                            var FRIDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "FRIDAY"
                                );
                            });
                            var SATURDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "SATURDAY"
                                );
                            });
                            var SUNDAY = json.data.timeTable.filter((x) => {
                                return (
                                    x.StartTimeEndTime ===
                                    json.data.timeTableUniqTime[i].StartTimeEndTime &&
                                    x.WEEKDAY.toUpperCase() === "SUNDAY"
                                );
                            });

                            if (data[0].MemberType.toLowerCase() === "student") {
                                varWeekTimeTable.push({
                                    TIME: (
                                        <label className="starttimeendtime">
                                            {json.data.timeTableUniqTime[i].StartTimeEndTime}
                                        </label>
                                    ),
                                    MONDAY:
                                        MONDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {MONDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {MONDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    TUESDAY:
                                        TUESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {TUESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {TUESDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    WEDNESDAY:
                                        WEDNESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {WEDNESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {WEDNESDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    THURSDAY:
                                        THURSDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {THURSDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {THURSDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    FRIDAY:
                                        FRIDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {FRIDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {FRIDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SATURDAY:
                                        SATURDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SATURDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SATURDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SUNDAY:
                                        SUNDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SUNDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SUNDAY[0]["TeacherName"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                });
                            } else {
                                varWeekTimeTable.push({
                                    TIME: (
                                        <label className="starttimeendtime">
                                            {json.data.timeTableUniqTime[i].StartTimeEndTime}
                                        </label>
                                    ),
                                    MONDAY:
                                        MONDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {MONDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {MONDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    TUESDAY:
                                        TUESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {TUESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {TUESDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    WEDNESDAY:
                                        WEDNESDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {WEDNESDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {WEDNESDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    THURSDAY:
                                        THURSDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {THURSDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {THURSDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    FRIDAY:
                                        FRIDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {FRIDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {FRIDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SATURDAY:
                                        SATURDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SATURDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SATURDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                    SUNDAY:
                                        SUNDAY.length > 0 ? (
                                            <>
                                                <label className="details-main-header">
                                                    {SUNDAY[0]["SubjectName"]}
                                                </label>
                                                <label className="details-sub-header">
                                                    {SUNDAY[0]["STDDIV"]}
                                                </label>
                                            </>
                                        ) : (
                                            ""
                                        ),
                                });
                            }
                            //varWeekTimeTable.push({
                            //    TIME: json.data.timeTableUniqTime[i].StartTimeEndTime,
                            //    MONDAY: MONDAY.length > 0 ? MONDAY[0]["DisplayText"] : "",
                            //    TUESDAY: TUESDAY.length > 0 ? TUESDAY[0]["DisplayText"] : "",
                            //    WEDNESDAY: WEDNESDAY.length > 0 ? WEDNESDAY[0]["DisplayText"] : "",
                            //    THURSDAY: THURSDAY.length > 0 ? THURSDAY[0]["DisplayText"] : "",
                            //    FRIDAY: FRIDAY.length > 0 ? FRIDAY[0]["DisplayText"] : "",
                            //    SATURDAY: SATURDAY.length > 0 ? SATURDAY[0]["DisplayText"] : "",
                            //    SUNDAY: SUNDAY.length > 0 ? SUNDAY[0]["DisplayText"] : "",
                            //});
                        }

                        //timeTableUniqTime
                    }

                    setTimeTable({ IsData: true, data: varWeekTimeTable });
                    setTimeTableToday({ IsData: true, data: varTodayTimeTable });
                    setLiveClass({ IsData: true, data: varWeekLiveClass });
                    setLiveClassToday({ IsData: true, data: varTodayLiveClass });
                } else if (json.status_code === 0) {
                    setTimeTable({ IsData: true, data: [] });
                    setTimeTableToday({ IsData: true, data: [] });
                    setLiveClass({ IsData: true, data: [] });
                    setLiveClassToday({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        
        if (commonService.getLoginRoleData("Timetable", "IsView") || commonService.getLoginRoleData("Liveclass", "IsView")) {
            BindList();
        } else {
            history.push("/access");
        }
    }, []);

    function JoinLiveClass(item) {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                onlineClassTimeTableDetailID: item.OnlineClassTimeTableDetailID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/timetableliveclass/checkonlineclasstimeanddetails",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    commonService.setItem("liveclass", JSON.stringify(json.data));
                    window.open("liveclass");
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("MenuTimetableLiveclass")}</h1>
            <Tabs
                defaultActiveKey={commonService.getLoginRoleData("Timetable", "IsView") ? "Timetable" : "Liveclass"}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 timetable-liveclass"
            >
                {
                    commonService.getLoginRoleData("Timetable", "IsView") &&
                    <Tab eventKey="Timetable" title={commonService.LabelDisplayText("Timetable")}>
                        {!TimeTableToday.IsData ? (
                            <_Skeleton type={_SkeletonType.TimeTable} />
                        ) : TimeTableToday.data.length === 0 ? (
                            <_NoDataFound fontsize={"100px"} />
                        ) : (
                            <div className="row">
                                {TimeTableToday.data.map((item, index) => (
                                    <div
                                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3"
                                        key={index}
                                    >
                                        <div className="card timetable-subject-card">
                                            <div className="timetable-white-card">
                                                <div className="timetable-subjectname mt-2">
                                                    <h6>{item.SubjectName}</h6>
                                                </div>
                                                <div className="timetable-division pb-2">
                                                    {data[0].MemberType.toLowerCase() === "student" ? (
                                                        <p>{item.TeacherName}</p>
                                                    ) : (
                                                        <p>{item.STDDIV}</p>
                                                    )}
                                                </div>
                                                <div className="lecture-time mt-4">
                                                    <p>{item.StartTimeEndTime}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <ul className="mb-3 timetable-liveclass nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className="nav-link active  weekly-nav-link"
                                >
                                    {commonService.LabelDisplayText("TimetableWeekly")}
                                </button>
                            </li>
                        </ul>

                        {!TimeTable.IsData ? (
                            <_Skeleton type={_SkeletonType.Table} />
                        ) : TimeTable.data.length === 0 ? (
                            <_NoDataFound fontsize={"100px"} />
                        ) : (
                            <Table responsive className="timetable-liveclass-table">
                                <thead>
                                    <tr>
                                        <th className="w-150px">{commonService.LabelDisplayText("Time")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Mon")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Tue")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Wed")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Thu")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Fri")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Sat")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Sun")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {TimeTable.data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.TIME}</td>
                                            <td>{item.MONDAY}</td>
                                            <td>{item.TUESDAY}</td>
                                            <td>{item.WEDNESDAY}</td>
                                            <td>{item.THURSDAY}</td>
                                            <td>{item.FRIDAY}</td>
                                            <td>{item.SATURDAY}</td>
                                            <td>{item.SUNDAY}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Tab>
                }
                {
                    commonService.getLoginRoleData("Liveclass", "IsView") &&
                    <Tab eventKey="Liveclass" title={commonService.LabelDisplayText("Liveclass")}>
                        {!LiveClassToday.IsData ? (
                            <_Skeleton type={_SkeletonType.LiveClass} />
                        ) : LiveClassToday.data.length === 0 ? (
                            <_NoDataFound fontsize={"100px"} />
                        ) : (
                            <div className="row">
                                {LiveClassToday.data.map((item, index) => (
                                    <div
                                        className="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3"
                                        key={index}
                                    >
                                        <div className="card timetable-subject-card">
                                            <div className="timetable-white-card ">
                                                <div className="timetable-subjectname  timetable-subjectname-join  mt-2">
                                                    <h6>{item.SubjectName}</h6>
                                                </div>
                                                <div className="division-timetable pb-2">
                                                    {data[0].MemberType.toLowerCase() === "student" ? (
                                                        <p>{item.TeacherName}</p>
                                                    ) : (
                                                        <p>
                                                            {item.StandardName} - {item.DivisionName}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="join-button-liveclass mt-3">
                                                    {item.IsRunning ? (
                                                        <button
                                                            className="joinclass-liveclass-button"
                                                            onClick={() => JoinLiveClass(item)}
                                                        >
                                                            <i className=" fa-regular fa-video mr-2"></i>
                                                            {commonService.LabelDisplayText("JoinClass")}
                                                        </button>
                                                    ) : (
                                                        <button className="joinclass-liveclass-button disabled">
                                                            {item.StartTimeEndTime}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <ul className="mb-3 timetable-liveclass nav nav-tabs">
                            <li className="nav-item">
                                <button type="button" className="nav-link active weekly-nav-link">
                                    {commonService.LabelDisplayText("TimetableWeekly")}
                                </button>
                            </li>
                        </ul>
                        {!LiveClass.IsData ? (
                            <_Skeleton type={_SkeletonType.Table} />
                        ) : LiveClass.data.length === 0 ? (
                            <_NoDataFound fontsize={"100px"} />
                        ) : (
                            <Table responsive className="timetable-liveclass-table">
                                <thead>
                                    <tr>
                                        <th className="w-150px">{commonService.LabelDisplayText("Time")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Mon")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Tue")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Wed")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Thu")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Fri")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Sat")}</th>
                                        <th className="w-200px">{commonService.LabelDisplayText("Sun")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LiveClass.data.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.TIME}</td>
                                            <td>{item.MONDAY}</td>
                                            <td>{item.TUESDAY}</td>
                                            <td>{item.WEDNESDAY}</td>
                                            <td>{item.THURSDAY}</td>
                                            <td>{item.FRIDAY}</td>
                                            <td>{item.SATURDAY}</td>
                                            <td>{item.SUNDAY}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Tab>
                }
            </Tabs>
        </>
    );
}

export default TimetableLiveclass;
