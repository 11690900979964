import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { alertService, AlertType } from "../_services/alert.service";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

function NoticeCircularEvents() {
    let TodayDate = null; //new Date();
    let Next30Date = null; // new Date();
    /*Next30Date = new Date(Next30Date.setDate(Next30Date.getDate() + 30));*/

    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [NoteEventCircular, setNoteEventCircular] = useState({
        IsData: false,
        data: [],
    });
    const [txtEndDate, setTxtEndDate] = useState(Next30Date);
    const [txtStartDate, setTxtStartDate] = useState(TodayDate);
    const [txtSearch, setTxtSearch] = useState("");
    const [
        IsNoteEventCircularListDateLodding,
        setIsNoteEventCircularListDateLodding,
    ] = useState(false);
    //const [HighlightWithRanges, setHighlightWithRanges] = useState([
    //    { "event-cal-indicator": [] },
    //    { "notice-cal-indicator": [] },
    //    { "circular-cal-indicator": [] },
    //]);
    /*const [CalViewDate, setCalViewDate] = useState(null);*/

    const [readstatus, setreadstatus] = useState({ isdata: false, data: [] });
    const [studentreadstatuspopup, setstudentreadstatuspopup] = useState(false);

    function BindNoteEventCircularList(_sRow) {
        let noOfData = 20;
        document
            .getElementById("root")
            .removeEventListener("scroll", WindowScrolling);

        /*setNoteEventCircular({ IsData: false, data: [] });*/
        setIsNoteEventCircularListDateLodding(true);
        //setHighlightWithRanges([
        //    { "event-cal-indicator": [] },
        //    { "notice-cal-indicator": [] },
        //    { "circular-cal-indicator": [] },
        //]);

        const reqNoteEventCircular = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: data[0].MemberType.toLowerCase() === "student" ? "Send" : "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                requestFrom: "Web",
                sRow: _sRow,
                noOfData: noOfData,
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/notes_event_circulare_getlistbymember",
            reqNoteEventCircular
        )
            .then((response) => response.json())
            .then(function (json) {
                document.getElementById("root").addEventListener("scroll", WindowScrolling);
                setIsNoteEventCircularListDateLodding(false);
                if (json.status_code === 1) {

                    if (_sRow === 1) {
                        setNoteEventCircular({ IsData: true, data: json.data });
                        json.data.map((item, index) => {
                            if (item.IsRead === 0) { approvereadpost(item.ID, item.DataType) }
                        });

                    } else {
                        setNoteEventCircular((oldData) => ({
                            IsData: true,
                            data: [...oldData.data, ...json.data],
                        }));
                    }

                    if (json.data.length < noOfData) {
                        document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    }

                    /*setNoteEventCircular({ IsData: true, data: json.data });*/

                    //let Highlight_event = [];
                    //let Highlight_circular = [];
                    //let Highlight_notice = [];

                    //for (let i = 0; i < json.data.length; i++) {
                    //    if (json.data[i].DataType.toUpperCase() === "EVENT") {
                    //        Highlight_event.push(new Date(json.data[i].Date));
                    //    } else if (json.data[i].DataType.toUpperCase() === "CIRCULAR") {
                    //        Highlight_circular.push(new Date(json.data[i].Date));
                    //    } else if (json.data[i].DataType.toUpperCase() === "NOTICE") {
                    //        Highlight_notice.push(new Date(json.data[i].Date));
                    //    }
                    //}
                    //setHighlightWithRanges([
                    //    { "event-cal-indicator": Highlight_event },
                    //    { "notice-cal-indicator": Highlight_notice },
                    //    { "circular-cal-indicator": Highlight_circular },
                    //]);
                    //json.data.map((item, index) => {
                    //    if (item.IsRead === 0) { approvereadpost(item.ID, item.DataType) }
                    //});


                } else if (json.status_code === 0) {
                    document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    setNoteEventCircular({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function studentreadstatus(aid, atype) {
        getstudentreadstatus(aid, atype);
        setstudentreadstatuspopup(true);
    }

    function getstudentreadstatus(aid, atype) {
        setreadstatus({ isdata: false, data: [] });
        var type = "";
        if (atype === "Notice") {
            type = "Note";
        } else if (atype === "Circular") {
            type = "Circular";
        } else if (atype === "Event") {
            type = "Event";
        }

        const reqstudentreadstatusdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associationID: aid,
                associationType: type,
                searchText: "",
                sRow: 0,
                noOfData: 0,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readunreadlist",
            reqstudentreadstatusdata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    var a = json.data;
                    setreadstatus({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setreadstatus({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindListUI(item, index) {

        if (item.DataType.toUpperCase() === "EVENT") {
            return (
                <>
                    <div
                        className={`${commonService.getDateInFormat(
                            item.StartDate
                        )} col-2 date-time-event classworkdata ${item.IsRead == 0 && item.SaveType == "Send"
                            ? "callisreadapprove"
                            : ""
                            }`}
                        key={index}
                        aid={item.ID}
                        at="Event"
                    >
                        <div className="event-date-time">
                            <h4 className="event-date">
                                {commonService.getDateInDateMonthFormat(item.StartDate)}
                            </h4>
                            <p className="event-time">
                                {commonService.getTimeInFormat(item.StartDate)}
                            </p>
                        </div>
                    </div>
                    <div className="col-10 date-time-detail detail-of-event">
                        <div className="event-date-time-detail ">
                            {item.Location && (
                                <div className="event-location">
                                    <i className="fa-regular fa-location-dot"></i>
                                    <p>
                                        <span>&nbsp;</span> {item.Location}
                                    </p>
                                </div>
                            )}
                            <div
                                className="event-title pointer-cursor"
                                onClick={() => handleshowAddEditPopupEvents(item.ID)}
                            >
                                <h6>{item.Title}</h6>
                            </div>
                            <label className="label-for-meeting">{item.Type}</label>{" "}
                            <label className="mark-label">
                                {commonService.getDateInFormat(item.StartDate) +
                                    " To " +
                                    commonService.getDateInFormat(item.EndDate)}{" "}
                            </label>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <label className={`pdf-label ml-1`}>
                                        {item.SaveType}
                                    </label>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="event-description">
                                <p dangerouslySetInnerHTML={{ __html: item.Details }}></p>
                            </div>
                            <div className="meeting-joining-link">
                                {item.Files ? (
                                    JSON.parse(item.Files).map((itemFile, indexFile) => (
                                        <label
                                            onClick={() =>
                                                viewFilePopupService.viewFilePopup({
                                                    data: JSON.parse(item.Files),
                                                })
                                            }
                                            key={indexFile}
                                            className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                        >
                                            {itemFile.FileType}
                                        </label>
                                    ))
                                ) : (
                                    <label></label>
                                )}
                            </div>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <div
                                        className="upload-section pointer-cursor"
                                        onClick={() => studentreadstatus(item.ID, item.DataType)}
                                    >
                                        <i className=" mr-1 fa-regular fa-check-double"></i>
                                        <span>Read Status</span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            );
        } else if (item.DataType.toUpperCase() === "CIRCULAR") {
            return (
                <>
                    <div
                        className={`${commonService.getDateInFormat(
                            item.StartDate
                        )} col-2 date-time-event classworkdata ${item.IsRead == 0 && item.SaveType == "Send"
                            ? "callisreadapprove"
                            : ""
                            }`}
                        key={index}
                        aid={item.ID}
                        at="Circular"
                    >
                        <div className="event-date-time parents-meeting-event">
                            <h4 className="event-date">
                                {commonService.getDateInDateMonthFormat(item.StartDate)}
                            </h4>
                            <p className="event-time">
                                {commonService.getTimeInFormat(item.StartDate)}
                            </p>
                        </div>
                    </div>
                    <div className="col-10 date-time-detail detail-of-circular">
                        <div className="event-date-time-detail ">
                            <div
                                className="event-title pointer-cursor"
                                onClick={() => handleshowAddEditPopupCirculars(item.ID)}
                            >
                                <h6>{item.Title}</h6>
                            </div>
                            <label className="label-for-meeting">{item.Type}</label>{" "}
                            <label className="mark-label">
                                {commonService.getDateInFormat(item.StartDate) +
                                    " To " +
                                    commonService.getDateInFormat(item.EndDate)}{" "}
                            </label>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <label className={`pdf-label ml-1`}>
                                        {item.SaveType}
                                    </label>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="event-description">
                                <p dangerouslySetInnerHTML={{ __html: item.Details }}></p>
                            </div>
                            <div className="meeting-joining-link">
                                {item.Files ? (
                                    JSON.parse(item.Files).map((itemFile, indexFile) => (
                                        <label
                                            onClick={() =>
                                                viewFilePopupService.viewFilePopup({
                                                    data: JSON.parse(item.Files),
                                                })
                                            }
                                            key={indexFile}
                                            className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                        >
                                            {itemFile.FileType}
                                        </label>
                                    ))
                                ) : (
                                    <label></label>
                                )}
                            </div>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <div
                                        className="upload-section pointer-cursor"
                                        onClick={() => studentreadstatus(item.ID, item.DataType)}
                                    >
                                        <i className="mr-2 fa-regular fa-check-double"></i>
                                        <span>Read Status</span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            );
        } else if (item.DataType.toUpperCase() === "NOTICE") {
            return (
                <>
                    <div
                        className={`${commonService.getDateInFormat(
                            item.StartDate
                        )} col-2 date-time-event classworkdata ${item.IsRead == 0 && item.SaveType == "Send"
                            ? "callisreadapprove"
                            : ""
                            }`}
                        key={index}
                        aid={item.ID}
                        at="Note"
                    >
                        <div className="event-date-time exam-event">
                            <h4 className="event-date">
                                {commonService.getDateInDateMonthFormat(item.StartDate)}
                            </h4>
                            <p className="event-time">
                                {commonService.getTimeInFormat(item.StartDate)}
                            </p>
                        </div>
                    </div>
                    <div className="col-10 date-time-detail detail-of-notice">
                        <div className="event-date-time-detail">
                            <div
                                className="event-title pointer-cursor"
                                onClick={() => handleshowAddEditPopupNotice(item.ID)}
                            >
                                <h6>{item.Title}</h6>
                            </div>
                            <label className="label-for-meeting">{item.Type}</label>{" "}
                            <label className="mark-label">
                                {commonService.getDateInFormat(item.StartDate) +
                                    " To " +
                                    commonService.getDateInFormat(item.EndDate)}{" "}
                            </label>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <label className={`pdf-label ml-1`}>
                                        {item.SaveType}
                                    </label>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="event-description">
                                <p dangerouslySetInnerHTML={{ __html: item.Details }}></p>
                            </div>
                            <div className="meeting-joining-link">
                                {item.Files ? (
                                    JSON.parse(item.Files).map((itemFile, indexFile) => (
                                        <label
                                            onClick={() =>
                                                viewFilePopupService.viewFilePopup({
                                                    data: JSON.parse(item.Files),
                                                })
                                            }
                                            key={indexFile}
                                            className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                        >
                                            {itemFile.FileType}
                                        </label>
                                    ))
                                ) : (
                                    <label></label>
                                )}
                            </div>
                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                <>
                                    <div
                                        className="upload-section pointer-cursor"
                                        onClick={() => studentreadstatus(item.ID, item.DataType)}
                                    >
                                        <i className=" mr-1 fa-regular fa-check-double"></i>
                                        <span>Read Status</span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </>
            );
        }
    }

    //function CalDateSelect(date) {
    //    setCalViewDate(date);
    //    if (
    //        document.getElementsByClassName(commonService.getDateInFormat(date))
    //            .length > 0
    //    ) {
    //        document.getElementById("root").scrollTop =
    //            document.getElementsByClassName(
    //                commonService.getDateInFormat(date)
    //            )[0].offsetTop;
    //    }
    //}

    const [showAddEditPopupNotice, setshowAddEditPopupNotice] = useState(false);
    const [AttachLinkNotice, setAttachLinkNotice] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachYoutubeLinkNotice, setAttachYoutubeLinkNotice] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachFilesNotice, setAttachFilesNotice] = useState({
        name: "",
        link: null,
        errors: "",
    });
    const [showLinkPopupNotice, setshowLinkPopupNotice] = useState(false);
    const [showYoutubeLinkPopupNotice, setshowYoutubeLinkPopupNotice] =
        useState(false);
    const [showFileSelectPopupNotice, setshowFileSelectPopupNotice] =
        useState(false);
    const [showScheduleDatePopupNotice, setshowScheduleDatePopupNotice] =
        useState(false);
    const [IsNoticeSaveing, setIsNoticeSaveing] = useState(false);
    const [NoticeData, setNoticeData] = useState({
        NotesID: "00000000-0000-0000-0000-000000000000",
        DateOfNotes: new Date(),
        NoteTitle: "",
        NoteDetails: "",
        ActionStartDate: new Date(),
        ActionEndDate: new Date(),
        DressCode: "",
        TagMemberIDs: "",
        SaveType: "",
        FilesDetails: [],
        IsForAll: true,
        RequestFrom: "Web",
        errors: {
            DateOfNotes: "",
            NoteTitle: "",
            ActionStartDate: "",
            ActionEndDate: "",
            DressCode: "",
        },
    });
    const [editorNoticesDetails, setEditorNoticesDetails] = useState(() =>
        EditorState.createEmpty()
    );

    const [DressCode, setDressCode] = useState([]);
    const [TeacherStdDiv, setTeacherStdDiv] = useState([]);
    const [SelectedStdDiv, setSelectedStdDiv] = useState("");
    const [CircularTypeTerm, setCircularTypeTerm] = useState([]);
    const [EventType, setEventType] = useState([]);
    const [StudentByStdDiv, setStudentByStdDiv] = useState([]);

    const params = new URLSearchParams(window.location.search);

    function handleshowAddEditPopupNotice(ID) {
        GetProjectTermData("DressCode", "Notice");
        setSelectedStdDiv("");
        GetStandardDivisionData();
        if (ID !== "") {
            const reqNotice = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/notes/getalldetailsbyid", reqNotice)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setNoticeData({
                            NotesID: json.data.detailsData[0].NotesID,
                            DateOfNotes: new Date(json.data.detailsData[0].DateOfNotes),
                            NoteTitle: json.data.detailsData[0].NoteTitle,
                            NoteDetails: json.data.detailsData[0].NoteDetails,
                            ActionStartDate: new Date(
                                json.data.detailsData[0].ActionStartDate
                            ),
                            ActionEndDate: new Date(json.data.detailsData[0].ActionEndDate),
                            DressCode: json.data.detailsData[0].DressCode,
                            //TagMemberIDs: json.data.detailsData[0].IsForAll
                            //    ? ""
                            //    : json.data.detailsData[0].TagMemberIDs,
                            TagMemberIDs: json.data.detailsData[0].TagMemberIDs,
                            SaveType: json.data.detailsData[0].SaveType,
                            FilesDetails: json.data.fileData,
                            IsForAll: json.data.detailsData[0].IsForAll,
                            RequestFrom: "Web",
                            errors: {
                                DateOfNotes: "",
                                NoteTitle: "",
                                ActionStartDate: "",
                                ActionEndDate: "",
                                DressCode: "",
                            },
                        });

                        if (json.data.memberData.length !== 0) {
                            let STDDIV = "";
                            for (let i = 0; i < json.data.memberData.length; i++) {
                                if (
                                    STDDIV.toUpperCase().indexOf(
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase()
                                    ) < 0
                                ) {
                                    STDDIV =
                                        STDDIV +
                                        "," +
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase();
                                }
                            }
                            setSelectedStdDiv(STDDIV);
                            GetStudentDataByStandardDivision(STDDIV);
                        }

                        setEditorNoticesDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].NoteDetails)
                                        .contentBlocks
                                )
                            )
                        );
                        setshowAddEditPopupNotice(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setNoticeData({
                NotesID: "00000000-0000-0000-0000-000000000000",
                DateOfNotes: new Date(),
                NoteTitle: "",
                NoteDetails: "",
                ActionStartDate: new Date(),
                ActionEndDate: new Date(),
                DressCode: "",
                TagMemberIDs: "",
                SaveType: "",
                FilesDetails: [],
                IsForAll: true,
                RequestFrom: "Web",
                errors: {
                    DateOfNotes: "",
                    NoteTitle: "",
                    ActionStartDate: "",
                    ActionEndDate: "",
                    DressCode: "",
                },
            });
            setEditorNoticesDetails(EditorState.createEmpty());
            setshowAddEditPopupNotice(true);
        }
    }

    function ButtonClickForSaveNotice(SaveType) {
        if (SaveType.toUpperCase() === "SCHEDULE") {
            setshowScheduleDatePopupNotice(true);
        } else if (
            SaveType.toUpperCase() === "SEND" ||
            SaveType.toUpperCase() === "DRAFT"
        ) {
            if (SaveType.toUpperCase() === "SEND") {
                setNoticeData({
                    ...NoticeData,
                    ["DateOfNotice"]: new Date(),
                });
            }
            AddEditNotice(SaveType);
        }
    }

    function AddEditNotice(SaveType) {
        if (validateNotice()) {
            setIsNoticeSaveing(true);
            setshowScheduleDatePopupNotice(false);
            let formData = new FormData();

            let TagMemberIDs = "";
            let IsForAll = false;
            if (SelectedStdDiv !== "") {
                if (NoticeData.TagMemberIDs !== "") {
                    TagMemberIDs = NoticeData.TagMemberIDs;
                } else {
                    TagMemberIDs = Array.isArray(StudentByStdDiv)
                        ? StudentByStdDiv.map((x) => x.value).join()
                        : "";
                }
            } else {
                IsForAll = true;
            }

            formData.append("NotesID", NoticeData.NotesID);
            formData.append(
                "DateOfNotes",
                commonService.getDateTimeInDBFormat(NoticeData.DateOfNotes)
            );
            formData.append("NoteTitle", NoticeData.NoteTitle);
            formData.append(
                "NoteDetails",
                draftToHtml(convertToRaw(editorNoticesDetails.getCurrentContent()))
            );
            formData.append(
                "ActionStartDate",
                commonService.getDateTimeInDBFormat(NoticeData.ActionStartDate)
            );
            formData.append(
                "ActionEndDate",
                commonService.getDateTimeInDBFormat(NoticeData.ActionEndDate)
            );
            formData.append("DressCode", NoticeData.DressCode);
            //formData.append("TagMemberIDs", NoticeData.TagMemberIDs);
            formData.append("TagMemberIDs", TagMemberIDs.replace("*,", ""));
            formData.append("SaveType", SaveType);
            let NewIndex = 0;
            for (let i = 0; i < NoticeData.FilesDetails.length; i++) {
                if (
                    NoticeData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    NoticeData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        NoticeData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        NoticeData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        NoticeData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        NoticeData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        NoticeData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        NoticeData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    NoticeData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    NoticeData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", NoticeData.FilesDetails[i].FilePath);
                }
            }
            //formData.append("IsForAll", NoticeData.TagMemberIDs === "" ? true : false);
            formData.append("IsForAll", IsForAll);

            formData.append("RequestFrom", "Web");

            const reqNotice = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/notes/save", reqNotice)
                .then((response) => response.json())
                .then(function (json) {
                    setIsNoticeSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        if (SaveType.toUpperCase() === "SEND") {
                            commonService.sendNotification();
                        }
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }

                    if (params.has("add")) {
                        if (params.get("add") === "note") {
                            history.push("wall");
                        } else {
                            setshowAddEditPopupNotice(false);
                        }
                    } else {
                        setshowAddEditPopupNotice(false);
                    }
                });
        }
    }

    function validateNotice() {
        let isValid = true;
        let errors = {};
        if (!NoticeData.NoteTitle) {
            errors.NoteTitle = "Title is required";
            isValid = false;
        } else {
            errors.NoteTitle = "";
        }

        if (!NoticeData.ActionStartDate) {
            errors.ActionStartDate = "Start date is required";
            isValid = false;
        } else {
            errors.ActionStartDate = "";
        }

        if (!NoticeData.ActionEndDate) {
            errors.ActionEndDate = "End date is required";
            isValid = false;
        } else {
            errors.ActionEndDate = "";
        }

        if (!NoticeData.DateOfNotes) {
            errors.DateOfNotice = "Schedule date is required";
            isValid = false;
        } else {
            errors.DateOfNotice = "";
        }

        if (!NoticeData.DressCode) {
            errors.DressCode = "Type is required";
            isValid = false;
        } else {
            errors.DressCode = "";
        }

        setNoticeData({
            ...NoticeData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function AddLinkInFilesDetailsNotice() {
        setAttachLinkNotice({ ...AttachLinkNotice, ["errors"]: "" });
        if (AttachLinkNotice.link !== "") {
            var res = AttachLinkNotice.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLinkNotice({
                    ...AttachLinkNotice,
                    ["errors"]: "Link is not valid",
                });
                return false;
            } else {
                let FilesDetails = NoticeData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLinkNotice.name,
                    FilePath: AttachLinkNotice.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setNoticeData({ ...NoticeData, ["FilesDetails"]: FilesDetails });
                setshowLinkPopupNotice(false);
                return true;
            }
        } else {
            setAttachLinkNotice({
                ...AttachLinkNotice,
                ["errors"]: "Link is required",
            });
        }
    }

    function AddYoutubeLinkInFilesDetailsNotice() {
        setAttachYoutubeLinkNotice({ ...AttachYoutubeLinkNotice, ["errors"]: "" });
        if (AttachYoutubeLinkNotice.link !== "") {
            var res = AttachYoutubeLinkNotice.link.match(
                AppConfigData.regex.YoutubeEmbed
            );
            if (res == null) {
                setAttachYoutubeLinkNotice({
                    ...AttachYoutubeLinkNotice,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = NoticeData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLinkNotice.name,
                    FilePath: AttachYoutubeLinkNotice.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setNoticeData({ ...NoticeData, ["FilesDetails"]: FilesDetails });
                setshowYoutubeLinkPopupNotice(false);
                return true;
            }
        } else {
            setAttachYoutubeLinkNotice({
                ...AttachYoutubeLinkNotice,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetailsNotice() {
        setAttachFilesNotice({ ...AttachFilesNotice, ["errors"]: "" });
        if (AttachFilesNotice.link !== null) {
            let FilesDetails = NoticeData.FilesDetails;
            for (let i = 0; i < AttachFilesNotice.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFilesNotice.link[i].name,
                    FilePath: AttachFilesNotice.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFilesNotice.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setNoticeData({ ...NoticeData, ["FilesDetails"]: FilesDetails });
            setshowFileSelectPopupNotice(false);
        } else {
            setAttachFilesNotice({
                ...AttachFilesNotice,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIUNotice(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }
        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromNoticeAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {data[0].MemberType.toLowerCase() !== "student" &&
                        NoticeData.SaveType.toUpperCase() !== "SEND" && (
                            <i
                                className="fa-regular fa-xmark pointer-cursor"
                                onClick={() =>
                                    confirmAlert({
                                        title: "Delete",
                                        message: "Are you sure delete ?",
                                        buttons: [
                                            {
                                                label: "Yes",
                                                onClick: () =>
                                                    fnDeleteFilesDetailsNotice(
                                                        FilesDetail.FilesID,
                                                        indexFile
                                                    ),
                                            },
                                            {
                                                label: "No",
                                            },
                                        ],
                                    })
                                }
                            ></i>
                        )}
                </div>
            </div>
        );
    }

    function ViewFileFromNoticeAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function fnDeleteFilesDetailsNotice(id, key) {
        let FilesDetails = NoticeData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setNoticeData({ ...NoticeData, ["FilesDetails"]: FilesDetails });
        } else if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqNotice = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/home/file_deletebyid", reqNotice)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        FilesDetails.splice(key, 1);
                        setNoticeData({ ...NoticeData, ["FilesDetails"]: FilesDetails });
                    } else if (json.status_code === 0) {
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function fnDeleteNotice(id) {
        if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqNotice = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/notes/deletebyid", reqNotice)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        setshowAddEditPopupNotice(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function GetProjectTermData(Category, CallFor) {
        setDressCode([]);
        setCircularTypeTerm([]);
        setEventType([]);
        const reqNotice = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                multiCategory: Category,
                requestFrom: "string",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_projectterm_selectbymulticategory",
            reqNotice
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    if (CallFor.toUpperCase() === "CIRCULARS") {
                        let CircularTypeOptions = [];
                        for (let i = 0; i < json.data.length; i++) {
                            if (json.data[i].Category.toUpperCase() === "CIRCULARTYPE") {
                                CircularTypeOptions.push({
                                    value: json.data[i].Term.toLocaleUpperCase(),
                                    label: json.data[i].Term,
                                });
                            }
                        }
                        setCircularTypeTerm(CircularTypeOptions);
                    } else if (CallFor.toUpperCase() === "NOTICE") {
                        let DressCodeOptions = [];
                        for (let i = 0; i < json.data.length; i++) {
                            if (json.data[i].Category.toUpperCase() === "DRESSCODE") {
                                DressCodeOptions.push({
                                    value: json.data[i].Term.toLocaleUpperCase(),
                                    label: json.data[i].Term,
                                });
                            }
                        }
                        setDressCode(DressCodeOptions);
                    } else if (CallFor.toUpperCase() === "EVENTS") {
                        let EventTypeOptions = [];
                        for (let i = 0; i < json.data.length; i++) {
                            if (json.data[i].Category.toUpperCase() === "EVENTTYPE") {
                                EventTypeOptions.push({
                                    value: json.data[i].Term.toLocaleUpperCase(),
                                    label: json.data[i].Term,
                                });
                            }
                        }
                        setEventType(EventTypeOptions);
                    }
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function GetStandardDivisionData() {
        setTeacherStdDiv([]);

        const reqNotice = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/get_teacher_standarddivision",
            reqNotice
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        Options.push({
                            value: json.data[i].IDs.toLocaleUpperCase(),
                            label: json.data[i].Text,
                        });
                    }
                    setTeacherStdDiv(Options);
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function StandardDivisionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setSelectedStdDiv(Array.isArray(this.options) ? this.options.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            );
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setSelectedStdDiv("")
            GetStudentDataByStandardDivision(
                ""
            );
        } else if (event.action === "deselect-option") {
            setSelectedStdDiv(Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            );
        } else if (value.length === this.options.length - 1) {
            setSelectedStdDiv(Array.isArray(this.options) ? this.options.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            );
        } else {
            setSelectedStdDiv(Array.isArray(value) ? value.map((x) => x.value).join() : "")
            GetStudentDataByStandardDivision(
                Array.isArray(value) ? value.map((x) => x.value).join() : ""
            );
        }
    }

    function GetStudentDataByStandardDivision(stddivids) {
        if (stddivids === "") {
            setNoticeData({ ...NoticeData, ["TagMemberIDs"]: "" });
            setCircularsData({ ...CircularsData, ["TagMemberIDs"]: "" });
            setEventsData({ ...EventsData, ["TagMemberIDs"]: "" });
        }
        setStudentByStdDiv([]);
        const reqNotice = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                stddivids: stddivids.replace("*,", ""),
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_student_listbystudentmultistandarddivision",
            reqNotice
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        Options.push({
                            value: json.data[i].StudentID.toLocaleUpperCase(),
                            label:
                                json.data[i].StandardName +
                                " - " +
                                json.data[i].DivisionName +
                                " - " +
                                json.data[i].RollNo +
                                " - " +
                                json.data[i].StudentFullName,
                        });
                    }
                    setStudentByStdDiv(Options);
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    const [showAddEditPopupCirculars, setshowAddEditPopupCirculars] =
        useState(false);
    const [AttachLinkCirculars, setAttachLinkCirculars] = useState({
        name: "",
        link: "",
        errors: "",
    });

    const [AttachYoutubeLinkCirculars, setAttachYoutubeLinkCirculars] = useState({
        name: "",
        link: "",
        errors: "",
    });

    const [AttachFilesCirculars, setAttachFilesCirculars] = useState({
        name: "",
        link: null,
        errors: "",
    });

    const [showLinkPopupCirculars, setshowLinkPopupCirculars] = useState(false);

    const [showYoutubeLinkPopupCirculars, setshowYoutubeLinkPopupCirculars] =
        useState(false);

    const [showFileSelectPopupCirculars, setshowFileSelectPopupCirculars] =
        useState(false);

    const [showScheduleDatePopupCirculars, setshowScheduleDatePopupCirculars] =
        useState(false);

    const [IsCircularsSaveing, setIsCircularsSaveing] = useState(false);

    const [CircularsData, setCircularsData] = useState({
        CircularID: "00000000-0000-0000-0000-000000000000",
        DateOfCircular: new Date(),
        CircularTitle: "",
        CircularDetails: "",
        CircularTypeTerm: "",
        TagMemberIDs: "",
        SaveType: "",
        FilesDetails: [],
        IsForAll: true,
        RequestFrom: "Web",
        errors: {
            DateOfCircular: "",
            CircularTitle: "",
            CircularTypeTerm: "",
        },
    });

    const [editorCircularssDetails, setEditorCircularssDetails] = useState(() =>
        EditorState.createEmpty()
    );

    function handleshowAddEditPopupCirculars(ID) {
        GetProjectTermData("CircularType", "Circulars");
        setSelectedStdDiv("");
        GetStandardDivisionData();
        if (ID !== "") {
            const reqCirculars = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/circulars/getalldetailsbyid",
                reqCirculars
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setCircularsData({
                            CircularID: json.data.detailsData[0].CircularID,
                            DateOfCircular: new Date(json.data.detailsData[0].DateOfCircular),
                            CircularTitle: json.data.detailsData[0].CircularTitle,
                            CircularDetails: json.data.detailsData[0].CircularDetails,
                            CircularTypeTerm: json.data.detailsData[0].CircularTypeTerm,
                            //TagMemberIDs: json.data.detailsData[0].IsForAll
                            //    ? ""
                            //    : json.data.detailsData[0].TagMemberIDs,
                            TagMemberIDs: json.data.detailsData[0].TagMemberIDs,
                            SaveType: json.data.detailsData[0].SaveType,
                            FilesDetails: json.data.fileData,
                            IsForAll: json.data.detailsData[0].IsForAll,
                            RequestFrom: "Web",
                            errors: {
                                DateOfCircular: "",
                                CircularTitle: "",
                                CircularTypeTerm: "",
                            },
                        });

                        if (json.data.memberData.length !== 0) {
                            let STDDIV = "";
                            for (let i = 0; i < json.data.memberData.length; i++) {
                                if (
                                    STDDIV.toUpperCase().indexOf(
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase()
                                    ) < 0
                                ) {
                                    STDDIV =
                                        STDDIV +
                                        "," +
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase();
                                }
                            }
                            setSelectedStdDiv(STDDIV);
                            GetStudentDataByStandardDivision(STDDIV);
                        }

                        setEditorCircularssDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].CircularDetails)
                                        .contentBlocks
                                )
                            )
                        );
                        setshowAddEditPopupCirculars(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setCircularsData({
                CircularID: "00000000-0000-0000-0000-000000000000",
                DateOfCircular: new Date(),
                CircularTitle: "",
                CircularDetails: "",
                CircularTypeTerm: "",
                TagMemberIDs: "",
                SaveType: "",
                FilesDetails: [],
                IsForAll: true,
                RequestFrom: "Web",
                errors: {
                    DateOfCircular: "",
                    CircularTitle: "",
                    CircularTypeTerm: "",
                },
            });
            setEditorCircularssDetails(EditorState.createEmpty());
            setshowAddEditPopupCirculars(true);
        }
    }

    function ButtonClickForSaveCirculars(SaveType) {
        if (SaveType.toUpperCase() === "SCHEDULE") {
            setshowScheduleDatePopupCirculars(true);
        } else if (
            SaveType.toUpperCase() === "SEND" ||
            SaveType.toUpperCase() === "DRAFT"
        ) {
            if (SaveType.toUpperCase() === "SEND") {
                setCircularsData({
                    ...CircularsData,
                    ["DateOfCirculars"]: new Date(),
                });
            }
            AddEditCirculars(SaveType);
        }
    }

    function AddEditCirculars(SaveType) {
        if (validateCirculars()) {
            setIsCircularsSaveing(true);
            setshowScheduleDatePopupCirculars(false);
            let formData = new FormData();

            let TagMemberIDs = "";
            let IsForAll = false;
            if (SelectedStdDiv !== "") {
                if (CircularsData.TagMemberIDs !== "") {
                    TagMemberIDs = CircularsData.TagMemberIDs;
                } else {
                    TagMemberIDs = Array.isArray(StudentByStdDiv)
                        ? StudentByStdDiv.map((x) => x.value).join()
                        : "";
                }
            } else {
                IsForAll = true;
            }

            formData.append("CircularID", CircularsData.CircularID);
            formData.append(
                "DateOfCircular",
                commonService.getDateTimeInDBFormat(CircularsData.DateOfCircular)
            );
            formData.append("CircularTitle", CircularsData.CircularTitle);
            formData.append(
                "CircularDetails",
                draftToHtml(convertToRaw(editorCircularssDetails.getCurrentContent()))
            );
            formData.append("CircularTypeTerm", CircularsData.CircularTypeTerm);
            //formData.append("TagMemberIDs", CircularsData.TagMemberIDs);
            formData.append("TagMemberIDs", TagMemberIDs.replace("*,", ""));

            formData.append("SaveType", SaveType);
            let NewIndex = 0;
            for (let i = 0; i < CircularsData.FilesDetails.length; i++) {
                if (
                    CircularsData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    CircularsData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        CircularsData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        CircularsData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        CircularsData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        CircularsData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        CircularsData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        CircularsData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    CircularsData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    CircularsData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", CircularsData.FilesDetails[i].FilePath);
                }
            }
            //formData.append("IsForAll",CircularsData.TagMemberIDs === "" ? true : false);
            formData.append("IsForAll", IsForAll);
            formData.append("RequestFrom", "Web");

            const reqCirculars = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/circulars/save", reqCirculars)
                .then((response) => response.json())
                .then(function (json) {
                    setIsCircularsSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        if (SaveType.toUpperCase() === "SEND") {
                            commonService.sendNotification();
                        }
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }

                    if (params.has("add")) {
                        if (params.get("add") === "circular") {
                            history.push("wall");
                        } else {
                            setshowAddEditPopupCirculars(false);
                        }
                    } else {
                        setshowAddEditPopupCirculars(false);
                    }
                });
        }
    }

    function validateCirculars() {
        let isValid = true;
        let errors = {};
        if (!CircularsData.CircularTitle) {
            errors.CircularTitle = "Title is required";
            isValid = false;
        } else {
            errors.CircularTitle = "";
        }

        if (!CircularsData.DateOfCircular) {
            errors.DateOfCircular = "Date is required";
            isValid = false;
        } else {
            errors.DateOfCircular = "";
        }

        if (!CircularsData.CircularTypeTerm) {
            errors.CircularTypeTerm = "Type is required";
            errors.CircularTypeTerm = "Type is required";
            isValid = false;
        } else {
            errors.CircularTypeTerm = "";
        }

        setCircularsData({
            ...CircularsData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function AddLinkInFilesDetailsCirculars() {
        setAttachLinkCirculars({ ...AttachLinkCirculars, ["errors"]: "" });
        if (AttachLinkCirculars.link !== "") {
            var res = AttachLinkCirculars.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLinkCirculars({
                    ...AttachLinkCirculars,
                    ["errors"]: "Link is not valid",
                });
                return false;
            } else {
                let FilesDetails = CircularsData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLinkCirculars.name,
                    FilePath: AttachLinkCirculars.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setCircularsData({ ...CircularsData, ["FilesDetails"]: FilesDetails });
                setshowLinkPopupCirculars(false);
                return true;
            }
        } else {
            setAttachLinkCirculars({
                ...AttachLinkCirculars,
                ["errors"]: "Link is required",
            });
        }
    }

    function AddYoutubeLinkInFilesDetailsCirculars() {
        setAttachYoutubeLinkCirculars({
            ...AttachYoutubeLinkCirculars,
            ["errors"]: "",
        });
        if (AttachYoutubeLinkCirculars.link !== "") {
            var res = AttachYoutubeLinkCirculars.link.match(
                AppConfigData.regex.YoutubeEmbed
            );
            if (res == null) {
                setAttachYoutubeLinkCirculars({
                    ...AttachYoutubeLinkCirculars,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = CircularsData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLinkCirculars.name,
                    FilePath: AttachYoutubeLinkCirculars.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setCircularsData({ ...CircularsData, ["FilesDetails"]: FilesDetails });
                setshowYoutubeLinkPopupCirculars(false);
                return true;
            }
        } else {
            setAttachYoutubeLinkCirculars({
                ...AttachYoutubeLinkCirculars,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetailsCirculars() {
        setAttachFilesCirculars({ ...AttachFilesCirculars, ["errors"]: "" });
        if (AttachFilesCirculars.link !== null) {
            let FilesDetails = CircularsData.FilesDetails;
            for (let i = 0; i < AttachFilesCirculars.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFilesCirculars.link[i].name,
                    FilePath: AttachFilesCirculars.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFilesCirculars.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setCircularsData({ ...CircularsData, ["FilesDetails"]: FilesDetails });
            setshowFileSelectPopupCirculars(false);
        } else {
            setAttachFilesCirculars({
                ...AttachFilesCirculars,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIUCirculars(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }

        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromCircularsAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {data[0].MemberType.toLowerCase() !== "student" &&
                        CircularsData.SaveType.toUpperCase() !== "SEND" && (
                            <i
                                className="fa-regular fa-xmark pointer-cursor"
                                onClick={() =>
                                    confirmAlert({
                                        title: "Delete",
                                        message: "Are you sure delete ?",
                                        buttons: [
                                            {
                                                label: "Yes",
                                                onClick: () =>
                                                    fnDeleteFilesDetailsCirculars(
                                                        FilesDetail.FilesID,
                                                        indexFile
                                                    ),
                                            },
                                            {
                                                label: "No",
                                            },
                                        ],
                                    })
                                }
                            ></i>
                        )}
                </div>
            </div>
        );
    }

    function ViewFileFromCircularsAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function fnDeleteFilesDetailsCirculars(id, key) {
        let FilesDetails = CircularsData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setCircularsData({ ...CircularsData, ["FilesDetails"]: FilesDetails });
        } else if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqCirculars = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/home/file_deletebyid", reqCirculars)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        FilesDetails.splice(key, 1);
                        setCircularsData({
                            ...CircularsData,
                            ["FilesDetails"]: FilesDetails,
                        });
                    } else if (json.status_code === 0) {
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function fnDeleteCirculars(id) {
        if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqCirculars = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/circulars/deletebyid", reqCirculars)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        setshowAddEditPopupCirculars(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const [showAddEditPopupEvents, setshowAddEditPopupEvents] = useState(false);

    const [AttachLinkEvents, setAttachLinkEvents] = useState({
        name: "",
        link: "",
        errors: "",
    });

    const [AttachYoutubeLinkEvents, setAttachYoutubeLinkEvents] = useState({
        name: "",
        link: "",
        errors: "",
    });

    const [AttachFilesEvents, setAttachFilesEvents] = useState({
        name: "",
        link: null,
        errors: "",
    });

    const [showLinkPopupEvents, setshowLinkPopupEvents] = useState(false);

    const [showYoutubeLinkPopupEvents, setshowYoutubeLinkPopupEvents] =
        useState(false);

    const [showFileSelectPopupEvents, setshowFileSelectPopupEvents] =
        useState(false);

    const [showScheduleDatePopupEvents, setshowScheduleDatePopupEvents] =
        useState(false);

    const [IsEventsSaveing, setIsEventsSaveing] = useState(false);

    const [EventsData, setEventsData] = useState({
        EventID: "00000000-0000-0000-0000-000000000000",
        Title: "",
        Details: "",
        ReminderDate: new Date(),
        StartDate: new Date(),
        EndDate: new Date(),
        EventType: "",
        Location: "",
        TagMemberIDs: "",
        SaveType: "",
        DateOfEvents: new Date(),
        FilesDetails: [],
        IsForAll: true,
        RequestFrom: "Web",
        errors: {
            Title: "",
            ReminderDate: "",
            StartDate: "",
            EndDate: "",
            EventType: "",
            Location: "",
            DateOfEvents: "",
        },
    });

    const [editorEventssDetails, setEditorEventssDetails] = useState(() =>
        EditorState.createEmpty()
    );

    function handleshowAddEditPopupEvents(ID) {
        GetProjectTermData("EventType", "Events");
        setSelectedStdDiv("");
        GetStandardDivisionData();
        if (ID !== "") {
            const reqEvents = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/events/getalldetailsbyid",
                reqEvents
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setEventsData({
                            EventID: json.data.detailsData[0].EventID,
                            Title: json.data.detailsData[0].Title,
                            Details: json.data.detailsData[0].Details,
                            ReminderDate: new Date(json.data.detailsData[0].ReminderDate),
                            StartDate: new Date(json.data.detailsData[0].StartDate),
                            EndDate: new Date(json.data.detailsData[0].EndDate),
                            EventType: json.data.detailsData[0].EventType,
                            Location: json.data.detailsData[0].Location,
                            //TagMemberIDs: json.data.detailsData[0].IsForAll
                            //    ? ""
                            //    : json.data.detailsData[0].TagMemberIDs,
                            TagMemberIDs: json.data.detailsData[0].TagMemberIDs,
                            SaveType: json.data.detailsData[0].SaveType,
                            DateOfEvents: new Date(json.data.detailsData[0].DateOfEvents),
                            FilesDetails: json.data.fileData,
                            IsForAll: json.data.detailsData[0].IsForAll,
                            RequestFrom: "Web",
                            errors: {
                                Title: "",
                                ReminderDate: "",
                                StartDate: "",
                                EndDate: "",
                                EventType: "",
                                Location: "",
                                DateOfEvents: "",
                            },
                        });

                        if (json.data.memberData.length !== 0) {
                            let STDDIV = "";
                            for (let i = 0; i < json.data.memberData.length; i++) {
                                if (
                                    STDDIV.toUpperCase().indexOf(
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase()
                                    ) < 0
                                ) {
                                    STDDIV =
                                        STDDIV +
                                        "," +
                                        json.data.memberData[i].StandardID.toUpperCase() +
                                        "|" +
                                        json.data.memberData[i].DivisionID.toUpperCase();
                                }
                            }
                            setSelectedStdDiv(STDDIV);
                            GetStudentDataByStandardDivision(STDDIV);
                        }

                        setEditorEventssDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].Details).contentBlocks
                                )
                            )
                        );
                        setshowAddEditPopupEvents(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setEventsData({
                EventID: "00000000-0000-0000-0000-000000000000",
                Title: "",
                Details: "",
                ReminderDate: new Date(),
                StartDate: new Date(),
                EndDate: new Date(),
                EventType: "",
                Location: "",
                TagMemberIDs: "",
                SaveType: "",
                DateOfEvents: new Date(),
                FilesDetails: [],
                IsForAll: true,
                RequestFrom: "Web",
                errors: {
                    Title: "",
                    ReminderDate: "",
                    StartDate: "",
                    EndDate: "",
                    EventType: "",
                    Location: "",
                    DateOfEvents: "",
                },
            });
            setEditorEventssDetails(EditorState.createEmpty());
            setshowAddEditPopupEvents(true);
        }
    }

    function ButtonClickForSaveEvents(SaveType) {
        if (SaveType.toUpperCase() === "SCHEDULE") {
            setshowScheduleDatePopupEvents(true);
        } else if (
            SaveType.toUpperCase() === "SEND" ||
            SaveType.toUpperCase() === "DRAFT"
        ) {
            if (SaveType.toUpperCase() === "SEND") {
                setEventsData({
                    ...EventsData,
                    ["DateOfEvents"]: new Date(),
                });
            }
            AddEditEvents(SaveType);
        }
    }

    function AddEditEvents(SaveType) {
        if (validateEvents()) {
            setIsEventsSaveing(true);
            setshowScheduleDatePopupEvents(false);
            let formData = new FormData();

            let TagMemberIDs = "";
            let IsForAll = false;
            if (SelectedStdDiv !== "") {
                if (EventsData.TagMemberIDs !== "") {
                    TagMemberIDs = EventsData.TagMemberIDs;
                } else {
                    TagMemberIDs = Array.isArray(StudentByStdDiv)
                        ? StudentByStdDiv.map((x) => x.value).join()
                        : "";
                }
            } else {
                IsForAll = true;
            }

            formData.append("EventID", EventsData.EventID);
            formData.append("Title", EventsData.Title);
            formData.append(
                "Details",
                draftToHtml(convertToRaw(editorEventssDetails.getCurrentContent()))
            );
            formData.append(
                "ReminderDate",
                commonService.getDateTimeInDBFormat(EventsData.ReminderDate)
            );
            formData.append(
                "StartDate",
                commonService.getDateTimeInDBFormat(EventsData.StartDate)
            );
            formData.append(
                "EndDate",
                commonService.getDateTimeInDBFormat(EventsData.EndDate)
            );
            formData.append("EventType", EventsData.EventType);
            formData.append("Location", EventsData.Location);
            //formData.append("TagMemberIDs", EventsData.TagMemberIDs);
            formData.append("TagMemberIDs", TagMemberIDs.replace("*,", ""));

            formData.append("SaveType", SaveType);
            formData.append(
                "DateOfEvents",
                commonService.getDateTimeInDBFormat(EventsData.DateOfEvents)
            );
            let NewIndex = 0;
            for (let i = 0; i < EventsData.FilesDetails.length; i++) {
                if (
                    EventsData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    EventsData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        EventsData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        EventsData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        EventsData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        EventsData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        EventsData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        EventsData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    EventsData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    EventsData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", EventsData.FilesDetails[i].FilePath);
                }
            }
            //formData.append("IsForAll", EventsData.TagMemberIDs === "" ? true : false);
            formData.append("IsForAll", IsForAll);

            formData.append("RequestFrom", "Web");

            const reqEvents = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/events/save", reqEvents)
                .then((response) => response.json())
                .then(function (json) {
                    setIsEventsSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        if (SaveType.toUpperCase() === "SEND") {
                            commonService.sendNotification();
                        }
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }

                    if (params.has("add")) {
                        if (params.get("add") === "event") {
                            history.push("wall");
                        } else {
                            setshowAddEditPopupEvents(false);
                        }
                    } else {
                        setshowAddEditPopupEvents(false);
                    }
                });
        }
    }

    function validateEvents() {
        let isValid = true;
        let errors = {};
        if (!EventsData.Title) {
            errors.Title = "Title is required";
            isValid = false;
        } else {
            errors.Title = "";
        }

        if (!EventsData.ReminderDate) {
            errors.ReminderDate = "Reminder date is required";
            isValid = false;
        } else {
            errors.ReminderDate = "";
        }

        if (!EventsData.StartDate) {
            errors.StartDate = "Start date is required";
            isValid = false;
        } else {
            errors.StartDate = "";
        }

        if (!EventsData.EndDate) {
            errors.EndDate = "End date is required";
            isValid = false;
        } else {
            errors.EndDate = "";
        }

        if (!EventsData.DateOfEvents) {
            errors.DateOfEvents = "Schedule date is required";
            isValid = false;
        } else {
            errors.DateOfEvents = "";
        }

        if (!EventsData.EventType) {
            errors.EventType = "Type is required";
            isValid = false;
        } else {
            errors.EventType = "";
        }

        if (!EventsData.Location) {
            errors.Location = "Location is required";
            isValid = false;
        } else {
            errors.Location = "";
        }

        setEventsData({
            ...EventsData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function AddLinkInFilesDetailsEvents() {
        setAttachLinkEvents({ ...AttachLinkEvents, ["errors"]: "" });
        if (AttachLinkEvents.link !== "") {
            var res = AttachLinkEvents.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLinkEvents({
                    ...AttachLinkEvents,
                    ["errors"]: "Link is not valid",
                });
                return false;
            } else {
                let FilesDetails = EventsData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLinkEvents.name,
                    FilePath: AttachLinkEvents.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setEventsData({ ...EventsData, ["FilesDetails"]: FilesDetails });
                setshowLinkPopupEvents(false);
                return true;
            }
        } else {
            setAttachLinkEvents({
                ...AttachLinkEvents,
                ["errors"]: "Link is required",
            });
        }
    }

    function AddYoutubeLinkInFilesDetailsEvents() {
        setAttachYoutubeLinkEvents({ ...AttachYoutubeLinkEvents, ["errors"]: "" });
        if (AttachYoutubeLinkEvents.link !== "") {
            var res = AttachYoutubeLinkEvents.link.match(
                AppConfigData.regex.YoutubeEmbed
            );
            if (res == null) {
                setAttachYoutubeLinkEvents({
                    ...AttachYoutubeLinkEvents,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = EventsData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLinkEvents.name,
                    FilePath: AttachYoutubeLinkEvents.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setEventsData({ ...EventsData, ["FilesDetails"]: FilesDetails });
                setshowYoutubeLinkPopupEvents(false);
                return true;
            }
        } else {
            setAttachYoutubeLinkEvents({
                ...AttachYoutubeLinkEvents,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetailsEvents() {
        setAttachFilesEvents({ ...AttachFilesEvents, ["errors"]: "" });
        if (AttachFilesEvents.link !== null) {
            let FilesDetails = EventsData.FilesDetails;
            for (let i = 0; i < AttachFilesEvents.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFilesEvents.link[i].name,
                    FilePath: AttachFilesEvents.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFilesEvents.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setEventsData({ ...EventsData, ["FilesDetails"]: FilesDetails });
            setshowFileSelectPopupEvents(false);
        } else {
            setAttachFilesEvents({
                ...AttachFilesEvents,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIUEvents(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }

        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromEventsAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {data[0].MemberType.toLowerCase() !== "student" &&
                        EventsData.SaveType.toUpperCase() !== "SEND" && (
                            <i
                                className="fa-regular fa-xmark pointer-cursor"
                                onClick={() =>
                                    confirmAlert({
                                        title: "Delete",
                                        message: "Are you sure delete ?",
                                        buttons: [
                                            {
                                                label: "Yes",
                                                onClick: () =>
                                                    fnDeleteFilesDetailsEvents(
                                                        FilesDetail.FilesID,
                                                        indexFile
                                                    ),
                                            },
                                            {
                                                label: "No",
                                            },
                                        ],
                                    })
                                }
                            ></i>
                        )}
                </div>
            </div>
        );
    }

    function ViewFileFromEventsAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function fnDeleteFilesDetailsEvents(id, key) {
        let FilesDetails = EventsData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setEventsData({ ...EventsData, ["FilesDetails"]: FilesDetails });
        } else if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqEvents = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/home/file_deletebyid", reqEvents)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        FilesDetails.splice(key, 1);
                        setEventsData({ ...EventsData, ["FilesDetails"]: FilesDetails });
                    } else if (json.status_code === 0) {
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function fnDeleteEvents(id) {
        if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqEvents = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/events/deletebyid", reqEvents)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindNoteEventCircularList(1);
                        setshowAddEditPopupEvents(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    React.useEffect(() => {
        let IsNotice = commonService.getLoginRoleData("Notice", "IsView");
        let IsEvent = commonService.getLoginRoleData("Event", "IsView");
        let IsCircular = commonService.getLoginRoleData("Circular", "IsView");
        if (IsNotice || IsEvent || IsCircular) {

            BindNoteEventCircularList(1);
            if (data[0].MemberType.toLowerCase() !== "student") {
                if (params.has("add")) {
                    if (params.get("add") === "circular") {
                        handleshowAddEditPopupCirculars("");
                    } else if (params.get("add") === "event") {
                        handleshowAddEditPopupEvents("");
                    } else if (params.get("add") === "note") {
                        handleshowAddEditPopupNotice("");
                    }
                }
            }
            document
                .getElementById("root")
                .addEventListener("scroll", WindowScrolling);
            return () => {
                document
                    .getElementById("root")
                    .removeEventListener("scroll", WindowScrolling);
            };
        } else {
            history.push("/access");
        }
    }, []);

    function WindowScrolling(event) {

        if (event.srcElement.scrollTop + window.innerHeight >= (event.srcElement.scrollHeight - 100)) {
            BindNoteEventCircularList(document.getElementsByClassName("classworkdata").length + 1);
        }

        let AllPost = document.getElementsByClassName("callisreadapprove");
        if (AllPost != null && AllPost.length != 0) {
            for (let i = 0; i < AllPost.length; i++) {
                if (isInViewport(AllPost[i])) {
                    let AID = AllPost[i].getAttribute("aid");
                    let AT = AllPost[i].getAttribute("at");
                    approvereadpost(AID, AT);
                    AllPost[i].classList.remove("callisreadapprove");
                }
            }
        }
    }

    function approvereadpost(AID, AT) {
        const reqreadapprovepostdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associatinID: AID,
                associationType: AT,
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readapprovepost",
            reqreadapprovepostdata
        )
            .then((response) => response.json())

            .then(function (json) {
                if (json.status_code === 1) {
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function isInViewport(e) {
        const rect = e.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function getDropdownButtonLabel({ value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    function NoteStudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setNoticeData({
                ...NoticeData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setNoticeData({
                ...NoticeData, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setNoticeData({
                ...NoticeData, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setNoticeData({
                ...NoticeData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setNoticeData({
                ...NoticeData, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })

        }
    }

    function CircularStudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setCircularsData({
                ...CircularsData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setCircularsData({
                ...CircularsData, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setCircularsData({
                ...CircularsData, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setCircularsData({
                ...CircularsData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setCircularsData({
                ...CircularsData, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })
        }
    }

    function EventStudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setEventsData({
                ...EventsData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setEventsData({
                ...EventsData, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setEventsData({
                ...EventsData, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setEventsData({
                ...EventsData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setEventsData({
                ...EventsData, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })
        }
    }

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("MenuNoteCircularEvents")}</h1>
            <div className="background-theme-color sticky-top notice-circular-event-searchbar">
                <div className="search-top-section">
                    <div className="row">
                        <div className="col-lg-12 col-xl-7">
                            <div className="header-search-area">
                                <input
                                    type="text"
                                    className=""
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    onChange={(e) => setTxtSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                            <div className="search-right-cal d-flex align-items-center">
                                <div className="header-date-area w-100 mr-3">
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerFrom")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtStartDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerTo")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtEndDate}
                                            minDate={txtStartDate && txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtEndDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                </div>
                                <div className="right-icon-cal d-flex align-items-center">
                                    {IsNoteEventCircularListDateLodding === false ? (
                                        <div
                                            className="search-icon-img pointer-cursor"
                                            onClick={() => BindNoteEventCircularList(1)}
                                        >
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </div>
                                    ) : (
                                        <div className="search-icon-img pointer-cursor">
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    )}
                                    {data[0].MemberType.toLowerCase() !== "student" && (
                                        <div className="plus-btn pointer-cursor custom-plus-btn ml-3">
                                            <DropdownButton as={ButtonGroup}>
                                                {
                                                    commonService.getLoginRoleData("Event", "IsCreate") &&
                                                    <Dropdown.Item
                                                        eventKey="1"
                                                        className="events"
                                                        onClick={() => handleshowAddEditPopupEvents("")}
                                                    >
                                                        {commonService.LabelDisplayText("Events")}
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    commonService.getLoginRoleData("Circular", "IsCreate") &&
                                                    <Dropdown.Item
                                                        eventKey="2"
                                                        className="circular"
                                                        onClick={() => handleshowAddEditPopupCirculars("")}
                                                    >
                                                        {commonService.LabelDisplayText("Circular")}
                                                    </Dropdown.Item>
                                                }
                                                {
                                                    commonService.getLoginRoleData("Notice", "IsCreate") &&
                                                    <Dropdown.Item
                                                        eventKey="3"
                                                        className="notice"
                                                        onClick={() => handleshowAddEditPopupNotice("")}
                                                    >
                                                        {commonService.LabelDisplayText("Notice")}
                                                    </Dropdown.Item>
                                                }
                                            </DropdownButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-result-block">
                    <span>
                        Result For{" "}
                        <span>
                            {txtSearch +
                                " From : " +
                                commonService.getDateInFormat(txtStartDate) +
                                " To : " +
                                commonService.getDateInFormat(txtEndDate)}
                        </span>
                    </span>
                </div>
            </div>

            <div className="row">
                {/*<div className="col-md-12 col-lg-4 col-xl-4 mb-3">*/}
                {/*    <div className="calendar-area sticky-top">*/}
                {/*        <div className="circular-event-note-calender">*/}
                {/*            <DatePicker*/}
                {/*                inline*/}
                {/*                highlightDates={HighlightWithRanges}*/}
                {/*                selected={CalViewDate}*/}
                {/*                onChange={(date) => CalDateSelect(date)}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-md-12 col-lg-12 col-xl-12">
                    {!NoteEventCircular.IsData ? (
                        <div className="event-details">
                            <_Skeleton type={_SkeletonType.NoteEventCircular} />
                            <_Skeleton type={_SkeletonType.NoteEventCircular} />
                        </div>
                    ) : NoteEventCircular.data.length === 0 ? (
                        <div className="event-details">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : (
                        <>
                            <div className="row circular-event-details sticky-top m-0 event-details">
                                {NoteEventCircular.data.map((item, index) =>
                                    BindListUI(item, index)
                                )}

                            </div>
                            {
                                NoteEventCircular.data.length !== 0 && IsNoteEventCircularListDateLodding &&
                                <_Skeleton type={_SkeletonType.NoteEventCircular} />
                            }
                        </>
                    )}
                </div>
            </div>

            <>
                <Modal
                    show={showAddEditPopupNotice}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowAddEditPopupNotice(false)}
                    className="modal-theme-color"
                    contentClassName={"background-transparent-color"}
                    dialogClassName={"modal-full-width"}
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() =>
                                data[0].MemberType.toLowerCase() !== "student"
                                    ? params.has("add") === true
                                        ? params.get("add") === "note"
                                            ? history.push("wall")
                                            : setshowAddEditPopupNotice(false)
                                        : setshowAddEditPopupNotice(false)
                                    : setshowAddEditPopupNotice(false)
                            }
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid p-0 Notice-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">{commonService.LabelDisplayText("Notice")}</h1>
                                        {
                                            data[0].MemberType.toLowerCase() !== "student" ? (
                                                IsNoticeSaveing === false ? (
                                                    <>{NoticeData.SaveType.toUpperCase() === "SEND" && commonService.getLoginRoleData("Notice", "IsDelete") ? <>
                                                        <button
                                                            className="btn btn-primary px-3 pr-4 mb-3"
                                                            type="button"
                                                            onClick={() =>
                                                                confirmAlert({
                                                                    title: "Delete",
                                                                    message: "Are you sure delete ?",
                                                                    buttons: [
                                                                        {
                                                                            label: "Yes",
                                                                            onClick: () =>
                                                                                fnDeleteNotice(
                                                                                    NoticeData.NotesID
                                                                                ),
                                                                        },
                                                                        {
                                                                            label: "No",
                                                                        },
                                                                    ],
                                                                })
                                                            }
                                                        >
                                                            {commonService.LabelDisplayText("Delete")}
                                                        </button>
                                                    </> : <>
                                                        <ButtonGroup className="mb-3 button-of-send">
                                                            <button
                                                                className="btn btn-primary px-3 pr-4"
                                                                type="button"
                                                                onClick={() => ButtonClickForSaveNotice("Send")}
                                                            >
                                                                {commonService.LabelDisplayText("Send")}
                                                            </button>
                                                            <DropdownButton
                                                                as={ButtonGroup}
                                                                title=""
                                                                className="btn-transparent"
                                                            >
                                                                <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={() => ButtonClickForSaveNotice("Send")}
                                                                >
                                                                    {commonService.LabelDisplayText("Send")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="2"
                                                                    onClick={() => ButtonClickForSaveNotice("Draft")}
                                                                >
                                                                    {commonService.LabelDisplayText("Draft")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="3"
                                                                    onClick={() =>
                                                                        ButtonClickForSaveNotice("Schedule")
                                                                    }
                                                                >
                                                                    {commonService.LabelDisplayText("Schedule")}
                                                                </Dropdown.Item>
                                                                {NoticeData.SaveType.toUpperCase() !== "SEND" &&
                                                                    NoticeData.SaveType.toUpperCase() !== "" && commonService.getLoginRoleData("Notice", "IsDelete") && (
                                                                        <div>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item
                                                                                eventKey="4"
                                                                                onClick={() =>
                                                                                    confirmAlert({
                                                                                        title: "Delete",
                                                                                        message: "Are you sure delete ?",
                                                                                        buttons: [
                                                                                            {
                                                                                                label: "Yes",
                                                                                                onClick: () =>
                                                                                                    fnDeleteNotice(
                                                                                                        NoticeData.NotesID
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                label: "No",
                                                                                            },
                                                                                        ],
                                                                                    })
                                                                                }
                                                                            >
                                                                                {commonService.LabelDisplayText("DeleteDraft")}
                                                                            </Dropdown.Item>
                                                                        </div>
                                                                    )}
                                                            </DropdownButton>
                                                        </ButtonGroup>
                                                    </>}</>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary px-3 pr-5"
                                                        type="button"
                                                        disabled="disabled"
                                                    >
                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}...
                                                    </button>
                                                )
                                            ) : (
                                                ""
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container classwork-popup-detail">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-9  date-section-area">
                                    <input
                                        type="text"
                                        className={`mt-4 title-textbox ${NoticeData.errors.NoteTitle && "invalid"
                                            }`}
                                        disabled={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "disabled"
                                        }
                                        placeholder="Notice Title"
                                        onChange={(e) =>
                                            setNoticeData({
                                                ...NoticeData,
                                                ["NoteTitle"]: e.target.value,
                                            })
                                        }
                                        defaultValue={NoticeData.NoteTitle}
                                    />
                                    {NoticeData.errors.NoteTitle && (
                                        <span className="error-message">
                                            {NoticeData.errors.NoteTitle}
                                        </span>
                                    )}
                                    <div className="rich-txt-editor-area mt-3">
                                        <Editor
                                            toolbarHidden={
                                                data[0].MemberType.toLowerCase() === "student"
                                            }
                                            readOnly={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "readOnly"
                                            }
                                            editorState={editorNoticesDetails}
                                            toolbar={AppConfigData.toolbarConfig}
                                            onEditorStateChange={setEditorNoticesDetails}
                                        />
                                    </div>
                                    {data[0].MemberType.toLowerCase() !== "student" && (
                                        <DropdownButton
                                            title="Add"
                                            className="mt-3"
                                            variant=" text-editor "
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                className="dropdown-item"
                                                onClick={() => setshowLinkPopupNotice(true)}
                                            >
                                                <i className="fa-solid fa-link-simple"></i> Link
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                className="dropdown-item"
                                                onClick={() => setshowFileSelectPopupNotice(true)}
                                            >
                                                <i className="fa-regular fa-paperclip"></i> File
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="3"
                                                className="dropdown-item"
                                                onClick={() => setshowYoutubeLinkPopupNotice(true)}
                                            >
                                                <i className="fa-brands fa-youtube"></i> YouTube
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    )}

                                    <div className="row">
                                        <div className="col-12 added-popup-data">
                                            {NoticeData.FilesDetails.map((itemFile, indexFile) => (
                                                <div
                                                    className="card Notice-page-card mt-3"
                                                    key={indexFile}
                                                >
                                                    {CreateFilesDetailsIUNotice(itemFile, indexFile)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-3 answer-section homework-popup-section">
                                    <div className="row mt-4">
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Start Date</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                            <DatePicker
                                                showTimeSelect
                                                className={`datepicker homework-input-date ${NoticeData.errors.ActionStartDate && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                selected={NoticeData.ActionStartDate}
                                                minDate={new Date()}
                                                dateFormat={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                                onChange={(date) =>
                                                    setNoticeData({
                                                        ...NoticeData,
                                                        ["ActionStartDate"]: date,
                                                        ["ActionEndDate"]: date,
                                                    })
                                                }
                                                placeholderText={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                            />
                                            {NoticeData.errors.ActionStartDate && (
                                                <span className="error-message">
                                                    {NoticeData.errors.ActionStartDate}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">End Date</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  col-8 mb-2">
                                            <DatePicker
                                                showTimeSelect
                                                className={`datepicker homework-input-date ${NoticeData.errors.ActionEndDate && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                selected={NoticeData.ActionEndDate}
                                                minDate={NoticeData.ActionStartDate}
                                                dateFormat={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                                onChange={(date) =>
                                                    setNoticeData({
                                                        ...NoticeData,
                                                        ["ActionEndDate"]: date,
                                                    })
                                                }
                                                placeholderText={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                            />
                                            {NoticeData.errors.ActionEndDate && (
                                                <span className="error-message">
                                                    {NoticeData.errors.ActionEndDate}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Type</label>
                                        </div>
                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                                <Select
                                                    className={`${NoticeData.errors.DressCode && "invalid"
                                                        }`}
                                                    placeholder={"Select Type"}
                                                    options={DressCode}
                                                    onChange={(e) =>
                                                        setNoticeData({
                                                            ...NoticeData,
                                                            ["DressCode"]: e.value,
                                                        })
                                                    }
                                                    value={DressCode.find(
                                                        (obj) =>
                                                            obj.value.toLocaleUpperCase() ===
                                                            NoticeData.DressCode.toLocaleUpperCase()
                                                    )}
                                                />
                                                {NoticeData.errors.DressCode && (
                                                    <span className="error-message">
                                                        {NoticeData.errors.DressCode}
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                                <input
                                                    type="text"
                                                    className={`title-textbox`}
                                                    disabled={
                                                        data[0].MemberType.toLowerCase() === "student" &&
                                                        "disabled"
                                                    }
                                                    defaultValue={NoticeData.errors.DressCode}
                                                />
                                            </div>
                                        )}

                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                            <>
                                                <div className="col-1"></div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                                    <label className="home-work-label">For</label>
                                                </div>
                                                <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 select-student-subject">
                                                    <div className="ReactMultiSelectCheckboxes selectSubject-popup">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={
                                                                "Select Standard Division"
                                                            }
                                                            options={TeacherStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={StandardDivisionChange}
                                                            value={TeacherStdDiv.filter((obj) =>
                                                                SelectedStdDiv.split(",").includes(obj.value)
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                    <div className="ReactMultiSelectCheckboxes mt-2 select-student-dropdown">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={"Select Student"}
                                                            options={StudentByStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={NoteStudentSelectionChange}
                                                            value={StudentByStdDiv.filter((obj) =>
                                                                NoticeData.TagMemberIDs.split(",").includes(
                                                                    obj.value
                                                                )
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showLinkPopupNotice}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowLinkPopupNotice(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>Add Link</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowLinkPopupNotice(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkNotice({
                                            ...AttachLinkNotice,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkNotice({
                                            ...AttachLinkNotice,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control ${AttachLinkNotice.errors && "invalid"
                                        }`}
                                    placeholder="Add link here"
                                />
                                {AttachLinkNotice.errors && (
                                    <span className="error-message">
                                        {AttachLinkNotice.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowLinkPopupNotice(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddLinkInFilesDetailsNotice()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showYoutubeLinkPopupNotice}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowYoutubeLinkPopupNotice(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("AddYoutube")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowYoutubeLinkPopupNotice(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkNotice({
                                            ...AttachYoutubeLinkNotice,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkNotice({
                                            ...AttachYoutubeLinkNotice,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control  ${AttachYoutubeLinkNotice.errors && "invalid"
                                        }`}
                                    placeholder="Add youtube embed link here"
                                />
                                {AttachYoutubeLinkNotice.errors && (
                                    <span className="error-message">
                                        {AttachYoutubeLinkNotice.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowYoutubeLinkPopupNotice(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddYoutubeLinkInFilesDetailsNotice()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showFileSelectPopupNotice}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowFileSelectPopupNotice(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowFileSelectPopupNotice(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="file"
                                    className={`form-control  ${AttachFilesNotice.errors && "invalid"
                                        }`}
                                    multiple
                                    onChange={(e) =>
                                        setAttachFilesNotice({
                                            ...AttachFilesNotice,
                                            ["link"]: e.target.files,
                                        })
                                    }
                                />
                                {AttachFilesNotice.errors && (
                                    <span className="error-message">
                                        {AttachFilesNotice.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowFileSelectPopupNotice(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddFileSelectInFilesDetailsNotice()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showScheduleDatePopupNotice}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowScheduleDatePopupNotice(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("SelectScheduleDate")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowScheduleDatePopupNotice(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <DatePicker
                                    className={`datepicker Notice-input-date form-control ${NoticeData.errors.DateOfNotes && "invalid"
                                        }`}
                                    selected={NoticeData.DateOfNotes}
                                    minDate={new Date()}
                                    maxDate={NoticeData.ActionStartDate}
                                    dateFormat={data[0].DateFormat_Term}
                                    onChange={(date) =>
                                        setNoticeData({ ...NoticeData, ["DateOfNotes"]: date })
                                    }
                                    placeholderText={data[0].DateFormat_Term}
                                />
                                {NoticeData.errors.DateOfNotes && (
                                    <span className="error-message">
                                        {NoticeData.errors.DateOfNotes}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowScheduleDatePopupNotice(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddEditNotice("Schedule")}
                        >
                            {commonService.LabelDisplayText("Schedule")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </>

            <>
                <Modal
                    show={showAddEditPopupCirculars}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowAddEditPopupCirculars(false)}
                    className="modal-theme-color"
                    contentClassName={"background-transparent-color"}
                    dialogClassName={"modal-full-width"}
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() =>
                                data[0].MemberType.toLowerCase() !== "student"
                                    ? params.has("add") === true
                                        ? params.get("add") === "circular"
                                            ? history.push("wall")
                                            : setshowAddEditPopupCirculars(false)
                                        : setshowAddEditPopupCirculars(false)
                                    : setshowAddEditPopupCirculars(false)
                            }
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid p-0 Notice-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">{commonService.LabelDisplayText("Circular")}</h1>
                                        {
                                            data[0].MemberType.toLowerCase() !== "student" ? (
                                                IsCircularsSaveing === false ? (
                                                    <>{CircularsData.SaveType.toUpperCase() === "SEND" && commonService.getLoginRoleData("Circular", "IsDelete") ? <>
                                                        <button
                                                            className="btn btn-primary px-3 pr-4 mb-3"
                                                            type="button"
                                                            onClick={() =>
                                                                confirmAlert({
                                                                    title: "Delete",
                                                                    message:
                                                                        "Are you sure delete ?",
                                                                    buttons: [
                                                                        {
                                                                            label: "Yes",
                                                                            onClick: () =>
                                                                                fnDeleteCirculars(
                                                                                    CircularsData.CircularID
                                                                                ),
                                                                        },
                                                                        {
                                                                            label: "No",
                                                                        },
                                                                    ],
                                                                })
                                                            }
                                                        >
                                                            {commonService.LabelDisplayText("Delete")}
                                                        </button>
                                                    </> : <>
                                                        <ButtonGroup className="mb-3">
                                                            <button
                                                                className="btn btn-primary px-3 pr-4"
                                                                type="button"
                                                                onClick={() => ButtonClickForSaveCirculars("Send")}
                                                            >
                                                                {commonService.LabelDisplayText("Send")}
                                                            </button>
                                                            <DropdownButton
                                                                as={ButtonGroup}
                                                                title=""
                                                                className="btn-transparent"
                                                            >
                                                                <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={() =>
                                                                        ButtonClickForSaveCirculars("Send")
                                                                    }
                                                                >
                                                                    {commonService.LabelDisplayText("Send")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="2"
                                                                    onClick={() =>
                                                                        ButtonClickForSaveCirculars("Draft")
                                                                    }
                                                                >
                                                                    {commonService.LabelDisplayText("Draft")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="3"
                                                                    onClick={() =>
                                                                        ButtonClickForSaveCirculars("Schedule")
                                                                    }
                                                                >
                                                                    {commonService.LabelDisplayText("Schedule")}
                                                                </Dropdown.Item>
                                                                {CircularsData.SaveType.toUpperCase() !== "SEND" &&
                                                                    CircularsData.SaveType.toUpperCase() !== "" && commonService.getLoginRoleData("Circular", "IsDelete") && (
                                                                        <div>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item
                                                                                eventKey="4"
                                                                                onClick={() =>
                                                                                    confirmAlert({
                                                                                        title: "Delete",
                                                                                        message:
                                                                                            "Are you sure delete ?",
                                                                                        buttons: [
                                                                                            {
                                                                                                label: "Yes",
                                                                                                onClick: () =>
                                                                                                    fnDeleteCirculars(
                                                                                                        CircularsData.CircularID
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                label: "No",
                                                                                            },
                                                                                        ],
                                                                                    })
                                                                                }
                                                                            >
                                                                                {commonService.LabelDisplayText("DeleteDraft")}
                                                                            </Dropdown.Item>
                                                                        </div>
                                                                    )}
                                                            </DropdownButton>
                                                        </ButtonGroup>
                                                    </>}</>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary px-3 pr-5"
                                                        type="button"
                                                        disabled="disabled"
                                                    >
                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}...
                                                    </button>
                                                )
                                            ) : (
                                                ""
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container classwork-popup-detail">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-9 date-section-area">
                                    <input
                                        type="text"
                                        className={`mt-4 title-textbox ${CircularsData.errors.CircularTitle && "invalid"
                                            }`}
                                        disabled={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "disabled"
                                        }
                                        placeholder="Circulars Title"
                                        onChange={(e) =>
                                            setCircularsData({
                                                ...CircularsData,
                                                ["CircularTitle"]: e.target.value,
                                            })
                                        }
                                        defaultValue={CircularsData.CircularTitle}
                                    />
                                    {CircularsData.errors.CircularTitle && (
                                        <span className="error-message">
                                            {CircularsData.errors.CircularTitle}
                                        </span>
                                    )}
                                    <div className="rich-txt-editor-area mt-3">
                                        <Editor
                                            toolbarHidden={
                                                data[0].MemberType.toLowerCase() === "student"
                                            }
                                            readOnly={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "readOnly"
                                            }
                                            editorState={editorCircularssDetails}
                                            toolbar={AppConfigData.toolbarConfig}
                                            onEditorStateChange={setEditorCircularssDetails}
                                        />
                                    </div>
                                    {data[0].MemberType.toLowerCase() !== "student" && (
                                        <DropdownButton
                                            title="Add"
                                            className="mt-3"
                                            variant=" text-editor "
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                className="dropdown-item"
                                                onClick={() => setshowLinkPopupCirculars(true)}
                                            >
                                                <i className="fa-solid fa-link-simple"></i> {commonService.LabelDisplayText("Link")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                className="dropdown-item"
                                                onClick={() => setshowFileSelectPopupCirculars(true)}
                                            >
                                                <i className="fa-regular fa-paperclip"></i>  {commonService.LabelDisplayText("File")}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="3"
                                                className="dropdown-item"
                                                onClick={() => setshowYoutubeLinkPopupCirculars(true)}
                                            >
                                                <i className="fa-brands fa-youtube"></i> {commonService.LabelDisplayText("Youtube")}
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    )}

                                    <div className="row">
                                        <div className="col-12 added-popup-data">
                                            {CircularsData.FilesDetails.map((itemFile, indexFile) => (
                                                <div
                                                    className="card Circulars-page-card mt-3"
                                                    key={indexFile}
                                                >
                                                    {CreateFilesDetailsIUCirculars(itemFile, indexFile)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-3 answer-section homework-popup-section">
                                    <div className="row mt-4">
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3  mb-2 d-flex align-items-center">
                                            <label className="home-work-label">{commonService.LabelDisplayText("Type")}</label>
                                        </div>
                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                                <Select
                                                    className={`${CircularsData.errors.CircularTypeTerm && "invalid"
                                                        }`}
                                                    placeholder={"Select Type"}
                                                    options={CircularTypeTerm}
                                                    onChange={(e) =>
                                                        setCircularsData({
                                                            ...CircularsData,
                                                            ["CircularTypeTerm"]: e.value,
                                                        })
                                                    }
                                                    value={CircularTypeTerm.find(
                                                        (obj) =>
                                                            obj.value.toLocaleUpperCase() ===
                                                            CircularsData.CircularTypeTerm.toLocaleUpperCase()
                                                    )}
                                                />
                                                {CircularsData.errors.CircularTypeTerm && (
                                                    <span className="error-message">
                                                        {CircularsData.errors.CircularTypeTerm}
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                                <input
                                                    type="text"
                                                    className={`title-textbox`}
                                                    disabled={
                                                        data[0].MemberType.toLowerCase() === "student" &&
                                                        "disabled"
                                                    }
                                                    defaultValue={CircularsData.errors.CircularTypeTerm}
                                                />
                                            </div>
                                        )}

                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                            <>
                                                <div className="col-1"></div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                                    <label className="home-work-label">For</label>
                                                </div>
                                                <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 select-student-subject">
                                                    <div className="ReactMultiSelectCheckboxes selectSubject-popup">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={
                                                                "Select Standard Division"
                                                            }
                                                            options={TeacherStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={StandardDivisionChange}
                                                            value={TeacherStdDiv.filter((obj) =>
                                                                SelectedStdDiv.split(",").includes(obj.value)
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                    <div className="ReactMultiSelectCheckboxes mt-2 select-student-dropdown">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={"Select Student"}
                                                            options={StudentByStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={CircularStudentSelectionChange}
                                                            value={StudentByStdDiv.filter((obj) =>
                                                                CircularsData.TagMemberIDs.split(",").includes(
                                                                    obj.value
                                                                )
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showLinkPopupCirculars}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowLinkPopupCirculars(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>Add Link</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowLinkPopupCirculars(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkCirculars({
                                            ...AttachLinkCirculars,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkCirculars({
                                            ...AttachLinkCirculars,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control ${AttachLinkCirculars.errors && "invalid"
                                        }`}
                                    placeholder="Add link here"
                                />
                                {AttachLinkCirculars.errors && (
                                    <span className="error-message">
                                        {AttachLinkCirculars.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowLinkPopupCirculars(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddLinkInFilesDetailsCirculars()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showYoutubeLinkPopupCirculars}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowYoutubeLinkPopupCirculars(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("AddYoutube")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowYoutubeLinkPopupCirculars(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkCirculars({
                                            ...AttachYoutubeLinkCirculars,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkCirculars({
                                            ...AttachYoutubeLinkCirculars,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control  ${AttachYoutubeLinkCirculars.errors && "invalid"
                                        }`}
                                    placeholder="Add youtube embed link here"
                                />
                                {AttachYoutubeLinkCirculars.errors && (
                                    <span className="error-message">
                                        {AttachYoutubeLinkCirculars.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowYoutubeLinkPopupCirculars(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddYoutubeLinkInFilesDetailsCirculars()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showFileSelectPopupCirculars}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowFileSelectPopupCirculars(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowFileSelectPopupCirculars(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="file"
                                    className={`form-control  ${AttachFilesCirculars.errors && "invalid"
                                        }`}
                                    multiple
                                    onChange={(e) =>
                                        setAttachFilesCirculars({
                                            ...AttachFilesCirculars,
                                            ["link"]: e.target.files,
                                        })
                                    }
                                />
                                {AttachFilesCirculars.errors && (
                                    <span className="error-message">
                                        {AttachFilesCirculars.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowFileSelectPopupCirculars(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddFileSelectInFilesDetailsCirculars()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showScheduleDatePopupCirculars}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowScheduleDatePopupCirculars(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("SelectScheduleDate")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowScheduleDatePopupCirculars(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <DatePicker
                                    className={`datepicker notice-input-date form-control ${CircularsData.errors.DateOfCircular && "invalid"
                                        }`}
                                    selected={CircularsData.DateOfCircular}
                                    minDate={new Date()}
                                    dateFormat={data[0].DateFormat_Term}
                                    onChange={(date) =>
                                        setCircularsData({
                                            ...CircularsData,
                                            ["DateOfCircular"]: date,
                                        })
                                    }
                                    placeholderText={data[0].DateFormat_Term}
                                />
                                {CircularsData.errors.DateOfCircular && (
                                    <span className="error-message">
                                        {CircularsData.errors.DateOfCircular}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowScheduleDatePopupCirculars(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddEditCirculars("Schedule")}
                        >
                            {commonService.LabelDisplayText("Schedule")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </>

            <>
                <Modal
                    show={showAddEditPopupEvents}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowAddEditPopupEvents(false)}
                    className="modal-theme-color"
                    contentClassName={"background-transparent-color"}
                    dialogClassName={"modal-full-width"}
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() =>
                                data[0].MemberType.toLowerCase() !== "student"
                                    ? params.has("add") === true
                                        ? params.get("add") === "event"
                                            ? history.push("wall")
                                            : setshowAddEditPopupEvents(false)
                                        : setshowAddEditPopupEvents(false)
                                    : setshowAddEditPopupEvents(false)
                            }
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid p-0 Events-popup-border border-bottom-exam position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">{commonService.LabelDisplayText("Events")}</h1>
                                        {
                                            data[0].MemberType.toLowerCase() !== "student" ? (
                                                IsEventsSaveing === false ? (
                                                    <>{EventsData.SaveType.toUpperCase() === "SEND" && commonService.getLoginRoleData("Event", "IsDelete") ? <>
                                                        <button
                                                            className="btn btn-primary px-3 pr-4 mb-3"
                                                            type="button"
                                                            onClick={() =>
                                                                confirmAlert({
                                                                    title: "Delete",
                                                                    message: "Are you sure delete ?",
                                                                    buttons: [
                                                                        {
                                                                            label: "Yes",
                                                                            onClick: () =>
                                                                                fnDeleteEvents(
                                                                                    EventsData.EventID
                                                                                ),
                                                                        },
                                                                        {
                                                                            label: "No",
                                                                        },
                                                                    ],
                                                                })
                                                            }
                                                        >
                                                            {commonService.LabelDisplayText("Delete")}
                                                        </button>
                                                    </> : <>
                                                        <ButtonGroup className="mb-3">
                                                            <button
                                                                className="btn btn-primary px-3 pr-4"
                                                                type="button"
                                                                onClick={() => ButtonClickForSaveEvents("Send")}
                                                            >
                                                                {commonService.LabelDisplayText("Send")}
                                                            </button>
                                                            <DropdownButton
                                                                as={ButtonGroup}
                                                                title=""
                                                                className="btn-transparent"
                                                            >
                                                                <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={() => ButtonClickForSaveEvents("Send")}
                                                                >
                                                                    {commonService.LabelDisplayText("Send")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="2"
                                                                    onClick={() => ButtonClickForSaveEvents("Draft")}
                                                                >
                                                                    {commonService.LabelDisplayText("Draft")}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    eventKey="3"
                                                                    onClick={() =>
                                                                        ButtonClickForSaveEvents("Schedule")
                                                                    }
                                                                >
                                                                    {commonService.LabelDisplayText("Schedule")}
                                                                </Dropdown.Item>
                                                                {EventsData.SaveType.toUpperCase() !== "SEND" &&
                                                                    EventsData.SaveType.toUpperCase() !== "" && commonService.getLoginRoleData("Event", "IsDelete") && (
                                                                        <div>
                                                                            <Dropdown.Divider />
                                                                            <Dropdown.Item
                                                                                eventKey="4"
                                                                                onClick={() =>
                                                                                    confirmAlert({
                                                                                        title: "Delete",
                                                                                        message: "Are you sure delete ?",
                                                                                        buttons: [
                                                                                            {
                                                                                                label: "Yes",
                                                                                                onClick: () =>
                                                                                                    fnDeleteEvents(
                                                                                                        EventsData.EventID
                                                                                                    ),
                                                                                            },
                                                                                            {
                                                                                                label: "No",
                                                                                            },
                                                                                        ],
                                                                                    })
                                                                                }
                                                                            >
                                                                                {commonService.LabelDisplayText("DeleteDraft")}
                                                                            </Dropdown.Item>
                                                                        </div>
                                                                    )}
                                                            </DropdownButton>
                                                        </ButtonGroup>
                                                    </>}</>
                                                ) : (
                                                    <button
                                                        className="btn btn-primary px-3 pr-5"
                                                        type="button"
                                                        disabled="disabled"
                                                    >
                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}...
                                                    </button>
                                                )
                                            ) : (
                                                ""
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container classwork-popup-detail">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-9 date-section-area">
                                    <input
                                        type="text"
                                        className={`mt-4 title-textbox ${EventsData.errors.Title && "invalid"
                                            }`}
                                        disabled={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "disabled"
                                        }
                                        placeholder="Events Title"
                                        onChange={(e) =>
                                            setEventsData({
                                                ...EventsData,
                                                ["Title"]: e.target.value,
                                            })
                                        }
                                        defaultValue={EventsData.Title}
                                    />
                                    {EventsData.errors.Title && (
                                        <span className="error-message">
                                            {EventsData.errors.Title}
                                        </span>
                                    )}
                                    <div className="rich-txt-editor-area mt-3">
                                        <Editor
                                            toolbarHidden={
                                                data[0].MemberType.toLowerCase() === "student"
                                            }
                                            readOnly={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "readOnly"
                                            }
                                            editorState={editorEventssDetails}
                                            toolbar={AppConfigData.toolbarConfig}
                                            onEditorStateChange={setEditorEventssDetails}
                                        />
                                    </div>
                                    {data[0].MemberType.toLowerCase() !== "student" && (
                                        <DropdownButton
                                            title="Add"
                                            className="mt-3"
                                            variant=" text-editor "
                                        >
                                            <Dropdown.Item
                                                eventKey="1"
                                                className="dropdown-item"
                                                onClick={() => setshowLinkPopupEvents(true)}
                                            >
                                                <i className="fa-solid fa-link-simple"></i> Link
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="2"
                                                className="dropdown-item"
                                                onClick={() => setshowFileSelectPopupEvents(true)}
                                            >
                                                <i className="fa-regular fa-paperclip"></i> File
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                eventKey="3"
                                                className="dropdown-item"
                                                onClick={() => setshowYoutubeLinkPopupEvents(true)}
                                            >
                                                <i className="fa-brands fa-youtube"></i> YouTube
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    )}

                                    <div className="row">
                                        <div className="col-12 added-popup-data">
                                            {EventsData.FilesDetails.map((itemFile, indexFile) => (
                                                <div
                                                    className="card Notice-page-card mt-3"
                                                    key={indexFile}
                                                >
                                                    {CreateFilesDetailsIUEvents(itemFile, indexFile)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-3 answer-section homework-popup-section">
                                    <div className="row mt-4">
                                        <div className="col-1"></div>

                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Location</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                            <input
                                                type="text"
                                                className={` title-textbox ${EventsData.errors.Location && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                placeholder="Location"
                                                onChange={(e) =>
                                                    setEventsData({
                                                        ...EventsData,
                                                        ["Location"]: e.target.value,
                                                    })
                                                }
                                                defaultValue={EventsData.Location}
                                            />
                                            {EventsData.errors.Location && (
                                                <span className="error-message">
                                                    {EventsData.errors.Location}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Reminder Date</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 reminder-datepicker">
                                            <DatePicker
                                                className={`datepicker homework-input-date ${EventsData.errors.ReminderDate && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                selected={EventsData.ReminderDate}
                                                minDate={new Date()}
                                                dateFormat={data[0].DateFormat_Term}
                                                onChange={(date) =>
                                                    setEventsData({
                                                        ...EventsData,
                                                        ["ReminderDate"]: date,
                                                    })
                                                }
                                                placeholderText={data[0].DateFormat_Term}
                                            />
                                            {EventsData.errors.ReminderDate && (
                                                <span className="error-message">
                                                    {EventsData.errors.ReminderDate}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Start Date</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                            <DatePicker
                                                showTimeSelect
                                                className={`datepicker homework-input-date ${EventsData.errors.StartDate && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                selected={EventsData.StartDate}
                                                minDate={new Date()}
                                                dateFormat={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                                onChange={(date) =>
                                                    setEventsData({
                                                        ...EventsData,
                                                        ["StartDate"]: date,
                                                        ["EndDate"]: date,
                                                    })
                                                }
                                                placeholderText={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                            />
                                            {EventsData.errors.StartDate && (
                                                <span className="error-message">
                                                    {EventsData.errors.StartDate}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">End Date</label>
                                        </div>
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                            <DatePicker
                                                showTimeSelect
                                                className={`datepicker homework-input-date ${EventsData.errors.EndDate && "invalid"
                                                    }`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                selected={EventsData.EndDate}
                                                minDate={EventsData.StartDate}
                                                dateFormat={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                                onChange={(date) =>
                                                    setEventsData({ ...EventsData, ["EndDate"]: date })
                                                }
                                                placeholderText={
                                                    data[0].DateFormat_Term +
                                                    " " +
                                                    data[0].TimeFormat_Term
                                                }
                                            />
                                            {EventsData.errors.EndDate && (
                                                <span className="error-message">
                                                    {EventsData.errors.EndDate}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                            <label className="home-work-label">Type</label>
                                        </div>
                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2">
                                                <Select
                                                    className={`${EventsData.errors.EventType && "invalid"
                                                        }`}
                                                    placeholder={"Select Type"}
                                                    options={EventType}
                                                    onChange={(e) =>
                                                        setEventsData({
                                                            ...EventsData,
                                                            ["EventType"]: e.value,
                                                        })
                                                    }
                                                    value={EventType.find(
                                                        (obj) =>
                                                            obj.value.toLocaleUpperCase() ===
                                                            EventsData.EventType.toLocaleUpperCase()
                                                    )}
                                                />
                                                {EventsData.errors.EventType && (
                                                    <span className="error-message">
                                                        {EventsData.errors.EventType}
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8  col-8 mb-2">
                                                <input
                                                    type="text"
                                                    className={`title-textbox`}
                                                    disabled={
                                                        data[0].MemberType.toLowerCase() === "student" &&
                                                        "disabled"
                                                    }
                                                    defaultValue={EventsData.errors.EventType}
                                                />
                                            </div>
                                        )}

                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                            <>
                                                <div className="col-1"></div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 col-3 mb-2 d-flex align-items-center">
                                                    <label className="home-work-label">For</label>
                                                </div>
                                                <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-8 mb-2 select-student-subject">
                                                    <div className="ReactMultiSelectCheckboxes selectSubject-popup">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={
                                                                "Select Standard Division"
                                                            }
                                                            options={TeacherStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={StandardDivisionChange}
                                                            value={TeacherStdDiv.filter((obj) =>
                                                                SelectedStdDiv.split(",").includes(obj.value)
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                    <div className="ReactMultiSelectCheckboxes mt-2  select-student-dropdown">
                                                        <ReactMultiSelectCheckboxes
                                                            width={"100%"}
                                                            placeholderButtonLabel={"Select Student"}
                                                            options={StudentByStdDiv}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            onChange={EventStudentSelectionChange}
                                                            value={StudentByStdDiv.filter((obj) =>
                                                                EventsData.TagMemberIDs.split(",").includes(
                                                                    obj.value
                                                                )
                                                            )}
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showLinkPopupEvents}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowLinkPopupEvents(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>Add Link</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowLinkPopupEvents(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkEvents({
                                            ...AttachLinkEvents,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachLinkEvents({
                                            ...AttachLinkEvents,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control ${AttachLinkEvents.errors && "invalid"
                                        }`}
                                    placeholder="Add link here"
                                />
                                {AttachLinkEvents.errors && (
                                    <span className="error-message">
                                        {AttachLinkEvents.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowLinkPopupEvents(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddLinkInFilesDetailsEvents()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showYoutubeLinkPopupEvents}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowYoutubeLinkPopupEvents(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>Add Youtube</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowYoutubeLinkPopupEvents(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkEvents({
                                            ...AttachYoutubeLinkEvents,
                                            ["name"]: e.target.value,
                                        })
                                    }
                                    className="form-control mb-2"
                                    placeholder="Title"
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setAttachYoutubeLinkEvents({
                                            ...AttachYoutubeLinkEvents,
                                            ["link"]: e.target.value,
                                        })
                                    }
                                    className={`form-control  ${AttachYoutubeLinkEvents.errors && "invalid"
                                        }`}
                                    placeholder="Add youtube embed link here"
                                />
                                {AttachYoutubeLinkEvents.errors && (
                                    <span className="error-message">
                                        {AttachYoutubeLinkEvents.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowYoutubeLinkPopupEvents(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddYoutubeLinkInFilesDetailsEvents()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showFileSelectPopupEvents}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowFileSelectPopupEvents(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>Select File</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowFileSelectPopupEvents(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <input
                                    type="file"
                                    className={`form-control  ${AttachFilesEvents.errors && "invalid"
                                        }`}
                                    multiple
                                    onChange={(e) =>
                                        setAttachFilesEvents({
                                            ...AttachFilesEvents,
                                            ["link"]: e.target.files,
                                        })
                                    }
                                />
                                {AttachFilesEvents.errors && (
                                    <span className="error-message">
                                        {AttachFilesEvents.errors}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowFileSelectPopupEvents(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddFileSelectInFilesDetailsEvents()}
                        >
                            {commonService.LabelDisplayText("Add")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showScheduleDatePopupEvents}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowScheduleDatePopupEvents(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={""}
                >
                    <Modal.Header className="">
                        <h5>{commonService.LabelDisplayText("SelectScheduleDate")}</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowScheduleDatePopupEvents(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <DatePicker
                                    className={`datepicker Notice-input-date form-control ${EventsData.errors.DateOfEvents && "invalid"
                                        }`}
                                    selected={EventsData.DateOfEvents}
                                    minDate={new Date()}
                                    maxDate={EventsData.StartDate}
                                    dateFormat={data[0].DateFormat_Term}
                                    onChange={(date) =>
                                        setEventsData({ ...EventsData, ["DateOfEvents"]: date })
                                    }
                                    placeholderText={data[0].DateFormat_Term}
                                />
                                {EventsData.errors.DateOfEvents && (
                                    <span className="error-message">
                                        {EventsData.errors.DateOfEvents}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            onClick={() => setshowScheduleDatePopupEvents(false)}
                        >
                            {commonService.LabelDisplayText("Close")}
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => AddEditEvents("Schedule")}
                        >
                            {commonService.LabelDisplayText("Schedule")}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={studentreadstatuspopup}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setstudentreadstatuspopup(false)}
                    className="modal-theme-color check-homework-modalpopup"
                    contentClassName={"background-transparent-color"}
                    dialogClassName="modal-full-width"
                >
                    <Modal.Header className="justify-content-end">
                        <button
                            className="poopup-close-button"
                            onClick={() => setstudentreadstatuspopup(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <h1 className="page-header mb-3">
                                                {" "}
                                                {commonService.LabelDisplayText("ReadStatus")}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {readstatus.isdata === true ? (
                                <>
                                    {readstatus.data.length !== 0 ? (
                                        <>
                                            <Table responsive className="upload-homework-table">
                                                <thead>
                                                    <th className="w-100px">{commonService.LabelDisplayText("GR")}</th>
                                                    <th>{commonService.LabelDisplayText("StudentName")}</th>
                                                    <th className="w-100px">{commonService.LabelDisplayText("STDDIV")}</th>
                                                    <th className="w-150px">{commonService.LabelDisplayText("FatherContact")}</th>
                                                    <th className="w-150px">{commonService.LabelDisplayText("MotherContact")}</th>
                                                    <th className="w-130px">{commonService.LabelDisplayText("Status")}</th>
                                                </thead>

                                                <tbody>
                                                    {readstatus.data.map((a) => (
                                                        <>
                                                            <tr>
                                                                <td>{a.GRNo}</td>
                                                                <td>{a.StudentFullName}</td>
                                                                <td>{a.STDDIV}</td>
                                                                <td>{a.FatherContactNo}</td>
                                                                <td>{a.MotherContactNo}</td>
                                                                <td>
                                                                    {a.StatusType === "Readed" ? (
                                                                        <>
                                                                            <label
                                                                                className={`link-label pointer-cursor ml-1`}
                                                                            >
                                                                                {a.StatusType}
                                                                            </label>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <label
                                                                                className={`file-label pointer-cursor ml-1`}
                                                                            >
                                                                                {a.StatusType}
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    ) : (
                                        <>
                                            <_NoDataFound fontsize={"150px"} />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="row col-12">
                                        <_Skeleton
                                            type={_SkeletonType.Table}
                                            count={4}
                                            lineheight={30}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    </Modal.Body>
                </Modal>
            </>
        </>
    );
}

export default NoticeCircularEvents;
