import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory } from "react-router-dom";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import { alertService, AlertType } from "../_services/alert.service";
import Select from "react-select";

function ExamSyllabus() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [IsDataSaveing, setIsDataSaveing] = useState(false);
    const [ExamDetails, setExamDetails] = useState({ IsData: false, data: [] });
    const [
        ShowViewStudentUploadedANSDataPopup,
        setShowViewStudentUploadedANSDataPopup,
    ] = useState(false);
    const [
        ExamStudentUploadedAnsSheetDetails,
        setExamStudentUploadedAnsSheetDetails,
    ] = useState({ IsData: false, data: [] });

    const [ShowMarkEntryPopup, setShowMarkEntryPopup] = useState(false);
    const [ExamMarkEntryData, setExamMarkEntryData] = useState({
        IsData: false,
        data: [],
    });
    const [TeacherStdDiv, setTeacherStdDiv] = useState([]);
    const [GradeMark, setGradeMark] = useState([]);
    const [TotalMarks, setTotalMarks] = useState(null);

    function BindExamDetails(exam) {
        setExamDetails({ IsData: false, data: [] });
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                stdExamSubScheduleID: exam.StdExamSubScheduleID,
                standardID: exam.StandardID,
                requestFrom: "web",
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/getalldetailsofexam", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExamDetails({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                    history.push("/exam");
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function StartExam(exam) {
        history.push({
            pathname: "/examquestion",
            state: exam,
        });
    }

    function ViewStudentAnswerSheet(exam) {
        setShowViewStudentUploadedANSDataPopup(true);
        setExamStudentUploadedAnsSheetDetails({ IsData: false, data: [] });
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                StdExamSubScheduleID: exam.StdExamSubScheduleID,
                SubjectID: exam.SubjectID,
                StdExamSubPassingMarkID: exam.StdExamSubPassingMarkID,
                ExamID: exam.ExamID,
                StandardID: exam.StandardID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/exam/getsubjectiveexamuploadedanssheet",
            reqExam
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExamStudentUploadedAnsSheetDetails({
                        IsData: true,
                        data: json.data,
                    });
                    //setExamStudentUploadedAnsSheetDetails({ IsData: true, data: commonService.groupArrayOfObjects(json.data, "DivisionName") });
                } else if (json.status_code === 0) {
                    setExamStudentUploadedAnsSheetDetails({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnUpdateStandardExamSubStudPaperAnsDetails(item) {
        document
            .getElementById("MarksObtained" + item.StandardExamSubStudPaperAnsID)
            .classList.remove("invalid");
        document
            .getElementById(item.StandardExamSubStudPaperAnsID)
            .classList.remove("background-green");
        document
            .getElementById(item.StandardExamSubStudPaperAnsID)
            .classList.remove("background-orange");

        let MarksObtained = document.getElementById(
            "MarksObtained" + item.StandardExamSubStudPaperAnsID
        ).value;
        let IsChecked = document.getElementById(
            "IsChecked" + item.StandardExamSubStudPaperAnsID
        ).checked;
        let TeacherNote = document.getElementById(
            "TeacherNote" + item.StandardExamSubStudPaperAnsID
        ).value;
        if (parseFloat(MarksObtained)) {
            const reqExam = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    standardExamSubStudPaperAnsID: item.StandardExamSubStudPaperAnsID,
                    marksObtained: parseFloat(MarksObtained),
                    isChecked: IsChecked,
                    teacherNote: TeacherNote,
                    RequestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/exam/standardexamsubstudpaperansupdate",
                reqExam
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        document
                            .getElementById(item.StandardExamSubStudPaperAnsID)
                            .classList.add("background-green");
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                        document
                            .getElementById(item.StandardExamSubStudPaperAnsID)
                            .classList.add("background-orange");
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            document
                .getElementById("MarksObtained" + item.StandardExamSubStudPaperAnsID)
                .classList.add("invalid");
        }
    }
    // Weekly Exam Mark Entry

    function fnUpdateWeekly_ExamMarks(item) {
        let lstMarkDetails = [];
        let AllDataArevalid = true;
        item.student.forEach((element) => {
            let MarksObtained = document.getElementById(
                "Weekly_MarksObtained" + element.StudentID
            ).value;
            let TotalNoCorrectQuestions = document.getElementById(
                "Weekly_TotalNoCorrectQuestions" + element.StudentID
            ).value;
            let WrongQuestion = document.getElementById(
                "Weekly_TotalWrongQuestion" + element.StudentID
            ).value;
            let TeacherNote = document.getElementById(
                "Weekly_TeacherNote" + element.StudentID
            ).value;
            //#region  Validation

            if (MarksObtained !== "" && MarksObtained !== "0") {
                if (parseInt(MarksObtained)) {
                    document
                        .getElementById("Weekly_MarksObtained" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById("Weekly_MarksObtained" + element.StudentID)
                        .classList.add("invalid");
                }
            } else {
                document
                    .getElementById("Weekly_MarksObtained" + element.StudentID)
                    .classList.add("invalid");
            }
            if (TotalMarks !== null && TotalMarks !== "") {
                if (MarksObtained !== "" && MarksObtained <= TotalMarks) {
                    document
                        .getElementById("Weekly_MarksObtained" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById("Weekly_MarksObtained" + element.StudentID)
                        .classList.add("invalid");
                }
            }
            if (TotalNoCorrectQuestions !== "" && TotalNoCorrectQuestions !== "0") {
                if (parseInt(TotalNoCorrectQuestions)) {
                    document
                        .getElementById(
                            "Weekly_TotalNoCorrectQuestions" + element.StudentID
                        )
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById(
                            "Weekly_TotalNoCorrectQuestions" + element.StudentID
                        )
                        .classList.add("invalid");
                }
            } else {
                document
                    .getElementById("Weekly_TotalNoCorrectQuestions" + element.StudentID)
                    .classList.remove("invalid");
                TotalNoCorrectQuestions = 0;
            }

            if (WrongQuestion !== "" && WrongQuestion !== "0") {
                if (parseInt(WrongQuestion)) {
                    document
                        .getElementById("Weekly_TotalWrongQuestion" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById("Weekly_TotalWrongQuestion" + element.StudentID)
                        .classList.add("invalid");
                }
            } else {
                document
                    .getElementById("Weekly_TotalWrongQuestion" + element.StudentID)
                    .classList.remove("invalid");
                WrongQuestion = 0;
            }
            //#endregion Validation
            lstMarkDetails.push({
                StudentID: element.StudentID,
                totalCorrectQuestion: parseInt(TotalNoCorrectQuestions),
                totalWrongQuestion: parseInt(WrongQuestion),
                totalNegativeMarks: 0,
                netObtainedMarks: parseFloat(MarksObtained),
                totalObtainedMarks: parseFloat(MarksObtained),
                teacherNote: TeacherNote,
            });
        });

        if (AllDataArevalid) {
            setIsDataSaveing(true)
            let QA = {
                spExamScheduleSetupID: ExamDetails.data[0].ExamScheduleSetupID,
                spExamTypeID: ExamDetails.data[0].ExamTypeID,
                spExamScheduleNameID: ExamDetails.data[0].ExamID,
                standardID: ExamDetails.data[0].StandardID,
                divisionID: ExamMarkEntryData.data.examDetails[0].DivisionID,
                shiftID: ExamDetails.data[0].ShiftID,
                subjectID: ExamDetails.data[0].SubjectID,
                batchID: data[0].BatchID,
                clientID: data[0].ClientID,
                instituteID: data[0].InstituteID,
                userID: data[0].UserID,
                examDate: ExamDetails.data[0].DateOfExam,
                startTime: ExamDetails.data[0].StartTime,
                endTime: ExamDetails.data[0].EndTime,
                SPExamSubjectScheduleID: ExamDetails.data[0].StdExamSubScheduleID,
                lstMarkDetails: lstMarkDetails,
                requestFrom: "web",
            };

            const reqExam = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify(QA),
            };
            fetch(AppConfigData.APIBasePath + "v1/exam/weeklymarkentry", reqExam)
                .then((response) => response.json())
                .then(function (json) {
                    setIsDataSaveing(false)
                    if (json.status_code === 1) {
                        setShowMarkEntryPopup(false);
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    // Term  Exam Mark Entry

    function fnUpdateTerm_ExamMarks(item) {
        let lstMarkDetails = [];
        let AllDataArevalid = true;
        let MarksObtained;
        let GradeID = null;
        item.student.forEach((element) => {
            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                MarksObtained = document.getElementById(
                    "Term_Marks" + element.StudentID
                ).value;
            } else {
                MarksObtained = 0;
                GradeID = document.getElementById(
                    "Term_Grade_Hidden" + element.StudentID
                ).value;
            }

            let TeacherNote = document.getElementById(
                "Term_TeacherNote" + element.StudentID
            ).value;

            //#region  Validation

            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                if (MarksObtained !== "" && MarksObtained !== "0") {
                    if (parseInt(MarksObtained)) {
                        document
                            .getElementById("Term_Marks" + element.StudentID)
                            .classList.remove("invalid");
                    } else {
                        AllDataArevalid = false;
                        document
                            .getElementById("Term_Marks" + element.StudentID)
                            .classList.add("invalid");
                    }
                } else {
                    document
                        .getElementById("Term_Marks" + element.StudentID)
                        .classList.add("invalid");
                }
            } else {
                if (GradeID !== "" && GradeID !== "0") {
                    document
                        .getElementById("Term_Grade" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById("Term_Grade" + element.StudentID)
                        .classList.add("invalid");
                }
            }
            if (TotalMarks !== null && TotalMarks !== "") {
                if (MarksObtained !== "" && MarksObtained <= TotalMarks) {
                    document
                        .getElementById("Term_Marks" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    if (MarksObtained !== "10001" && MarksObtained !== "10002") {
                        AllDataArevalid = false;
                        document
                            .getElementById("Term_Marks" + element.StudentID)
                            .classList.add("invalid");
                    }
                }
            }
            //#endregion Validation
            let valueAttendance = false;
            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                if (MarksObtained === "10001") {
                    valueAttendance = true;
                } else {
                    valueAttendance = false;
                }
            }

            lstMarkDetails.push({
                examSubStudID: element.ExamSubStudID,
                marksObtained: parseFloat(MarksObtained),
                gradeID: GradeID,
                teacherNote: TeacherNote,
                isAbsent: valueAttendance,
                examEnrollStdID: element.ExamEnrollStdID,
                stdExamSubjectScheduleID: element.StdExamSubjectScheduleID,
                studentID: element.StudentID,
                stdExamSubPassingMarkID: ExamDetails.data[0].StdExamSubPassingMarkID,
                stdExaScheduleID: element.StdExaScheduleID,
            });
        });

        let absentGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "Absent")
        );
        let copyCaseGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "Copy Case")
        );
        let sickLeaveGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "SEEK")
        );

        if (AllDataArevalid) {
            setIsDataSaveing(true)
            let QA = {
                shiftID: ExamDetails.data[0].ShiftID,
                batchID: data[0].BatchID,
                standardID: ExamDetails.data[0].StandardID,
                divisionID: ExamMarkEntryData.data.examDetails[0].DivisionID,
                clientID: data[0].ClientID,
                instituteID: data[0].InstituteID,
                absentGradeID: absentGradeID.GradeSetupID,
                copyCaseGradeID: copyCaseGradeID.GradeSetupID,
                sickLeaveGradeID: sickLeaveGradeID.GradeSetupID,
                lstMarkDetails: lstMarkDetails,
                requestFrom: "web",
            };

            const reqExam = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify(QA),
            };

            fetch(AppConfigData.APIBasePath + "v1/exam/termandcbsemarkentry", reqExam)
                .then((response) => response.json())
                .then(function (json) {
                    setIsDataSaveing(false)
                    if (json.status_code === 1) {
                        setShowMarkEntryPopup(false);
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    // Semester  Exam Mark Entry

    function fnUpdateSemester_ExamMarks(item) {
        
        let lstMarkDetails = [];
        let AllDataArevalid = true;
        let MarksObtained;
        let ConvertedMarksObtained;
        let GradeID = null;
        item.student.forEach((element) => {
            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                MarksObtained = document.getElementById(
                    "Semester_Marks" + element.StudentID
                ).value;

                ConvertedMarksObtained = document.getElementById(
                    "Semester_ConvertedMarks" + element.StudentID
                ).value;
            } else {
                MarksObtained = 0;
                ConvertedMarksObtained = 0;

                GradeID = document.getElementById(
                    "Semester_Grade_Hidden" + element.StudentID
                ).value;
            }

            let TeacherNote = document.getElementById(
                "Semester_TeacherNote" + element.StudentID
            ).value;
            //#region  Validation
            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                if (MarksObtained !== "" && MarksObtained !== "0") {
                    if (parseInt(MarksObtained)) {
                        document
                            .getElementById("Semester_Marks" + element.StudentID)
                            .classList.remove("invalid");
                    } else {
                        document
                            .getElementById("Semester_Marks" + element.StudentID)
                            .classList.add("invalid");
                    }
                } else {
                    document
                        .getElementById("Semester_Marks" + element.StudentID)
                        .classList.add("invalid");
                }
            } else {
                if (GradeID !== "" && GradeID !== "0") {
                    document
                        .getElementById("Semester_Grade" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    AllDataArevalid = false;
                    document
                        .getElementById("Semester_Grade" + element.StudentID)
                        .classList.add("invalid");
                }
            }
            if (TotalMarks !== null && TotalMarks !== "") {
                if (MarksObtained !== "" && MarksObtained <= TotalMarks) {
                    document
                        .getElementById("Semester_Marks" + element.StudentID)
                        .classList.remove("invalid");
                } else {
                    if (MarksObtained !== "10001" && MarksObtained !== "10002") {
                        AllDataArevalid = false;
                        document
                            .getElementById("Semester_Marks" + element.StudentID)
                            .classList.add("invalid");
                    }
                }
            }

            //#endregion Validation
            let valueAttendance = false;
            if (ExamMarkEntryData.data.student[0].IsGradeSystem === false) {
                if (MarksObtained === "10001") {
                    valueAttendance = true;
                } else {
                    valueAttendance = false;
                }
            }

            lstMarkDetails.push({
                examSubStudID: element.ExamSubStudID,
                marksObtained: parseFloat(MarksObtained),
                gradeID: GradeID,
                teacherNote: TeacherNote,
                isAbsent: valueAttendance,
                examEnrollStdID: element.ExamEnrollStdID,
                stdExamSubjectScheduleID: element.StdExamSubjectScheduleID,
                studentID: element.StudentID,
                stdExaScheduleID: element.StdExaScheduleID,
                convertedMarks: parseFloat(ConvertedMarksObtained),
                stdExamSubID: element.StdExamSubID,
                stdExamSubPassingMarkID: ExamDetails.data[0].StdExamSubPassingMarkID,
            });
        });

        let absentGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "Absent")
        );
        let copyCaseGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "Copy Case")
        );
        let sickLeaveGradeID = ExamMarkEntryData.data.otherDetails.find(
            (e) => (e.value = "SEEK")
        );

        if (AllDataArevalid) {
            setIsDataSaveing(true)
            let QA = {
                shiftID: ExamDetails.data[0].ShiftID,
                batchID: data[0].BatchID,
                standardID: ExamDetails.data[0].StandardID,
                divisionID: ExamMarkEntryData.data.examDetails[0].DivisionID,
                clientID: data[0].ClientID,
                instituteID: data[0].InstituteID,
                absentGradeID: absentGradeID.GradeSetupID,
                copyCaseGradeID: copyCaseGradeID.GradeSetupID,
                sickLeaveGradeID: sickLeaveGradeID.GradeSetupID,
                lstMarkDetails: lstMarkDetails,
                requestFrom: "web",
            };

            const reqExam = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify(QA),
            };

            fetch(AppConfigData.APIBasePath + "v1/exam/semestermarkentry", reqExam)
                .then((response) => response.json())
                .then(function (json) {
                    setIsDataSaveing(false)
                    if (json.status_code === 1) {
                        setShowMarkEntryPopup(false);
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function ConvertedMarkCalculate(e, id, IsRounding) {
        let ConvertedMarkCal = 0;
        if (e.target.value !== "") {
            ConvertedMarkCal = (e.target.value * ExamDetails.data[0].PassingMarks) / ExamDetails.data[0].TotakMarks;
            if (IsRounding) {
                ConvertedMarkCal = Math.round(ConvertedMarkCal);
            }

            if (e.target.value === "10001" || e.target.value === "10002") {
                ConvertedMarkCal = e.target.value;
            }
        }
        document.getElementById("Semester_ConvertedMarks" + id).value =
            ConvertedMarkCal;
    }
    function setfocus(e, index) {
        if (e.which === 13) {
            let nextindex = index + 1;
            let list = document.getElementsByClassName("setfocus_" + nextindex);
            if (list !== null && list.length !== 0) {
                list[0].focus();
            }
            // document.getElementById("Semester_Marks" + id).eq(index).focus();
        }
    }
    function GoToMarkEntry(exam) {
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                standardID: exam.StandardID,
                shiftID: exam.ShiftID,
                requestFrom: "Web",
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/home/get_standarddivision", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                //#region DivisionData set
                if (json.status_code === 1) {
                    let Options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        Options.push({
                            value: json.data[i].DivisionID,
                            label: json.data[i].DivisionName,
                        });
                    }

                    setTeacherStdDiv(Options);
                }

                //#endregion
                if (json.status_code === 1) {
                    setShowMarkEntryPopup(true);
                    getExamMarkEntryStudentList(exam, json.data[0].DivisionID);
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getExamMarkEntryStudentList(exam, divisionid) {
        setExamMarkEntryData({ IsData: false, data: [] });
        setTotalMarks(null);
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                shiftID: exam.ShiftID,
                standardID: exam.StandardID,
                divisionID: divisionid,
                examID: exam.ExamID,
                examTypeID: exam.ExamTypeID,
                examdate: exam.DateOfExam,
                subjectID: exam.SubjectID,
                startTime: exam.StartTime,
                endTime: exam.EndTime,
                stdExamSubPassingMarkID: exam.StdExamSubPassingMarkID,
                stdExamSubID: exam.StdExamSubID,
                examOfTypeTerm: exam.ExamOfTypeTerm,
                requestFrom: "Web",
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/getmarkentrydetails", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExamMarkEntryData({ IsData: true, data: json.data });
                    setTotalMarks(ExamDetails.data[0].TotakMarks);

                    // Term Grade Dropdown Set
                    let GradeOptions = [];

                    for (let i = 0; i < json.data.gradeDetails.length; i++) {
                        GradeOptions.push({
                            label: json.data.gradeDetails[i].Grade,
                            value: json.data.gradeDetails[i].GradeSetupID,
                        });
                    }
                    setGradeMark(GradeOptions);
                } else if (json.status_code === 0) {
                    setExamMarkEntryData({ IsData: true, data: [] });
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnGradeChangeInMarkEntry(e, id) {
        document.getElementById("Term_Grade_Hidden" + id).value = e;
    }
    function fnGradeChangeInSemesterMarkEntry(e, id) {
        document.getElementById("Semester_Grade_Hidden" + id).value = e;
    }
    React.useEffect(() => {
        if (commonService.getLoginRoleData("Exam", "IsView")) {
            if (history.location.state !== undefined) {
                BindExamDetails(history.location.state);
            } else {
                history.push("/exam");
            }
        } else {
            history.push("/access");
        }
    }, []);
    return (
        <>
            {!ExamDetails.IsData ? (
                <>
                    <h1 className="page-header my-4 width-20per"></h1>
                </>
            ) : (
                <>
                    <h1 className="page-header my-4">
                        {ExamDetails.data[0].StandardName} -{" "}
                        {ExamDetails.data[0].SubjectName}
                    </h1>
                </>
            )}

            <Tabs
                defaultActiveKey="Instructions"
                transition={false}
                id="noanim-tab-example"
                className="mb-3 timetable-liveclass"
            >
                <Tab eventKey="Instructions" title="Instructions">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <div className="card exam-card-main">
                                    <div className="card-body">
                                        {!ExamDetails.IsData ? (
                                            <>
                                                <p className="details-info width-50per">
                                                    {" "}
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                                <p className="subject-name blue-text width-30per">
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="details-info">{commonService.LabelDisplayText("Duration")}</p>
                                                <p className="subject-name blue-text">
                                                    {ExamDetails.data[0].TotalMinutes}{commonService.LabelDisplayText("min")}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="card exam-card-main">
                                    <div className="card-body">
                                        {!ExamDetails.IsData ? (
                                            <>
                                                <p className="details-info width-50per">
                                                    {" "}
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                                <p className="subject-name blue-text width-30per">
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="details-info">{commonService.LabelDisplayText("Question")} </p>
                                                <p className="subject-name blue-text">
                                                    {ExamDetails.data[0].TotalQuestions}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="card exam-card-main">
                                    <div className="card-body">
                                        {!ExamDetails.IsData ? (
                                            <>
                                                <p className="details-info width-50per">
                                                    {" "}
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                                <p className="subject-name blue-text width-30per">
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="details-info">{commonService.LabelDisplayText("Marks")}</p>
                                                <p className="subject-name blue-text">
                                                    {ExamDetails.data[0].TotakMarks}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="card exam-card-main">
                                    <div className="card-body">
                                        {!ExamDetails.IsData ? (
                                            <>
                                                <p className="details-info width-50per">
                                                    {" "}
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                                <p className="subject-name blue-text width-30per">
                                                    <_Skeleton type={_SkeletonType.Line} count={1} />
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="details-info">{commonService.LabelDisplayText("ExamTime")}</p>
                                                <p className="subject-name blue-text">
                                                    {ExamDetails.data[0].StartTime}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Syllabus" title="Syllabus">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 instruction-exam">
                                {!ExamDetails.IsData ? (
                                    <>
                                        <_Skeleton type={_SkeletonType.Line} count={5} />
                                    </>
                                ) : (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: ExamDetails.data[0].Syllabus,
                                        }}
                                    ></p>
                                )}
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
            <div className="d-flex align-items-center justify-content-center mt-10">
                {data[0].MemberType.toLowerCase() === "student" ? (

                    !ExamDetails.IsData ? (
                        <a className="btn btn-primary mt-10">
                            <Spinner animation="border" size="sm" />{commonService.LabelDisplayText("Loading")}
                        </a>
                    ) : ExamDetails.data[0].IsOnlineExam && commonService.getLoginRoleData("Exam", "IsCreate") ? (
                        <a
                            onClick={(e) => StartExam(ExamDetails.data[0])}
                            className="btn btn-primary mt-10"
                        >
                            Start Exam
                        </a>
                    ) : (
                        <></>
                    )
                ) : !ExamDetails.IsData ? (
                    <a className="btn btn-primary mt-10">
                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Loading")}
                    </a>
                ) : ExamDetails.data[0].IsOnlineExam &&
                    !(new Date(ExamDetails.data[0].DateOfExam) >= new Date()) ? (
                    <>
                        <a
                            onClick={(e) => GoToMarkEntry(ExamDetails.data[0])}
                            className="btn btn-primary mt-10"
                        >
                            Go To Mark Entry
                        </a>
                        {ExamDetails.data[0].PaperType.toLowerCase() === "subjective" && (
                            <>
                                <a
                                    onClick={(e) => ViewStudentAnswerSheet(ExamDetails.data[0])}
                                    className="btn btn-primary mt-10 ml-5"
                                >
                                    {commonService.LabelDisplayText("ViewStudentAnswerSheet")}
                                </a>
                                <a
                                    target="_blank"
                                    className="btn btn-primary mt-10 ml-5"
                                    href={
                                        AppConfigData.ERPFileBasePath.replace("/Files/", "") +
                                        ExamDetails.data[0].PaperFilePath
                                    }
                                >
                                    {commonService.LabelDisplayText("ViewPaper")}
                                </a>
                            </>
                        )}
                    </>
                ) : !(new Date(ExamDetails.data[0].DateOfExam) >= new Date()) ? (
                    <a
                        onClick={(e) => GoToMarkEntry(ExamDetails.data[0])}
                        className="btn btn-primary mt-10"
                    >
                        {commonService.LabelDisplayText("GoToMarkEntry")}
                    </a>
                ) : (
                    <></>
                )}
            </div>
            <Modal
                show={ShowViewStudentUploadedANSDataPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowViewStudentUploadedANSDataPopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">
                                        {commonService.LabelDisplayText("StudentListWithAnswerSheet")}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowViewStudentUploadedANSDataPopup(false)}
                    >
                        {" "}
                        <i className="icon icon-fi-rr-cross"></i>{" "}
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {!ExamStudentUploadedAnsSheetDetails.IsData ? (
                        <div className="row col-12">
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        </div>
                    ) : ExamStudentUploadedAnsSheetDetails.data.length === 0 ? (
                        <div className="col-12">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : (
                        <Table responsive className="mark-entry-table">
                            <thead>
                                <tr>
                                    <th className="w-50px">{commonService.LabelDisplayText("Roll")}</th>
                                    <th>{commonService.LabelDisplayText("StudentName")}</th>
                                    <th className="w-50px">{commonService.LabelDisplayText("Seat")}</th>
                                    <th className="w-100px">{commonService.LabelDisplayText("AnsSheet")}</th>
                                    <th className="w-100px">{commonService.LabelDisplayText("UploadOn")}</th>
                                    <th className="w-100px">{commonService.LabelDisplayText("Mark")}</th>
                                    <th className="w-50px">{commonService.LabelDisplayText("Checked")}</th>
                                    <th>{commonService.LabelDisplayText("TeacherNote")}</th>
                                    <th className="w-50px">{commonService.LabelDisplayText("Action")}</th>
                                    <th>{commonService.LabelDisplayText("Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ExamStudentUploadedAnsSheetDetails.data.map((item, index) => (
                                    <tr key={index} id={item.StandardExamSubStudPaperAnsID}>
                                        <td>{item.RollNo}</td>
                                        <td>{item.StudentFullName}</td>
                                        <td>{item.SeatNo}</td>
                                        <td className={"text-center"}>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" &&
                                                item.FilePath !== null &&
                                                item.FilePath !== "" ? (
                                                <>
                                                    <a
                                                        onClick={(e) =>
                                                            viewFilePopupService.viewFilePopup({
                                                                data: [
                                                                    {
                                                                        Name:
                                                                            item.StudentFullName +
                                                                            " (" +
                                                                            item.RollNo +
                                                                            ")",
                                                                        FilePath: item.FilePath,
                                                                        OrderNo: 1,
                                                                        FileType: "PDF",
                                                                    },
                                                                ],
                                                            })
                                                        }
                                                        className="btn btn-primary"
                                                    >
                                                        {commonService.LabelDisplayText("View")}
                                                    </a>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" &&
                                                item.UploadOn !== null &&
                                                item.UploadOn !== "" ? (
                                                <>{commonService.getDateInFormat(item.UploadOn)}</>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        id={
                                                            "MarksObtained" +
                                                            item.StandardExamSubStudPaperAnsID
                                                        }
                                                        className="title-textbox"
                                                        defaultValue={item.MarksObtained}
                                                    />{" "}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td className={"text-center"}>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" ? (
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        id={
                                                            "IsChecked" + item.StandardExamSubStudPaperAnsID
                                                        }
                                                        defaultChecked={item.IsChecked}
                                                    />{" "}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        id={
                                                            "TeacherNote" + item.StandardExamSubStudPaperAnsID
                                                        }
                                                        className="title-textbox"
                                                        defaultValue={item.TeacherNote}
                                                    />{" "}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td className={"text-center"}>
                                            {item.StandardExamSubStudPaperAnsID !== null &&
                                                item.StandardExamSubStudPaperAnsID !==
                                                "00000000-0000-0000-0000-000000000000" && commonService.getLoginRoleData("Exam", "IsCreate") ? (
                                                <a
                                                    onClick={(e) =>
                                                        fnUpdateStandardExamSubStudPaperAnsDetails(item)
                                                    }
                                                    className="btn btn-primary"
                                                >
                                                    {commonService.LabelDisplayText("Save")}
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </td>
                                        <td className={"text-center"}>
                                            {item.AttemptedOn !== null && item.AttemptedOn !== "" ? (
                                                item.UploadOn !== null && item.UploadOn !== "" ? (
                                                    item.FilePath !== null && item.FilePath !== "" ? (
                                                        <>{commonService.LabelDisplayText("SubmitedWithFile")}</>
                                                    ) : (
                                                        <>{commonService.LabelDisplayText("SubmitedWithoutFile")}</>
                                                    )
                                                ) : (
                                                    <>{commonService.LabelDisplayText("Appear")}</>
                                                )
                                            ) : (
                                                <>{commonService.LabelDisplayText("NotAppear")}</>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={ShowMarkEntryPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowMarkEntryPopup(false)}
                className="modal-theme-color check-homework-modalpopup  check-mark-entry-popup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">{commonService.LabelDisplayText("StudentMarkEntry")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowMarkEntryPopup(false)}
                    >
                        {" "}
                        <i className="icon icon-fi-rr-cross"></i>{" "}
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="background-theme-color  notice-circular-event-searchbar attendence-header-section">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section  first-attendence-section">
                                    <div className="row align-items-center m-0 selection-attendence-row">
                                        <div className="col-12 p-0">
                                            <div className="row">
                                                <div className="col-6">
                                                    <Select
                                                        className="attendence-select"
                                                        placeholder={commonService.LabelDisplayText("SelectStandardDivision")}
                                                        options={TeacherStdDiv}
                                                        defaultValue={TeacherStdDiv[0]}
                                                        onChange={(e) =>
                                                            getExamMarkEntryStudentList(
                                                                ExamDetails.data[0],
                                                                e.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-6 mark-entry-detail">
                                                    <p className="m-0">
                                                        {commonService.LabelDisplayText("PleaseEnterMarkForAbsent10001CopyCase10002")}
                                                    </p>
                                                    {TotalMarks !== null ? (
                                                        <p className="m-0">{commonService.LabelDisplayText("TotalMark")} : {TotalMarks}</p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Weekly Exam Mark Entry */}

                    {!ExamMarkEntryData.IsData ? (
                        <div className="row col-12">
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        </div>
                    ) : ExamMarkEntryData.data.length === 0 ? (
                        <div className="col-12">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : ExamDetails.data[0].ExamOfTypeTerm === "Weekly Test" ? (
                        <>
                            {ExamMarkEntryData.data.otherDetails[0].IsClassRoomMarkLock ? (<div className="error-message text-center"> You are not able to update mark because of marks are already lock.</div>) : ""}
                            <Table responsive className="mark-entry-table">
                                <thead>
                                    <tr>
                                        <th className="w-50px">{commonService.LabelDisplayText("Roll")}</th>
                                        <th className="w-75px">{commonService.LabelDisplayText("Seat")}</th>
                                        <th>{commonService.LabelDisplayText("StudentName")}</th>
                                        <th className="w-250px">{commonService.LabelDisplayText("NetObtainedMarks")}</th>
                                        <th className="w-250px">{commonService.LabelDisplayText("NoOfCorrectAnswer")}</th>
                                        <th className="w-250px">{commonService.LabelDisplayText("NoOfInCorrectAnswer")}</th>
                                        <th className="w-250px">{commonService.LabelDisplayText("TeacherNote")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ExamMarkEntryData.data.student.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.RollNo}</td>
                                            <td>{item.ExamSeatNo}</td>
                                            <td>{item.StudentName}</td>
                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={"Weekly_MarksObtained" + item.StudentID}
                                                        onKeyDown={(e) => setfocus(e, index)}
                                                        defaultValue={
                                                            item.IsAbsent || item.IsCopyCase
                                                                ? item.IsAbsent === true
                                                                    ? 10001
                                                                    : item.IsCopyCase === true
                                                                        ? 10002
                                                                        : item.NetObtainedMarks
                                                                : item.NetObtainedMarks
                                                        }
                                                        className={`title-textbox setfocus_${index}`}
                                                    />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={
                                                            "Weekly_TotalNoCorrectQuestions" + item.StudentID
                                                        }
                                                        defaultValue={item.TotalNoCorrectQuestions}
                                                        className="title-textbox"
                                                    />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={"Weekly_TotalWrongQuestion" + item.StudentID}
                                                        defaultValue={item.TotalNoOfWrongQuestions}
                                                        className="title-textbox"
                                                    />
                                                }
                                            </td>

                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={"Weekly_TeacherNote" + item.StudentID}
                                                        defaultValue={item.TeacherNote}
                                                        className="title-textbox"
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {
                                commonService.getLoginRoleData("Exam", "IsCreate") ?
                                    IsDataSaveing === false ?
                                        (!ExamMarkEntryData.data.otherDetails[0].IsClassRoomMarkLock ?
                                            <a
                                                onClick={(e) =>
                                                    fnUpdateWeekly_ExamMarks(ExamMarkEntryData.data)
                                                }
                                                className="btn btn-primary"
                                            >
                                                {commonService.LabelDisplayText("Save")}
                                            </a> : "")
                                        :
                                        <a
                                            className="btn btn-primary"
                                        >
                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                        </a>
                                    : <></>
                            }


                        </>
                    ) : ExamDetails.data[0].ExamOfTypeTerm === "Term Exam" ||
                        ExamDetails.data[0].ExamOfTypeTerm === "CBSC Exam" ? (
                        <>
                            {ExamMarkEntryData.data.isClassRoomMarkLock ? (<div className="error-message text-center"> You are not able to update mark because of marks are already lock.</div>) : ""}
                            <Table responsive className="mark-entry-table">
                                <thead>
                                    <tr>
                                        <th className="w-50px">{commonService.LabelDisplayText("Roll")}</th>
                                        <th className="w-75px">{commonService.LabelDisplayText("Seat")}</th>
                                        <th>{commonService.LabelDisplayText("StudentName")}</th>
                                        {ExamMarkEntryData.data.student[0].IsGradeSystem ===
                                            false ? (
                                            <th className="w-100px">{commonService.LabelDisplayText("Marks")}</th>
                                        ) : (
                                            <th className="w-100px">{commonService.LabelDisplayText("Grade")}</th>
                                        )}
                                        <th className="w-100px">{commonService.LabelDisplayText("TeacherNote")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ExamMarkEntryData.data.student.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.RollNo}</td>
                                            <td>{item.ExamSeatNo}</td>
                                            <td>{item.StudentName}</td>

                                            {ExamMarkEntryData.data.student[0].IsGradeSystem ===
                                                false ? (
                                                <td>
                                                    {
                                                        <input
                                                            type="text"
                                                            id={"Term_Marks" + item.StudentID}
                                                            className={`title-textbox setfocus_${index}`}
                                                            onKeyDown={(e) => setfocus(e, index)}
                                                            defaultValue={
                                                                item.IsAbsent || item.IsCopyCase
                                                                    ? item.IsAbsent === true
                                                                        ? 10001
                                                                        : item.IsCopyCase === true
                                                                            ? 10002
                                                                            : item.Marks
                                                                    : item.Marks
                                                            }
                                                        />
                                                    }
                                                </td>
                                            ) : (
                                                <td>
                                                    <Select
                                                        className="attendence-select"
                                                        id={"Term_Grade" + item.StudentID}
                                                        name={"Term_Grade" + item.StudentID}
                                                        placeholder={commonService.LabelDisplayText("SelectGrade")}
                                                        options={GradeMark}
                                                        defaultValue={GradeMark.find(
                                                            (obj) => obj.value === item.GradeID
                                                        )}
                                                        onChange={(e) =>
                                                            fnGradeChangeInMarkEntry(e.value, item.StudentID)
                                                        }
                                                    />
                                                    <input
                                                        type="hidden"
                                                        id={"Term_Grade_Hidden" + item.StudentID}
                                                        value={item.GradeID}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={"Term_TeacherNote" + item.StudentID}
                                                        className="title-textbox"
                                                        defaultValue={item.TeacherNotes}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {
                                commonService.getLoginRoleData("Exam", "IsCreate") ?
                                    IsDataSaveing === false ?
                                        (!ExamMarkEntryData.data.isClassRoomMarkLock ?
                                            <a
                                                onClick={(e) => fnUpdateTerm_ExamMarks(ExamMarkEntryData.data)}
                                                className="btn btn-primary"
                                            >
                                                {commonService.LabelDisplayText("Save")}
                                            </a> : "")
                                        :
                                        <a
                                            className="btn btn-primary"
                                        >
                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                        </a>
                                    : <></>
                            }

                        </>
                    ) : ExamDetails.data[0].ExamOfTypeTerm === "Semester Exam" ? (
                        <>
                            {ExamMarkEntryData.data.isClassRoomMarkLock ? (<div className="error-message text-center"> You are not able to update mark because of marks are already lock.</div>) : ""}
                            <Table responsive className="mark-entry-table">
                                <thead>
                                    <tr>
                                        <th className="w-50px">{commonService.LabelDisplayText("Roll")}</th>
                                        <th className="w-75px">{commonService.LabelDisplayText("Seat")}</th>
                                        <th>{commonService.LabelDisplayText("StudentName")}</th>
                                        {ExamMarkEntryData.data.student[0].IsGradeSystem ===
                                            true ? (
                                            <th className="w-100px">{commonService.LabelDisplayText("Grade")}</th>
                                        ) : (
                                            <>
                                                <th className="w-100px">{commonService.LabelDisplayText("Marks")}</th>
                                                <th className="w-100px">{commonService.LabelDisplayText("ConvertedMarks")}</th>
                                            </>
                                        )}
                                        <th>{commonService.LabelDisplayText("TeacherNote")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ExamMarkEntryData.data.student.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.RollNo}</td>
                                            <td>{item.ExamSeatNo}</td>
                                            <td>{item.StudentName}</td>

                                            {ExamMarkEntryData.data.student[0].IsGradeSystem ===
                                                true ? (
                                                <td>
                                                    <Select
                                                        className="attendence-select"
                                                        id={"Semester_Grade" + item.StudentID}
                                                        name={"Semester_Grade" + item.StudentID}
                                                        placeholder={commonService.LabelDisplayText("SelectGrade")}
                                                        options={GradeMark}
                                                        defaultValue={GradeMark.find(
                                                            (obj) => obj.value === item.GradeID
                                                        )}
                                                        onChange={(e) =>
                                                            fnGradeChangeInSemesterMarkEntry(
                                                                e.value,
                                                                item.StudentID
                                                            )
                                                        }
                                                    />
                                                    <input
                                                        type="hidden"
                                                        id={"Semester_Grade_Hidden" + item.StudentID}
                                                        value={item.GradeID}
                                                    />
                                                </td>
                                            ) : (
                                                <>
                                                    <td>
                                                        {
                                                            <input
                                                                type="text"
                                                                id={"Semester_Marks" + item.StudentID}
                                                                className={`title-textbox setfocus_${index}`}
                                                                onKeyDown={(e) => setfocus(e, index)}
                                                                onKeyUp={(e) =>
                                                                    ConvertedMarkCalculate(
                                                                        e,
                                                                        item.StudentID,
                                                                        item.IsRounding !== null
                                                                            ? item.IsRounding
                                                                            : false
                                                                    )
                                                                }
                                                                defaultValue={
                                                                    item.IsAbsent || item.IsCopyCase
                                                                        ? item.IsAbsent === true
                                                                            ? 10001
                                                                            : item.IsCopyCase === true
                                                                                ? 10002
                                                                                : item.Marks
                                                                        : item.Marks
                                                                }
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            <input
                                                                type="text"
                                                                id={"Semester_ConvertedMarks" + item.StudentID}
                                                                className="title-textbox"
                                                                disabled
                                                                defaultValue={item.ConvertedMark}
                                                            />
                                                        }
                                                    </td>
                                                </>
                                            )}
                                            <td>
                                                {
                                                    <input
                                                        type="text"
                                                        id={"Semester_TeacherNote" + item.StudentID}
                                                        className="title-textbox"
                                                        defaultValue={item.TeacherNotes}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {
                                commonService.getLoginRoleData("Exam", "IsCreate") ?
                                    IsDataSaveing === false ?
                                        (!ExamMarkEntryData.data.isClassRoomMarkLock ?
                                            <a
                                                onClick={(e) =>
                                                    fnUpdateSemester_ExamMarks(ExamMarkEntryData.data)
                                                }
                                                className="btn btn-primary"
                                            >
                                                {commonService.LabelDisplayText("Save")}
                                            </a> : "")
                                        :
                                        <a
                                            className="btn btn-primary"
                                        >
                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                        </a>
                                    : <></>
                            }
                        </>
                    ) : (
                        <></>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ExamSyllabus;
