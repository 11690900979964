import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const alertSubject = new Subject();
const defaultId = 'default-alert';

export const alertService = {
    onAlert,
    alert,
    clear
};

export const AlertType = {
    Success: 'Success',
    Error: 'Error',
    Info: 'Info',
    Warning: 'Warning'
}

export const AlertPosition = {
    LeftTop: 'left-top',
    RightTop: 'right-top'
}

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
    return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// core alert method
function alert(alert) {
    alert.id = alert.id || defaultId;
    alert.position = alert.position || "right-top";
    alert.header = alert.header || alert.type;
    alert.autoClose = alert.autoClose || true;
    alert.keepAfterRouteChange = alert.keepAfterRouteChange || true;
    alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
    alertSubject.next({ id });
}