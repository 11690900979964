import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { viewFilePopupService } from '../_services/viewFilePopup.service';
import { commonService, AppConfigData } from '../_services/common.service';

const propTypes = {
    id: PropTypes.string
};

const defaultProps = {
    id: 'default-viewFilePopup',
};

function ViewFilePopup({ id }) {
    let data = commonService.getLoginUserData();
    const history = useHistory();
    const [viewFilePopups, setViewFilePopups] = useState([]);
    const [viewFile, setViewFile] = useState([]);
    const [viewFileIndex, setViewFileIndex] = useState(0);

    useEffect(() => {

        // subscribe to new viewFilePopup notifications
        const subscription = viewFilePopupService.onViewFilePopup(id)
            .subscribe(viewFilePopup => {
                // clear viewFilePopups when an empty viewFilePopup is received

                if (!viewFilePopup.data) {
                    setViewFilePopups(viewFilePopups => {
                        // filter out viewFilePopups without 'keepAfterRouteChange' flag
                        const filteredViewFilePopups = viewFilePopups.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredViewFilePopups.forEach(x => delete x.keepAfterRouteChange);
                        return filteredViewFilePopups;
                    });
                } else {
                    // add viewFilePopup to array
                    setViewFilePopups(viewFilePopups => ([...viewFilePopups, viewFilePopup]));
                    setViewFile(viewFilePopup.data[0]);
                }
            });

        // clear viewFilePopups on location change
        const historyUnlisten = history.listen(() => {
            setViewFileIndex(0);
            viewFilePopupService.clear(id);
        });


        // clean up function that runs when the component unmounts
        return () => {
            setViewFileIndex(0);
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

    function removeViewFilePopup(viewFilePopup) {
        setViewFileIndex(0);
        viewFilePopupService.clear(viewFilePopup.id);
    }

    function cssClasses(viewFilePopup) {
        if (!viewFilePopup) return;
        const classes = ['file-view-popup', 'view'];

        return classes.join(' ');
    }

    function ViewPrevious(viewFilePopup) {

        if (viewFileIndex > 0) {
            setViewFile(viewFilePopup.data[viewFileIndex - 1]);
            setViewFileIndex(viewFileIndex - 1);
        }

    }

    function ViewNext(viewFilePopup) {
        if (viewFileIndex < viewFilePopup.data.length - 1) {
            setViewFile(viewFilePopup.data[viewFileIndex + 1]);
            setViewFileIndex(viewFileIndex + 1);
        }
    }

    function fnEditPDF(FilePath, viewFilePopup) {
        commonService.setItem("pdfedit", FilePath);
        removeViewFilePopup(viewFilePopup)
        window.open("pdfedit");
    }

    function CreateViewFileHTML(viewFile, viewFilePopup) {
        let WindowHight = window.innerHeight - 120;
        let BaseURL = AppConfigData.APIFileBasePath;
        if (viewFile.FilePath !== null && viewFile.FilePath !== "") {
            if (viewFile.FilePath.toUpperCase().indexOf("HTTP") === 0) {
                BaseURL = "";
            }
        }

        if (viewFile.FileType.toUpperCase() === "PDF") {
            return <div className="file-display-area">
                {/*<button className="btn-edit-pdf" title="Click Here For Edit PDF" onClick={(e) => fnEditPDF(viewFile.FilePath, viewFilePopup)}>Edit PDF</button>*/}
                <iframe src={BaseURL + viewFile.FilePath} title={viewFile.Name} style={{ height: WindowHight }}></iframe></div>
        }
        else if (viewFile.FileType.toUpperCase() === "YOUTUBE") {
            return <div className="file-display-area"><iframe src={viewFile.FilePath} title={viewFile.Name} frameborder="0" allowfullscreen style={{ height: WindowHight }}></iframe></div>
        }
        else if (viewFile.FileType.toUpperCase() === "VIDEO") {
            return <div className="file-display-area"><video controls style={{ height: WindowHight }}><source src={BaseURL + viewFile.FilePath} type={viewFile.FileMimeType} title={viewFile.Name} />{commonService.LabelDisplayText("BrowserNotSupportVideo")}</video></div>
        }
        else if (viewFile.FileType.toUpperCase() === "AUDIO") {
            return <div className="file-display-area"><audio controls><source src={BaseURL + viewFile.FilePath} type={viewFile.FileMimeType} title={viewFile.Name} />{commonService.LabelDisplayText("BrowserNotSupportAudio")}</audio></div>
        }
        else if (viewFile.FileType.toUpperCase() === "LINK") {
            return <div className="file-display-area"><a href={viewFile.FilePath} title={viewFile.Name} target="_blank" style={{ height: WindowHight }}><div className="icon-display"><i className="icon-fi-rr-info"></i></div><div className="message-display" dangerouslySetInnerHTML={{
                __html: commonService.LabelDisplayText("UnableToLoadContent")
            }}></div></a></div>

        }
        else if (viewFile.FileType.toUpperCase() === "IMAGE") {
            return <div className="file-display-area"><img src={BaseURL + viewFile.FilePath} title={viewFile.Name} /></div>
        }
        else if (viewFile.FileType.toUpperCase() === "TEXT") {
            return <div className="html-display-area" dangerouslySetInnerHTML={{ __html: viewFile.FilePath }}></div>
        }
        else {
            return <div className="file-display-area"><a href={BaseURL + viewFile.FilePath} title={viewFile.Name} target="_blank" style={{ height: WindowHight }}><div className="icon-display"><i className="icon-fi-rr-info"></i></div><div className="message-display" dangerouslySetInnerHTML={{
                __html: commonService.LabelDisplayText("UnableToLoadContent")
            }}></div></a></div>
        }
    }

    if (!viewFilePopups.length) return null;

    return (
        viewFilePopups.map((viewFilePopup, index) =>
            <div key={index} className={cssClasses(viewFilePopup)}>
                <div className="file-view-popup-dialog">
                    <div className="file-view-popup-dialog-header">
                        <div className="row">
                            <div className="col-1">
                                <b>{viewFileIndex + 1} {commonService.LabelDisplayText("Of")} {viewFilePopup.data.length}</b>
                            </div>
                            <div className="col-1 nextpriv-icon">
                                <i className="icon-fi-rr-angle-double-left pointer-cursor" onClick={() => ViewPrevious(viewFilePopup)}></i>
                            </div>
                            <div className="col-8 file-name">
                                {viewFile.Name + " - (" + viewFile.FileType + ")"}
                            </div>
                            <div className="col-1 nextpriv-icon">
                                <i className="icon-fi-rr-angle-double-right pointer-cursor" onClick={() => ViewNext(viewFilePopup)}></i>
                            </div>
                            <div className="col-1">
                                <button className="close-button" onClick={() => removeViewFilePopup(viewFilePopup)}>
                                    <i className="icon icon-fi-rr-cross"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="file-view-popup-dialog-body">
                        <div className="row">
                            <div className="col-12 file-view">
                                {
                                    CreateViewFileHTML(viewFile, viewFilePopup)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

ViewFilePopup.propTypes = propTypes;
ViewFilePopup.defaultProps = defaultProps;
export default ViewFilePopup;