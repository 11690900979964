import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/Spinner";

function PDFEdit() {
    const history = useHistory();
    let _canvas = [];



    const [FileData, setFileData] = useState({ IsData: false, data: [] });
    const [IsSaveing, setIsSaveing] = useState(false);

    function CreateCanvas() {
        const img_list = document.getElementsByTagName("img");
        if (img_list != null && img_list.length != 0) {
            const Container = document.getElementById("canvas_container");
            for (var i = 0; i < img_list.length; i++) {
                var imgDiv = document.createElement("div");
                imgDiv.id = "div_" + img_list[i].id;
                imgDiv.style.width = img_list[i].width + "px";
                imgDiv.className = "img-canvas"
                Container.appendChild(imgDiv);

                const _imgDiv = document.getElementById("div_" + img_list[i].id);
                var strMenuString = "";
                var mydiv = document.createElement("div");
                mydiv.id = "menu_" + img_list[i].id;
                mydiv.className = "img-menu"

                strMenuString = '<div class="pdfeditor-navbar">';
                strMenuString += '<a href="javascript:;" data-attr="canvas_' + img_list[i].id + '" class="AddText" title="Add Text" >Text</a>';
                strMenuString += '<a href="javascript:;" data-attr="canvas_' + img_list[i].id + '" class="DrawLine" title="Draw Line">Line</a>';
                strMenuString += '<a href="javascript:;" data-attr="canvas_' + img_list[i].id + '" class="Undo" title="Undo">Undo</a>';
                strMenuString += '</div> ';

                mydiv.innerHTML = strMenuString;



                _imgDiv.appendChild(mydiv);
                _canvas["canvas_" + img_list[i].id] = [];
                var mycanvas = document.createElement("canvas");
                mycanvas.id = "canvas_" + img_list[i].id;
                mycanvas.height = img_list[i].height;
                mycanvas.width = img_list[i].width;
                _imgDiv.appendChild(mycanvas);





                imgDiv.style.height = img_list[i].height + mydiv.clientHeight + "px";

                imgDiv.style.display = (i == 0 ? "block" : "none");

                var canvas = document.getElementById("canvas_" + img_list[i].id);
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img_list[i], 0, 0);
                _canvas["canvas_" + img_list[i].id].push(document.getElementById("canvas_" + img_list[i].id).toDataURL());

                img_list[i].width = 120;
                img_list[i].onclick = HideShowPage;

                _imgDiv.getElementsByClassName("AddText")[0].onclick = fnAddText;
                _imgDiv.getElementsByClassName("DrawLine")[0].onclick = fnDrawline;
                _imgDiv.getElementsByClassName("Undo")[0].onclick = fnUndo;
            }
        }
    }

    function HideShowPage(e) {
        var ImgCanvas = document.getElementsByClassName("img-canvas");
        for (var i = 0; i < ImgCanvas.length; i++) {
            ImgCanvas[i].style.display = "none";
        }
        document.getElementById("div_" + e.target.id).style.display = "block";
    }

    function fnAddText(e) {
        let id = e.target.getAttribute("data-attr");
        ClearEvents(id);
        var newID = CreateGuid();
        var MenuHeight = document.getElementById(id.replace("canvas_", "menu_")).clientHeight;
        var StrDIV = '<div data-id="' + id + '" class="dragElement" id="' + newID + '" style="top: ' + MenuHeight + 'px; left: 0px;"><div class="menuoption"><div id="' + newID + 'header" class="movemenu">&#10016;</div><div class="stylemenu" id="stylemenu_' + id + '" data-attr="' + newID + '" >&#9997;</div><div class="deletemenu" id="deletemenu_' + id + '" data-attr="' + newID + '">&#10006;</div></div><textarea class="textdata" contenteditable="true" style="font-family:Arial;font-size:16px;color: rgb(0, 0, 0);" data-color="#000000">Enter Your Text Here</textarea> </div>';
        document.getElementById(id).insertAdjacentHTML('beforebegin', StrDIV);
        document.getElementById("deletemenu_" + id).onclick = DeleteAddedText;
        document.getElementById("stylemenu_" + id).onclick = StyleAddedText;

        dragElement(document.getElementById(newID))


    }

    function DeleteAddedText(e) {
        let id = e.target.getAttribute("data-attr");
        document.getElementById(id).remove();
    }

    function StyleAddedText(e) {
        let id = e.target.getAttribute("data-attr");
        var TESP = document.getElementsByClassName("text-editor-style-popup-parents");
        if (TESP != null && TESP.length != 0) {
            TESP[0].remove();
        }

        var font_family_val = "Arial";
        var font_size_val = "16px";
        var font_color_val = "#000000";

        var TextEditorParents = document.getElementById(id);
        if (TextEditorParents != null && TextEditorParents.length != 0) {
            var TextArea = TextEditorParents.getElementsByTagName("textarea");
            if (TextArea != null && TextArea.length != 0) {
                font_family_val = TextArea[0].style.fontFamily;
                font_size_val = TextArea[0].style.fontSize;
                font_color_val = TextArea[0].getAttribute("data-color");
            }
        }

        var font_family = '<select id="font_family" data-attr="' + id + '" class="font_family_selection">';
        font_family += '<option value="Arial">Arial</option>';
        font_family += '<option value="Brush Script MT">Brush Script MT</option>';
        font_family += '<option value="Courier New">Courier New</option>';
        font_family += '<option value="Copperplate">Copperplate</option>';
        font_family += '<option value="Georgia">Georgia</option>';
        font_family += '<option value="Garamond">Garamond</option>';
        font_family += '<option value="Helvetica">Helvetica</option>';
        font_family += '<option value="Lucida Console">Lucida Console</option>';
        font_family += '<option value="Lucida Handwriting">Lucida Handwriting</option>';
        font_family += '<option value="Monaco">Monaco</option>';
        font_family += '<option value="Papyrus">Papyrus</option>';
        font_family += '<option value="Times New Roman">Times New Roman</option>';
        font_family += '<option value="Verdana">Verdana</option>';
        font_family += "</select>";

        var font_Size = '<select id="font_size" data-attr="' + id + '" class="font_size_selection">';
        font_Size += '<option value="8px">8px</option>';
        font_Size += '<option value="9px">9px</option>';
        font_Size += '<option value="10px">10px</option>';
        font_Size += '<option value="11px">11px</option>';
        font_Size += '<option value="12px">12px</option>';
        font_Size += '<option value="13px">13px</option>';
        font_Size += '<option value="14px">14px</option>';
        font_Size += '<option value="15px">15px</option>';
        font_Size += '<option value="16px">16px</option>';
        font_Size += '<option value="17px">17px</option>';
        font_Size += '<option value="18px">18px</option>';
        font_Size += '<option value="19px">19px</option>';
        font_Size += '<option value="20px">20px</option>';
        font_Size += '<option value="21px">21px</option>';
        font_Size += '<option value="22px">22px</option>';
        font_Size += '<option value="23px">23px</option>';
        font_Size += '<option value="24px">24px</option>';
        font_Size += '<option value="25px">25px</option>';
        font_Size += '<option value="26px">26px</option>';
        font_Size += '<option value="27px">27px</option>';
        font_Size += '<option value="28px">28px</option>';
        font_Size += '<option value="29px">29px</option>';
        font_Size += '<option value="30px">30px</option>';
        font_Size += '<option value="31px">31px</option>';
        font_Size += '<option value="32px">32px</option>';
        font_Size += '<option value="33px">33px</option>';
        font_Size += '<option value="34px">34px</option>';
        font_Size += '<option value="35px">35px</option>';
        font_Size += '<option value="36px">36px</option>';
        font_Size += '<option value="37px">37px</option>';
        font_Size += '<option value="38px">38px</option>';
        font_Size += '<option value="39px">39px</option>';
        font_Size += '<option value="40px">40px</option>';
        font_Size += "</select>";
        var ColorPicker = '<input type="color" data-attr="' + id + '" id="font_color"   value="#000000" style="height: 25px !important;border: none;" >';

        var UIstr = "<div class='text-editor-style-popup-parents'>";
        UIstr += "<div class='text-editor-style-popup'>";
        UIstr += "<div class='input-container'><b>Font Family : </b> " + font_family + " </div>";
        UIstr += "<div class='input-container'><b>Font Size : </b>" + font_Size + "</div>";
        UIstr += "<div class='input-container'><b>Font Color : </b>" + ColorPicker + "</div>";
        UIstr += "<div class='input-container'><input type='button' id='SetEditorStryle' value='Done' data-attr='" + id + "' style='float: right; padding: 5px;' onclick=fnSetEditorStryle('" + id + "') /></div>";
        UIstr += "</div></div>";
        document.getElementsByTagName("body")[0].insertAdjacentHTML('beforebegin', UIstr);

        document.getElementById("font_family").value = font_family_val;
        document.getElementById("font_size").value = font_size_val;
        document.getElementById("font_color").value = font_color_val;
        document.getElementById("SetEditorStryle").onclick = fnSetEditorStryle;

        document.getElementById("font_family").onchange = fnEditorValuesChagne;
        document.getElementById("font_size").onchange = fnEditorValuesChagne;
        document.getElementById("font_color").onchange = fnEditorValuesChagne;
    }

    function fnEditorValuesChagne(e) {
        let id = e.target.getAttribute("data-attr");
        var font_family = document.getElementById("font_family").value;
        var font_size = document.getElementById("font_size").value;
        var font_color = document.getElementById("font_color").value;
        var TextEditorParents = document.getElementById(id);
        if (TextEditorParents != null && TextEditorParents.length != 0) {
            var TextArea = TextEditorParents.getElementsByTagName("textarea");
            if (TextArea != null && TextArea.length != 0) {
                TextArea[0].style.fontFamily = font_family;
                TextArea[0].style.fontSize = font_size;
                TextArea[0].style.color = font_color;
                TextArea[0].setAttribute("data-color", font_color)
            }
        }
    }

    function fnSetEditorStryle(e) {
        let id = e.target.getAttribute("data-attr");
        var TESP = document.getElementsByClassName("text-editor-style-popup-parents");
        if (TESP != null && TESP.length != 0) {
            TESP[0].remove();
        }
    }

    function fnDrawline(e) {
        let id = e.target.getAttribute("data-attr");
        ClearEvents(id);
        var canvas = document.getElementById(id);
        var context = canvas.getContext('2d');
        context.strokeStyle = "#f00";
        context.lineWidth = 2;

        canvas.onmousedown = startDrawing;
        canvas.onmouseup = stopDrawing;
        canvas.onmousemove = draw;
        var isDrawing = false;

        function startDrawing(e) {
            isDrawing = true;
            context.beginPath();
            context.moveTo(e.pageX - canvas.offsetLeft - 10, e.pageY - canvas.offsetTop - 10);
        }

        function draw(e) {
            if (isDrawing == true) {
                var x = e.pageX - canvas.offsetLeft - 10;
                var y = e.pageY - canvas.offsetTop - 10;
                context.lineTo(x, y);
                context.stroke();
            }
        }

        function stopDrawing() {
            isDrawing = false;
            _canvas[id].push(document.getElementById(id).toDataURL());
        }
    }

    function fnUndo(e) {
        let id = e.target.getAttribute("data-attr");
        var arrayLastElement = _canvas[id].length - 1;
        var canvas = document.getElementById(id);
        var ctx = canvas.getContext("2d");
        var canvasPic = new Image();
        canvasPic.src = _canvas[id][arrayLastElement];
        canvasPic.onload = function () {
            ctx.drawImage(canvasPic, 0, 0);
            if (arrayLastElement > 0) {
                _canvas[id].splice(arrayLastElement, 1);
            }
        }
    }

    function ClearEvents(id) {
        var canvas = document.getElementById(id);
        canvas.onmousedown = null;
        canvas.onmouseup = null;
        canvas.onmousemove = null;
    }

    function CreateGuid() {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    function dragElement(elmnt) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    function SavePDFChange() {
        BindTextOnCanvas();
    }

    function BindTextOnCanvas() {
        var ImgCanvas = document.getElementsByClassName("img-canvas");
        for (var i = 0; i < ImgCanvas.length; i++) {
            ImgCanvas[i].style.display = "none";
        }
        var lsttextdata = document.getElementsByClassName("textdata");

        if (lsttextdata != null && lsttextdata.length != 0) {

            var dataid = lsttextdata[0].parentElement.getAttribute("data-id");
            document.getElementById(dataid.replace("canvas_", "div_")).style.display = "block";

            var top = lsttextdata[0].parentElement.offsetTop + lsttextdata[0].parentElement.offsetHeight;
            var left = lsttextdata[0].parentElement.offsetLeft;

            var fontfamily = lsttextdata[0].style["font-family"];
            var fontsize = lsttextdata[0].style["font-size"];
            var fontcolor = lsttextdata[0].style["color"];
            var text = lsttextdata[0].value.replaceAll('\n', '<br />');
            var width = lsttextdata[0].clientWidth;
            var height = lsttextdata[0].clientHeight;
            var canvas = document.getElementById(dataid);
            var MenuHeight = document.getElementById(dataid.replace("canvas_", "menu_")).clientHeight;
            var ctx = canvas.getContext("2d");

            const data = "<svg xmlns='http://www.w3.org/2000/svg' width='" + width + "' height='" + height + "'><foreignObject width='100%' height='100%'><div xmlns='http://www.w3.org/1999/xhtml' style='font-family: " + fontfamily + "; font-size: " + fontsize + ";color:" + fontcolor + "'>" + text + "</div></foreignObject></svg>";
            const img = new Image();
            const svg = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
            const url = URL.createObjectURL(svg);

            img.onload = function () {
                ctx.drawImage(img, parseFloat(left), (parseFloat(top - MenuHeight - height)));
                URL.revokeObjectURL(url);
                lsttextdata[0].remove();
                BindTextOnCanvas();
            };
            img.src = url;
        }
        else {
            setIsSaveing(true);
            ImgCanvas[0].style.display = "block";

            let imageBase64String = [];

            for (var i = 0; i < ImgCanvas.length; i++) {
                var canvas = document.getElementById("canvas_Image_" + i);
                imageBase64String.push(canvas.toDataURL('image/png'))
            }

            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    "imageBase64String": imageBase64String,
                    "filePath": commonService.getItem("pdfedit"),
                    "requestFrom": "web"
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/pdfeditor/ConvertImageBase64StringToPDF",
                reqClasswork
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsSaveing(false);
                    if (json.status_code === 1) {
                        confirmAlert({
                            title: "Saved successfully.",
                            message: "After Click Ok Button Window Close Automatically.",
                            buttons: [
                                {
                                    label: "Ok",
                                    onClick: () =>
                                        window.close(),
                                },
                            ],
                        })

                    } else if (json.status_code === 0) {

                    } else if (json.status_code === 2) {
                        window.close();
                    }
                });
        }
    }

    React.useEffect(() => {
        if (document.getElementById("main") !== null) {
            document.getElementById("main").classList.remove("main-loading-container")
            document.getElementById("main").id = "";
        }
        if (document.getElementById("section-main") !== null) {
            document.getElementById("section-main").id = "";
        }
        if (document.getElementById("root") != null) {
            document.getElementById("root").classList.remove("main-class-container");
        }
        if (document.getElementsByTagName("html") !== null) {
            document.getElementsByTagName("html")[0].classList.add("PDFEditPageHTMl");
        }

        let pdfedit = commonService.getItem("pdfedit");
        if (pdfedit !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    filePath: pdfedit,
                    requestFrom: "Web"
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/pdfeditor/ConvertPDFToImageBase64String",
                reqClasswork
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setFileData({ IsData: true, data: json.data });

                        let myInterval = setInterval(function () {
                            clearInterval(myInterval);
                            CreateCanvas();
                        }, 1000);

                    } else if (json.status_code === 0) {
                        setFileData({ IsData: true, data: [] });
                    } else if (json.status_code === 2) {
                        window.close();
                    }
                });
        }
    }, []);

    return (
        <>{
            FileData.IsData ?
                <>
                    {
                        FileData.data.imageBase64String.length === 0 ? (
                            <div className="col-12">
                                <_NoDataFound fontsize={"300px"} />
                            </div>
                        )
                            :
                            <div className="pdfedit-container">
                                <div className="pdfpage" id={"canvas_container"}>

                                </div>
                                <div className="pdfpageselection">
                                    {
                                        IsSaveing === false ?
                                            <button className="btn btn-primary mb-2" onClick={() => SavePDFChange()}>Save Change</button>
                                            :
                                            <button className="btn btn-primary mb-2"> <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}</button>
                                    }

                                    {
                                        FileData.data.imageBase64String.map((item, index) => (
                                            <img src={item} key={index} className="image mb-2" id={"Image_" + index} />
                                        ))
                                    }

                                </div>
                                <div className={"clear"}></div>
                            </div>

                    }
                </>

                :
                <div className="row">
                    <div className="col-sm-12">
                        <_Skeleton type={_SkeletonType.PDFEDIT} count={10} />
                    </div>
                </div>
        }</>
    );
}

export default PDFEdit;
