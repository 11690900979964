import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { commonService, AppConfigData } from "../_services/common.service";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import { alertService, AlertType } from "../_services/alert.service";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Nav, Row } from "react-bootstrap";

function ChapterDetails() {
    const history = useHistory();

    const [ChapterAllData, setChapterAllData] = useState({
        IsData: false,
        data: [],
    });

    let data = commonService.getLoginUserData();
    const [checkpublish, setcheckpublish] = useState(false);


    function changecheckpublish(e, topicid) {
        setcheckpublish(e.target.checked);
        setpublishunpublish(e.target.checked, topicid);
    }

    function setpublishunpublish(checkpublish, topicid) {
        const reqpublishunpublish = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: topicid,
                isPublish: checkpublish,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/lms/publishunpublishtopic",
            reqpublishunpublish
        )
            .then((response) => response.json())
            .then(function (json) {

                if (json.status_code === 1) {
                    alertService.alert({
                        type: AlertType.Success,
                        message: json.message,
                    });

                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function fnChagneTopic(index) {
        let objTopicHeader = document.getElementById("lbl_topic_header_" + index);
        if (objTopicHeader !== null) {
            objTopicHeader.classList.add("m-active");
            let objTopicSection = document.getElementById(
                "sec_topic_details_" + index
            );

            if (objTopicSection !== null) {
                document.getElementById("root").scrollTop = objTopicSection.offsetTop;
            }
        }
    }
    function ValueSet(Files, index) {
        let FilesDetails = [];

        if (Files.FileType == "Upload Image") {
            FilesDetails.push({
                FileMimeType: "image/png",
                FilePath: AppConfigData.ERPFileBasePathLMS + Files.FilePath,
                FileType: "IMAGE",
                Name: Files.AttachmentTitle,
            });
        } else {
            FilesDetails.push({
                FileMimeType: "application/pdf",
                FilePath: AppConfigData.ERPFileBasePathLMS + Files.FilePath,
                FileType: "PDF",
                Name: Files.AttachmentTitle,
            });
        }

        viewFilePopupService.viewFilePopup({
            data: FilesDetails,
        });
    }

    function GetAllData(obj) {
        const reqbody = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                id: obj.LessionID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/lms/getlessionmasterfulllessiondatabylessionid",
            reqbody
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setChapterAllData({ IsData: true, data: json.data });
                    // setFilesDataFormat(json.data);
                } else if (json.status_code === 0) {
                    setChapterAllData({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("LMS Subject List", "IsView")) {
            if (history.location.state !== undefined) {
                GetAllData(history.location.state);
            } else {
                history.push("/subjectlisting");
            }
        } else {
            history.push("/access");
        }
    }, []);

    function fnCheckFileTypeExist(Data, TopicID, Type) {
        let IsExist = false;
        for (let i = 0; i < Data.length; i++) {

            if (TopicID ===
                Data[i].AssociationID &&
                Type.toUpperCase().indexOf(Data[i].FileType.toUpperCase()) >= 0) {
                IsExist = true
            }
        }

        return IsExist;
    }

    return (
        <div>
            {!ChapterAllData.IsData ? (
                <_Skeleton type={_SkeletonType.ChapterAllData} />
            ) : ChapterAllData.data.length === 0 ? (
                <_NoDataFound fontsize={"200px"} />
            ) : (
                <div class="container">
                    <div class="background-theme-color sticky-top mt-3 wall-page-top-section">
                        <h1 class="page-header chapter-name pl-0 m-4 text-align-center">
                            {history.location.state.LessionTitle}
                        </h1>
                        <div class="row subject-list-tabs">
                            <ul class="d-flex list-unstyled subject-list-main m-0">
                                {ChapterAllData.data.topic.map((item, index) => (
                                    <li className="nav-item">
                                        <label
                                            id={"lbl_topic_header_" + index}
                                            className={"nav-link scroll-tab"}
                                            onClick={() => fnChagneTopic(index)}
                                        >
                                            <strong> {item.TopicTitle}</strong>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        {ChapterAllData.data.topic.map((Chapteritems, chapterindex) => (
                            <>
                                <div
                                    class="col-12 subject-datails-tabs"
                                    id={"sec_topic_details_" + chapterindex}
                                >
                                    <h3 class="topic-header-subject">
                                        {Chapteritems.TopicTitle}
                                        {data[0].MemberType.toLowerCase() === "teacher" && commonService.getLoginRoleData("LMS Subject List", "IsEdit") ?
                                            <>
                                                <OverlayTrigger
                                                    delay={{ hide: 450, show: 300 }}
                                                    overlay={(props) => (
                                                        <Tooltip {...props}>
                                                            {checkpublish ? <>{commonService.LabelDisplayText("TopicIsPublishClickForUnpublish")}</> : <>{commonService.LabelDisplayText("TopicIsPublishClickForPublish")}</>}
                                                        </Tooltip>
                                                    )}
                                                    placement="bottom"
                                                ><label className="float-right"><input type="checkbox" value={checkpublish} defaultChecked={Chapteritems.IsPublish ? "checked" : ""} onChange={(e) => changecheckpublish(e, Chapteritems.TopicID)} /></label>
                                                </OverlayTrigger>
                                            </> : <></>}
                                    </h3>
                                    <Tabs
                                        defaultActiveKey={"home_" + chapterindex}
                                        id="uncontrolled-tab-example"
                                        className="chapter-detail-tabs "
                                    >
                                        {
                                            Chapteritems.TopicView !== "" && Chapteritems.TopicView !== null &&

                                            <Tab eventKey={"home_" + chapterindex} title="Html">
                                                <div class="topic-data-subject">
                                                    <div
                                                        class="mb-3"
                                                        dangerouslySetInnerHTML={{
                                                            __html: Chapteritems.TopicView,
                                                        }}
                                                    ></div>
                                                </div>
                                            </Tab>
                                        }

                                        {
                                            fnCheckFileTypeExist(ChapterAllData.data.attachment, Chapteritems.TopicID, "DOC") &&
                                            <Tab eventKey={"profile_" + chapterindex} title="Pdf">
                                                <div class="topic-data-subject pointer-cursor">
                                                    <div class="mb-3">
                                                        {ChapterAllData.data.attachment.map(
                                                            (Chapter, cindex) => (
                                                                <>
                                                                    {Chapteritems.TopicID ===
                                                                        Chapter.AssociationID &&
                                                                        Chapter.FileType.toUpperCase() === "DOC" && (
                                                                            <div className="mr-3 img-background">
                                                                                <img
                                                                                    className="mr-3 document-image-section"
                                                                                    src={
                                                                                        AppConfigData.ERPFileBasePathLMS +
                                                                                        Chapter.FilePath.replace("pdf", "png")
                                                                                    }
                                                                                    onClick={() =>
                                                                                        ValueSet(Chapter, cindex)
                                                                                    }
                                                                                ></img>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Tab>

                                        }

                                        {
                                            fnCheckFileTypeExist(ChapterAllData.data.attachment, Chapteritems.TopicID, "VIMEO VIDEO,YOUTUBE VIDEO") &&
                                            <Tab eventKey={"contact_" + chapterindex} title="Video">
                                                <div class="topic-data-subject pointer-cursor">
                                                    <div class="mb-3">
                                                        {ChapterAllData.data.attachment.map(
                                                            (ChapterVideo, cvideoindex) => (
                                                                <>
                                                                    {Chapteritems.TopicID ===
                                                                        ChapterVideo.AssociationID &&
                                                                        (ChapterVideo.FileType.toUpperCase() ===
                                                                            "YOUTUBE VIDEO" ? (
                                                                            <iframe
                                                                                className="mr-3"
                                                                                src={
                                                                                    AppConfigData.YoutubeVideo +
                                                                                    ChapterVideo.FilePath
                                                                                }
                                                                                allowFullScreen
                                                                                frameBorder={0}
                                                                            ></iframe>
                                                                        ) : ChapterVideo.FileType.toUpperCase() ===
                                                                            "VIMEO VIDEO" ? (
                                                                            <iframe
                                                                                className="mr-3"
                                                                                src={
                                                                                    AppConfigData.VimeoVideo +
                                                                                    ChapterVideo.FilePath
                                                                                }
                                                                                allowFullScreen
                                                                                frameBorder={0}
                                                                            ></iframe>
                                                                        ) : (
                                                                            ""
                                                                        ))}
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Tab>

                                        }

                                        {
                                            fnCheckFileTypeExist(ChapterAllData.data.attachment, Chapteritems.TopicID, "UPLOAD IMAGE") &&
                                            <Tab eventKey={"Image_" + chapterindex} title="Image">
                                                <div class="topic-data-subject ">
                                                    <div class="mb-3 d-flex align-items-center">
                                                        {ChapterAllData.data.attachment.map(
                                                            (ChapterImage, cImageindex) => (
                                                                <>
                                                                    {Chapteritems.TopicID ===
                                                                        ChapterImage.AssociationID &&
                                                                        ChapterImage.FileType.toUpperCase() ===
                                                                        "UPLOAD IMAGE" && (
                                                                            <div className="mr-3 img-background">
                                                                                <img
                                                                                    src={
                                                                                        AppConfigData.ERPFileBasePathLMS +
                                                                                        ChapterImage.FilePath
                                                                                    }
                                                                                    onClick={() =>
                                                                                        ValueSet(ChapterImage, cImageindex)
                                                                                    }
                                                                                ></img>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Tab>

                                        }



                                    </Tabs>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChapterDetails;
