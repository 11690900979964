import React from "react";
import Skeleton from "react-loading-skeleton";

export const _SkeletonType = {
    Line: "Line",
    Post: "Post",
    PostNoAvtar: "PostNoAvtar",
    Table: "Table",
    ClassWork: "ClassWork",
    HomeWork: "HomeWork",
    Exam: "Exam",
    BirthDay: "BirthDay",
    NoteEventCircular: "NoteEventCircular",
    ExamHome: "EXAMHOME",
    TimeTable: "TimeTable",
    LiveClass: "LiveClass",
    ExamQuestion: "ExamQuestion",
    PDFEDIT: "PDFEDIT",
    BOTTOMLOADER: "BOTTOMLOADER"

};

function _Skeleton(prop) {
    let count = prop.count || 4;
    let type = prop.type || "Line";
    let lineheight = prop.lineheight || 20;
    let line = [];
    for (let i = 0; i < count; i++) {
        line.push(i);
    }
    if (type.toUpperCase() === "POST") {
        return (
            <div className="skeleton post">
                <div className="card-body">
                    <div className="card-body-header">
                        <div className="left-section">
                            <div className="user-pic">
                                <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            </div>
                            <p>
                                {" "}
                                <Skeleton width={200} />
                            </p>
                        </div>
                        <div className="right-section">
                            <label className="tag-label">
                                <Skeleton width={100} />
                            </label>
                        </div>
                    </div>
                    <div className="card-body-main">
                        <p className="card-body-content post">
                            <Skeleton count={count} height={lineheight} />
                        </p>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="reaction">
                        <div className="like-section">
                            <Skeleton width={100} />
                        </div>
                        <div className="dislike-section">
                            <Skeleton width={100} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "POSTNOAVTAR") {
        return (
            <div className="skeleton post">
                <div className="card-body">
                    <div className="card-body-header">
                        <div className="left-section">
                            <p>
                                {" "}
                                <Skeleton width={200} />
                            </p>
                        </div>
                        <div className="right-section">
                            <label className="tag-label">
                                <Skeleton width={100} />
                            </label>
                        </div>
                    </div>
                    <div className="card-body-main">
                        <p className="card-body-content post">
                            <Skeleton count={count} height={lineheight} />
                        </p>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="reaction">
                        <div className="like-section">
                            <Skeleton width={100} />
                        </div>
                        <div className="dislike-section">
                            <Skeleton width={100} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "BIRTHDAY") {
        return (
            <>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center" style={{ width: "100%" }}>
                        <div className="user-pic mr-3">
                            <Skeleton circle={true} height={45} width={45} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <div className="birthday-name">
                                <Skeleton width={"70%"} />
                            </div>
                            <div>
                                <span className="birthday-details">
                                    <Skeleton width={185} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (type.toUpperCase() === "LINE") {
        return (
            <div className="skeleton">
                <Skeleton count={count} height={lineheight} />
            </div>
        );
    } else if (type.toUpperCase() === "TABLE") {
        return (
            <div className="skeleton p-4">
                <table>
                    <tbody>
                        {line.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Skeleton height={lineheight} />
                                </td>
                                <td>
                                    <Skeleton height={lineheight} />
                                </td>
                                <td>
                                    <Skeleton height={lineheight} />
                                </td>
                                <td>
                                    <Skeleton height={lineheight} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else if (type.toUpperCase() === "CLASSWORK") {
        return (
            <div className="card home-card-homework">
                <div className="card-body">
                    <h2 className="card-body-header">
                        <Skeleton />
                    </h2>
                    <Skeleton width={100} />
                    <p className="card-body-content">
                        <Skeleton count={count} height={lineheight} />
                    </p>
                    <div className="mt-3">
                        <Skeleton width={50} /> <Skeleton width={50} />{" "}
                        <Skeleton width={50} />
                    </div>
                    <div className="section-for-footer-card">
                        <div className="left-section">
                            <Skeleton width={150} />
                        </div>
                        <div className="right-section">
                            <div className="user-pic">
                                <Skeleton circle={true} height={35} width={35} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "HOMEWORK") {
        return (
            <div className="card home-card-homework p-4">
                <div className="card-homework-header pointer-cursor">
                    <div className="user-pic">
                        <Skeleton circle={true} height={35} width={35} />
                    </div>
                    <h2 className="card-body-header">
                        <Skeleton width={200} />
                    </h2>
                </div>
                <Skeleton width={200} />

                <p className="card-body-content">
                    <Skeleton count={count} height={lineheight} />
                </p>
                <div className="mt-3">
                    <Skeleton width={50} /> <Skeleton width={50} />{" "}
                    <Skeleton width={50} />
                </div>
                <div className="section-for-footer-card">
                    <div className="left-section">
                        <Skeleton width={150} />
                    </div>
                    <div className="right-section">
                        <Skeleton width={150} />
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "EXAM") {
        return (
            <div className="row">
                <div className="col-3 ">
                    <div className="card p-3 ">
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                        <div className="row m-3">
                            <div className="col-3 text-center"></div>
                            <div className="col-6 text-center">
                                <Skeleton height={lineheight} count={count} />
                            </div>
                            <div className="col-3 text-center"></div>
                        </div>
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3 ">
                    <div className="card p-3 ">
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                        <div className="row m-3">
                            <div className="col-3 text-center"></div>
                            <div className="col-6 text-center">
                                <Skeleton height={lineheight} count={count} />
                            </div>
                            <div className="col-3 text-center"></div>
                        </div>
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3 ">
                    <div className="card p-3 ">
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                        <div className="row m-3">
                            <div className="col-3 text-center"></div>
                            <div className="col-6 text-center">
                                <Skeleton height={lineheight} count={count} />
                            </div>
                            <div className="col-3 text-center"></div>
                        </div>
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3 ">
                    <div className="card p-3 ">
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                        <div className="row m-3">
                            <div className="col-3 text-center"></div>
                            <div className="col-6 text-center">
                                <Skeleton height={lineheight} count={count} />
                            </div>
                            <div className="col-3 text-center"></div>
                        </div>
                        <div className="row">
                            <div className="col-6 ">
                                <Skeleton width={100} height={20} />
                            </div>
                            <div className="col-6 text-right">
                                <Skeleton width={100} height={20} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "EXAMHOME") {
        return (
            <>
                <div className="row">
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                            <div className="row m-3">
                                <div className="col-3 text-center"></div>
                                <div className="col-6 text-center">
                                    <Skeleton height={lineheight} count={count} />
                                </div>
                                <div className="col-3 text-center"></div>
                            </div>
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                            <div className="row m-3">
                                <div className="col-3 text-center"></div>
                                <div className="col-6 text-center">
                                    <Skeleton height={lineheight} count={count} />
                                </div>
                                <div className="col-3 text-center"></div>
                            </div>
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                            <div className="row m-3">
                                <div className="col-3 text-center"></div>
                                <div className="col-6 text-center">
                                    <Skeleton height={lineheight} count={count} />
                                </div>
                                <div className="col-3 text-center"></div>
                            </div>
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                            <div className="row m-3">
                                <div className="col-3 text-center"></div>
                                <div className="col-6 text-center">
                                    <Skeleton height={lineheight} count={count} />
                                </div>
                                <div className="col-3 text-center"></div>
                            </div>
                            <div className="row">
                                <div className="col-6 ">
                                    <Skeleton width={65} height={20} />
                                </div>
                                <div className="col-6 text-right">
                                    <Skeleton width={65} height={20} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (type.toUpperCase() === "NOTEEVENTCIRCULAR") {
        return (
            <div className="row circular-event-details sticky-top m-0 event-details">
                <div className="col-2 date-time-event">
                    <div className="event-date-time">
                        <h4 className="event-date">
                            <Skeleton width={100} height={30} />
                        </h4>
                        <p className="event-time">
                            <Skeleton width={100} />
                        </p>
                    </div>
                </div>
                <div className="col-10 date-time-detail">
                    <div className="event-date-time-detail">
                        <div className="event-location">
                            <Skeleton width={200} />
                        </div>
                        <div className="event-title">
                            <Skeleton width={"70%"} />
                        </div>
                        <div className="event-description">
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "TIMETABLE") {
        return (
            <div className="row">
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card">
                            <div className="timetable-subjectname mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="timetable-division pb-2">
                                <p>
                                    <Skeleton width={50} />
                                </p>
                            </div>
                            <div className="lecture-time mt-4">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card">
                            <div className="timetable-subjectname mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="timetable-division pb-2">
                                <p>
                                    <Skeleton width={50} />
                                </p>
                            </div>
                            <div className="lecture-time mt-4">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card">
                            <div className="timetable-subjectname mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="timetable-division pb-2">
                                <p>
                                    <Skeleton width={50} />
                                </p>
                            </div>
                            <div className="lecture-time mt-4">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card">
                            <div className="timetable-subjectname mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="timetable-division pb-2">
                                <p>
                                    <Skeleton width={50} />
                                </p>
                            </div>
                            <div className="lecture-time mt-4">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (type.toUpperCase() === "LIVECLASS") {
        return (
            <div className="row">
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card ">
                            <div className="timetable-subjectname  timetable-subjectname-join  mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="division-timetable pb-2">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                            <div className="join-button-liveclass mt-3">
                                <Skeleton width={150} height={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card ">
                            <div className="timetable-subjectname  timetable-subjectname-join  mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="division-timetable pb-2">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                            <div className="join-button-liveclass mt-3">
                                <Skeleton width={150} height={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card ">
                            <div className="timetable-subjectname  timetable-subjectname-join  mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="division-timetable pb-2">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                            <div className="join-button-liveclass mt-3">
                                <Skeleton width={150} height={35} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card timetable-subject-card">
                        <div className="timetable-white-card ">
                            <div className="timetable-subjectname  timetable-subjectname-join  mt-2">
                                <h6>
                                    <Skeleton width={200} height={20} />
                                </h6>
                            </div>
                            <div className="division-timetable pb-2">
                                <p>
                                    <Skeleton width={100} />
                                </p>
                            </div>
                            <div className="join-button-liveclass mt-3">
                                <Skeleton width={150} height={35} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (type.toUpperCase() === "EXAMQUESTION") {
        return (
            <div className="row">
                <div className="col-9 question-setion">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className="blue-text question-text"><Skeleton height={20} width={20} /></span>
                            <span className="question-title"><Skeleton width={200} height={20} /></span>
                        </div>
                        <div>
                            <label className=""><Skeleton width={60} height={20} /></label>
                        </div>
                    </div>
                    <div className="mb-4">
                        <Skeleton count={4} height={20} />
                    </div>
                    <div className="option-section">
                        <div>
                            <label htmlFor="mcq-option-1">
                                <span className="option-mcq"><Skeleton circle={true} height={40} width={40} lineheight={2} /></span>
                                <span className="option-discription"> <Skeleton height={35} width={400} /></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="mcq-option-1" >
                                <span className="option-mcq"><Skeleton circle={true} height={40} width={40} lineheight={2} /></span>
                                <span className="option-discription"> <Skeleton height={35} width={400} /></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="mcq-option-1">
                                <span className="option-mcq"><Skeleton circle={true} height={40} width={40} lineheight={2} /></span>
                                <span className="option-discription"> <Skeleton height={35} width={400} /></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="mcq-option-1" >
                                <span className="option-mcq"><Skeleton circle={true} height={40} width={40} lineheight={2} /></span>
                                <span className="option-discription"> <Skeleton height={35} width={400} /></span>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <Skeleton height={35} />
                        </div>
                        <div className="col-4">
                            <Skeleton height={35} />
                        </div>
                        <div className="col-4">
                            <Skeleton height={35} />
                        </div>
                    </div>
                </div>
                <div className="col-3 answer-section">
                    <div className="col-12">
                        <div className="three-grid-sections">
                            <div className="status-colors">
                                <Skeleton circle={true} height={20} width={20} lineheight={2} />
                                <span><Skeleton width={50} height={20} /></span>
                            </div>
                            <div className="status-colors">
                                <Skeleton circle={true} height={20} width={20} lineheight={2} />
                                <span><Skeleton width={50} height={20} /></span>
                            </div>
                            <div className="status-colors">
                                <Skeleton circle={true} height={20} width={20} lineheight={2} />
                                <span><Skeleton width={50} height={20} /></span>
                            </div>
                        </div>
                        <div className="question-status-section">
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                            <Skeleton circle={true} height={40} width={40} lineheight={2} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (type.toUpperCase() === "PDFEDIT") {
        return (
            <div className="pdfedit-container">
                <div className="pdfpage">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className="blue-text question-text"><Skeleton height={20} width={20} /></span>
                            <span className="question-title"><Skeleton width={200} height={20} /></span>
                        </div>
                        <div>
                            <label className=""><Skeleton width={60} height={20} /></label>
                        </div>
                    </div>
                    <div className="mb-4">
                        <Skeleton count={4} height={20} />
                    </div>
                    <div className="mb-4">
                        <Skeleton count={10} height={20} />
                    </div>
                    <div className="mb-4">
                        <Skeleton count={7} height={20} />
                    </div>
                </div>
                <div className="pdfpageselection">
                    <Skeleton height={150} width={120} />
                    <Skeleton height={150} width={120} />
                    <Skeleton height={150} width={120} />
                </div>
                <div className={"clear"}></div>
            </div>
        );
    }
    else if (type.toUpperCase() === "BOTTOMLOADER") {
        return (
            <div className="skeleton p-1 text-center bottomloader">
                <Skeleton className={"mr-2"} height={"20px"} circle={true} width={"20px"} />
                <Skeleton className={"mr-2"} height={"20px"} circle={true} width={"20px"}/>
                <Skeleton className={"mr-2"} height={"20px"} circle={true} width={"20px"} />
                <Skeleton className={"mr-2"} height={"20px"} circle={true} width={"20px"} />
                <Skeleton className={"mr-2"} height={"20px"} circle={true} width={"20px"} />
            </div>
        );
    }
    else {
        return (
            <div className="skeleton p-4">
                <Skeleton count={count} height={lineheight} />
            </div>
        );
    }
}
export default _Skeleton;
