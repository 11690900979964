import { React, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    commonService,
    AppConfigData,

} from "../_services/common.service";
import { useLocation } from "react-router-dom";

var mini = true;

function _SideMenu() {
    let data = commonService.getLoginUserData();
    const size = useWindowSize();
    const history = useHistory();


    function toggleSidebar() {
        document.getElementById("main").classList.remove("menu-open");
        if (mini) {
            document.getElementById("aside-bar-main").style.width = "280px";
            document.getElementById("main").style.marginLeft = "280px";
            document.getElementById("main").classList.add("menu-open");
            mini = false;
        } else {
            document.getElementById("aside-bar-main").style.width = "100px";
            document.getElementById("main").style.marginLeft = "100px";
            mini = true;
            if (size.width <= 1440) {
                document.getElementById("aside-bar-main").style.width = "80px";
                document.getElementById("main").style.marginLeft = "80px";
                mini = true;
            } else {
                document.getElementById("aside-bar-main").style.width = "100px";
                document.getElementById("main").style.marginLeft = "100px";
                mini = true;
            }
        }
    }

    function logout() {
        const theme = window.localStorage.getItem("site-theme");
        localStorage.clear();
        window.localStorage.setItem("site-theme", theme);
        history.push("/");
    }
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            function handleResize() {

                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    return (
        <aside id="aside-bar-main">
            <div className="side-bar">
                <div className="toggler-menu">
                    <div onClick={toggleSidebar} className="menu-item">
                        <a>
                            <i className="fa-light fa-bars"></i>
                        </a>
                    </div>
                </div>
                <div className="main-section-menu">
                    <div className="upper-section">
                        <div
                            title={commonService.LabelDisplayText("MenuHome")}
                            className={
                                window.location.pathname.toLowerCase() === "/home"
                                    ? "menu-item active"
                                    : "menu-item"
                            }
                        >
                            <Link to="/home">
                                <span className="icon-sidebar">
                                    <i className="fa-light fa-house"></i>
                                    <i className="fa-solid fa-house"></i>
                                </span>
                                <span>{commonService.LabelDisplayText("MenuHome")}</span>
                            </Link>
                        </div>
                        {commonService.getLoginRoleData("wall", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/wall"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuWall")}
                            >
                                <Link to="/wall">
                                    <span className="icon-sidebar">
                                        <i className="fa-light fa-gallery-thumbnails"></i>
                                        <i className="fa-solid fa-gallery-thumbnails"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuWall")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("homework", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/homework"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuHomework")}
                            >
                                <Link to="/homework">
                                    <span className="icon-sidebar homework-icons">
                                        <i className="fa-light fa-book-open-cover"></i>
                                        <i className="fa-solid fa-book-open-cover"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuHomework")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("classwork", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/classwork"
                                        ? "menu-item active"
                                        : "menu-item"
                                }

                                title={commonService.LabelDisplayText("MenuClasswork")}
                            >
                                <Link to="/classwork">
                                    <span className="icon-sidebar">
                                        <i className="fa-light fa-book-open-reader"></i>
                                        <i className="fa-solid fa-book-open-reader"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuClasswork")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Notice", "IsView") || commonService.getLoginRoleData("Event", "IsView") || commonService.getLoginRoleData("Circular", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() ===
                                        "/notecircualreevents"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuNoteCircularEvents")}
                            >
                                <Link to="/notecircualreevents">
                                    <span className="icon-sidebar">
                                        <i className="fa-light fa-calendar-days"></i>
                                        <i className="fa-solid fa-calendar-days"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuNoteCircularEvents")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Timetable", "IsView") || commonService.getLoginRoleData("Liveclass", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/timetableliveclass"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuTimetableLiveclass")}
                            >
                                <Link to="/timetableliveclass">
                                    <span className="icon-sidebar">
                                        <i className="fa-light fa-calendar-range"></i>
                                        <i className="fa-solid fa-calendar-range"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuTimetableLiveclass")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Exam", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/exam" || window.location.pathname.toLowerCase() === "/examsyllabus"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuExam")}
                            >
                                <Link to="/exam">
                                    <span className="icon-sidebar">
                                        <i className="fa-light fa-file-signature"></i>
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuExam")}</span>
                                </Link>
                            </div>
                        </> : <></>}



                        {commonService.getLoginRoleData("Fees", "IsView") ? <>
                            {
                                data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "student" &&
                                <div
                                    className={
                                        window.location.pathname.toLowerCase() === "/fees"
                                            ? "menu-item active"
                                            : "menu-item"
                                    }
                                    title={commonService.LabelDisplayText("MenuFees")}
                                >
                                    <Link to="/fees">
                                        <span className="icon-sidebar  fees-icon-sidebar">
                                            <i className="fa-light fa-file-invoice-dollar"></i>
                                            <i className="fa-solid fa-file-invoice-dollar"></i>
                                        </span>
                                        <span>{commonService.LabelDisplayText("MenuFees")}</span>
                                    </Link>
                                </div>
                            }

                        </> : <></>}

                        {commonService.getLoginRoleData("Attendance", "IsView") || commonService.getLoginRoleData("Teacher Attendance", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/attendance"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuAttendance")}
                            >
                                <Link to="/attendance">
                                    <span className="icon-sidebar">
                                        <i className="fa-solid fa-calendar-lines-pen"></i>
                                        <i className="fa-light fa-calendar-lines-pen"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuAttendance")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Hostel", "IsView") ? <>
                            {
                                data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "student" &&
                                <div
                                    className={
                                        window.location.pathname.toLowerCase() === "/pocketmoney"
                                            ? "menu-item active"
                                            : "menu-item"
                                    }
                                    title={commonService.LabelDisplayText("HostelTransactionDetail")}
                                >
                                    <Link to="/pocketmoney">
                                        <span className="icon-sidebar  fees-icon-sidebar">
                                            <i className="fa-light fa-file-invoice-dollar"></i>
                                            <i className="fa-solid fa-file-invoice-dollar"></i>
                                        </span>
                                        <span>{commonService.LabelDisplayText("HostelTransactionDetail")}</span>
                                    </Link>
                                </div>
                            }
                        </> : <></>}

                        {commonService.getLoginRoleData("LMS Subject List", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/subjectlisting"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("MenuSubjectList")}
                            >
                                <Link to="/subjectlisting">
                                    <span className="icon-sidebar">
                                        <i className="fa-solid fa-book"></i>
                                        <i className="fa-light fa-book"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("MenuSubjectList")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Leave Application", "IsView") || commonService.getLoginRoleData("Teacher Leave Application", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/leave"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("Menuleave")}
                            >
                                <Link to="/leave">
                                    <span className="icon-sidebar">
                                        <i className="fa-solid icon-fi-rr-sign-out-1"></i>
                                        <i className="fa-light icon-fi-rr-sign-out-1"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("Menuleave")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                        {commonService.getLoginRoleData("Teachers Communication", "IsView") ? <>
                            {
                                data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "teacher" &&

                                <div
                                    className={
                                        window.location.pathname.toLowerCase() === "/communication"
                                            ? "menu-item active"
                                            : "menu-item"
                                    }
                                    title={commonService.LabelDisplayText("Communication")}
                                >
                                    <Link to="/communication">
                                        <span className="icon-sidebar">
                                            <i className="fa-solid fa-comments"></i>
                                            <i className="fa-light fa-comments"></i>
                                        </span>
                                        <span>{commonService.LabelDisplayText("Communication")}</span>
                                    </Link>
                                </div>
                            }

                        </> : <></>}

                        {commonService.getLoginRoleData("Poll", "IsView") ? <>
                            {
                                data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "teacher" &&
                                <div
                                    className={
                                        window.location.pathname.toLowerCase() === "/poll"
                                            ? "menu-item active"
                                            : "menu-item"
                                    }
                                    title={commonService.LabelDisplayText("Poll")}
                                >
                                    <Link to="/poll">
                                        <span className="icon-sidebar">
                                            <i className="fa-solid fa-square-poll-vertical"></i>
                                            <i className="fa-light fa-square-poll-vertical"></i>
                                        </span>
                                        <span>{commonService.LabelDisplayText("Poll")}</span>
                                    </Link>
                                </div>
                            }

                        </> : <></>}
                        {commonService.getLoginRoleData("My To Do", "IsView") || commonService.getLoginRoleData("To Do", "IsView") ? <>
                            {
                                data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "teacher" &&
                                <div
                                    className={
                                        window.location.pathname.toLowerCase() === "/todotask"
                                            ? "menu-item active"
                                            : "menu-item"
                                    }
                                    title={commonService.LabelDisplayText("ToDoTask")}
                                >
                                    <Link to="/todotask">
                                        <span className="icon-sidebar">
                                            <i className="fa-light fa-clipboard-list-check"></i>
                                            <i className="fa-solid fa-clipboard-list-check"></i>
                                        </span>
                                        <span>{commonService.LabelDisplayText("ToDoTask")}</span>
                                    </Link>
                                </div>
                            }

                        </> : <></>}
                        {
                            commonService.getLoginRoleData("Building Maintenance", "IsView") ? <>
                                {
                                    data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() !== "student" && data[0].MemberType.toLowerCase() !== "parent" &&
                                    <div
                                        className={
                                            window.location.pathname.toLowerCase() === "/complainlogbook"
                                                ? "menu-item active"
                                                : "menu-item"
                                        }
                                        title={commonService.LabelDisplayText("ComplainLogBook")}
                                    >
                                        <Link to="/ComplainLogBook">
                                            <span className="icon-sidebar">
                                                <i className="fa-solid fa-book"></i>
                                                <i className="fa-light fa-book"></i>
                                            </span>
                                            <span>{commonService.LabelDisplayText("ComplainLogBook")}</span>
                                        </Link>
                                    </div>
                                }
                            </> : <></>
                        }
                        <>
                            {commonService.getLoginRoleData("Teacher Charge Patraks", "IsView") ? <>
                                {
                                    data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "teacher" &&
                                    <div
                                        className={
                                            window.location.pathname.toLowerCase() === "/teacherchargepatrak"
                                                ? "menu-item active"
                                                : "menu-item"
                                        }
                                        title={commonService.LabelDisplayText("TeacherChargePatraks")}
                                    >
                                        <Link to="/TeacherChargePatrak">
                                            <span className="icon-sidebar">
                                                <i className="fa-solid fa-book"></i>
                                                <i className="fa-light fa-book"></i>
                                            </span>
                                            <span>{commonService.LabelDisplayText("TeacherChargePatraks")}</span>
                                        </Link>
                                    </div>
                                }

                            </> : <></>}
                        </>
                        {commonService.getLoginRoleData("Holiday", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/holiday"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("Holiday")}
                            >
                                <Link to="/holiday">
                                    <span className="icon-sidebar">
                                        <i className="fa-solid fa-calendar-lines-pen"></i>
                                        <i className="fa-light fa-calendar-lines-pen"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("Holiday")}</span>
                                </Link>
                            </div>
                        </> : <></>}
                        {commonService.getLoginRoleData("Institute Page", "IsView") ? <>
                            <div
                                className={
                                    window.location.pathname.toLowerCase() === "/institutepage"
                                        ? "menu-item active"
                                        : "menu-item"
                                }
                                title={commonService.LabelDisplayText("InstitutePage")}
                            >
                                <Link to="/institutepage">
                                    <span className="icon-sidebar">
                                        <i className="fa-solid icon-fi-rr-bank"></i>
                                        <i className="fa-light icon-fi-rr-bank"></i>
                                    </span>
                                    <span>{commonService.LabelDisplayText("InstitutePage")}</span>
                                </Link>
                            </div>
                        </> : <></>}

                    </div>
                    <div className="lower-section">
                        <div
                            className={
                                window.location.pathname.toLowerCase() === "/profile"
                                    ? "menu-item active"
                                    : "menu-item"
                            }
                            title={commonService.LabelDisplayText("MenuProfile")}
                        >
                            <Link to="/profile">
                                <span className="icon-sidebar">
                                    <i className="fa-light fa-user-vneck-hair"></i>
                                    <i className="fa-solid fa-user-vneck-hair"></i>
                                </span>
                                <span>{commonService.LabelDisplayText("MenuProfile")}</span>
                            </Link>
                        </div>
                        <div
                            className={
                                window.location.pathname.toLowerCase() === "/updateinfo"
                                    ? "menu-item active"
                                    : "menu-item"
                            }
                            title={commonService.LabelDisplayText("MenuUpdateInfo")}
                        >
                            <Link to="/updateinfo">
                                <span className="icon-sidebar">
                                    <i className="fa-light fa-square-question"></i>
                                    <i className="fa-solid fa-square-question"></i>
                                </span>
                                <span>{commonService.LabelDisplayText("MenuUpdateInfo")}</span>
                            </Link>
                        </div>

                        <div
                            className={
                                window.location.pathname.toLowerCase() === "/"
                                    ? "menu-item active"
                                    : "menu-item"
                            }
                            title={commonService.LabelDisplayText("MenuLogout")}
                        >
                            <a onClick={logout}>
                                <span className="icon-sidebar">
                                    <i className="fa-light fa-power-off"></i>
                                    <i className="fa-solid fa-power-off"></i>
                                </span>
                                <span>{commonService.LabelDisplayText("MenuLogout")}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default _SideMenu;
