import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";

function ParentView() {

    const [parent, setparent] = useState({ IsData: false, data: [] });

    function BindParent() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "PARENT"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setparent({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setparent({ IsData: true, data: [] });
                }
            });
    }

    React.useEffect(() => {
        BindParent();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile">

                    {
                        parent.IsData ?
                            (
                                <>
                                    {parent.data.map((item, index) => (
                                        <>
                                            {item.IsFather &&
                                                (<div className="inner-header-for-section float-left">
                                                        <h2 className="m-0">Father Information</h2>
                                                    </div>) 
                                            }
                                            {item.IsMother &&
                                                (<div className="inner-header-for-section float-left">
                                                    <h2 className="m-0">Mother Information</h2>
                                                </div>)
                                            }
                                            <div className="col-lg-12 float-left mt-1">
                                                <div className="col-lg-4 float-left">
                                                    <div className="col-lg-12 my-1 event-description">
                                                        <b className="student-roll-no mt-1">Name : </b>{item.FullName}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Date Of Birth : </b>{commonService.getDateInFormat(item.DateOfBirth)}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Religion : </b>{item.Religion_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Education : </b>{item.EducationalQualification_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Passport # : </b>{item.PassportNo}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Occupation : </b>{item.Occupation_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Profession  : </b>{item.Profession_Term}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 float-left">
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Mobile # : </b>{item.MobileNo}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Anniversary Date : </b>{commonService.getDateInFormat(item.DateOfAnniversary)}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Cast : </b>{item.Cast_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Annual Salary : </b>{item.AnnualSalary}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Licence # : </b>{item.LicenseNo}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Business Name : </b>{item.BusinessName}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 float-left">
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Email : </b>{item.Email}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Blood Group : </b>{item.BloodGroup_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Sub Caste : </b>{item.SubCast_Term}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Aadhar # : </b>{item.AadharNo}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Pancard # : </b>{item.PancardNo}
                                                    </div>
                                                    <div className="col-lg-12 my-1">
                                                        <b className="student-roll-no mt-1">Designation : </b>{item.Designation}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </>
                            ) :
                            (
                                <>
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                    <Skeleton count={1} height={30} />
                                    <_Skeleton type={_SkeletonType.Table} />
                                </>
                            )
                    }
                </div>
            </div>
        </>
    );
}

export default ParentView;
