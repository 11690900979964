import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ZoomMtg } from '@zoom/meetingsdk';
const KJUR = require('jsrsasign')
ZoomMtg.preLoadWasm();

ZoomMtg.prepareWebSDK();
function LiveClassWebView() {
    const history = useHistory();
    document.getElementById("main").classList.add("login")
    document.getElementById("main").classList.remove("menu-open");
    document.getElementById("main").style.marginLeft = null;
    React.useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);

        var leaveUrl = '/timetableliveclass'
        var signature = "";
        if (searchParams.has('signature')) {
            signature = searchParams.get('signature');
        }

        var sdkKey = "";
        if (searchParams.has('sdkkey')) {
            sdkKey = searchParams.get('sdkkey');
        }

        var meetingNumber = "";
        if (searchParams.has('meetingnumber')) {
            meetingNumber = searchParams.get('meetingnumber');
        }

        var passWord = "";
        if (searchParams.has('password')) {
            passWord = searchParams.get('password');
        }

        var userName = "";
        if (searchParams.has('username')) {
            userName = searchParams.get('username');
        }
        if (signature !== "" && sdkKey !== "" && meetingNumber !== "" && passWord !== "" && userName !== "") {
            document.getElementById('zmmtg-root').style.display = 'block'
            ZoomMtg.init({
                leaveUrl: leaveUrl,
                patchJsMedia: true,
                success: (success) => {
                    console.log(success)

                    ZoomMtg.join({
                        signature: signature,
                        sdkKey: sdkKey,
                        meetingNumber: meetingNumber,
                        passWord: passWord,
                        userName: userName,
                        userEmail: "",
                        tk: "",
                        zak: "",
                        success: (success) => {
                            console.log(success)
                        },
                        error: (error) => {
                            console.log(error)
                        }
                    })

                },
                error: (error) => {
                    console.log(error)
                }
            })
        }
        else {
            history.push("/timetableliveclass");
        }
    }, []);

    //function generateSignature(key, secret, meetingNumber, role) {

    //    const iat = Math.round(new Date().getTime() / 1000) - 30
    //    const exp = iat + 60 * 60 * 2
    //    const oHeader = { alg: 'HS256', typ: 'JWT' }

    //    const oPayload = {
    //        sdkKey: key,
    //        appKey: key,
    //        mn: meetingNumber,
    //        role: role,
    //        iat: iat,
    //        exp: exp,
    //        tokenExp: exp
    //    }

    //    const sHeader = JSON.stringify(oHeader)
    //    const sPayload = JSON.stringify(oPayload)
    //    const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, secret)
    //    return sdkJWT
    //}

    return (
        <>
            <h1 className="page-header my-4"> Live Class</h1>
        </>
    );
}

export default LiveClassWebView;
