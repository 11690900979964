import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "react-datepicker/dist/react-datepicker.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { alertService, AlertType } from "../_services/alert.service";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";

function Classwork() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [Classwork, setClasswork] = useState({ IsData: false, data: [] });
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [showAddEditPopup, setShowAddEditPopup] = useState(false);
    const [TeacherSubject, setTeacherSubject] = useState([]);
    const [SubjectEnrollStudent, setSubjectEnrollStudent] = useState([]);
    const [AttachLink, setAttachLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachYoutubeLink, setAttachYoutubeLink] = useState({
        name: "",
        link: "",
        errors: "",
    });
    const [AttachFiles, setAttachFiles] = useState({
        name: "",
        link: null,
        errors: "",
    });
    const [showLinkPopup, setShowLinkPopup] = useState(false);
    const [showYoutubeLinkPopup, setShowYoutubeLinkPopup] = useState(false);
    const [showFileSelectPopup, setShowFileSelectPopup] = useState(false);
    const [showScheduleDatePopup, setShowScheduleDatePopup] = useState(false);
    const [IsClassworkSaveing, setIsClassworkSaveing] = useState(false);
    const [IsClassworkListDateLodding, setIsClassworkListDateLodding] =
        useState(false);
    const [ClassworkData, setClassworkData] = useState({
        ClassWorkID: "00000000-0000-0000-0000-000000000000",
        GradeID: "00000000-0000-0000-0000-000000000000",
        DivisionID: "00000000-0000-0000-0000-000000000000",
        SubjectID: "00000000-0000-0000-0000-000000000000",
        DateOfClassWork: new Date(),
        ClassWorkTitle: "",
        ClassWorkDetails: "",
        ExpectingDateOfCompletion: new Date(),
        StartTime: "",
        EndTime: "",
        SaveType: "",
        TagMemberIDs: "",
        FilesDetails: [],
        IsForAll: true,
        RequestFrom: "Web",
        StandardName: "",
        DivisionName: "",
        SubjectName: "",
        errors: {
            ClassWorkTitle: "",
            ExpectingDateOfCompletion: "",
            SubjectID: "",
            DateOfClassWork: "",
        },
    });
    const [editorClassworksDetails, setEditorClassworksDetails] = useState(() =>
        EditorState.createEmpty()
    );

    const [readstatus, setreadstatus] = useState({ isdata: false, data: [] });
    const [studentreadstatuspopup, setstudentreadstatuspopup] = useState(false);

    const params = new URLSearchParams(window.location.search);

    function BindClassworkList(_sRow) {
        let noOfData = 21;
        document
            .getElementById("root")
            .removeEventListener("scroll", WindowScrolling);

        setIsClassworkListDateLodding(true);
        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: data[0].MemberType.toLowerCase() === "student" ? "Send" : "",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                sRow: _sRow,
                noOfData: noOfData,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/classwork/getlistbymember",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                document.getElementById("root").addEventListener("scroll", WindowScrolling);
                setIsClassworkListDateLodding(false);
                if (json.status_code === 1) {
                    if (_sRow === 1) {
                        setClasswork({ IsData: true, data: json.data });
                        json.data.map((item, index) => {
                            if (item.IsRead === 0) { approvereadpost(item.ClassWorkID, "ClassWork") }
                        });
                        
                    } else {
                        setClasswork((oldData) => ({
                            IsData: true,
                            data: [...oldData.data, ...json.data],
                        }));
                    }

                    if (json.data.length < noOfData) {
                        document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    }
                } else if (json.status_code === 0) {
                    /* setClasswork({ IsData: true, data: [] });*/
                    document.getElementById("root").removeEventListener("scroll", WindowScrolling);
                    setClasswork((oldData) => ({
                        IsData: true,
                        data: oldData.data,
                    }));
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function StandardDivisionSubjectChange(IDs) {
        setSubjectEnrollStudent([]);
        if (IDs !== "") {
            setClassworkData({
                ...ClassworkData,
                ["GradeID"]: IDs.split("|")[0],
                ["DivisionID"]: IDs.split("|")[1],
                ["SubjectID"]: IDs.split("|")[2],
            });
            get_student_listbystudentsubjectenrollment(
                IDs.split("|")[0],
                IDs.split("|")[1],
                IDs.split("|")[2]
            );
        } else {
            setClassworkData({
                ...ClassworkData,
                ["GradeID"]: "00000000-0000-0000-0000-000000000000",
                ["DivisionID"]: "00000000-0000-0000-0000-000000000000",
                ["SubjectID"]: "00000000-0000-0000-0000-000000000000",
            });
        }
    }

    function handleShowAddEditPopup(ID, StandardName, DivisionName, SubjectName) {
        get_teacher_standarddivisionsubject();
        if (ID !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: ID,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/classwork/getalldetailsbyid",
                reqClasswork
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        setClassworkData({
                            ClassWorkID: json.data.detailsData[0].ClassWorkID,
                            GradeID: json.data.detailsData[0].GradeID,
                            DivisionID: json.data.detailsData[0].DivisionID,
                            SubjectID: json.data.detailsData[0].SubjectID,
                            DateOfClassWork: new Date(
                                json.data.detailsData[0].DateOfClassWork
                            ),
                            ClassWorkTitle: json.data.detailsData[0].ClassWorkTitle,
                            ClassWorkDetails: json.data.detailsData[0].ClassWorkDetails,
                            ExpectingDateOfCompletion: new Date(
                                json.data.detailsData[0].ExpectingDateOfCompletion
                            ),
                            StartTime: json.data.detailsData[0].StartTime,
                            EndTime: json.data.detailsData[0].EndTime,
                            SaveType: json.data.detailsData[0].SaveType,
                            //TagMemberIDs: json.data.detailsData[0].IsForAll
                            //    ? ""
                            //    : json.data.detailsData[0].TagMemberIDs,
                            TagMemberIDs: json.data.detailsData[0].TagMemberIDs,
                            FilesDetails: json.data.fileData,
                            IsForAll: json.data.detailsData[0].IsForAll,
                            RequestFrom: "Web",
                            StandardName: StandardName,
                            DivisionName: DivisionName,
                            SubjectName: SubjectName,
                            errors: {
                                ClassWorkTitle: "",
                                ExpectingDateOfCompletion: "",
                                SubjectID: "",
                                DateOfClassWork: "",
                            },
                        });

                        setEditorClassworksDetails(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(json.data.detailsData[0].ClassWorkDetails)
                                        .contentBlocks
                                )
                            )
                        );

                        get_student_listbystudentsubjectenrollment(
                            json.data.detailsData[0].GradeID,
                            json.data.detailsData[0].DivisionID,
                            json.data.detailsData[0].SubjectID
                        );
                        setShowAddEditPopup(true);
                    }
                    //else if (json.status_code === 0) {

                    //}
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setClassworkData({
                ClassWorkID: "00000000-0000-0000-0000-000000000000",
                GradeID: "00000000-0000-0000-0000-000000000000",
                DivisionID: "00000000-0000-0000-0000-000000000000",
                SubjectID: "00000000-0000-0000-0000-000000000000",
                DateOfClassWork: new Date(),
                ClassWorkTitle: "",
                ClassWorkDetails: "",
                ExpectingDateOfCompletion: new Date(),
                StartTime: "",
                EndTime: "",
                SaveType: "",
                TagMemberIDs: "",
                FilesDetails: [],
                IsForAll: true,
                RequestFrom: "Web",
                StandardName: "",
                DivisionName: "",
                SubjectName: "",
                errors: {
                    ClassWorkTitle: "",
                    ExpectingDateOfCompletion: "",
                    SubjectID: "",
                    DateOfClassWork: "",
                },
            });
            setEditorClassworksDetails(EditorState.createEmpty());
            setShowAddEditPopup(true);
        }
    }

    function get_teacher_standarddivisionsubject() {
        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/get_teacher_standarddivisionsubject",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].IDs.toLocaleUpperCase(),
                            label: json.data[i].Text,
                        });
                    }
                    setTeacherSubject(options);
                } else if (json.status_code === 0) {
                    setTeacherSubject([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function get_student_listbystudentsubjectenrollment(
        standardID,
        divisionID,
        subjectID
    ) {
        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                standardID: standardID,
                divisionID: divisionID,
                subjectID: subjectID,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/home/get_student_listbystudentsubjectenrollment",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let options = [{ label: "All", value: "*" }];
                    for (let i = 0; i < json.data.length; i++) {
                        options.push({
                            value: json.data[i].StudentID.toLocaleUpperCase(),
                            label: json.data[i].RollNo + " - " + json.data[i].StudentFullName,
                        });
                    }
                    setSubjectEnrollStudent(options);
                } else if (json.status_code === 0) {
                    setSubjectEnrollStudent([]);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function ButtonClickForSave(SaveType) {
        if (SaveType.toUpperCase() === "SCHEDULE") {
            setShowScheduleDatePopup(true);
        } else if (
            SaveType.toUpperCase() === "SEND" ||
            SaveType.toUpperCase() === "DRAFT"
        ) {
            if (SaveType.toUpperCase() === "SEND") {
                setClassworkData({
                    ...ClassworkData,
                    ["DateOfClassWork"]: new Date(),
                });
            }
            AddEditClasswork(SaveType);
        }
    }

    function AddEditClasswork(SaveType) {
        if (validate()) {
            setIsClassworkSaveing(true);
            setShowScheduleDatePopup(false);
            let formData = new FormData();
            formData.append("ClassWorkID", ClassworkData.ClassWorkID);
            formData.append("GradeID", ClassworkData.GradeID);
            formData.append("DivisionID", ClassworkData.DivisionID);
            formData.append("SubjectID", ClassworkData.SubjectID);
            formData.append(
                "DateOfClassWork",
                commonService.getDateTimeInDBFormat(ClassworkData.DateOfClassWork)
            );
            formData.append("ClassWorkTitle", ClassworkData.ClassWorkTitle);
            formData.append(
                "ClassWorkDetails",
                draftToHtml(convertToRaw(editorClassworksDetails.getCurrentContent()))
            );
            formData.append(
                "ExpectingDateOfCompletion",
                commonService.getDateTimeInDBFormat(
                    ClassworkData.ExpectingDateOfCompletion
                )
            );
            formData.append(
                "StartTime",
                commonService.getDateTimeInDBFormat(ClassworkData.DateOfClassWork)
            );
            formData.append(
                "EndTime",
                commonService.getDateTimeInDBFormat(
                    ClassworkData.ExpectingDateOfCompletion
                )
            );
            formData.append("SaveType", SaveType);
            formData.append("TagMemberIDs", ClassworkData.TagMemberIDs.replace("*,", ""));
            formData.append(
                "IsForAll",
                ClassworkData.TagMemberIDs === "" ? true : false
            );
            formData.append("RequestFrom", "Web");
            let NewIndex = 0;
            for (let i = 0; i < ClassworkData.FilesDetails.length; i++) {
                if (
                    ClassworkData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    ClassworkData.FilesDetails[i].FileMimeType.toUpperCase() !== "FILE"
                ) {
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilesID",
                        ClassworkData.FilesDetails[i].FilesID
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].Name",
                        ClassworkData.FilesDetails[i].Name
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FilePath",
                        ClassworkData.FilesDetails[i].FilePath
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].OrderNo",
                        ClassworkData.FilesDetails[i].OrderNo
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileType",
                        ClassworkData.FilesDetails[i].FileType
                    );
                    formData.append(
                        "FilesDetails[" + NewIndex + "].FileMimeType",
                        ClassworkData.FilesDetails[i].FileMimeType
                    );
                    formData.append("FilesDetails[" + NewIndex + "].RequestFrom", "Web");
                    NewIndex = NewIndex + 1;
                } else if (
                    ClassworkData.FilesDetails[i].FilesID ===
                    "00000000-0000-0000-0000-000000000000" &&
                    ClassworkData.FilesDetails[i].FileMimeType.toUpperCase() === "FILE"
                ) {
                    formData.append("FormFiles", ClassworkData.FilesDetails[i].FilePath);
                }
            }
            const reqClasswork = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(AppConfigData.APIBasePath + "v1/classwork/save", reqClasswork)
                .then((response) => response.json())
                .then(function (json) {
                    setIsClassworkSaveing(false);
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindClassworkList(1);
                        if (SaveType.toUpperCase() === "SEND") {
                            commonService.sendNotification();
                        }
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }

                    if (params.has("add")) {
                        if (params.get("add") === "classwork") {
                            history.push("wall");
                        } else {
                            setShowAddEditPopup(false);
                        }
                    } else {
                        setShowAddEditPopup(false);
                    }
                });
        }
    }

    function validate() {
        let isValid = true;
        let errors = {};
        if (!ClassworkData.ClassWorkTitle) {
            errors.ClassWorkTitle = "Title is required";
            isValid = false;
        } else {
            errors.ClassWorkTitle = "";
        }

        if (!ClassworkData.ExpectingDateOfCompletion) {
            errors.ExpectingDateOfCompletion = "Due date is required";
            isValid = false;
        } else {
            errors.ExpectingDateOfCompletion = "";
        }

        if (!ClassworkData.DateOfClassWork) {
            errors.DateOfClassWork = "Schedule date is required";
            isValid = false;
        } else {
            errors.DateOfClassWork = "";
        }

        if (
            !ClassworkData.SubjectID ||
            ClassworkData.SubjectID === "00000000-0000-0000-0000-000000000000"
        ) {
            errors.SubjectID = "Subject is required";
            isValid = false;
        } else {
            errors.SubjectID = "";
        }

        setClassworkData({
            ...ClassworkData,
            ["errors"]: errors,
        });

        return isValid;
    }

    function AddLinkInFilesDetails() {
        setAttachLink({ ...AttachLink, ["errors"]: "" });
        if (AttachLink.link !== "") {
            var res = AttachLink.link.match(AppConfigData.regex.URL);
            if (res == null) {
                setAttachLink({ ...AttachLink, ["errors"]: "Link is not valid" });
                return false;
            } else {
                let FilesDetails = ClassworkData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachLink.name,
                    FilePath: AttachLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "LINK",
                    FileMimeType: "LINK",
                    RequestFrom: "Web",
                });
                setClassworkData({ ...ClassworkData, ["FilesDetails"]: FilesDetails });
                setShowLinkPopup(false);
                return true;
            }
        } else {
            setAttachLink({ ...AttachLink, ["errors"]: "Link is required" });
        }
    }

    function AddYoutubeLinkInFilesDetails() {
        setAttachYoutubeLink({ ...AttachYoutubeLink, ["errors"]: "" });
        if (AttachYoutubeLink.link !== "") {
            var res = AttachYoutubeLink.link.match(AppConfigData.regex.YoutubeEmbed);
            if (res == null) {
                setAttachYoutubeLink({
                    ...AttachYoutubeLink,
                    ["errors"]: "Youtube embed link is not valid",
                });
                return false;
            } else {
                let FilesDetails = ClassworkData.FilesDetails;
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachYoutubeLink.name,
                    FilePath: AttachYoutubeLink.link,
                    OrderNo: FilesDetails.length + 1,
                    FileType: "YOUTUBE",
                    FileMimeType: "YOUTUBE",
                    RequestFrom: "Web",
                });
                setClassworkData({ ...ClassworkData, ["FilesDetails"]: FilesDetails });
                setShowYoutubeLinkPopup(false);
                return true;
            }
        } else {
            setAttachYoutubeLink({
                ...AttachYoutubeLink,
                ["errors"]: "Youtube embed link is required",
            });
        }
    }

    function AddFileSelectInFilesDetails() {
        setAttachFiles({ ...AttachFiles, ["errors"]: "" });
        if (AttachFiles.link !== null) {
            let FilesDetails = ClassworkData.FilesDetails;
            for (let i = 0; i < AttachFiles.link.length; i++) {
                FilesDetails.push({
                    FilesID: "00000000-0000-0000-0000-000000000000",
                    Name: AttachFiles.link[i].name,
                    FilePath: AttachFiles.link[i],
                    OrderNo: FilesDetails.length + 1,
                    FileType: commonService.getFileType(AttachFiles.link[i]),
                    FileMimeType: "FILE",
                    RequestFrom: "Web",
                });
            }

            setClassworkData({ ...ClassworkData, ["FilesDetails"]: FilesDetails });
            setShowFileSelectPopup(false);
        } else {
            setAttachFiles({
                ...AttachFiles,
                ["errors"]: "File selection is required",
            });
        }
    }

    function CreateFilesDetailsIU(FilesDetail, indexFile) {
        let Icon = "";

        if (FilesDetail.FileType.toUpperCase() === "LINK") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["LINK"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "YOUTUBE") {
            Icon = (
                <i
                    className={`file-list-icon ${AppConfigData.fileIcon["YOUTUBE"]}`}
                ></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "IMAGE") {
            if (
                FilesDetail.FileMimeType === "FILE" &&
                FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000"
            ) {
                Icon = (
                    <img
                        src={URL.createObjectURL(FilesDetail.FilePath)}
                        className="file-list-icon"
                    />
                );
            } else {
                Icon = <img src={FilesDetail.FilePath} className="file-list-icon" />;
            }
        } else if (FilesDetail.FileType.toUpperCase() === "AUDIO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["AUDIO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "VIDEO") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["VIDEO"]}`}></i>
            );
        } else if (FilesDetail.FileType.toUpperCase() === "PDF") {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></i>
            );
        } else {
            Icon = (
                <i className={`file-list-icon ${AppConfigData.fileIcon["FILE"]}`}></i>
            );
        }

        return (
            <div className="card-body p-0 card-home-work-grid">
                {Icon}
                <div
                    className="card-text-inner pointer-cursor"
                    title="Click here for view attachment"
                    onClick={() => ViewFileFromClassworkAddPopup(FilesDetail)}
                >
                    <p>{FilesDetail.Name}</p>
                    <p>
                        {FilesDetail.FileType.toUpperCase() === "LINK" ||
                            FilesDetail.FileType.toUpperCase() === "YOUTUBE"
                            ? FilesDetail.FilePath
                            : ""}
                    </p>
                </div>
                <div className="close-btn-area">
                    {data[0].MemberType.toLowerCase() !== "student" &&
                        ClassworkData.SaveType.toUpperCase() !== "SEND" && (
                            <i
                                className="fa-regular fa-xmark pointer-cursor"
                                onClick={() =>
                                    confirmAlert({
                                        title: "Delete",
                                        message: "Are you sure delete ?",
                                        buttons: [
                                            {
                                                label: "Yes",
                                                onClick: () =>
                                                    fnDeleteFilesDetails(FilesDetail.FilesID, indexFile),
                                            },
                                            {
                                                label: "No",
                                            },
                                        ],
                                    })
                                }
                            ></i>
                        )}
                </div>
            </div>
        );
    }

    function ViewFileFromClassworkAddPopup(FilesDetail) {
        if (FilesDetail.FilesID === "00000000-0000-0000-0000-000000000000") {
            alertService.alert({
                type: AlertType.Error,
                message:
                    "Without upload you can not able to view this file , if you can't view this file then open from your destination folder",
            });
        } else {
            let FilesDetails = [];
            FilesDetails.push(FilesDetail);
            viewFilePopupService.viewFilePopup({ data: FilesDetails });
        }
    }

    function fnDeleteFilesDetails(id, key) {
        let FilesDetails = ClassworkData.FilesDetails;
        if (id === "00000000-0000-0000-0000-000000000000") {
            FilesDetails.splice(key, 1);
            setClassworkData({ ...ClassworkData, ["FilesDetails"]: FilesDetails });
        } else if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/home/file_deletebyid", reqClasswork)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        FilesDetails.splice(key, 1);
                        setClassworkData({
                            ...ClassworkData,
                            ["FilesDetails"]: FilesDetails,
                        });
                    } else if (json.status_code === 0) {
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function fnDeleteClasswork(id) {
        if (id !== "00000000-0000-0000-0000-000000000000" && id !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(AppConfigData.APIBasePath + "v1/classwork/deletebyid", reqClasswork)
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindClassworkList(1);
                        setShowAddEditPopup(false);
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    function getDropdownButtonLabel({ value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `All`;
        } else {
            return `${value.length} selected`;
        }
    }

    function StudentSelectionChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            setClassworkData({
                ...ClassworkData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            setClassworkData({
                ...ClassworkData, TagMemberIDs: ""
            })
        } else if (event.action === "deselect-option") {
            setClassworkData({
                ...ClassworkData, TagMemberIDs: Array.isArray(value.filter((o) => o.value !== "*")) ? value.filter((o) => o.value !== "*").map((x) => x.value).join() : ""
            })
        } else if (value.length === this.options.length - 1) {
            setClassworkData({
                ...ClassworkData, TagMemberIDs: Array.isArray(this.options) ? this.options.map((x) => x.value).join() : ""
            })
        } else {
            setClassworkData({
                ...ClassworkData, TagMemberIDs: Array.isArray(value) ? value.map((x) => x.value).join() : ""
            })

        }
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Classwork", "IsView")) {

            document.getElementById("root").addEventListener("scroll", WindowScrolling);
            BindClassworkList(1);

            if (data[0].MemberType.toLowerCase() !== "student") {
                if (params.has("add")) {
                    if (params.get("add") === "classwork") {
                        handleShowAddEditPopup("", "", "", "");
                    }
                }
            }
            return () => {
                document
                    .getElementById("root")
                    .removeEventListener("scroll", WindowScrolling);
            };
        } else {
            history.push("/access");
        }
    }, []);

    function WindowScrolling(event) {

        if (event.srcElement.scrollTop + window.innerHeight >= (event.srcElement.scrollHeight - 100)) {
            BindClassworkList(document.getElementsByClassName("classworkdata").length+1);
        }

        let AllPost = document.getElementsByClassName("callisreadapprove");
        if (AllPost != null && AllPost.length != 0) {
            for (let i = 0; i < AllPost.length; i++) {
                if (isInViewport(AllPost[i])) {
                    let AID = AllPost[i].getAttribute("aid");
                    let AT = AllPost[i].getAttribute("at");
                    approvereadpost(AID, AT);
                    AllPost[i].classList.remove("callisreadapprove");
                }
            }
        }
    }

    function approvereadpost(AID, AT) {
        const reqreadapprovepostdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associatinID: AID,
                associationType: AT,
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readapprovepost",
            reqreadapprovepostdata
        )
            .then((response) => response.json())

            .then(function (json) {
                if (json.status_code === 1) {
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function isInViewport(e) {
        const rect = e.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    function studentreadstatus(aid) {
        getstudentreadstatus(aid);
        setstudentreadstatuspopup(true);
    }

    function getstudentreadstatus(aid) {
        setreadstatus({ isdata: false, data: [] });
        const reqstudentreadstatusdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                associationID: aid,
                associationType: "ClassWork",
                searchText: "",
                sRow: 0,
                noOfData: 0,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/readunreadlist",
            reqstudentreadstatusdata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    var a = json.data;
                    setreadstatus({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setreadstatus({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    return (
        <>
            <h1 className="page-header my-4"> {commonService.LabelDisplayText("MenuClasswork")}</h1>
            <div className="background-theme-color sticky-top">
                <div className="search-top-section">
                    <div className="row">
                        <div className="col-lg-12 col-xl-7">
                            <div className="header-search-area">
                                <input
                                    type="text"
                                    className=""
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    onChange={(e) => setTxtSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                            <div className="search-right-cal d-flex align-items-center">
                                <div className="header-date-area w-100 mr-3">
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerFrom")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtStartDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {" "}
                                            {commonService.LabelDisplayText("DatepickerTo")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtEndDate}
                                            minDate={txtStartDate && txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtEndDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                </div>
                                <div className="right-icon-cal d-flex align-items-center">
                                    {IsClassworkListDateLodding === false ? (
                                        <div
                                            className="search-icon-img pointer-cursor"
                                            onClick={() => BindClassworkList(1)}
                                        >
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </div>
                                    ) : (
                                        <div className="search-icon-img pointer-cursor">
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    )}
                                    {data[0].MemberType.toLowerCase() !== "student" && commonService.getLoginRoleData("Classwork", "IsCreate") && (
                                        <div
                                            className="plus-btn pointer-cursor ml-3"
                                            onClick={() => handleShowAddEditPopup("", "", "", "")}
                                        >
                                            <i className="fa-regular fa-plus"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-result-block">
                    <span>
                        Result For{" "}
                        <span>
                            {txtSearch +
                                " From : " +
                                commonService.getDateInFormat(txtStartDate) +
                                " To : " +
                                commonService.getDateInFormat(txtEndDate)}
                        </span>
                    </span>
                </div>
            </div>

            {!Classwork.IsData ? (
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                    </div>
                </div>
            ) : Classwork.data.length === 0 ? (
                <div className="col-12">
                    <_NoDataFound fontsize={"300px"} />
                </div>
            ) : (
                <>
                    <div className="row">
                        {Classwork.data.map((item, index) => (
                            <div
                                className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3 classworkdata ${item.IsRead == 0 && item.SaveType == "Send"
                                    ? "callisreadapprove"
                                    : ""
                                    }`}
                                key={index}
                                aid={item.ClassWorkID}
                                at="ClassWork"
                            >
                                <div className="card home-card-homework">
                                    <div className="card-body">
                                        <div
                                            className="d-flex justify-content-between pointer-cursor"
                                            onClick={() =>
                                                handleShowAddEditPopup(
                                                    item.ClassWorkID,
                                                    item.StandardName,
                                                    item.DivisionName,
                                                    item.SubjectName
                                                )
                                            }
                                        >
                                            <h2
                                                title={item.ClassWorkTitle}
                                                className="card-body-header align-items-center"
                                            >
                                                {item.ClassWorkTitle}
                                            </h2>
                                            <i className="fa-regular fa-square-arrow-up-right expand-classwork-icon"></i>
                                        </div>
                                        <label className="language-label mr-1">
                                            {item.SubjectName}
                                        </label>
                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                            <label className="std-homework-label">
                                                {item.StandardName + " - " + item.DivisionName}
                                            </label>
                                        )}
                                        {data[0].MemberType.toLowerCase() === "teacher" ? (
                                            <>
                                                <label className={`pdf-label ml-1`}>
                                                    {item.SaveType}
                                                </label>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <p
                                            className="card-body-content mt-2"
                                            dangerouslySetInnerHTML={{
                                                __html: item.ClassWorkDetails,
                                            }}
                                        >
                                            {/*{item.ClassWorkDetails}*/}
                                        </p>

                                        <div className="mt-3">
                                            {item.Files ? (
                                                JSON.parse(item.Files).map(
                                                    (itemFile, indexFile) =>
                                                        indexFile < 3 && (
                                                            <label
                                                                onClick={() =>
                                                                    viewFilePopupService.viewFilePopup({
                                                                        data: JSON.parse(item.Files),
                                                                    })
                                                                }
                                                                key={indexFile}
                                                                className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                                            >
                                                                {itemFile.FileType}
                                                            </label>
                                                        )
                                                )
                                            ) : (
                                                <label></label>
                                            )}
                                        </div>
                                        <div className="section-for-footer-card">
                                            <div className="left-section">
                                                <span className="fa-light fa-calendar-lines calendar-icons"></span>
                                                <p>
                                                    {commonService.getDateInFormat(item.DateOfClassWork)}
                                                </p>
                                            </div>
                                            {data[0].MemberType.toLowerCase() === "teacher" ? (
                                                <>
                                                    <div
                                                        className="upload-section pointer-cursor"
                                                        onClick={() => studentreadstatus(item.ClassWorkID)}
                                                    >
                                                        <i className=" mr-1 fa-regular fa-check-double"></i>
                                                        <span>{commonService.LabelDisplayText("ReadStatus")}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <div className="right-section">
                                                <div
                                                    className="user-pic"
                                                    title={item.CreateByName}
                                                    style={{
                                                        backgroundColor: commonService.CreateColor(
                                                            item.Initials
                                                        ).BGColor,
                                                        color: commonService.CreateColor(item.Initials)
                                                            .FontColor,
                                                    }}
                                                >
                                                    {item.Avatar !== "" ? (
                                                        <img
                                                            src={AppConfigData.ERPFileBasePath + item.Avatar}
                                                        />
                                                    ) : (
                                                        item.Initials
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {
                        Classwork.data.length !== 0 && IsClassworkListDateLodding &&
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                            </div>
                        </div>
                    }

                </>
            )}

            <Modal
                show={showAddEditPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowAddEditPopup(false)}
                className="modal-theme-color"
                contentClassName={"background-transparent-color"}
                dialogClassName={"modal-full-width"}
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() =>
                            data[0].MemberType.toLowerCase() !== "student"
                                ? params.has("add") === true
                                    ? params.get("add") === "classwork"
                                        ? history.push("wall")
                                        : setShowAddEditPopup(false)
                                    : setShowAddEditPopup(false)
                                : setShowAddEditPopup(false)
                        }
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h1 className="page-header mb-3">{commonService.LabelDisplayText("ClassworkTitle")}</h1>
                                    {
                                        data[0].MemberType.toLowerCase() !== "student" ? (
                                            IsClassworkSaveing === false ? (
                                                <>{ClassworkData.SaveType.toUpperCase() === "SEND" && commonService.getLoginRoleData("Classwork", "IsDelete") ? <>
                                                    <button
                                                        className="btn btn-primary px-3 pr-4 mb-3 popup-send-button"
                                                        type="button"
                                                        onClick={() =>
                                                            confirmAlert({
                                                                title: "Delete",
                                                                message: "Are you sure delete ?",
                                                                buttons: [
                                                                    {
                                                                        label: "Yes",
                                                                        onClick: () =>
                                                                            fnDeleteClasswork(
                                                                                ClassworkData.ClassWorkID
                                                                            ),
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                    },
                                                                ],
                                                            })
                                                        }
                                                    >
                                                        {commonService.LabelDisplayText("Delete")}
                                                    </button>
                                                </> : <>
                                                    <ButtonGroup className="button-of-send mb-3">
                                                        <button
                                                            className="btn btn-primary px-3 pr-4 popup-send-button"
                                                            type="button"
                                                            onClick={() => ButtonClickForSave("Send")}
                                                        >
                                                            {commonService.LabelDisplayText("Send")}
                                                        </button>
                                                        <DropdownButton
                                                            as={ButtonGroup}
                                                            title=""
                                                            className="btn-transparent send-dropdown-button"
                                                        >
                                                            <Dropdown.Item
                                                                eventKey="1"
                                                                onClick={() => ButtonClickForSave("Send")}
                                                            >
                                                                {commonService.LabelDisplayText("Send")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                eventKey="2"
                                                                onClick={() => ButtonClickForSave("Draft")}
                                                            >
                                                                {commonService.LabelDisplayText("Draft")}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                eventKey="3"
                                                                onClick={() => ButtonClickForSave("Schedule")}
                                                            >
                                                                {commonService.LabelDisplayText("Schedule")}
                                                            </Dropdown.Item>
                                                            {ClassworkData.SaveType.toUpperCase() !== "SEND" &&
                                                                ClassworkData.SaveType.toUpperCase() !== "" && commonService.getLoginRoleData("Classwork", "IsDelete") && (
                                                                    <div>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item
                                                                            eventKey="4"
                                                                            onClick={() =>
                                                                                confirmAlert({
                                                                                    title: "Delete",
                                                                                    message: "Are you sure delete ?",
                                                                                    buttons: [
                                                                                        {
                                                                                            label: "Yes",
                                                                                            onClick: () =>
                                                                                                fnDeleteClasswork(
                                                                                                    ClassworkData.ClassWorkID
                                                                                                ),
                                                                                        },
                                                                                        {
                                                                                            label: "No",
                                                                                        },
                                                                                    ],
                                                                                })
                                                                            }
                                                                        >
                                                                            {commonService.LabelDisplayText("DeleteDraft")}
                                                                        </Dropdown.Item>
                                                                    </div>
                                                                )}
                                                        </DropdownButton>
                                                    </ButtonGroup>
                                                </>}</>
                                            ) : (
                                                <button
                                                    className="btn btn-primary px-3 pr-5"
                                                    type="button"
                                                    disabled="disabled"
                                                >
                                                    <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                </button>
                                            )
                                        ) : (
                                            ""
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container classwork-popup-detail">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9 date-section-area">
                                <input
                                    type="text"
                                    className={`mt-4 title-textbox ${ClassworkData.errors.ClassWorkTitle && "invalid"
                                        }`}
                                    disabled={
                                        data[0].MemberType.toLowerCase() === "student" && "disabled"
                                    }
                                    placeholder="Class Work Title"
                                    onChange={(e) =>
                                        setClassworkData({
                                            ...ClassworkData,
                                            ["ClassWorkTitle"]: e.target.value,
                                        })
                                    }
                                    defaultValue={ClassworkData.ClassWorkTitle}
                                />
                                {ClassworkData.errors.ClassWorkTitle && (
                                    <span className="error-message">
                                        {ClassworkData.errors.ClassWorkTitle}
                                    </span>
                                )}
                                <div className="rich-txt-editor-area mt-3">
                                    <Editor
                                        toolbarHidden={
                                            data[0].MemberType.toLowerCase() === "student"
                                        }
                                        readOnly={
                                            data[0].MemberType.toLowerCase() === "student" &&
                                            "readOnly"
                                        }
                                        editorState={editorClassworksDetails}
                                        toolbar={AppConfigData.toolbarConfig}
                                        onEditorStateChange={setEditorClassworksDetails}
                                    />
                                </div>
                                {data[0].MemberType.toLowerCase() !== "student" && (
                                    <DropdownButton
                                        title="Add"
                                        className="mt-3"
                                        variant=" text-editor "
                                    >
                                        <Dropdown.Item
                                            eventKey="1"
                                            className="dropdown-item"
                                            onClick={() => setShowLinkPopup(true)}
                                        >
                                            <i className="fa-solid fa-link-simple"></i> {commonService.LabelDisplayText("Link")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="2"
                                            className="dropdown-item"
                                            onClick={() => setShowFileSelectPopup(true)}
                                        >
                                            <i className="fa-regular fa-paperclip"></i> {commonService.LabelDisplayText("File")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="3"
                                            className="dropdown-item"
                                            onClick={() => setShowYoutubeLinkPopup(true)}
                                        >
                                            <i className="fa-brands fa-youtube"></i> {commonService.LabelDisplayText("Youtube")}
                                        </Dropdown.Item>
                                    </DropdownButton>
                                )}

                                <div className="row">
                                    <div className="col-12 added-popup-data">
                                        {ClassworkData.FilesDetails.map((itemFile, indexFile) => (
                                            <div
                                                className="card homework-page-card mt-3"
                                                key={indexFile}
                                            >
                                                {CreateFilesDetailsIU(itemFile, indexFile)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 answer-section homework-popup-section">
                                <div className="row mt-4">
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-2">
                                        <label className="home-work-label">{commonService.LabelDisplayText("DueDate")}</label>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 mb-2">
                                        <DatePicker
                                            showTimeSelect
                                            className={`datepicker homework-input-date ${ClassworkData.errors.ExpectingDateOfCompletion &&
                                                "invalid"
                                                }`}
                                            disabled={
                                                data[0].MemberType.toLowerCase() === "student" &&
                                                "disabled"
                                            }
                                            selected={ClassworkData.ExpectingDateOfCompletion}
                                            minDate={ClassworkData.ExpectingDateOfCompletion}
                                            dateFormat={
                                                data[0].DateFormat_Term + " " + data[0].TimeFormat_Term
                                            }
                                            onChange={(date) =>
                                                setClassworkData({
                                                    ...ClassworkData,
                                                    ["ExpectingDateOfCompletion"]: date,
                                                })
                                            }
                                            placeholderText={
                                                data[0].DateFormat_Term + " " + data[0].TimeFormat_Term
                                            }
                                        />
                                        {ClassworkData.errors.ExpectingDateOfCompletion && (
                                            <span className="error-message">
                                                {ClassworkData.errors.ExpectingDateOfCompletion}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-1"></div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-3 mb-2">
                                        <label className="home-work-label">For</label>
                                    </div>
                                    {data[0].MemberType.toLowerCase() !== "student" ? (
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 mb-2 select-student-subject">
                                            <div className="selectSubject-popup">
                                                <Select
                                                    className={`${ClassworkData.errors.SubjectID && "invalid"
                                                        }`}
                                                    placeholder={"Select Subject"}
                                                    options={TeacherSubject}
                                                    onChange={(e) =>
                                                        StandardDivisionSubjectChange(e.value)
                                                    }
                                                    value={TeacherSubject.find(
                                                        (obj) =>
                                                            obj.value ===
                                                            ClassworkData.GradeID.toLocaleUpperCase() +
                                                            "|" +
                                                            ClassworkData.DivisionID.toLocaleUpperCase() +
                                                            "|" +
                                                            ClassworkData.SubjectID.toLocaleUpperCase()
                                                    )}
                                                />
                                                {ClassworkData.errors.SubjectID && (
                                                    <span className="error-message">
                                                        {ClassworkData.errors.SubjectID}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="ReactMultiSelectCheckboxes mt-2 select-student-dropdown">
                                                <ReactMultiSelectCheckboxes
                                                    width={"100%"}
                                                    placeholderButtonLabel={"Select Student"}
                                                    options={SubjectEnrollStudent}
                                                    isMulti
                                                    onChange={StudentSelectionChange}
                                                    getDropdownButtonLabel={getDropdownButtonLabel}
                                                    closeMenuOnSelect={false}
                                                    value={SubjectEnrollStudent.filter((obj) =>
                                                        ClassworkData.TagMemberIDs.split(",").includes(
                                                            obj.value
                                                        )
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 mb-2">
                                            <input
                                                type="text"
                                                className={`title-textbox`}
                                                disabled={
                                                    data[0].MemberType.toLowerCase() === "student" &&
                                                    "disabled"
                                                }
                                                defaultValue={ClassworkData.SubjectName}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowLinkPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddLink")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["name"]: e.target.value })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachLink({ ...AttachLink, ["link"]: e.target.value })
                                }
                                className={`form-control ${AttachLink.errors && "invalid"}`}
                                placeholder="Add link here"
                            />
                            {AttachLink.errors && (
                                <span className="error-message">{AttachLink.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowLinkPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddLinkInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showYoutubeLinkPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowYoutubeLinkPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("AddYoutube")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["name"]: e.target.value,
                                    })
                                }
                                className="form-control mb-2"
                                placeholder="Title"
                            />
                        </div>
                        <div className="col-12">
                            <input
                                type="text"
                                onChange={(e) =>
                                    setAttachYoutubeLink({
                                        ...AttachYoutubeLink,
                                        ["link"]: e.target.value,
                                    })
                                }
                                className={`form-control  ${AttachYoutubeLink.errors && "invalid"
                                    }`}
                                placeholder="Add youtube embed link here"
                            />
                            {AttachYoutubeLink.errors && (
                                <span className="error-message">
                                    {AttachYoutubeLink.errors}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowYoutubeLinkPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddYoutubeLinkInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showFileSelectPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowFileSelectPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectFile")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="file"
                                className={`form-control  ${AttachFiles.errors && "invalid"}`}
                                multiple
                                onChange={(e) =>
                                    setAttachFiles({ ...AttachFiles, ["link"]: e.target.files })
                                }
                            />
                            {AttachFiles.errors && (
                                <span className="error-message">{AttachFiles.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowFileSelectPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddFileSelectInFilesDetails()}
                    >
                        {commonService.LabelDisplayText("Add")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showScheduleDatePopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setShowScheduleDatePopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={""}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("SelectScheduleDate")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setShowScheduleDatePopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                showTimeSelect
                                className={`datepicker homework-input-date form-control ${ClassworkData.errors.DateOfClassWork && "invalid"
                                    }`}
                                selected={ClassworkData.DateOfClassWork}
                                dateFormat={
                                    data[0].DateFormat_Term + " " + data[0].TimeFormat_Term
                                }
                                onChange={(date) =>
                                    setClassworkData({
                                        ...ClassworkData,
                                        ["DateOfClassWork"]: date,
                                    })
                                }
                                placeholderText={
                                    data[0].DateFormat_Term + " " + data[0].TimeFormat_Term
                                }
                            />
                            {ClassworkData.errors.DateOfClassWork && (
                                <span className="error-message">
                                    {ClassworkData.errors.DateOfClassWork}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShowScheduleDatePopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => AddEditClasswork("Schedule")}
                    >
                        {commonService.LabelDisplayText("Schedule")}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={studentreadstatuspopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setstudentreadstatuspopup(false)}
                className="modal-theme-color check-homework-modalpopup"
                contentClassName={"background-transparent-color"}
                dialogClassName="modal-full-width"
            >
                <Modal.Header className="justify-content-end">
                    <button
                        className="poopup-close-button"
                        onClick={() => setstudentreadstatuspopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="container-fluid p-0 homework-popup-border border-bottom-exam position-relative">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <h1 className="page-header mb-3">
                                            {" "}
                                            {commonService.LabelDisplayText("ReadStatus")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {readstatus.isdata === true ? (
                            <>
                                {readstatus.data.length !== 0 ? (
                                    <>
                                        <Table responsive className="read-status">
                                            <thead>
                                                <th className="w-130px">{commonService.LabelDisplayText("GR")}</th>
                                                <th>{commonService.LabelDisplayText("StudentName")}</th>
                                                <th className="w-100px">{commonService.LabelDisplayText("STDDIV")}</th>
                                                <th className="w-150px">{commonService.LabelDisplayText("FatherContact")}</th>
                                                <th className="w-150px">{commonService.LabelDisplayText("MotherContact")}</th>
                                                <th className="w-130px">{commonService.LabelDisplayText("Status")}</th>
                                            </thead>

                                            <tbody>
                                                {readstatus.data.map((a) => (
                                                    <>
                                                        <tr>
                                                            <td>{a.GRNo}</td>
                                                            <td>{a.StudentFullName}</td>
                                                            <td>{a.STDDIV}</td>
                                                            <td>{a.FatherContactNo}</td>
                                                            <td>{a.MotherContactNo}</td>
                                                            <td>
                                                                {a.StatusType === "Readed" ? (
                                                                    <>
                                                                        <label
                                                                            className={`link-label pointer-cursor ml-1`}
                                                                        >
                                                                            {a.StatusType}
                                                                        </label>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <label
                                                                            className={`file-label pointer-cursor ml-1`}
                                                                        >
                                                                            {a.StatusType}
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </>
                                ) : (
                                    <>
                                        <_NoDataFound fontsize={"150px"} />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="row col-12">
                                    <_Skeleton
                                        type={_SkeletonType.Table}
                                        count={4}
                                        lineheight={30}
                                    />
                                </div>
                            </>
                        )}
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Classwork;
