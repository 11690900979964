import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";

function TransportationView(props) {

    const [pickuppoints, setpickuppoints] = useState({ IsData: false, data: [] });
    const [droppoints, setdroppoints] = useState({ IsData: false, data: [] });
    const [transportationdetails, settransportationdetails] = useState({ IsData: false, data: [] });
    const [showAddEditPopup, setshowAddEditPopup] = useState(false);
    
    function BindTransportation() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "TRANSPORTATION"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    settransportationdetails({ IsData: true, data: json.data.transportationDetails });
                    setpickuppoints({ IsData: true, data: json.data.pickuppoints });
                    setdroppoints({ IsData: true, data: json.data.droppoints });
                } else if (json.status_code === 0) {
                    settransportationdetails({ IsData: true, data: [] });
                    setpickuppoints({ IsData: true, data: [] });
                    setdroppoints({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindTransportation();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {transportationdetails.IsData ?
                        (<div className="inner-header-for-section float-left">
                            <h2 className="m-0">Transportation Details</h2>
                        </div>)
                        : (<Skeleton count={1} height={30} />)
                    }
                    {transportationdetails.IsData ?
                        (
                            transportationdetails.data.map((item, index) => (
                                <>
                                    <div className="col-lg-12 my-2 float-left">
                                        <div className="col-lg-6 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Pickup Point : </b>{item.PointName}
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Address : {item.Address}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Bus Fee : {item.Fees}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Pickup Bus Details</h2>
                                    </div>
                                    <div className="col-lg-12 my-2 float-left">
                                        <div className="col-lg-12">
                                            <div className="col-lg-12 float-left">
                                                <div className="float-left">
                                                    <b className="student-roll-no mt-1 ">Pickup Route : {item.PickupRoute}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 float-left">
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Bus Code: {item.PickupBusCode}</b>
                                            </div>
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Driver: {item.pickupDriver}</b>
                                            </div>
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Conatct# : {item.pickupDriverMo}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Drop Bus Details</h2>
                                    </div>
                                    <div className="col-lg-12 my-2 float-left">
                                        <div className="col-lg-12">
                                            <div className="col-lg-12 float-left">
                                                <div className="float-left">
                                                    <b className="student-roll-no mt-1 ">Drop Route : {item.DropRoute}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 float-left">
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Bus Code: {item.dropbusCode}</b>
                                            </div>
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Driver: {item.DropDriver}</b>
                                            </div>
                                            <div className="col-lg-4 float-left my-1">
                                                <b className="student-roll-no mt-1">Conatct# : {item.DropDriverMo}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inner-header-for-section float-left">
                                        <h2 className="m-0">Pickup Contact Person Details</h2>
                                    </div>
                                    <div className="col-lg-12 my-2 float-left">
                                        <div className="col-lg-6 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Name : {item.PickupContactPerson1}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Contact# : {item.EmergencyContact1}</b>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 float-left">
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Name : {item.PickupContactPerson2}</b>
                                            </div>
                                            <div className="col-lg-12 my-1">
                                                <b className="student-roll-no mt-1">Contact# : {item.EmergencyContact2}</b>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <>
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                            </>
                        )}
                    {transportationdetails.data.length === 0 && (<_NoDataFound fontsize={"300px"} />)}
                </div>
            </div>
           
        </>
    );
}

export default TransportationView;
