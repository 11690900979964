import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  commonService,
  AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";

function SubjectDetails() {
  const history = useHistory();
  const [ChapterData, setChapterData] = useState({ IsData: false, data: [] });

  React.useEffect(() => {
    if(commonService.getLoginRoleData("LMS Subject List","IsView")){
    GetChapter();
    }
    else{
      history.push("/access");
    }
  }, []);

  function GetChapter() {
    const reqbody = {
      method: "POST",
      headers: commonService.getHeaders(),
      body: JSON.stringify({
        id: history.location.state.LMSStandardSubjectID,
        requestFrom: "web",
      }),
    };

    fetch(
      AppConfigData.APIBasePath + "v1/lms/getlessionmasterbysubjectid",
      reqbody
    )
      .then((response) => response.json())
      .then(function (json) {
        if (json.status_code === 1) {
          setChapterData({ IsData: true, data: json.data });
        } else if (json.status_code === 0) {
          setChapterData({ IsData: true, data: [] });
        } else if (json.status_code === 2) {
          localStorage.clear();
          history.push("/");
        }
      });
  }

  function ViewLessonDetailPage(item) {
    /* history.push("/examsyllabus");*/
    history.push({
      pathname: "/chapterdetails",
      state: item,
    });
  }

  return (
    <div>
      <div class="container">
        <h1 class="page-header my-4">{history.location.state.SubjectName}</h1>
        <div class="row">
          {!ChapterData.IsData ? (
            <_Skeleton type={_SkeletonType.ChapterData} />
          ) : ChapterData.data.length === 0 ? (
            <_NoDataFound fontsize={"200px"} />
          ) : (
            ChapterData.data.map((item, index) => (
              <div class="col-6 mb-3">
                <a onClick={(e) => ViewLessonDetailPage(item)}>
                  <div class="card subject-view-card">
                    <div class="card-body">
                      <div class="left-area-chapter-number">
                        <span class="chapter-number">{index + 1}</span>
                      </div>
                      <div class="mid-area-chapter-details">
                        <p class="chapter-name">{item.LessionTitle}</p>
                        <span class="topic-count">
                                        {item.TopicCount} {commonService.LabelDisplayText("Topics")}
                        </span>
                      </div>
                      <div class="right-area-chapter-length">
                        <span class="chapter-duration">
                          <i class="fa-solid fa-play"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default SubjectDetails;
