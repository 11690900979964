import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";

function DocumentView() {

    const [document, setdocument] = useState({ IsData: false, data: [] });

    function BindDocument() {
        
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "DOCUMENTS"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setdocument({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setdocument({ IsData: true, data: [] });
                }
            });
    }

    React.useEffect(() => {
        BindDocument();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {document.IsData &&
                        (
                            <>
                                <div className="col-lg-12 my-2">
                                    <div className="upload-files mt-3">
                                        <div className="items-file d-flex flex-wrap">
                                            {
                                                document.data.map((item) =>
                                                    (item !== "" ?
                                                        (item.FilePath.split(".").pop().toUpperCase() === "PDF" ?
                                                            (<a href={"http://erp.orataro.com/DropBoxDoc/" + item.FilePath} target="_blank">
                                                                <div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["PDF"]}`}></div>
                                                            </a>)
                                                            : item.FilePath.split(".").pop().toUpperCase() === "JPG" ||
                                                                item.FilePath.split(".").pop().toUpperCase() === "JPEG" ||
                                                                item.FilePath.split(".").pop().toUpperCase() === "PNG" ?
                                                                (
                                                                    <div className="item-file" >
                                                                        <a href={"http://erp.orataro.com/DropBoxDoc/" + item.FilePath} target="_blank"><img src={"http://erp.orataro.com/DropBoxDoc/" + item.FilePath} alt="" /></a>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <a href={item.FilePath} target="_blank">
                                                                        <div className={`todotaskfileicon item-file file-list-icon ${AppConfigData.fileIcon["FILE"]}`}> </div>
                                                                    </a>
                                                                )
                                                        )
                                                        : ""
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </div>
        </>
    );
}

export default DocumentView;
