import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory } from "react-router-dom";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { Doughnut } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import $ from 'jquery';

function Exam() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [Exam, setExam] = useState({ IsData: false, data: [] });
    const [WeeklyResult, setWeeklyResult] = useState({ IsData: false, data: [] });
    const [TermsResult, setTermsResult] = useState({ IsData: false, data: [] });
    const [SemResult, setSemResult] = useState({ IsData: false, data: [] });
    const [CbseResult, setCbseResult] = useState({ IsData: false, data: [] });
    const [ResultSheetData, setResultSheetData] = useState({ IsData: false, data: [] });
    const [txtEndDate, setTxtEndDate] = useState(null);
    const [txtStartDate, setTxtStartDate] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [IsListDateLodding, setIsListDateLodding] = useState(false);

    function BindExamList() {
        setExam({ IsData: false, data: [] });
        setIsListDateLodding(true);
        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "Send",
                searchText: txtSearch,
                fromDate: txtStartDate,
                toDate: txtEndDate,
                requestFrom: "Web",
                noOfData: null,
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/getlistbymember", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                setIsListDateLodding(false);
                if (json.status_code === 1) {
                    setExam({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setExam({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function ViewExamDetailsPage(item) {
        history.push({
            pathname: "/examsyllabus",
            state: item,
        });
    }

    function BindWeeklyResult() {
        setWeeklyResult({ IsData: false, data: [] });
        const reqWeeklyResult = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                examType: "Weekly",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/exam/getstudentweeklyresultlist",
            reqWeeklyResult
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setWeeklyResult({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setWeeklyResult({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindTermsResult() {
        setTermsResult({ IsData: false, data: [] });

        const reqTermResult = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                examType: "TERM",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/exam/getstudent_term_sem_cbse_resultlist",
            reqTermResult
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setTermsResult({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setTermsResult({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindSemResult() {
        setSemResult({ IsData: false, data: [] });

        const reqSemResult = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                examType: "SEMESTER",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/exam/getstudent_term_sem_cbse_resultlist",
            reqSemResult
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setSemResult({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setSemResult({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindCbseResult() {
        setCbseResult({ IsData: false, data: [] });

        const reqCbseResult = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                examType: "CBSE",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/exam/getstudent_term_sem_cbse_resultlist",
            reqCbseResult
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setCbseResult({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setCbseResult({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function ChangeExamTypeDisplayName(Type) {
        if (Type.toLowerCase() === "cbsc exam") {
            Type = "CBSE/ICSE Exam";
        }

        return Type;
    }

    function GetResultSheetPath(item) {
        $.ajax({
            url: item,
            type: "POST",
            success: function (data) {
                debugger
                window.open(AppConfigData.ERPBasePath + data, "_blank");
            }
        });
    }

    function BindResultSheet() {
        setResultSheetData({ IsData: false, data: [] });

        const reqResultSheet = {
            method: "POST",
            headers: commonService.getHeaders()
        };

        fetch(
            AppConfigData.APIBasePath + "v1/exam/GetClassTeacherWiseExams",
            reqResultSheet
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setResultSheetData({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setResultSheetData({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Exam", "IsView")) {
            BindExamList();
            BindWeeklyResult();
            BindTermsResult();
            BindSemResult();
            BindCbseResult();
            BindResultSheet();
        }
        else {
            history.push("/access");
        }
    }, []);
    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("MenuExam")}</h1>
            <div className="background-theme-color sticky-top">
                <div className="search-top-section">
                    <div className="row">
                        <div className="col-lg-12 col-xl-7">
                            <div className="header-search-area">
                                <input
                                    type="text"
                                    className=""
                                    placeholder={commonService.LabelDisplayText("SearchText")}
                                    onChange={(e) => setTxtSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-5 mt-3 mt-xl-0">
                            <div className="search-right-cal d-flex align-items-center">
                                <div className="header-date-area w-100 mr-3">
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerFrom")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtStartDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <label className="from-date">
                                            {commonService.LabelDisplayText("DatepickerTo")}
                                        </label>
                                        <DatePicker
                                            className="datepicker"
                                            selected={txtEndDate}
                                            minDate={txtStartDate && txtStartDate}
                                            dateFormat={data[0].DateFormat_Term}
                                            onChange={(date) => setTxtEndDate(date)}
                                            placeholderText={data[0].DateFormat_Term}
                                        />
                                    </div>
                                </div>
                                <div className="right-icon-cal d-flex align-items-center">
                                    {IsListDateLodding === false ? (
                                        <div
                                            className="search-icon-img pointer-cursor"
                                            onClick={() => BindExamList()}
                                        >
                                            <i className="fa-regular fa-magnifying-glass"></i>
                                        </div>
                                    ) : (
                                        <div className="search-icon-img pointer-cursor">
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-result-block">
                    <span>
                        Result For{" "}
                        <span>
                            {txtSearch +
                                " From : " +
                                commonService.getDateInFormat(txtStartDate) +
                                " To : " +
                                commonService.getDateInFormat(txtEndDate)}
                        </span>
                    </span>
                </div>
            </div>
            <Tabs
                defaultActiveKey={"Today"}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 timetable-liveclass"
            >

                <Tab eventKey="Today" title={commonService.LabelDisplayText("TodayExam")}>
                    <div className={Exam.data.length === 0 ? "" : "row"}>
                        {!Exam.IsData ? (
                            <_Skeleton type={_SkeletonType.Exam} count={1} lineheight={40} />
                        ) : Exam.data.length === 0 ? (
                            <_NoDataFound fontsize={"300px"} />
                        ) : (
                            Exam.data.map(
                                (item, index) =>
                                    commonService.getDateInDBFormat(item.DateOfExam) === commonService.getDateInDBFormat(new Date()) && new Date(item.EndDateTime) >= new Date() && (
                                        <div
                                            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 exam-section-area mb-3"
                                            key={index}
                                        >
                                            <a
                                                className={`pointer-cursor`}
                                                onClick={(e) => ViewExamDetailsPage(item)}
                                            >
                                                <div className="section">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <label>
                                                            {commonService.getDateInFormat(item.DateOfExam)}
                                                        </label>
                                                        <label>{commonService.LabelDisplayText("TotalMark")} {item.TotakMarks}</label>
                                                    </div>
                                                    <h3 className="science-text">
                                                        <div className="d-flex align-items-center justify-content-center">{item.IsOnlineExam ? (
                                                            <i className="fa-solid fa-laptop-mobile mr-2"></i>
                                                        ) : (
                                                            <i className="fa-regular fa-school mr-2"></i>
                                                        )}
                                                            {item.SubjectName}</div>
                                                        <label className="d-flex align-items-center justify-content-center">{item.ExamTypeAndPaperType}</label>
                                                    </h3>
                                                    <div className="d-flex justify-content-between align-items-cente ">
                                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                                            <label className="mark-label float-left">
                                                                {commonService.LabelDisplayText("STD")} : {item.StandardName} - {item.ExamName}
                                                            </label>
                                                        ) : (
                                                            <label className="mark-label float-left">
                                                                {item.ExamName}
                                                            </label>
                                                        )}
                                                        <label>{ChangeExamTypeDisplayName(item.ExamOfTypeTerm)}</label>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                            )
                        )}
                    </div>
                </Tab>

                <Tab eventKey="UpcommigExam" title={commonService.LabelDisplayText("UpcomingExam")}>
                    <div className={Exam.data.length === 0 ? "" : "row"}>
                        {!Exam.IsData ? (
                            <_Skeleton type={_SkeletonType.Exam} count={1} lineheight={40} />
                        ) : Exam.data.length === 0 ? (
                            <_NoDataFound fontsize={"300px"} />
                        ) : (
                            Exam.data.map(
                                (item, index) =>
                                    new Date(item.DateOfExam) >= new Date() && (
                                        <div
                                            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 exam-section-area mb-3"
                                            key={index}
                                        >
                                            <a
                                                className={`pointer-cursor`}
                                                onClick={(e) => ViewExamDetailsPage(item)}
                                            >
                                                <div className="section">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <label>
                                                            {commonService.getDateInFormat(item.DateOfExam)}
                                                        </label>
                                                        <label>{commonService.LabelDisplayText("TotalMark")} {item.TotakMarks}</label>
                                                    </div>
                                                    <h3 className="science-text">
                                                        <div className="d-flex align-items-center justify-content-center">{item.IsOnlineExam ? (
                                                            <i className="fa-solid fa-laptop-mobile mr-2"></i>
                                                        ) : (
                                                            <i className="fa-regular fa-school mr-2"></i>
                                                        )}
                                                            {item.SubjectName}</div>
                                                        <label className="d-flex align-items-center justify-content-center">{item.ExamTypeAndPaperType}</label>
                                                    </h3>
                                                    <div className="d-flex justify-content-between align-items-cente ">
                                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                                            <label className="mark-label float-left">
                                                                {commonService.LabelDisplayText("STD")} : {item.StandardName} - {item.ExamName}
                                                            </label>
                                                        ) : (
                                                            <label className="mark-label float-left">
                                                                {item.ExamName}
                                                            </label>
                                                        )}
                                                        <label>{ChangeExamTypeDisplayName(item.ExamOfTypeTerm)}</label>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                            )
                        )}
                    </div>
                </Tab>

                <Tab eventKey="CompletedExam" title={commonService.LabelDisplayText("CompletedExam")}>
                    <div className={Exam.data.length === 0 ? "" : "row"}>
                        {!Exam.IsData ? (
                            <_Skeleton type={_SkeletonType.Exam} count={1} lineheight={40} />
                        ) : Exam.data.length === 0 ? (
                            <_NoDataFound fontsize={"300px"} />
                        ) : (
                            Exam.data.map(
                                (item, index) =>
                                    new Date(item.EndDateTime) <= new Date() && (
                                        <div
                                            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 exam-section-area mb-3"
                                            key={index}
                                        >
                                            <a
                                                className={`pointer-cursor`}
                                                onClick={(e) => ViewExamDetailsPage(item)}
                                            >
                                                <div className="section">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <label>
                                                            {commonService.getDateInFormat(item.DateOfExam)}
                                                        </label>
                                                        <label>{commonService.LabelDisplayText("TotalMark")} {item.TotakMarks}</label>
                                                    </div>
                                                    <h3 className="science-text">
                                                        <div className="d-flex align-items-center justify-content-center">{item.IsOnlineExam ? (
                                                            <i className="fa-solid fa-laptop-mobile mr-2"></i>
                                                        ) : (
                                                            <i className="fa-regular fa-school mr-2"></i>
                                                        )}
                                                            {item.SubjectName}</div>
                                                        <label className="d-flex align-items-center justify-content-center">{item.ExamTypeAndPaperType}</label>
                                                    </h3>


                                                    <div className="d-flex justify-content-between align-items-cente ">
                                                        {data[0].MemberType.toLowerCase() !== "student" ? (
                                                            <label className="mark-label float-left">
                                                                {commonService.LabelDisplayText("STD")} : {item.StandardName} - {item.ExamName}
                                                            </label>
                                                        ) : (
                                                            <label className="mark-label float-left">
                                                                {item.ExamName}
                                                            </label>
                                                        )}

                                                        <label>{ChangeExamTypeDisplayName(item.ExamOfTypeTerm)}</label>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                            )
                        )}
                    </div>
                </Tab>

                {data[0].MemberType.toLowerCase() === "teacher" &&
                    <Tab eventKey="ResultSheet" title={commonService.LabelDisplayText("ResultSheet")}>
                        <div className={ResultSheetData.data.length === 0 ? "" : "row"}>
                            {!ResultSheetData.IsData ? (
                                <_Skeleton type={_SkeletonType.Exam} count={1} lineheight={40} />
                            ) : ResultSheetData.data.length === 0 ? (
                                <_NoDataFound fontsize={"300px"} />
                            ) : (
                                ResultSheetData.data.map((item, index) => {
                                    let hrefLink = "";
                                    if (item.ExamType.toLowerCase() === "weekly test") {
                                        hrefLink = AppConfigData.ERPBasePath + "mobile_api/rptPDF_examwiseresultsummary?StandardID=" + item.StandardID + "&ExamID=" + item.ExamID + "&DivisionID=" + item.DivisionID + "&InstituteID="
                                            + item.InstituteID + "&ClientID=" + item.ClientID + "&TermYearID=" + item.BatchID + "&ShiftID=" + item.ShiftID;
                                    }
                                    else if (item.ExamType.toLowerCase() === "cbse exam") {
                                        hrefLink = AppConfigData.ERPBasePath + "mobile_api/rptPDF_ExamWiseResultSheetData?StandardID=" + item.StandardID + "&ExamID=" + item.ExamID + "&DivisionID=" + item.DivisionID + "&InstituteID="
                                            + item.InstituteID + "&ClientID=" + item.ClientID + "&TermYearID=" + item.BatchID + "&ShiftID=" + item.ShiftID;
                                    }
                                    else if (item.ExamType.toLowerCase() === "term exam") {
                                        hrefLink = AppConfigData.ERPBasePath + "mobile_api/ExamWiseResultSheet_reportdataPDF?StandardID=" + item.StandardID + "&ExamID=" + item.ExamID + "&DivisionID=" + item.DivisionID + "&InstituteID="
                                            + item.InstituteID + "&ClientID=" + item.ClientID + "&TermYearID=" + item.BatchID + "&ShiftID=" + item.ShiftID;
                                    }
                                    else if (item.ExamType.toLowerCase() === "semester exam") {
                                        hrefLink = AppConfigData.ERPBasePath + "mobile_api/rptpdf_semesterexamwiseresultsheet?StandardID=" + item.StandardID + "&ExamID=" + item.ExamID + "&DivisionID=" + item.DivisionID + "&InstituteID="
                                            + item.InstituteID + "&ClientID=" + item.ClientID + "&TermYearID=" + item.BatchID + "&ShiftID=" + item.ShiftID;
                                    }

                                    return <div
                                        className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 exam-section-area mb-3"
                                        key={index}
                                    >
                                        <a
                                            className={`pointer-cursor`}
                                            onClick={(e) => GetResultSheetPath(hrefLink)}
                                            target={"_blank"}
                                        >
                                            <div className="section">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <label>
                                                        {commonService.getDateInFormat(item.StartDate)}
                                                    </label>
                                                    <label>
                                                        {commonService.getDateInFormat(item.Enddate)}
                                                    </label>
                                                </div>
                                                <h3 className="science-text">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        {item.ExamName}</div>
                                                </h3>
                                                <div className="d-flex justify-content-between align-items-cente ">
                                                    <label className="mark-label float-left">
                                                        {commonService.LabelDisplayText("STD")} : {item.Standard} - {item.Division}
                                                    </label>
                                                    <label>{ChangeExamTypeDisplayName(item.ExamType)}</label>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                })
                            )}
                        </div>
                    </Tab>
                }

                {data[0].MemberType.toLowerCase() === "student" &&
                    <Tab eventKey="ExamResult" title={commonService.LabelDisplayText("ExamResult")}>
                        <Tabs
                            defaultActiveKey="Weekly"
                            id="uncontrolled-tab-example"
                            className="mb-3 exam-result-tabs chapter-detail-tabs"
                        >
                            <Tab eventKey="Weekly" title={commonService.LabelDisplayText("WeeklyResult")}>
                                <div className="row">
                                    {WeeklyResult.data.map((item, index) => (
                                        <div
                                            key={index}
                                            className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-3"
                                        >
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="w-100">
                                                            <div className="col-12 d-flex align-items-center justify-content-between p-0 mb-2 exam-result-header">
                                                                <h2 className="card-body-header exam-result-header m-0">
                                                                    <span className="bus-number-plate">
                                                                        {item.SubjectName}
                                                                    </span>
                                                                    <span className="exam-result-year">
                                                                        {item.StandardName}-{item.DivisionName} (
                                                                        {item.BatchName})
                                                                    </span>
                                                                </h2>
                                                            </div>
                                                            <div className="bus-name-number">
                                                                <p className="bus-number-plate exam-result-date  mb-1">
                                                                    {commonService.getDateInFormat(item.DateOfExam)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="weekly-report-chart">
                                                            <Doughnut
                                                                className="weekly-report-chart"
                                                                data={{
                                                                    datasets: [
                                                                        {
                                                                            data: [item.TotalMarks - item.ObtainedMarks, item.ObtainedMarks],
                                                                            backgroundColor: ["#10AB4A", "#FFAE48"],
                                                                            borderWidth: [3, 8],
                                                                            borderColor: ["#10AB4A", "#FFAE48"],
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    cutout: 40,
                                                                }}
                                                            />
                                                            <div className="weekly-chart-numbers">
                                                                <span className="weekly-chart-highest">
                                                                    {item.TotalMarks}
                                                                </span>
                                                                /
                                                                <span className="weekly-chart-lowest">
                                                                    {item.ObtainedMarks}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="marks-indicators d-flex align-items-center justify-content-around mt-2">
                                                        <div className="d-flex text-align-center align-items-center">
                                                            <span className="mb-1">{commonService.LabelDisplayText("Standard")}</span>
                                                            <div className="std-highest" title={commonService.LabelDisplayText("Standard") + " Highest"}>
                                                                <label className="mb-1">
                                                                    {item.ClassHighest}<br />
                                                                    <p style={{ fontSize: "8px" }} className="align-items-center">High</p>
                                                                </label>
                                                            </div>
                                                            <div className="std-Lowest">
                                                                <label className="mb-1" title={commonService.LabelDisplayText("Standard") + " Highest"}>
                                                                    {item.ClassLowest}<br />
                                                                    <p style={{ fontSize: "8px" }} className="align-items-center">Low</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex text-align-center align-items-center">
                                                            <span className="mb-1">{commonService.LabelDisplayText("Section")}</span>
                                                            <div className="sec-highest" title={commonService.LabelDisplayText("Section") + " Highest"}>
                                                                <label className="mb-1">
                                                                    {item.DivisionHighest}<br />
                                                                    <p style={{ fontSize: "8px" }} className="text-items-center">High</p>
                                                                </label>
                                                            </div>
                                                            <div className="sec-lowest" title={commonService.LabelDisplayText("Section") + " Lowest"}>
                                                                <label className="mb-1">
                                                                    {item.DivsionLowest}<br />
                                                                    <p style={{ fontSize: "8px" }} className="text-items-center">Low</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Tab>
                            <Tab eventKey="Terms" title={commonService.LabelDisplayText("TermsResult")}>
                                <div className="row">
                                    {TermsResult.data.map((item, index) => (
                                        <div
                                            key={index}
                                            className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-3"
                                        >
                                            <a
                                                onClick={(e) =>
                                                    viewFilePopupService.viewFilePopup({
                                                        data: [
                                                            {
                                                                Name: item.ExamName,
                                                                FilePath:
                                                                    AppConfigData.ERPFileBasePath +
                                                                    item.ClientID +
                                                                    "/" +
                                                                    item.InstituteID +
                                                                    "/MarkSheets/" +
                                                                    item.ID +
                                                                    ".pdf",
                                                                OrderNo: 1,
                                                                FileType: "PDF",
                                                            },
                                                        ],
                                                    })
                                                }
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="col-12 d-flex align-items-center justify-content-between p-0 exam-result-header">
                                                            <h2 className="card-body-header exam-result-header m-0">
                                                                {item.ExamName} ({commonService.LabelDisplayText("STDDIV")} : {item.Standard} -{" "}
                                                                {item.Division} , {commonService.LabelDisplayText("Year")} : {item.Year})
                                                            </h2>

                                                            <i className="fa-regular fa-file-lines"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </Tab>
                            <Tab eventKey="Semester" title={commonService.LabelDisplayText("SemResult")}>
                                {SemResult.data.map((item, index) => (
                                    <div
                                        key={index}
                                        className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3"
                                    >
                                        <a
                                            onClick={(e) =>
                                                viewFilePopupService.viewFilePopup({
                                                    data: [
                                                        {
                                                            Name: item.ExamName,
                                                            FilePath:
                                                                AppConfigData.ERPFileBasePath +
                                                                item.ClientID +
                                                                "/" +
                                                                item.InstituteID +
                                                                "/MarkSheets/" +
                                                                item.ID +
                                                                ".pdf",
                                                            OrderNo: 1,
                                                            FileType: "PDF",
                                                        },
                                                    ],
                                                })
                                            }
                                        >
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="col-12 d-flex align-items-center justify-content-between p-0 exam-result-header">
                                                        <h2 className="card-body-header exam-result-header m-0">
                                                            {item.ExamName} ({commonService.LabelDisplayText("STDDIV")} : {item.Standard} -{" "}
                                                            {item.Division} , {commonService.LabelDisplayText("Year")} : {item.Year})
                                                        </h2>
                                                        <i className="fa-regular fa-file-lines"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </Tab>
                            <Tab eventKey="ICSE/CBSE" title={commonService.LabelDisplayText("CBSEResult")}>
                                {CbseResult.data.map((item, index) => (
                                    <div
                                        key={index}
                                        className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3"
                                    >
                                        <a
                                            onClick={(e) =>
                                                viewFilePopupService.viewFilePopup({
                                                    data: [
                                                        {
                                                            Name: item.ExamName,
                                                            FilePath:
                                                                AppConfigData.ERPFileBasePath +
                                                                item.ClientID +
                                                                "/" +
                                                                item.InstituteID +
                                                                "/MarkSheets/" +
                                                                item.ID +
                                                                ".pdf",
                                                            OrderNo: 1,
                                                            FileType: "PDF",
                                                        },
                                                    ],
                                                })
                                            }
                                        >
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="col-12 d-flex align-items-center justify-content-between p-0 exam-result-header">
                                                        <h2 className="card-body-header exam-result-header m-0">
                                                            {item.ExamName} ({commonService.LabelDisplayText("STDDIV")} : {item.Standard} -{" "}
                                                            {item.Division} , {commonService.LabelDisplayText("Year")} : {item.Year})
                                                        </h2>
                                                        <i className="fa-regular fa-file-lines"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </Tab>
                        </Tabs>
                    </Tab>
                }
            </Tabs>
        </>
    );
}

export default Exam;
