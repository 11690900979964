import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Alert from "./components/_Alert";
import FileViewPopup from "./components/_FileViewPopup";
import Login from "./components/login";
import SwitchStud from "./components/SwitchStud";
import Layout from "./components/_Layout";
import ExamQuestion from "./components/ExamQuestion";
import LiveClass from "./components/LiveClass";
import LiveClassWebView from "./components/LiveClassWebView";
import PDFEdit from "./components/PDFEdit";


function App() {
    //   const [isLoading, setLoading] = useState(true);

    //   function fakeRequest() {
    //   return new Promise(resolve => setTimeout(() => resolve(), 5500));
    // }
    // useEffect(() => {
    //   fakeRequest().then(() => {
    //     const el = document.querySelector(".loader-container");
    //     if (el) {
    //       el.remove();
    //       setLoading(!isLoading);
    //     }
    //   });
    // }, []);

    // if (isLoading) {
    //   return null;
    // }
    return (
        <Router>
            <div className="alert-container">
                <Alert />
            </div>
            <FileViewPopup />
            <Switch>
                <Route path="/" exact component={() => <Login />} />
                <Route path="/switch" exact component={() => <SwitchStud />} />
                <Route path="/examquestion" exact component={() => <ExamQuestion />} />
                <Route path="/liveclass" exact component={() => <LiveClass />} />
                <Route path="/pdfedit" exact component={() => <PDFEdit />} />
                <Route path="/liveclasswebview" exact component={() => <LiveClassWebView />} />
                <Layout />
            </Switch>
        </Router>
    );
}

export default App;
