import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory, Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Map, GoogleApiWrapper, Polyline, Marker } from "google-maps-react";
import { left } from "@popperjs/core";
import ToDoTask from "./ToDoTask";
import Wall from "./Wall";
import BasicInformationView from "./BasicInformationView";
import ParentView from "./ParentView";
import FeeView from "./FeeView";
import ExamView from "./ExamView";
import SiblingView from "./SiblingView";
import TransportationView from "./TransportationView";
import Healthview from "./HealthView";
import OtherInfoView from "./OtherInfoView";
import ChildCustodyView from "./ChildCustodyview";
import RewardView from "./RewardView";
import SubjectEnrollmentView from "./SubjectEnrollmentView";
import HostelDetailView from "./HostelView";
import DocumentView from "./DocumentView";

function Profile(props) {
    const history = useHistory();

    let data = commonService.getLoginUserData();
    const ChartBackgroundColor = "#10AB4A";
    const ChartDatasetsLabel = "Attendence";

    const MapStyle = {
        width: "100%",
        height: "100%",
        position: "relative",
    };
    const ChartFontColor = {
        maintainAspectRatio: false,
        Plugins: {
            labels: {
                fontColor: "#ffffff",
            },
        },
        options: {
            scales: {
                x: {
                    ticks: {
                        color: "red",
                    },
                },
            },
        },
    };
    const [attendenceByMonth, setattendenceByMonth] = useState({
        IsData: false,
        data: [],
    });
    const [attendenceSummary, setattendenceSummary] = useState({
        IsData: false,
        data: [],
    });
    const [basicDetails, setbasicDetails] = useState({ IsData: false, data: [] });
    const [hostelDetails, sethostelDetails] = useState({
        IsData: false,
        data: [],
    });
    const [transportationDetails, settransportationDetails] = useState({
        IsData: false,
        data: [],
    });
    const [PickupMapinitialCenter, setPickupMapinitialCenter] = useState({
        lat: 23.0389,
        lng: 72.64032,
    });
    const [DropMapinitialCenter, setDropMapinitialCenter] = useState({
        lat: 23.0389,
        lng: 72.64032,
    });

    const [PickupMarker, setPickupMarker] = useState([]);
    const [DropMarker, setDropMarker] = useState([]);

    const [Exam, setExam] = useState({ IsData: false, data: [] });
    const [Fees, setFees] = useState({ IsData: false, data: [] });

    const [Chartdata, setChartdata] = useState({
        labels: [],
        datasets: [
            {
                label: ChartDatasetsLabel,
                data: [],
                backgroundColor: [ChartBackgroundColor],
            },
        ],
    });
    const [StudentDetailType, setStudentDetailType] = useState("");

    function BindProfileData() {
        const req = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(AppConfigData.APIBasePath + "v1/user/getprofilebymember", req)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setattendenceByMonth({
                        IsData: true,
                        data: json.data.attendenceByMonth,
                    });
                    setattendenceSummary({
                        IsData: true,
                        data: json.data.attendenceSummary,
                    });
                    setbasicDetails({ IsData: true, data: json.data.basicDetails });
                    sethostelDetails({ IsData: true, data: json.data.hostelDetails });
                    settransportationDetails({
                        IsData: true,
                        data: json.data.transportationDetails,
                    });

                    if (json.data.attendenceByMonth.length !== 0) {
                        let labels = [];
                        let data = [];
                        for (let i = 0; i < json.data.attendenceByMonth.length; i++) {
                            labels.push(
                                json.data.attendenceByMonth[i].MonthName.substr(0, 3) +
                                "-" +
                                json.data.attendenceByMonth[i].YEAR
                            );
                            data.push(json.data.attendenceByMonth[i].DataCount);
                        }
                        setChartdata({
                            labels: labels,
                            datasets: [
                                {
                                    label: ChartDatasetsLabel,
                                    data: data,
                                    backgroundColor: [ChartBackgroundColor],
                                },
                            ],
                        });
                    }

                    if (json.data.transportationDetails.length !== 0) {
                        const decodePolyline = require("decode-google-map-polyline");
                        if (
                            json.data.transportationDetails[0].PolyLineForPickup !== "" &&
                            json.data.transportationDetails[0].PolyLineForPickup
                        ) {
                            setPickupMarker(
                                decodePolyline(
                                    json.data.transportationDetails[0].PolyLineForPickup
                                )
                            );
                            let Array = decodePolyline(
                                json.data.transportationDetails[0].PolyLineForPickup
                            );
                            setPickupMapinitialCenter(Array[0]);
                        }

                        if (
                            json.data.transportationDetails[0].PolyLineForDrop !== "" &&
                            json.data.transportationDetails[0].PolyLineForDrop
                        ) {
                            setDropMarker(
                                decodePolyline(
                                    json.data.transportationDetails[0].PolyLineForDrop
                                )
                            );
                            let Array = decodePolyline(
                                json.data.transportationDetails[0].PolyLineForDrop
                            );
                            setDropMapinitialCenter(Array[0]);
                        }
                    }
                } else if (json.status_code === 0) {
                    setattendenceByMonth({ IsData: true, data: [] });
                    setattendenceSummary({ IsData: true, data: [] });
                    setbasicDetails({ IsData: true, data: [] });
                    sethostelDetails({ IsData: true, data: [] });
                    settransportationDetails({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindExamList() {
        setExam({ IsData: false, data: [] });

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "Send",
                searchText: "",
                fromDate: data[0].StartDate,
                toDate: new Date(),
                requestFrom: "Web",
                noOfData: 3,
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/getlistbymember", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExam({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setExam({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindFeesList() {
        setFees({ IsData: false, data: [] });

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(AppConfigData.APIBasePath + "v1/fees/getdetailsbymember", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setFees({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setFees({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {

        BindProfileData();
        BindExamList();
        BindFeesList();


    }, []);

    return (
        <>
            <h1 className="page-header my-4 uppercase">
                {data[0].FirstName} {data[0].LastName}
            </h1>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3  col-xl-3 left-side-student-profile mb-3">
                    <div className="card student-main-profile">
                        <div className="student-profile-edit">
                            <div className="student-id">
                                {data[0].MemberType.toLowerCase() !== "student" ? (
                                    <label className="student-nameId">
                                        {commonService.LabelDisplayText("EMPCode")}{" "}
                                        <span>
                                            {basicDetails.data.length !== 0 &&
                                                basicDetails.data[0].GRNo}
                                        </span>
                                    </label>
                                ) : (
                                        <label className="student-nameId">
                                            {commonService.LabelDisplayText("GRScholar")}{" "}
                                            <span>
                                                {basicDetails.data.length !== 0 &&
                                                    basicDetails.data[0].GRNo}
                                            </span>
                                        </label>
                                    )}
                            </div>
                        </div>
                        <div className="student-photo-profile mt-4">
                            <div
                                className="profile-area-main"
                                style={{
                                    backgroundColor: commonService.CreateColor(data[0].Initials)
                                        .BGColor,
                                    color: commonService.CreateColor(data[0].Initials).FontColor,
                                }}
                            >
                                {data[0].Avatar !== "" ? (
                                    <img src={AppConfigData.ERPFileBasePath + data[0].Avatar} />
                                ) : (
                                        data[0].Initials
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="std-classNameroom-detail mt-4 mb-4">
                        <div className="student-classNameroom-id">
                            {basicDetails.data.length && basicDetails.data[0].UID && (
                                <label className="student-id-label">
                                    {basicDetails.data[0].UID}
                                </label>
                            )}
                        </div>
                        <div className="student-divison mt-2">
                            <h2>
                                {basicDetails.data.length !== 0 &&
                                    basicDetails.data[0].STDDIVDEPT}
                            </h2>
                        </div>
                        <div className="student-roll-no mt-1">
                            {basicDetails.data.length && basicDetails.data[0].RollNo && (
                                <>
                                    {commonService.LabelDisplayText("Roll")}{" "}
                                    <label className="roll-number">
                                        {" "}
                                        {basicDetails.data[0].RollNo}
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                    {
                        data[0].MemberType.toLowerCase() === "teacher" ? (
                            <div className="row attendence-leaderboard">
                                <div className="col-6 student-attendence">
                                    <h2 className="student-attendence-percentage">
                                        {
                                            attendenceSummary.data.length !== 0 &&
                                            attendenceSummary.data[0].NoOfPresentInPer
                                        }
                                        %
                            </h2>
                                    <p className="student-attendence-leaderboard">
                                        {commonService.LabelDisplayText("MenuAttendance")}
                                    </p>
                                </div>
                                <div className="col-6 student-leaderbaord">
                                    <img src="achivement.svg" />
                                    <h2 className="student-attendence-percentage student-achivement-percentage">
                                        {basicDetails.data.length !== 0 &&
                                            basicDetails.data[0].CreditStar}
                                    </h2>
                                    <p className="student-attendence-leaderboard student-achivement-leader">
                                        {commonService.LabelDisplayText("Leaderboard")}
                                    </p>
                                </div>
                            </div>) : (
                                <>
                                    <div className="right-sidebar ">
                                        <div style={{ width: "100%" }}>
                                            <ul style={{ listStyle: "none" }}>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("STUDENT")}>Basic Information </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("PARENT")}>Parent  </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("SIBLING")}>Sibling  </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("FEE")}>Fee </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("EXAM")}>Exam </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("TRANSPORTATION")}>Transportation </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("HEALTH")}>Health </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("DOCUMNETS")}>Documnets </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("REWARD")}>Reward </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("OTHER INFORMATION")}>Other Information </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("CHILD CUSTODY")}>Child Custody </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("SUBJECT ENROLLMENT")}>Subject Enrollment </a></div></li>
                                                <li style={{ textAlign: "left", padding: "5px 5px", fontSize: "15px" }}><div style={{ borderBottom: "1px solid #d8d8d8" }}><a onClick={() => setStudentDetailType("HOSTEL DETAILS")}>Hostel Details </a></div></li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )
                    }

                    {hostelDetails.data.length !== 0 && commonService.getLoginRoleData("Hostel", "IsView") && (
                        <div className="student-hostel mt-4">
                            <h5>{commonService.LabelDisplayText("Hostel")}</h5>
                            <div className="hostel-information mt-2">
                                <div className="student-hostel-number">
                                    <p className="room-number">
                                        {commonService.LabelDisplayText("BedHostel")}{" "}
                                        <span> {hostelDetails.data[0].BedCode}</span>
                                    </p>
                                    <label className="hostel-room-rent">
                                        {hostelDetails.data[0].BedType_Term}
                                    </label>
                                </div>
                                <div className="student-hostel-number">
                                    <p className="room-number">
                                        {commonService.LabelDisplayText("RoomHostel")}{" "}
                                        <span> {hostelDetails.data[0].RoomNo}</span>
                                    </p>
                                    <label className="hostel-room-rent">
                                        {hostelDetails.data[0].RoomTypeName}
                                    </label>
                                </div>
                                <div className="student-hostel-number">
                                    <p className="room-number">
                                        {commonService.LabelDisplayText("FloorHostel")}{" "}
                                        <span> {hostelDetails.data[0].FloorName}</span>
                                    </p>
                                </div>
                                <div className="student-hostel-number">
                                    <p className="room-number">
                                        {commonService.LabelDisplayText("BuildingHostel")}{" "}
                                        <span> {hostelDetails.data[0].BuildingName}</span>
                                    </p>
                                </div>
                                {/*<div className="rector-name-number">*/}
                                {/*    <p className="room-number">Bed <span> {hostelDetails.data[0].BedCode}</span> Type <span>{hostelDetails.data[0].BedType_Term}</span></p>*/}
                                {/*</div>*/}
                                <div className="hostel-other-information mt-1">
                                    <label className="other-information-hostel">
                                        {commonService.LabelDisplayText("HR")} {hostelDetails.data[0].HRNo}
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="hostel-address mt-4">
                        {basicDetails.data.length !== 0 ? (
                            <>
                                <div className="location-icon mr-2">
                                    <i className="fa-regular fa-location-dot"></i>
                                </div>
                                <div className="address-location">
                                    {basicDetails.data[0].Add1 === "" && (
                                        <p>{commonService.LabelDisplayText("NoAddressDetails")}</p>
                                    )}
                                    <p>
                                        {basicDetails.data.length !== 0 &&
                                            basicDetails.data[0].Add1 +
                                            " " +
                                            basicDetails.data[0].City +
                                            " " +
                                            basicDetails.data[0].Taluka +
                                            " " +
                                            basicDetails.data[0].Distict +
                                            " " +
                                            basicDetails.data[0].State +
                                            " " +
                                            basicDetails.data[0].Country +
                                            " " +
                                            basicDetails.data[0].PinCode}
                                    </p>
                                </div>{" "}
                            </>
                        ) : (
                                <>
                                    <div className="location-icon mr-2">
                                        <i className="fa-regular fa-location-dot"></i>
                                    </div>
                                    <div className="address-location">{commonService.LabelDisplayText("NoAddressDetails")}</div>
                                </>
                            )}
                    </div>
                </div>
                {
                    StudentDetailType.toUpperCase() === "STUDENT" ?
                        (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                            <BasicInformationView />
                        </div>) :
                        (StudentDetailType.toUpperCase() === "PARENT" ?
                            (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                <ParentView />
                            </div>) :
                            (StudentDetailType.toUpperCase() === "SIBLING" ?
                                (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                    <SiblingView />
                                </div>) :
                                (StudentDetailType.toUpperCase() === "FEE" ?
                                    (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                        <FeeView />
                                    </div>) :
                                    (StudentDetailType.toUpperCase() === "EXAM" ?
                                        (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                            <ExamView />
                                        </div>) :
                                        (StudentDetailType.toUpperCase() === "TRANSPORTATION" ?
                                            (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                <TransportationView />
                                            </div>) :
                                            (StudentDetailType.toUpperCase() === "HEALTH" ?
                                                (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                    <Healthview />
                                                </div>) :
                                                (StudentDetailType.toUpperCase() === "OTHER INFORMATION" ?
                                                    (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                        <OtherInfoView />
                                                    </div>) :
                                                    (StudentDetailType.toUpperCase() === "CHILD CUSTODY" ?
                                                        (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                            <ChildCustodyView />
                                                        </div>) :
                                                        (StudentDetailType.toUpperCase() === "REWARD" ?
                                                            (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                                <RewardView />
                                                            </div>) :
                                                            (StudentDetailType.toUpperCase() === "SUBJECT ENROLLMENT" ?
                                                                (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                                    <SubjectEnrollmentView />
                                                                </div>) :
                                                                (StudentDetailType.toUpperCase() === "HOSTEL DETAILS" ?
                                                                    (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                                        <HostelDetailView />
                                                                    </div>) :
                                                                    (StudentDetailType.toUpperCase() === "DOCUMNETS" ?
                                                                        (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                                            <DocumentView />
                                                                        </div>) :
                                                                        (
                                                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 right-side-student-profile pr-0">
                                                                                {
                                                                                    commonService.getLoginRoleData("Attendance", "IsView") &&
                                                                                    <>{!attendenceSummary.IsData ? (
                                                                                        <div className="bar-chart">
                                                                                            <_Skeleton type={_SkeletonType.Line} count={6} lineheight={30} />
                                                                                        </div>
                                                                                    ) : (
                                                                                            <div className="bar-chart">
                                                                                                <div className="attendence-title-ratio">
                                                                                                    <h5>{commonService.LabelDisplayText("MenuAttendance")}</h5>
                                                                                                    <p className="attendence-ratio">
                                                                                                        <span className="ratio-attendence">
                                                                                                            {attendenceSummary.data.length !== 0 &&
                                                                                                                attendenceSummary.data[0].NoOfPresentDays +
                                                                                                                "/" +
                                                                                                                attendenceSummary.data[0].NoOfDays}
                                                                                                        </span>
                                                                                                        {commonService.LabelDisplayText("Days")}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <Bar
                                                                                                    data={Chartdata}
                                                                                                    width={100}
                                                                                                    height={50}
                                                                                                    options={ChartFontColor}
                                                                                                />
                                                                                            </div>
                                                                                        )}</>
                                                                                }
                                                                                {
                                                                                    commonService.getLoginRoleData("Exam", "IsView") &&
                                                                                    <div className="finish-exams">
                                                                                        <div className="inner-header-for-section mt-4 mb-3">
                                                                                            <h2 className="m-0">{commonService.LabelDisplayText("FinishExam")}({
                                                                                                Exam.IsData ? <>{Exam.data.length}</> : <>0</>
                                                                                            })</h2>
                                                                                            <Link to="/exam">{commonService.LabelDisplayText("ViewAll")}</Link>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            {!Exam.IsData ? (
                                                                                                <div className="col-sm-12"> <_Skeleton
                                                                                                    type={_SkeletonType.Exam}
                                                                                                    count={1}
                                                                                                    lineheight={40}
                                                                                                /></div>

                                                                                            ) : Exam.data.length === 0 ? (
                                                                                                <_NoDataFound fontsize={"150px"} />
                                                                                            ) : (
                                                                                                        Exam.data.map(
                                                                                                            (item, index) =>
                                                                                                                new Date(item.DateOfExam) <= new Date() && (
                                                                                                                    <div
                                                                                                                        className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 exam-section-area mb-3"
                                                                                                                        key={index}
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            className={`pointer-cursor`}

                                                                                                                        >
                                                                                                                            <div className="section">
                                                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                                                    <label>
                                                                                                                                        {commonService.getDateInFormat(item.DateOfExam)}
                                                                                                                                    </label>
                                                                                                                                    <label>{commonService.LabelDisplayText("TotalMark")} {item.TotakMarks}</label>
                                                                                                                                </div>
                                                                                                                                <h3 className="science-text">
                                                                                                                                    <div className="d-flex align-items-center justify-content-center">{item.IsOnlineExam ? (
                                                                                                                                        <i className="fa-solid fa-laptop-mobile mr-2"></i>
                                                                                                                                    ) : (
                                                                                                                                            <i className="fa-regular fa-school mr-2"></i>
                                                                                                                                        )}
                                                                                                                                        {item.SubjectName}</div>
                                                                                                                                    <label className="d-flex align-items-center justify-content-center">{item.ExamTypeAndPaperType}</label>
                                                                                                                                </h3>
                                                                                                                                <div className="d-flex justify-content-between align-items-cente ">
                                                                                                                                    {data[0].MemberType.toLowerCase() !== "student" ? (
                                                                                                                                        <label className="mark-label float-left">
                                                                                                                                            {commonService.LabelDisplayText("STD")} : {item.StandardName} - {item.ExamName}
                                                                                                                                        </label>
                                                                                                                                    ) : (
                                                                                                                                            <label className="mark-label float-left">
                                                                                                                                                {item.ExamName}
                                                                                                                                            </label>
                                                                                                                                        )}
                                                                                                                                    <label>{item.ExamOfTypeTerm}</label>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                )
                                                                                                        )
                                                                                                    )}
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    commonService.getLoginRoleData("Fees", "IsView") &&
                                                                                    <>{data[0].MemberType.toUpperCase() !== "TEACHER" && (
                                                                                        <div className="fees-status">
                                                                                            <div className="inner-header-for-section mt-4 mb-3">
                                                                                                <h2 className="m-0">{commonService.LabelDisplayText("MenuFees")}</h2>
                                                                                                <Link to="/fees">{commonService.LabelDisplayText("ViewAll")}</Link>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                {!Fees.IsData ? (
                                                                                                    <>
                                                                                                        {" "}
                                                                                                        <div className="col-6">
                                                                                                            <div className="student-term-fees">
                                                                                                                <_Skeleton
                                                                                                                    type={_SkeletonType.Line}
                                                                                                                    count={1}
                                                                                                                    lineheight={40}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6">
                                                                                                            <div className="student-term-fees">
                                                                                                                <_Skeleton
                                                                                                                    type={_SkeletonType.Line}
                                                                                                                    count={1}
                                                                                                                    lineheight={40}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : Fees.data.feesDetails.length === 0 ? (
                                                                                                    <_NoDataFound fontsize={"100px"} />
                                                                                                ) : (
                                                                                                            Fees.data.feesDetails.map(
                                                                                                                (item, index) =>
                                                                                                                    index < 2 && (
                                                                                                                        <div
                                                                                                                            className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-3"
                                                                                                                            key={index}
                                                                                                                        >
                                                                                                                            <div className="student-term-fees">
                                                                                                                                <div className="first-term">
                                                                                                                                    <p>{item.DisplayName}</p>
                                                                                                                                </div>
                                                                                                                                <div className="term-fees-status">
                                                                                                                                    <p>
                                                                                                                                        {item.Amount}/-
                                {item.UnpaidAmount > 0 ? (
                                                                                                                                            <span className="fess-paid-pending">
                                                                                                                                                {" "}
                                                                                                                                                {commonService.LabelDisplayText("Unpaid")}
                                                                                                                                            </span>
                                                                                                                                        ) : (
                                                                                                                                                <span className="fess-paid-pending">
                                                                                                                                                    {" "}
                                                                                                                                                    {commonService.LabelDisplayText("paid")}
                                                                                                                                                </span>
                                                                                                                                            )}
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                            )
                                                                                                        )}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}</>
                                                                                }

                                                                                {
                                                                                    commonService.getLoginRoleData("Transport", "IsView") &&
                                                                                    <>{!transportationDetails.IsData ? (
                                                                                        <div className="student-transportation">
                                                                                            <div className="inner-header-for-section mt-4 mb-3">
                                                                                                <h2 className="m-0">{commonService.LabelDisplayText("Transport")}</h2>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                {" "}
                                                                                                <div className="col-6">
                                                                                                    <div className="student-term-fees">
                                                                                                        <_Skeleton
                                                                                                            type={_SkeletonType.Line}
                                                                                                            count={1}
                                                                                                            lineheight={120}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6">
                                                                                                    <div className="student-term-fees">
                                                                                                        <_Skeleton
                                                                                                            type={_SkeletonType.Line}
                                                                                                            count={1}
                                                                                                            lineheight={100}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                            transportationDetails.data.length !== 0 && (
                                                                                                <div className="student-transportation">
                                                                                                    <div className="inner-header-for-section mt-4 mb-3">
                                                                                                        <h2 className="m-0">{commonService.LabelDisplayText("Transport")}</h2>
                                                                                                    </div>
                                                                                                    <div className="transportation-detail">
                                                                                                        <div className="row">
                                                                                                            <div className="col-6 p-2 pl-3">
                                                                                                                <h6 className="m-0 mb-3">
                                                                                                                    <b>{commonService.LabelDisplayText("PickupDetail")}</b>
                                                                                                                </h6>
                                                                                                                <div className="col-12 p-0">
                                                                                                                    <div
                                                                                                                        className="col-12 p-0  pickup-map"
                                                                                                                        style={{ height: "200px" }}
                                                                                                                    >
                                                                                                                        <Map
                                                                                                                            google={props.google}
                                                                                                                            zoom={11}
                                                                                                                            style={MapStyle}
                                                                                                                            initialCenter={PickupMapinitialCenter}
                                                                                                                            center={PickupMapinitialCenter}
                                                                                                                        >
                                                                                                                            {transportationDetails.data[0].PickupRoutePoint &&
                                                                                                                                JSON.parse(
                                                                                                                                    transportationDetails.data[0].PickupRoutePoint
                                                                                                                                ).map((point, index) => (
                                                                                                                                    <Marker
                                                                                                                                        key={index}
                                                                                                                                        position={{
                                                                                                                                            lat: parseFloat(point.Latitude),
                                                                                                                                            lng: parseFloat(point.Longitude),
                                                                                                                                        }}
                                                                                                                                        label={(index + 1).toString()}
                                                                                                                                        title={point.PointName}
                                                                                                                                    />
                                                                                                                                ))}
                                                                                                                            <Polyline
                                                                                                                                path={PickupMarker}
                                                                                                                                strokeColor="#007bff"
                                                                                                                                strokeWeight={4}
                                                                                                                            />
                                                                                                                        </Map>
                                                                                                                    </div>
                                                                                                                    <div className="col-12 student-detail-bus mt-2">
                                                                                                                        <div className="student-bus-detail">
                                                                                                                            <div className="bus-name-number">
                                                                                                                                <p className="bus-name mr-2">{commonService.LabelDisplayText("Point")}</p>
                                                                                                                                <p className="bus-number-plate ">
                                                                                                                                    {transportationDetails.data[0].PickupPointName}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="bus-name-number">
                                                                                                                                <p className="bus-name mr-2">{commonService.LabelDisplayText("BusNum")}</p>
                                                                                                                                <p className="bus-number-plate">
                                                                                                                                    {transportationDetails.data[0].RTORegNoForDrop}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="bus-timing">
                                                                                                                                <div className="in-time">
                                                                                                                                    <i className="icon-fi-rr-time-forward-1 mr-2"> </i>
                                                                                                                                    <p className="morning-time">
                                                                                                                                        {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .BusRouteForPickupStartTime
                                                                                                                                        }
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="in-time ml-3">
                                                                                                                                    <i className="icon-fi-rr-time-forward-1 mr-2"> </i>
                                                                                                                                    <p className="morning-time ">
                                                                                                                                        {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .BusRouteForPickupEndTime
                                                                                                                                        }
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="bus-driver-name">
                                                                                                                                <p className="bus-driver">
                                                                                                                                    {commonService.LabelDisplayText("BusDriver")}{" "}
                                                                                                                                    <span>
                                                                                                                                        {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .DriverNameForPickup
                                                                                                                                        }{" "}
                                                                                                                                        (
                                  {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .DriverMobileNoForPickup
                                                                                                                                        }
                                                                                                                                        )
                                </span>
                                                                                                                                </p>
                                                                                                                                <p className="bus-driver">
                                                                                                                                    {commonService.LabelDisplayText("BusHelper")}{" "}
                                                                                                                                    <span>
                                                                                                                                        {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .HelperNameForPickup
                                                                                                                                        }{" "}
                                                                                                                                        (
                                  {
                                                                                                                                            transportationDetails.data[0]
                                                                                                                                                .HelperMobileNoForPickup
                                                                                                                                        }
                                                                                                                                        )
                                </span>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-6 p-2 pr-3">
                                                                                                                <h6 className="m-0 mb-3">
                                                                                                                    <b>{commonService.LabelDisplayText("DropDetail")}</b>
                                                                                                                </h6>
                                                                                                                <div className="col-12 p-0 ">
                                                                                                                    <div
                                                                                                                        className="col-12 p-0 Drop-map"
                                                                                                                        style={{ height: "200px" }}
                                                                                                                    >
                                                                                                                        <Map
                                                                                                                            google={props.google}
                                                                                                                            zoom={11}
                                                                                                                            style={MapStyle}
                                                                                                                            initialCenter={DropMapinitialCenter}
                                                                                                                            center={DropMapinitialCenter}
                                                                                                                        >
                                                                                                                            {transportationDetails.data[0].DropRoutePoint &&
                                                                                                                                JSON.parse(
                                                                                                                                    transportationDetails.data[0].DropRoutePoint
                                                                                                                                ).map((point, index) => (
                                                                                                                                    <Marker
                                                                                                                                        key={index}
                                                                                                                                        position={{
                                                                                                                                            lat: parseFloat(point.Latitude),
                                                                                                                                            lng: parseFloat(point.Longitude),
                                                                                                                                        }}
                                                                                                                                        label={(index + 1).toString()}
                                                                                                                                        title={point.PointName}
                                                                                                                                    />
                                                                                                                                ))}
                                                                                                                            <Polyline
                                                                                                                                path={DropMarker}
                                                                                                                                strokeColor="#007bff"
                                                                                                                                strokeWeight={4}
                                                                                                                            />
                                                                                                                        </Map>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12 student-detail-bus  mt-2">
                                                                                                                    <div className="student-bus-detail">
                                                                                                                        <div className="bus-name-number">
                                                                                                                            <p className="bus-name mr-2">Point</p>
                                                                                                                            <p className="bus-number-plate">
                                                                                                                                {transportationDetails.data[0].DropPointName}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div className="bus-name-number">
                                                                                                                            <p className="bus-name mr-2">Bus#</p>
                                                                                                                            <p className="bus-number-plate">
                                                                                                                                {transportationDetails.data[0].RTORegNoForDrop}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div className="bus-timing">
                                                                                                                            <div className="in-time">
                                                                                                                                <i className="icon-fi-rr-time-forward-1 mr-2"> </i>
                                                                                                                                <p className="morning-time ">
                                                                                                                                    {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .BusRouteForDropStartTime
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="in-time ml-3">
                                                                                                                                <i className="icon-fi-rr-time-forward-1 mr-2"> </i>
                                                                                                                                <p className="morning-time ">
                                                                                                                                    {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .BusRouteForDropEndTime
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="bus-driver-name">
                                                                                                                            <p className="bus-driver">
                                                                                                                                {commonService.LabelDisplayText("BusDriver")}{" "}
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .DriverNameForDrop
                                                                                                                                    }{" "}
                                                                                                                                    (
                                {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .DriverMobileNoForDrop
                                                                                                                                    }
                                                                                                                                    )
                              </span>
                                                                                                                            </p>
                                                                                                                            <p className="bus-driver">
                                                                                                                                {commonService.LabelDisplayText("BusHelper")}{" "}
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .HelperNameForDrop
                                                                                                                                    }{" "}
                                                                                                                                    (
                                {
                                                                                                                                        transportationDetails.data[0]
                                                                                                                                            .HelperMobileNoForDrop
                                                                                                                                    }
                                                                                                                                    )
                              </span>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        )}</>
                                                                                }

                                                                            </div>
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                }
            </div>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyC5jLWPa_hRkT8ryV11Tmi_Zpd05hG3S2A",
})(Profile);

                //export default Profile;
