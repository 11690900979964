import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import { useHistory, Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import _NoPostFound from "./_NoPostFound";
import { viewFilePopupService } from "../_services/viewFilePopup.service";
import { alertService, AlertType } from "../_services/alert.service";
import Modal from "react-bootstrap/Modal";

function Home() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [AttendanceMsg, setAttendanceMsg] = useState("");
    const [MaintenanceMsg, setMaintenanceMsg] = useState("");
    const [Post, setPost] = useState({ IsData: false, data: [] });
    const [Classwork, setClasswork] = useState({ IsData: false, data: [] });
    const [Homework, setHomework] = useState({ IsData: false, data: [] });
    const [OnlineClass, setOnlineClass] = useState({ IsData: false, data: [] });
    const [Exam, setExam] = useState({ IsData: false, data: [] });
    const [polldata, setpolldata] = useState({ isdata: false, data: [] });
    const [openanswerpopup, setopenanswerpopup] = useState(false)
    const [radiovalue, setradiovalue] = useState("")
    const [Gallery, setGallery] = useState({ IsData: false, data: [] });
    const [pollvotedata, setpollvotedata] = useState({
        PollID: "00000000-0000-0000-0000-000000000000",
        Title: "",
        Details: "",
        IsMultiChoice: false,
        Options: [],
        RequestFrom: "Web",
        isdata: false,
        errors: {
            optionerror: ""
        }

    })

    function getpolldata() {
        setpolldata({ isdata: false, data: [] });
        const reqteacherlistingdata = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/poll/getlistbymemberforvote",
            reqteacherlistingdata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    var a = json.data;
                    setpolldata({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setpolldata({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }

    function pollvote(id) {
        setopenanswerpopup(true)
        if (id !== "") {
            const reqClasswork = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    id: id,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/poll/getalldetailsbyid",
                reqClasswork
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {

                        let Options = [];
                        if (json.data.optionData.length !== 0) {
                            json.data.optionData.map((item) =>
                                Options.push({ Option: item.Option, PollOptionID: item.PollOptionID, PollID: item.PollID, IsCheck: false })
                            )
                        }

                        setpollvotedata({
                            PollID: json.data.detailsData[0].PollID,
                            Title: json.data.detailsData[0].Title,
                            Details: json.data.detailsData[0].Details,
                            IsMultiChoice: json.data.detailsData[0].IsMultiChoice,
                            Options: Options,
                            RequestFrom: "Web",
                            isdata: true,
                            errors: {
                                optionerror: ""
                            }
                        });

                        setopenanswerpopup(true)
                    }
                    else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setpollvotedata({
                PollID: "00000000-0000-0000-0000-000000000000",
                Title: "",
                Details: "",
                IsMultiChoice: false,
                Options: [],
                RequestFrom: "Web",
                isdata: false,
                errors: {
                    optionerror: ""
                }
            })
            setopenanswerpopup(false)
        }
    }

    function getcheckboxinfo(e, item) {
        item.IsCheck = e.target.checked;

    }
    function getradioinfo(e, item) {
        setradiovalue(e.target.value)

    }

    function savepollvote(id) {

        if (radiovalue !== "") {
            for (let i = 0; i < pollvotedata.Options.length; i++) {
                if (pollvotedata.Options[i].PollOptionID === radiovalue) {
                    pollvotedata.Options[i].IsCheck = true;
                }
            }
        }

        let isValid = false;
        let errors = {
            optionerror: "",
        };
        for (let i = 0; i < pollvotedata.Options.length; i++) {
            if (pollvotedata.Options[i].IsCheck) {
                isValid = true;
            }
        }
        if (isValid) {
            let formData = new FormData();
            formData.append("PollID", id);
            let SelectedOptionIndex = 0;
            for (let i = 0; i < pollvotedata.Options.length; i++) {
                if (pollvotedata.Options[i].IsCheck) {

                    formData.append(
                        "SelectedOptions[" + SelectedOptionIndex + "]",
                        pollvotedata.Options[i].PollOptionID
                    );
                    SelectedOptionIndex++;
                }
            }
            const reqteacherlistingdata = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(
                AppConfigData.APIBasePath + "v1/poll/savevote",
                reqteacherlistingdata
            ).then((response) => response.json())
                .then(function (json) {

                    if (json.status_code === 1) {
                        setpollvotedata({
                            PollID: "00000000-0000-0000-0000-000000000000",
                            Title: "",
                            Details: "",
                            IsMultiChoice: false,
                            Options: [],
                            RequestFrom: "Web",
                            isdata: false,
                            errors: {
                                optionerror: ""
                            }
                        })
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        getpolldata();
                        setopenanswerpopup(false);

                    }
                    else if (json.status_code === 0) {

                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    }
                    else if (json.status_code === 2) {

                        localStorage.clear();
                        history.push("/");
                    }
                })
        }
        else {
            errors.optionerror = "Selection required";

            setpollvotedata({
                ...pollvotedata,
                ["errors"]: errors,
            });
        }


    }

    let strClassworkClassName = "col-12";
    strClassworkClassName =
        Classwork.data.length > 1
            ? "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3"
            : "col-12";

    let strHomeworkClassName = "col-12";

    if (Homework.data.length > 2) {
        strHomeworkClassName =
            "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3";
    } else if (Homework.data.length > 1) {
        strHomeworkClassName = "col-6";
    }

    function getcommonwallpost() {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                wallID: data[0].WallID,
                sRow: 1,
                noOfData: 5,
                filterAssociationType: "Circular,Event,Note",
                startDate: null,
                endDate: null,
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/postcomments/getcommonwallpost",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setPost({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setPost({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getgalleryfileslist() {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/getgalleryfileslist",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setGallery({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setGallery({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    React.useEffect(() => {
        let TodayDate = new Date();
        let Next15Date = new Date();
        Next15Date = new Date(Next15Date.setDate(Next15Date.getDate() + 15));
        getpolldata();
        const reqAtteMain = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                memberName: data[0].FullName,
                wallID: data[0].WallID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/dashboard/getdashboarddata_attendance_maintenance_post",
            reqAtteMain
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setAttendanceMsg(json.data.attendance[0].AttendanceMsg);
                    setMaintenanceMsg(json.data.maintenance[0].MaintenanceMsg);
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

        getcommonwallpost();

        getgalleryfileslist();

        const reqClasswork = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                memberName: data[0].FullName,
                wallID: data[0].WallID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/dashboard/getdashboarddata_classwork",
            reqClasswork
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setClasswork({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setClasswork({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

        const reqHomework = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                memberName: data[0].FullName,
                wallID: data[0].WallID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/dashboard/getdashboarddata_homework",
            reqHomework
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setHomework({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setHomework({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

        const reqOnlineClass = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                memberName: data[0].FullName,
                wallID: data[0].WallID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/dashboard/getdashboarddata_onlineclass",
            reqOnlineClass
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setOnlineClass({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setOnlineClass({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                saveType: "Send",
                searchText: "",
                fromDate: TodayDate,
                toDate: Next15Date,
                requestFrom: "Web",
                noOfData: 4,
            }),
        };
        fetch(AppConfigData.APIBasePath + "v1/exam/getlistbymember", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setExam({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setExam({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }, []);

    function JoinLiveClass(item) {
        const reqPost = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                onlineClassTimeTableDetailID: item.OnlineClassTimeTableDetailID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath +
            "v1/timetableliveclass/checkonlineclasstimeanddetails",
            reqPost
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    commonService.setItem("liveclass", JSON.stringify(json.data));
                    window.open("liveclass");
                } else if (json.status_code === 0) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: json.message,
                    });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    return (
        <>
            <h1 className="page-header my-4 uppercase">
                Welcome {data.length !== 0 ? data[0].FullName : ""} !
            </h1>
            <div className="row fix-height-container">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8  ">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                            <div className="card-area">
                                {AttendanceMsg === "" ? (
                                    <div className="card attendancemaintenancemsg">
                                        <div className="card-body">
                                            <_Skeleton type={_SkeletonType.Line} count={2} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card red-card-long attendancemaintenancemsg">
                                        <div className="card-body">
                                            <p>{AttendanceMsg}</p>
                                        </div>
                                    </div>
                                )}
                                {MaintenanceMsg === "" ? (
                                    <div className="card mt-10 attendancemaintenancemsg">
                                        <div className="card-body">
                                            <_Skeleton type={_SkeletonType.Line} count={2} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card blue-card-long mt-10 attendancemaintenancemsg">
                                        <div className="card-body">
                                            <p>{MaintenanceMsg}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            commonService.getLoginRoleData("Wall", "IsView") &&
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                                <div className="card-area">
                                    <div className="card home-card-post">
                                        {!Post.IsData ? (
                                            <_Skeleton type={_SkeletonType.Post} count={4} />
                                        ) : Post.data.length === 0 ? (
                                            <_NoPostFound />
                                        ) : (
                                            <Carousel controls={false} interval={5000} slide={false}>
                                                {Post.data.map((item, index) => (
                                                    <Carousel.Item key={index}>
                                                        <div className="card-body">
                                                            <div className="card-body-header mb-1">
                                                                <div className="left-section">
                                                                    <div
                                                                        className="user-pic"
                                                                        title={item.DisplayName}
                                                                        style={{
                                                                            backgroundColor: commonService.CreateColor(
                                                                                item.Initials
                                                                            ).BGColor,
                                                                            color: commonService.CreateColor(
                                                                                item.Initials
                                                                            ).FontColor,
                                                                        }}
                                                                    >
                                                                        {item.Avatar !== "" ? (
                                                                            <img
                                                                                src={
                                                                                    AppConfigData.ERPFileBasePath +
                                                                                    item.Avatar
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            item.Initials
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <p> {item.DisplayName}</p>
                                                                        <div className={"upper-section-area"}>
                                                                            <span className="birthday-details">
                                                                                {commonService.getDateTimeInFormat(
                                                                                    item.DateOfPost
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="right-section">
                                                                    <label
                                                                        className={`tag-label ${item.AssociationType.toLowerCase()}-label`}
                                                                    >
                                                                        {item.AssociationType}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="card-body-main">
                                                                <p
                                                                    className="card-body-content post mt-3"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.PostCommentNote,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </div>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        Gallery.IsData && Gallery.data.length > 0 &&
                        <div className="row">
                            <div className="col-12 mb-3">
                                <Carousel controls={false} interval={5000} slide={false} className={"gallery-view"}>
                                    {Gallery.data.map((item, index) => (
                                        <Carousel.Item key={index}>
                                            <div className={"image-title-container"}>
                                                <img src={item.FilePath} />
                                                {
                                                    item.Name &&
                                                    <div className={"title"}>{item.Name}</div>
                                                }
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    }
                    {
                        commonService.getLoginRoleData("Classwork", "IsView") &&
                        <div className="row">
                            <div className="col-12">
                                <div className="inner-header-for-section mb-3">
                                    <h2 className="m-0">{commonService.LabelDisplayText("MenuClasswork")}</h2>
                                    <Link to="/classwork">{commonService.LabelDisplayText("ViewAll")}</Link>
                                </div>
                            </div>
                            {!Classwork.IsData ? (
                                <div className="row col-12">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <_Skeleton type={_SkeletonType.ClassWork} count={3} />
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <_Skeleton type={_SkeletonType.ClassWork} count={3} />
                                    </div>
                                </div>
                            ) : Classwork.data.length === 0 ? (
                                <div className="col-12">
                                    <div className="card home-card-classwork">
                                        {/* <_NoDataFound fontsize={"120px"} /> */}
                                        <_NoPostFound />
                                    </div>
                                </div>
                            ) : (
                                Classwork.data.map(
                                    (item, index) =>
                                        index < 2 && (
                                            <div className={strClassworkClassName} key={index}>
                                                <div className="card home-card-homework">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h2
                                                                className="card-body-header m-0"
                                                                title={item.ClassWorkTitle}
                                                            >
                                                                {item.ClassWorkTitle}
                                                            </h2>
                                                            <i className="fa-regular fa-square-arrow-up-right expand-classwork-icon"></i>
                                                        </div>
                                                        <label className="language-label mr-1">
                                                            {item.SubjectName}
                                                        </label>
                                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                                            <label className="std-homework-label">
                                                                {item.StandardName + " - " + item.DivisionName}
                                                            </label>
                                                        )}
                                                        <p
                                                            className="card-body-content mt-2"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.ClassWorkDetails,
                                                            }}
                                                        >
                                                            {/*{item.ClassWorkDetails}*/}
                                                        </p>
                                                        <div className="mt-3">
                                                            {item.Files ? (
                                                                JSON.parse(item.Files).map(
                                                                    (itemFile, indexFile) =>
                                                                        indexFile < 3 && (
                                                                            <label
                                                                                onClick={() =>
                                                                                    viewFilePopupService.viewFilePopup({
                                                                                        data: JSON.parse(item.Files),
                                                                                    })
                                                                                }
                                                                                key={indexFile}
                                                                                className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                                                            >
                                                                                {itemFile.FileType}
                                                                            </label>
                                                                        )
                                                                )
                                                            ) : (
                                                                <label></label>
                                                            )}
                                                        </div>
                                                        <div className="section-for-footer-card">
                                                            <div className="left-section">
                                                                <i className="fa-light fa-calendar-lines calendar-icons"></i>
                                                                <p>
                                                                    {commonService.getDateInFormat(
                                                                        item.DateOfClassWork
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="right-section">
                                                                <div
                                                                    className="user-pic"
                                                                    title={item.CreateByName}
                                                                    style={{
                                                                        backgroundColor: commonService.CreateColor(
                                                                            item.Initials
                                                                        ).BGColor,
                                                                        color: commonService.CreateColor(
                                                                            item.Initials
                                                                        ).FontColor,
                                                                    }}
                                                                >
                                                                    {item.Avatar !== "" ? (
                                                                        <img
                                                                            src={
                                                                                AppConfigData.ERPFileBasePath +
                                                                                item.Avatar
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        item.Initials
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )
                            )}
                        </div>
                    }

                    {
                        commonService.getLoginRoleData("Homework", "IsView") &&
                        <div className="row">
                            <div className="col-12">
                                <div className="inner-header-for-section mb-3 mt-3">
                                    <h2 className="m-0">{commonService.LabelDisplayText("MenuHomework")}</h2>
                                    <Link to="/homework">{commonService.LabelDisplayText("ViewAll")}</Link>
                                </div>
                            </div>
                            {!Homework.IsData ? (
                                <div className="row col-12">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                        <div className="card home-card-homework">
                                            <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                        <div className="card home-card-homework">
                                            <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                        <div className="card home-card-homework">
                                            <_Skeleton type={_SkeletonType.ClassWork} count={4} />
                                        </div>
                                    </div>
                                </div>
                            ) : Homework.data.length === 0 ? (
                                <div className="col-12">
                                    <div className="card home-card-homework">
                                        <_NoDataFound fontsize={"120px"} />
                                    </div>
                                </div>
                            ) : (
                                Homework.data.map(
                                    (item, index) =>
                                        index < 3 && (
                                            <div className={strHomeworkClassName} key={index}>
                                                <div className="card home-card-homework">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <h2 className="card-body-header" title={item.Title}>
                                                                {item.Title}
                                                            </h2>
                                                            <i className="fa-regular fa-square-arrow-up-right expand-classwork-icon"></i>
                                                        </div>
                                                        <label className="language-label mr-1">
                                                            {item.SubjectName}
                                                        </label>
                                                        {data[0].MemberType.toLowerCase() !== "student" && (
                                                            <label className="std-homework-label">
                                                                {item.StandardName + " - " + item.DivisionName}
                                                            </label>
                                                        )}
                                                        <p
                                                            className="card-body-content mt-2"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.HomeWorksDetails,
                                                            }}
                                                        >
                                                            {/*{item.HomeWorksDetails}*/}
                                                        </p>
                                                        <div className="mt-3">
                                                            {item.Files ? (
                                                                JSON.parse(item.Files).map(
                                                                    (itemFile, indexFile) =>
                                                                        indexFile < 3 && (
                                                                            <label
                                                                                onClick={() =>
                                                                                    viewFilePopupService.viewFilePopup({
                                                                                        data: JSON.parse(item.Files),
                                                                                    })
                                                                                }
                                                                                key={indexFile}
                                                                                className={`${itemFile.FileType.toLowerCase()}-label pointer-cursor mr-1`}
                                                                            >
                                                                                {itemFile.FileType}
                                                                            </label>
                                                                        )
                                                                )
                                                            ) : (
                                                                <label></label>
                                                            )}
                                                        </div>
                                                        <div className="section-for-footer-card">
                                                            <div className="left-section">
                                                                <i className="fa-light fa-calendar-lines calendar-icons"></i>
                                                                <p>
                                                                    {commonService.getDateInFormat(
                                                                        item.DateOfHomeWork
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="right-section">
                                                                <div
                                                                    className="user-pic"
                                                                    title={item.CreateByName}
                                                                    style={{
                                                                        backgroundColor: commonService.CreateColor(
                                                                            item.Initials
                                                                        ).BGColor,
                                                                        color: commonService.CreateColor(
                                                                            item.Initials
                                                                        ).FontColor,
                                                                    }}
                                                                >
                                                                    {item.Avatar !== "" ? (
                                                                        <img
                                                                            src={
                                                                                AppConfigData.ERPFileBasePath +
                                                                                item.Avatar
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        item.Initials
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                )
                            )}
                        </div>
                    }
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="row">
                        {
                            commonService.getLoginRoleData("Liveclass", "IsView") &&
                            <div className="col-12">
                                <div className="inner-header-for-section mb-3 mt-3 mt-xl-0 live-class-section">
                                    <h2 className="m-0">{commonService.LabelDisplayText("LiveClassHome")}</h2>
                                    <Link to="/timetableliveclass">{commonService.LabelDisplayText("ViewAll")}</Link>
                                </div>
                                <div className="calendar-area">
                                    {!OnlineClass.IsData ? (
                                        <div className="subject-section-grid">
                                            <div className="card subject-class-card mb-3">
                                                <_Skeleton
                                                    type={_SkeletonType.Line}
                                                    count={6}
                                                    lineheight={25}
                                                />
                                            </div>
                                        </div>
                                    ) : OnlineClass.data.length === 0 ? (
                                        <_NoDataFound fontsize={"120px"} />
                                    ) : (
                                        <div className="subject-section-grid">
                                            {OnlineClass.data.map((item, index) => (
                                                <div
                                                    className={`card subject-class-card mb-3 ${item.IsRunning ? "blue-color-card" : ""
                                                        }`}
                                                    key={index}
                                                >
                                                    <div className="cardbody">
                                                        <h4>{item.SubjectName}</h4>
                                                        <h5>
                                                            {data[0].MemberType.toLowerCase() !== "student"
                                                                ? item.StandardName + " - " + item.DivisionName
                                                                : ""}
                                                        </h5>
                                                        <div className="btn-join duration-time-btn d-flex">
                                                            {" "}
                                                            <a
                                                                onClick={() => JoinLiveClass(item)}
                                                                className={`joinClassBtn  ${item.IsRunning ? "" : "disabled"
                                                                    }`}
                                                            >
                                                                {item.IsRunning ? (
                                                                    <i className=" fa-regular fa-video mr-2"></i>
                                                                ) : (
                                                                    <div></div>
                                                                )}

                                                                {item.IsRunning
                                                                    ? "Join Class"
                                                                    : item.StartTimeEndTime}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>


                            </div>
                        }

                        {
                            commonService.getLoginRoleData("Poll", "IsView") &&
                            <div className="col-12">
                                <div className="calendar-area">
                                    {!polldata.isdata ? (
                                        <div className="subject-section-grid ">
                                            <div className="card subject-class-card mb-3">
                                                <_Skeleton
                                                    type={_SkeletonType.Line}
                                                    count={6}
                                                    lineheight={25}
                                                />
                                            </div>
                                        </div>
                                    ) : polldata.data.length === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div className="inner-header-for-section mb-3 mt-3 mt-xl-0 live-class-section">
                                                <h2 className="m-0">{commonService.LabelDisplayText("Poll")}</h2>

                                            </div>
                                            <div className="subject-section-grid">
                                                {polldata.data.map((item, index) => (
                                                    <div
                                                        className={`card subject-class-card mb-3 blue-color-card `}
                                                        key={index}
                                                    >
                                                        <div className="cardbody">
                                                            <p>{item.Title}</p>

                                                            <div className="btn-join duration-time-btn d-flex">

                                                                <a
                                                                    onClick={() => pollvote(item.PollID)}
                                                                    className={`joinClassBtn`}
                                                                >participate
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div></>
                                    )}
                                </div>
                            </div>
                        }

                        {
                            commonService.getLoginRoleData("Exam", "IsView") &&
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="inner-header-for-section exam-class-section mb-3 mt-3 mt-xl-0">
                                            <h2 className="m-0">Exam</h2>
                                            <Link to="/exam">{commonService.LabelDisplayText("ViewAll")}</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className={Exam.data.length === 0 ? "" : "row"}>
                                    {!Exam.IsData ? (
                                        <_Skeleton
                                            type={_SkeletonType.ExamHome}
                                            count={1}
                                            lineheight={40}
                                        />
                                    ) : Exam.data.length === 0 ? (
                                        <_NoDataFound fontsize={"120px"} color={"#000000"} />
                                    ) : (
                                        Exam.data.map((item, index) => (
                                            <div
                                                className="col-xs-12 col-md-6 col-lg-4 col-xl-6 exam-section-area mb-3"
                                                key={index}
                                            >
                                                <a className="pointer-cursor">
                                                    <div className="section">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <label>
                                                                {item.NoOfDay == 0
                                                                    ? "Today"
                                                                    : item.NoOfDay + " Days to go"}{" "}
                                                            </label>
                                                            <label>Total Mark {item.TotakMarks}</label>
                                                        </div>
                                                        <div>
                                                            <h3 className="science-text">{item.SubjectName}</h3>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-cente ">
                                                            {data[0].MemberType.toLowerCase() !== "student" ? (
                                                                <label>SDT : {item.StandardName} </label>
                                                            ) : (
                                                                <label>{item.ExamName}</label>
                                                            )}

                                                            <label>{item.ExamOfTypeTerm}</label>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={openanswerpopup}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="answermodal"
                onHide={() => setopenanswerpopup(false)}
            >
                <Modal.Header className="align-items-center">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select Answer
                    </Modal.Title>
                    <button
                        class="poopup-close-button"
                        onClick={() => pollvote("")}
                    >
                        <i class="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {pollvotedata.isdata === true ? <>

                        <div className="row">
                            <div className="col-md-12">
                                <h2>
                                    <span className="text-muted">Title :</span> {pollvotedata.Title}
                                </h2>
                            </div>
                            {pollvotedata.Options.map((a, index) => (
                                <div className="col-md-12 mt-3 mb-2">
                                    <div className={`selctioncard ${pollvotedata.errors.optionerror && "invalid"}`} >
                                        <div className="d-flex">
                                            <div className="circler">
                                                <span>
                                                    {index + 1}</span>
                                                {pollvotedata.IsMultiChoice === true ? <>
                                                    <input
                                                        type="checkbox"
                                                        id={a.PollOptionID}
                                                        onChange={(e) => getcheckboxinfo(e, a)}
                                                    />
                                                </> : <><input type="radio"

                                                    value={a.PollOptionID}
                                                    checked={radiovalue === a.PollOptionID}
                                                    onChange={(e) => getradioinfo(e, a)}
                                                /></>}
                                            </div>

                                            <div className={`resultdesc`}>
                                                <p >

                                                    {a.Option}

                                                </p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                            {pollvotedata.errors.optionerror && (
                                <span className="error-message">
                                    {pollvotedata.errors.optionerror}
                                </span>
                            )}

                        </div>


                    </> :
                        <>
                            <_Skeleton
                                type={_SkeletonType.Line}
                                count={6}
                                lineheight={25}
                            /></>}

                </Modal.Body>
                {
                    commonService.getLoginRoleData("Poll", "IsCreate") &&
                    <Modal.Footer className="footwermodal">
                        <button className="btn btn-secondary bt1" onClick={() => pollvote("")}>
                            Cancel
                        </button>
                        <button className="btn btn-primary bt2" onClick={() => savepollvote(pollvotedata.PollID)}>Submit</button>
                    </Modal.Footer>
                }

            </Modal>
        </>
    );
}

export default Home;
