import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import FormCheck from "react-bootstrap/FormCheck";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { alertService, AlertType } from "../_services/alert.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function Attendance() {
    let data = commonService.getLoginUserData();

    let SelectAttendanceFrom = {
        value: "",
        label: commonService.LabelDisplayText("SelectAttendanceFrom"),
    };

    let SelectAttendanceTo = {
        value: "",
        label: commonService.LabelDisplayText("SelectAttendenceTo"),
    };

    const [IsAttendanceSaveing, setIsAttendanceSaveing] = useState(false);

    const [IsGenerateReport, setIsGenerateReport] = useState(false);
    const [IsSendMother, setIsSendMother] = useState(false);
    const [IsSendFather, setIsSendFather] = useState(false);
    const [IsSendStudent, setIsSendStudent] = useState(false);

    const history = useHistory();

    const [TeacherStdDiv, setTeacherStdDiv] = useState([]);

    const [MonthYear, setMonthYear] = useState([]);

    const [IsAttandanceListDateLodding, setIsAttandanceListDateLodding] =
        useState(false);

    const [SearchData, setSearchData] = useState({
        StdDiv: data[0].CurrentStandardID + "|" + data[0].CurrentDivisionID,
        StartEndDate: data[0].StartDate + "#" + data[0].EndDate,
        AttendanceFrom: "",
        AttendanceTo: "",
    });

    const [AttendanceFrom, setAttendanceFrom] = useState([SelectAttendanceFrom]);
    const [AttendanceTo, setAttendanceTo] = useState([SelectAttendanceTo]);
    const [attendence, setattendence] = useState({ IsData: true, data: [] });
    const [studentattendence, setstudentattendence] = useState({ IsData: true, data: [] });
    const [teacherAttendance, setteacherAttendance] = useState({
        IsData: true,
        data: [],
    });
    const [attendanceRegisterMaster, setattendanceRegisterMaster] = useState({
        IsData: true,
        data: [],
    });
    const [teacherAttendanceSave, setteacherAttendanceSave] = useState({
        isin: true,
        inputimage: null,
        errors: {
            isin: "",
            inputimage: "",
        },
    });

    const [txtStartDate, setTxtStartDate] = useState(null);

    const [saveload, setsaveload] = useState(false);

    function getteachetdata() {
        setIsAttandanceListDateLodding(true);
        setteacherAttendance({ IsData: false, data: [] });
        const reqTeacherAttendanceData = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                startDate: SearchData.StartEndDate.split("#")[0],
                endDate: SearchData.StartEndDate.split("#")[1],
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/attendance/getemployeeattendencelist",
            reqTeacherAttendanceData
        )
            .then((response) => response.json())
            .then(function (json) {
                setIsAttandanceListDateLodding(false);

                if (json.status_code === 1) {
                    var a = json.data;
                    setteacherAttendance({ IsData: true, data: a });
                } else if (json.status_code === 0) {
                    setteacherAttendance({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function getlectuderattendancedata() {
        setIsAttandanceListDateLodding(true);
        setstudentattendence({ IsData: false, data: [] });
        const reqTeacherAttendanceData = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                startDate: commonService.getDateInDBFormat(data[0].StartDate),
                endDate: commonService.getDateInDBFormat(data[0].EndDate),
                requestFrom: "Web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/attendance/getattendance_bylectureforstudent",
            reqTeacherAttendanceData
        )
            .then((response) => response.json())
            .then(function (json) {
                setIsAttandanceListDateLodding(false);

                if (json.status_code === 1) {
                    var a = json.data;
                    setstudentattendence({ IsData: true, data: a });
                } else if (json.status_code === 0) {
                    setstudentattendence({ IsData: false, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function BindAttendanceData() {
        setAttendanceFrom([SelectAttendanceFrom]);
        setAttendanceTo([SelectAttendanceTo]);
        let TempSearchData = SearchData;
        TempSearchData.AttendanceFrom = "";
        TempSearchData.AttendanceTo = "";
        setSearchData(TempSearchData);

        setIsAttandanceListDateLodding(true);
        setattendence({ IsData: false, data: [] });
        setattendanceRegisterMaster({ IsData: false, data: [] });

        if (
            SearchData.StdDiv !== null &&
            SearchData.StdDiv !== "" &&
            SearchData.StartEndDate !== null &&
            SearchData.StartEndDate !== ""
        ) {
            const reqAttendanceData = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    standardID: SearchData.StdDiv.split("|")[0],
                    divisionID: SearchData.StdDiv.split("|")[1],
                    startDate: SearchData.StartEndDate.split("#")[0],
                    endDate: SearchData.StartEndDate.split("#")[1],
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/attendance/getattendance",
                reqAttendanceData
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsAttandanceListDateLodding(false);
                    if (json.status_code === 1) {
                        setattendence({ IsData: true, data: json.data.attendence });
                        setattendanceRegisterMaster({
                            IsData: true,
                            data: json.data.attendanceRegisterMaster,
                        });
                        if (json.data.attendanceRegisterMaster.length !== 0) {
                            let Options = [];
                            Options.push(SelectAttendanceFrom);
                            for (
                                let i = 0;
                                i < json.data.attendanceRegisterMaster.length;
                                i++
                            ) {
                                if (
                                    new Date(json.data.attendanceRegisterMaster[i].Date) <=
                                    new Date()
                                )
                                    Options.push({
                                        value: json.data.attendanceRegisterMaster[i].Date,
                                        label: commonService.getDateInFormat(
                                            json.data.attendanceRegisterMaster[i].Date
                                        ),
                                    });
                            }
                            setAttendanceFrom(Options);
                        }
                    } else if (json.status_code === 0) {
                        setattendence({ IsData: false, data: [] });
                        setattendanceRegisterMaster({ IsData: true, data: [] });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setIsAttandanceListDateLodding(false);
            setattendence({ IsData: true, data: [] });
            setattendanceRegisterMaster({ IsData: true, data: [] });
        }
    }



    function GetStandardDivisionData() {
        setTeacherStdDiv([]);
        const reqSTDDIV = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/home/get_teacher_standarddivision",
            reqSTDDIV
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    let Options = [];
                    for (let i = 0; i < json.data.length; i++) {
                        if (json.data[i].IsClassTeacher) {
                            Options.push({
                                value: json.data[i].IDs.toLocaleUpperCase(),
                                label: json.data[i].Text,
                            });
                        }
                    }
                    setTeacherStdDiv(Options);
                } else if (json.status_code === 0) {
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    React.useEffect(() => {
        if (commonService.getLoginRoleData("Attendance", "IsView") || commonService.getLoginRoleData("Teacher Attendance", "IsView")) {
            if (data[0].MemberType.toLowerCase() !== "student") {
                getteachetdata();
                if (data[0].MemberType.toLowerCase() === "teacher") {
                    GetStandardDivisionData();
                    if (
                        data[0].StartDate !== null &&
                        data[0].StartDate !== "" &&
                        data[0].EndDate !== null &&
                        data[0].EndDate !== ""
                    ) {
                        let dt = new Date(data[0].StartDate);
                        let StartDate = new Date(dt);
                        let EndDate = new Date(dt);

                        let Options = [];
                        while (dt <= new Date(data[0].EndDate)) {
                            StartDate = new Date(dt);
                            EndDate = new Date(dt);
                            EndDate = new Date(
                                StartDate.getFullYear(),
                                StartDate.getMonth() + 1,
                                0
                            );

                            Options.push({
                                value:
                                    commonService.getDateInDBFormat(StartDate) +
                                    "#" +
                                    commonService.getDateInDBFormat(EndDate),
                                label: commonService.getDateInMonthYearFormat(dt),
                            });
                            dt = new Date(StartDate.getFullYear(), StartDate.getMonth() + 1, 1);
                        }
                        setMonthYear(Options);
                    }
                }
            }
            else if (data[0].MemberType.toLowerCase() === "student") {
                if (data[0].AttendenceType_Term.toUpperCase() === "LECTURE WISE") {
                    getlectuderattendancedata();
                }
                else {
                    BindAttendanceData();
                }
            }
        } else {
            history.push("/access");
        }
    }, []);


    React.useEffect(() => {
        checkgetlecturedata()
    }, [SearchData.StdDiv, txtStartDate])

    function IsWorkingDayChange(e, index) {
        let temp = attendanceRegisterMaster.data;
        temp[index].IsWorkingDay = e.target.checked;
        setattendanceRegisterMaster({ IsData: true, data: temp });
    }

    function AttendenceTypeTerm_MenuClikc(type, index_a, date) {
        let temp = attendence.data;
        temp[index_a][date] = type;
        setattendence({ IsData: true, data: temp });
    }

    function StandardDivisionChange(e) {
        setattendence({ IsData: true, data: [] });
        setattendanceRegisterMaster({ IsData: true, data: [] });
        setSearchData({
            ...SearchData,
            ["StdDiv"]: e.value,
        });


    }
    function setTxtStartDateforlecturedata(date) {
        setattendence({ IsData: true, data: [] });
        setattendanceRegisterMaster({ IsData: true, data: [] });
        setTxtStartDate(date);
    }

    function MonthYearChange(e) {
        setattendence({ IsData: true, data: [] });
        setattendanceRegisterMaster({ IsData: true, data: [] });
        setSearchData({
            ...SearchData,
            ["StartEndDate"]: e.value,
        });
    }

    function AttendanceFromChange(e) {

        setAttendanceTo([SelectAttendanceTo]);
        let TempSearchData = SearchData;
        TempSearchData.AttendanceFrom = e.value;
        TempSearchData.AttendanceTo = "";
        setSearchData(TempSearchData);

        if (attendanceRegisterMaster.data.length !== 0) {
            let Options = [];
            Options.push(SelectAttendanceTo);
            for (let i = 0; i < attendanceRegisterMaster.data.length; i++) {
                if (
                    new Date(attendanceRegisterMaster.data[i].Date) > new Date(e.value) &&
                    new Date(attendanceRegisterMaster.data[i].Date) <= new Date()
                ) {
                    Options.push({
                        value: attendanceRegisterMaster.data[i].Date,
                        label: commonService.getDateInFormat(
                            attendanceRegisterMaster.data[i].Date
                        ),
                    });
                }
            }
            setAttendanceTo(Options);
        }

        let temp = attendanceRegisterMaster.data;
        let temp_a = attendence.data;
        if (temp.length !== 0) {
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].Date === e.value) {
                    temp[i].IsWorkingDay = true;

                    for (let j = 0; j < temp_a.length; j++) {
                        if (
                            temp_a[j][temp[i].Date] === null ||
                            temp_a[j][temp[i].Date] === ""
                        ) {
                            if (
                                new Date(temp[i].Date) >=
                                new Date(temp_a[j].DateofRegistration) &&
                                new Date(temp[i].Date) <= new Date(temp_a[j].DateOfLeaving)
                            ) {
                                //temp_a[j][temp[i].Date] = "PRESENT";
                            }
                        }
                    }
                }
            }
            setattendence({ IsData: true, data: temp_a });
            setattendanceRegisterMaster({ IsData: true, data: temp });
        }
    }

    function AttendanceToChange(e) {

        let TempSearchData = SearchData;
        TempSearchData.AttendanceTo = e.value;
        setSearchData(TempSearchData);

        let _AttendanceFrom = SearchData.AttendanceFrom;
        let temp = attendanceRegisterMaster.data;
        let temp_a = attendence.data;
        while (new Date(_AttendanceFrom) <= new Date(e.value)) {
            var _tempDate = commonService.getDateInDBFormat(
                new Date(_AttendanceFrom)
            );
            if (temp.length !== 0) {
                for (let i = 0; i < temp.length; i++) {
                    if (temp[i].Date === _tempDate) {
                        temp[i].IsWorkingDay = true;
                        for (let j = 0; j < temp_a.length; j++) {
                            if (
                                temp_a[j][temp[i].Date] === null ||
                                temp_a[j][temp[i].Date] === ""
                            ) {
                                if (
                                    new Date(temp[i].Date) >=
                                    new Date(temp_a[j].DateofRegistration) &&
                                    new Date(temp[i].Date) <= new Date(temp_a[j].DateOfLeaving)
                                ) {
                                    //temp_a[j][temp[i].Date] = "PRESENT";
                                }
                            }
                        }
                    }
                }
            }
            _AttendanceFrom = new Date(
                new Date(_AttendanceFrom).setDate(
                    new Date(_AttendanceFrom).getDate() + 1
                )
            );
        }
        setattendence({ IsData: true, data: temp_a });
        setattendanceRegisterMaster({ IsData: true, data: temp });
    }

    function BindTableHeaders(arm) {
        if (arm !== null && arm.length !== 0) {
            return (
                <>
                    <tr>
                        <th rowSpan={3} className="w-50px">
                            {commonService.LabelDisplayText("Roll")}
                        </th>
                        <th rowSpan={3}>{commonService.LabelDisplayText("Name")}</th>
                        {arm.map((item, index) => (
                            <th
                                key={index}
                                className={
                                    item.IsWeekOff !== null && item.IsWeekOff !== ""
                                        ? "WeekOff"
                                        : "" + " " + item.IsHoliday !== null && item.IsHoliday > 0
                                            ? "Holiday"
                                            : ""
                                }
                            >
                                <FormCheck
                                    type={"checkbox"}
                                    inline
                                    id={item.StudentAttRegMasterID}
                                    checked={item.IsWorkingDay}
                                    className="header-checkbox"
                                    onChange={(e) => IsWorkingDayChange(e, index)}
                                />
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {arm.map((item, index) => (
                            <th
                                key={index}
                                className={
                                    item.IsWeekOff !== null && item.IsWeekOff !== ""
                                        ? "WeekOff"
                                        : "" + " " + item.IsHoliday !== null && item.IsHoliday > 0
                                            ? "Holiday"
                                            : ""
                                }
                            >
                                {item.DayOfAttendence_Term.substr(0, 2)}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {arm.map((item, index) => (
                            <th
                                key={index}
                                className={
                                    item.IsWeekOff !== null && item.IsWeekOff !== ""
                                        ? "WeekOff"
                                        : "" + " " + item.IsHoliday !== null && item.IsHoliday > 0
                                            ? "Holiday"
                                            : ""
                                }
                            >
                                {new Date(item.DateValue).getDate()}
                            </th>
                        ))}
                    </tr>
                </>
            );
        }
    }

    function BindTableData(arm, att) {
        if (arm !== null && arm.length !== 0 && att !== null && att.length !== 0) {
            return (
                <>
                    {att.map((item_a, index_a) => (
                        <tr key={index_a}>
                            <td>{item_a.RollNo}</td>
                            <td className="student-name-attendence ">
                                <p className="atttendece-stud-name">{item_a.FullName}</p>
                            </td>
                            {arm.map((item_am, index_am) => (
                                <td

                                    key={index_am}
                                    className={
                                        item_am.IsWeekOff !== null && item_am.IsWeekOff !== ""
                                            ? "student-attendence-data WeekOff"
                                            : "student-attendence-data" + " " + item_am.IsHoliday !== null &&
                                                item_am.IsHoliday > 0
                                                ? "student-attendence-data Holiday"
                                                : "student-attendence-data"
                                    }
                                >
                                    {item_a[item_am.Date] !== null &&
                                        item_a[item_am.Date] !== "" && (
                                            <DropdownButton
                                                as={ButtonGroup}
                                                className={
                                                    item_a[item_am.Date] !== null
                                                        ? item_a[item_am.Date]
                                                            .toUpperCase()
                                                            .replaceAll(" ", "_") +
                                                        " AttendenceTypeTerm_Menu"
                                                        : "AttendenceTypeTerm_Menu"
                                                }
                                                title={
                                                    item_a[item_am.Date] !== null &&
                                                    item_a[item_am.Date].substr(0, 1).toUpperCase()
                                                }
                                            >
                                                <Dropdown.Item
                                                    eventKey="1"
                                                    className="PRESENT"
                                                    onClick={() =>
                                                        AttendenceTypeTerm_MenuClikc(
                                                            "PRESENT",
                                                            index_a,
                                                            item_am.Date
                                                        )
                                                    }
                                                >
                                                    {commonService.LabelDisplayText("AttendencePresent")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    eventKey="2"
                                                    className="ABSENT"
                                                    onClick={() =>
                                                        AttendenceTypeTerm_MenuClikc(
                                                            "ABSENT",
                                                            index_a,
                                                            item_am.Date
                                                        )
                                                    }
                                                >
                                                    {commonService.LabelDisplayText("AttendenceAbsent")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    eventKey="3"
                                                    className="LEAVE"
                                                    onClick={() =>
                                                        AttendenceTypeTerm_MenuClikc(
                                                            "LEAVE",
                                                            index_a,
                                                            item_am.Date
                                                        )
                                                    }
                                                >
                                                    {commonService.LabelDisplayText("AttendenceLeave")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    eventKey="3"
                                                    className="SICK_LEAVE"
                                                    onClick={() =>
                                                        AttendenceTypeTerm_MenuClikc(
                                                            "SICK LEAVE",
                                                            index_a,
                                                            item_am.Date
                                                        )
                                                    }
                                                >
                                                    {commonService.LabelDisplayText("AttendenceSickLeave")}
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </>
            );
        }
    }

    function LectureAttendanceSave() {
        if (SearchData.StdDiv !== null && txtStartDate !== null) {
            if (SearchData.StdDiv !== "" && txtStartDate !== "") {
                setIsAttendanceSaveing(true);
                let formData = new FormData();
                formData.append("requestFrom", "Web");
                formData.append("standardID", SearchData.StdDiv.split("|")[0]);
                formData.append("divisionID", SearchData.StdDiv.split("|")[1]);
                formData.append("PeriodID", periodselect);
                let _AttendanceFrom = txtStartDate;
                let _AttendanceTo = txtStartDate;
                if (_AttendanceTo === null) {
                    _AttendanceTo = _AttendanceFrom;
                }

                if (_AttendanceTo === "") {
                    _AttendanceTo = _AttendanceFrom;
                }

                let arr_attRegMaster = attendanceRegisterMaster.data;
                let arr_att = attendence.data;
                let sa_jj = 0;
                let a_i = 0;

                while (new Date(_AttendanceFrom) <= new Date(_AttendanceTo)) {
                    var _tempDate = commonService.getDateInDBFormat(
                        new Date(_AttendanceFrom)
                    );
                    if (arr_attRegMaster.length !== 0) {
                        for (let i = 0; i < arr_attRegMaster.length; i++) {
                            if (arr_attRegMaster[i].Date === _tempDate) {
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].studentAttRegMasterID",
                                    arr_attRegMaster[i].StudentAttRegMasterID
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].dateOfAttendence",
                                    arr_attRegMaster[i].Date
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].dayOfAttendence_Term",
                                    arr_attRegMaster[i].DayOfAttendence_Term
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].isWorkingDay",
                                    arr_attRegMaster[i].IsWorkingDay
                                );
                                for (let j = 0; j < arr_att.length; j++) {
                                    if (arr_att[j][arr_attRegMaster[i].Date] !== null) {
                                        if (arr_att[j][arr_attRegMaster[i].Date] !== "") {
                                            formData.append(
                                                "studentAttendence[" +
                                                sa_jj +
                                                "].studentAttRegMasterID",
                                                arr_attRegMaster[i].StudentAttRegMasterID
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].studentID",
                                                arr_att[j].StudentID
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].dateOfAttendence",
                                                arr_attRegMaster[i].Date
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].dayOfAttendence_Term",
                                                arr_attRegMaster[i].DayOfAttendence_Term
                                            );
                                            if (
                                                arr_att[j].RollNo !== null &&
                                                arr_att[j].RollNo !== ""
                                            ) {
                                                formData.append(
                                                    "studentAttendence[" + sa_jj + "].rollNo",
                                                    arr_att[j].RollNo
                                                );
                                            } else {
                                                formData.append(
                                                    "studentAttendence[" + sa_jj + "].rollNo",
                                                    0
                                                );
                                            }
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].attendenceType_Term",
                                                arr_att[j][arr_attRegMaster[i].Date]
                                            );
                                            sa_jj = sa_jj + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    _AttendanceFrom = new Date(
                        new Date(_AttendanceFrom).setDate(
                            new Date(_AttendanceFrom).getDate() + 1
                        )
                    );
                    a_i = a_i + 1;
                }

                const reqAtt = {
                    method: "POST",
                    enctype: "multipart/form-data",
                    processData: false,
                    contentType: false,
                    headers: commonService.getHeadersFromData(),
                    body: formData,
                };
                fetch(
                    AppConfigData.APIBasePath + "v1/attendance/saveattendancedatabylecture",
                    reqAtt
                )
                    .then((response) => response.json())
                    .then(function (json) {
                        setIsAttendanceSaveing(false);
                        if (json.status_code === 1) {
                            alertService.alert({
                                type: AlertType.Success,
                                message: json.message,
                            });
                        } else if (json.status_code === 0) {
                            alertService.alert({
                                type: AlertType.Error,
                                message: json.message,
                            });
                        } else if (json.status_code === 2) {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            } else {
                alertService.alert({
                    type: AlertType.Error,
                    message: commonService.LabelDisplayText("SelectAttendanceFrom"),
                });
            }
        } else {
            alertService.alert({
                type: AlertType.Error,
                message: commonService.LabelDisplayText("SelectAttendanceFrom"),
            });
        }
    }


    function AttendanceSave() {
        if (SearchData.StdDiv !== null && SearchData.AttendanceFrom !== null) {
            if (SearchData.StdDiv !== "" && SearchData.AttendanceFrom !== "") {
                setIsAttendanceSaveing(true);
                let formData = new FormData();
                formData.append("requestFrom", "Web");
                formData.append("standardID", SearchData.StdDiv.split("|")[0]);
                formData.append("divisionID", SearchData.StdDiv.split("|")[1]);
                formData.append("IsMother", IsSendMother); 
                formData.append("IsFather", IsSendFather);
                formData.append("IsStudent", IsSendStudent);

                let _AttendanceFrom = SearchData.AttendanceFrom;
                let _AttendanceTo = SearchData.AttendanceTo;
                if (_AttendanceTo === null) {
                    _AttendanceTo = _AttendanceFrom;
                }

                if (_AttendanceTo === "") {
                    _AttendanceTo = _AttendanceFrom;
                }

                let arr_attRegMaster = attendanceRegisterMaster.data;
                let arr_att = attendence.data;
                let sa_jj = 0;
                let a_i = 0;

                while (new Date(_AttendanceFrom) <= new Date(_AttendanceTo)) {
                    var _tempDate = commonService.getDateInDBFormat(
                        new Date(_AttendanceFrom)
                    );
                    if (arr_attRegMaster.length !== 0) {
                        for (let i = 0; i < arr_attRegMaster.length; i++) {
                            if (arr_attRegMaster[i].Date === _tempDate) {
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].studentAttRegMasterID",
                                    arr_attRegMaster[i].StudentAttRegMasterID
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].dateOfAttendence",
                                    arr_attRegMaster[i].Date
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].dayOfAttendence_Term",
                                    arr_attRegMaster[i].DayOfAttendence_Term
                                );
                                formData.append(
                                    "attendanceRegisterMaster[" + a_i + "].isWorkingDay",
                                    arr_attRegMaster[i].IsWorkingDay
                                );
                                for (let j = 0; j < arr_att.length; j++) {
                                    if (arr_att[j][arr_attRegMaster[i].Date] !== null) {
                                        if (arr_att[j][arr_attRegMaster[i].Date] !== "") {
                                            formData.append(
                                                "studentAttendence[" +
                                                sa_jj +
                                                "].studentAttRegMasterID",
                                                arr_attRegMaster[i].StudentAttRegMasterID
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].studentID",
                                                arr_att[j].StudentID
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].dateOfAttendence",
                                                arr_attRegMaster[i].Date
                                            );
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].dayOfAttendence_Term",
                                                arr_attRegMaster[i].DayOfAttendence_Term
                                            );
                                            if (
                                                arr_att[j].RollNo !== null &&
                                                arr_att[j].RollNo !== ""
                                            ) {
                                                formData.append(
                                                    "studentAttendence[" + sa_jj + "].rollNo",
                                                    arr_att[j].RollNo
                                                );
                                            } else {
                                                formData.append(
                                                    "studentAttendence[" + sa_jj + "].rollNo",
                                                    0
                                                );
                                            }
                                            formData.append(
                                                "studentAttendence[" + sa_jj + "].attendenceType_Term",
                                                arr_att[j][arr_attRegMaster[i].Date]
                                            );
                                            sa_jj = sa_jj + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    _AttendanceFrom = new Date(
                        new Date(_AttendanceFrom).setDate(
                            new Date(_AttendanceFrom).getDate() + 1
                        )
                    );
                    a_i = a_i + 1;
                }

                const reqAtt = {
                    method: "POST",
                    enctype: "multipart/form-data",
                    processData: false,
                    contentType: false,
                    headers: commonService.getHeadersFromData(),
                    body: formData,
                };
                fetch(
                    AppConfigData.APIBasePath + "v1/attendance/saveattendancedata",
                    reqAtt
                )
                    .then((response) => response.json())
                    .then(function (json) {
                        setIsAttendanceSaveing(false);
                        if (json.status_code === 1) {
                            alertService.alert({
                                type: AlertType.Success,
                                message: json.message,
                            });
                        } else if (json.status_code === 0) {
                            alertService.alert({
                                type: AlertType.Error,
                                message: json.message,
                            });
                        } else if (json.status_code === 2) {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            } else {
                alertService.alert({
                    type: AlertType.Error,
                    message: commonService.LabelDisplayText("SelectAttendanceFrom"),
                });
            }
        } else {
            alertService.alert({
                type: AlertType.Error,
                message: commonService.LabelDisplayText("SelectAttendanceFrom"),
            });
        }
    }

    function GenerateReport() {
        if (
            SearchData.StdDiv !== null &&
            SearchData.StdDiv !== "" &&
            SearchData.StartEndDate !== null &&
            SearchData.StartEndDate !== ""
        ) {
            setIsGenerateReport(true);
            let dt = new Date(SearchData.StartEndDate.split("#")[0]);
            let isWorkingDay = null;
            let standardDivisionName = "";
            if (
                document.querySelector('input[name="GenerateReportType"]:checked')
                    .value === "true"
            ) {
                isWorkingDay = true;
            }
            for (let i = 0; i < TeacherStdDiv.length; i++) {
                if (TeacherStdDiv[i].value === SearchData.StdDiv) {
                    standardDivisionName = TeacherStdDiv[i].label;
                }
            }

            const reqAttendanceData = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    standardID: SearchData.StdDiv.split("|")[0],
                    divisionID: SearchData.StdDiv.split("|")[1],
                    month: dt.getMonth() + 1,
                    year: dt.getFullYear(),
                    batchStartDate: data[0].StartDate,
                    batchEndDate: data[0].EndDate,
                    isWorkingDay: isWorkingDay,
                    instituteName: data[0].InstituteName,
                    standardDivisionName: standardDivisionName,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/attendance/getattendancereport",
                reqAttendanceData
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsGenerateReport(false);
                    if (json.status_code === 1) {
                        window.open(AppConfigData.APIFileBasePath + json.data, "_blank");
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setIsGenerateReport(false);
        }
    }

    function bindStudMonthCal(att) {
        if (
            data[0].StartDate !== null &&
            data[0].StartDate !== "" &&
            data[0].EndDate !== null &&
            data[0].EndDate !== ""
        ) {
            let dt = new Date(data[0].StartDate);

            let NoofMonth = [];

            while (dt <= new Date(data[0].EndDate)) {
                let StartDate = new Date(dt);
                let EndDate = new Date(dt);

                EndDate = new Date(EndDate.setMonth(EndDate.getMonth() + 1));
                EndDate = new Date(EndDate.setDate(EndDate.getDate() - 1));

                let Highlight_present = [];
                let Highlight_absent = [];
                let Highlight_leave = [];
                let Highlight_sick_leave = [];
                let Highlight_cancel = [];

                if (att.length !== 0) {
                    let loopStartDate = new Date(StartDate);
                    let loopEndDate = new Date(EndDate);
                    while (loopStartDate <= new Date(loopEndDate)) {
                        let _DtDBFormat = commonService.getDateInDBFormat(loopStartDate);
                        if (att[0][_DtDBFormat] !== null) {
                            if (att[0][commonService.getDateInDBFormat(dt)] !== "") {
                                if (att[0][_DtDBFormat].toUpperCase() === "PRESENT") {
                                    Highlight_present.push(new Date(_DtDBFormat));
                                } else if (att[0][_DtDBFormat].toUpperCase() === "ABSENT") {
                                    Highlight_absent.push(new Date(_DtDBFormat));
                                } else if (att[0][_DtDBFormat].toUpperCase() === "LEAVE") {
                                    Highlight_leave.push(new Date(_DtDBFormat));
                                } else if (att[0][_DtDBFormat].toUpperCase() === "SICK LEAVE") {
                                    Highlight_sick_leave.push(new Date(_DtDBFormat));
                                }
                                else if (att[0][_DtDBFormat].toUpperCase() === "CANCEL") {
                                    Highlight_cancel.push(new Date(_DtDBFormat));
                                }
                            }
                        }
                        loopStartDate = new Date(
                            loopStartDate.setDate(loopStartDate.getDate() + 1)
                        );
                    }
                }

                NoofMonth.push({
                    StartDate: commonService.getDateInDBFormat(StartDate),
                    EndDate: commonService.getDateInDBFormat(EndDate),
                    HighlightWithRanges: [
                        { "present-cal-indicator": Highlight_present },
                        { "absent-cal-indicator": Highlight_absent },
                        { "leave-cal-indicator": Highlight_leave },
                        { "sick_leave-cal-indicator": Highlight_sick_leave },
                        { "cancel-cal-indicator": Highlight_cancel },
                    ],
                });
                dt = new Date(dt.setMonth(dt.getMonth() + 1));
            }

            if (NoofMonth.length !== 0) {
                return NoofMonth.map((item, index) => (
                    <div
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 attendence-calendar-student"
                        key={index}
                    >
                        <div className="stud-attendance-view">
                            <DatePicker
                                selected={""}
                                highlightDates={item.HighlightWithRanges}
                                inline
                                minDate={new Date(item.StartDate)}
                                maxDate={new Date(item.EndDate)}
                            />
                        </div>
                    </div>
                ));
            } else {
                return (
                    <div className="col-12 m-2">
                        <_NoDataFound fontsize={"150px"} />
                    </div>
                );
            }
        }
    }

    function bindTeacherMonthCal(att) {

        if (
            data[0].StartDate !== null &&
            data[0].StartDate !== "" &&
            data[0].EndDate !== null &&
            data[0].EndDate !== ""
        ) {
            let dt = new Date(data[0].StartDate);

            let NoofMonth = [];

            while (dt <= new Date(data[0].EndDate)) {

                let StartDate = new Date(dt);
                let EndDate = new Date(dt);

                EndDate = new Date(EndDate.setMonth(EndDate.getMonth() + 1));
                EndDate = new Date(EndDate.setDate(EndDate.getDate() - 1));

                NoofMonth.push({
                    StartDate: commonService.getDateInDBFormat(StartDate),
                    EndDate: commonService.getDateInDBFormat(EndDate),
                });
                dt = new Date(dt.setMonth(dt.getMonth() + 1));
            }

            if (NoofMonth.length !== 0) {
                let ForSetLabelInDate = setInterval(function () {
                    const boxes = document.querySelectorAll('.cal-indicator-attendance');
                    boxes.forEach(box => {
                        box.remove();
                    });

                    for (let i = 0; i < att.attendanceCount.length; i++) {
                        let elements = document.getElementsByClassName(commonService.getDateInDBFormat(att.attendanceCount[i].DateOfAttendence));
                        if (elements !== null) {
                            let status = att.attendanceCount[i].AllAttendenceType_Term.split(",");
                            if (status !== null && status.length !== 0) {
                                for (let i = 0; i < status.length; i++) {
                                    const para = document.createElement("p");
                                    para.classList.add(status[i].replaceAll(" ", "").toLowerCase())
                                    para.classList.add("cal-indicator-attendance");
                                    elements[0].appendChild(para);
                                }
                            }
                        }
                    }
                    clearInterval(ForSetLabelInDate);
                }, 1000);
                return NoofMonth.map((item, index) => (
                    <div
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 attendence-calendar-student"
                        key={index}
                    >
                        <div className="stud-attendance-view">
                            <DatePicker
                                selected={""}
                                highlightDates={item.HighlightWithRanges}
                                inline
                                minDate={new Date(item.StartDate)}
                                maxDate={new Date(item.EndDate)}
                                dayClassName={(date) =>
                                    commonService.getDateInDBFormat(date)
                                }
                                onChange={(date) => fnShowAttendanceDetailsByLecture(date, att.attendence)}
                            />
                        </div>
                    </div>
                ));
            } else {
                return (
                    <div className="col-12 m-2">
                        <_NoDataFound fontsize={"150px"} />
                    </div>
                );
            }
        }

    }
    const [showAttendanceDetailsByLecturePopup, setshowAttendanceDetailsByLecturePopup] = useState(false);

    const [showTeacherAttendanceDetailsPopup, setshowTeacherAttendanceDetailsPopup] = useState(false);
    const [showTeacherAttendanceDetailsData, setshowTeacherAttendanceDetailsData] = useState({});
    const [showTeacherAttendanceDetailsViewDate, setshowTeacherAttendanceDetailsViewDate] = useState("");

    const [showAttendanceDetailsByLectureData, setshowAttendanceDetailsByLectureData] = useState([]);
    const [showAttendanceDetailsByLectureViewDate, setshowAttendanceDetailsByLectureViewDate] = useState("");


    function fnShowAttendanceDetailsByLecture(date, data) {
        setshowAttendanceDetailsByLectureData([]);
        setshowAttendanceDetailsByLectureViewDate(commonService.getDateInFormat(date));
        let FilerData = [];
        for (let i = 0; i < data.length; i++) {
            if (commonService.getDateInDBFormat(data[i].DateOfAttendence) === commonService.getDateInDBFormat(date)) {
                FilerData.push(data[i]);
            }
        }
        if (FilerData.length !== 0) {
            setshowAttendanceDetailsByLecturePopup(true);
            setshowAttendanceDetailsByLectureData(FilerData);
        }
    }

    function fnShowTeacherAttendanceDetailsByLecture(date, data) {
        setshowTeacherAttendanceDetailsData({});
        setshowTeacherAttendanceDetailsViewDate(commonService.getDateInFormat(date));
        let FilerData = [];
        for (let i = 0; i < data.length; i++) {
            if (commonService.getDateInDBFormat(data[i].DateOfAttendence) === commonService.getDateInDBFormat(date)) {
                if (data[i].AttendenceType_Term.toUpperCase() !== "ABSENT" && data[i].AttendenceType_Term.toUpperCase() !== "LEAVE" && data[i].AttendenceType_Term.toUpperCase() !== "SICK LEAVE") {
                    setshowTeacherAttendanceDetailsPopup(true);
                    setshowTeacherAttendanceDetailsData(data[i]);
                }
            }
        }
    }

    function bindTeadMonthCal() {
        if (
            data[0].StartDate !== null &&
            data[0].StartDate !== "" &&
            data[0].EndDate !== null &&
            data[0].EndDate !== ""
        ) {
            let dt = new Date(data[0].StartDate);
            let NoofMonth = [];

            while (dt <= new Date(data[0].EndDate)) {
                let StartDate = new Date(dt);
                let EndDate = new Date(dt);

                EndDate = new Date(EndDate.setMonth(EndDate.getMonth() + 1));
                EndDate = new Date(EndDate.setDate(EndDate.getDate() - 1));

                let ttpresent = [];
                let ttabsent = [];
                let ttleave = [];
                let ttsickleave = [];
                let Highlight_cancel = [];
                for (let i = 0; i < teacherAttendance.data.length; i++) {
                    if (teacherAttendance.data[i].AttendenceType_Term !== null) {
                        if (teacherAttendance.data[i].AttendenceType_Term !== "") {
                            if (
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "PRESENT" ||
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "LATE COMING" ||
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "EARLY GOING"
                            ) {
                                ttpresent.push(
                                    new Date(teacherAttendance.data[i].DateOfAttendence)
                                );
                            } else if (
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "ABSENT"
                            ) {
                                ttabsent.push(
                                    new Date(teacherAttendance.data[i].DateOfAttendence)
                                );
                            } else if (
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "LEAVE"
                            ) {
                                ttleave.push(
                                    new Date(teacherAttendance.data[i].DateOfAttendence)
                                );
                            } else if (
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "SICK LEAVE"
                            ) {
                                ttsickleave.push(
                                    new Date(teacherAttendance.data[i].DateOfAttendence)
                                );
                            }
                            else if (
                                teacherAttendance.data[i].AttendenceType_Term.toUpperCase() ===
                                "CANCEL"
                            ) {
                                Highlight_cancel.push(
                                    new Date(teacherAttendance.data[i].DateOfAttendence)
                                );
                            }
                        }
                    }
                }

                NoofMonth.push({
                    StartDate: commonService.getDateInDBFormat(StartDate),
                    EndDate: commonService.getDateInDBFormat(EndDate),
                    HighlightWithRanges: [
                        { "present-cal-indicator": ttpresent },
                        { "absent-cal-indicator": ttabsent },
                        { "leave-cal-indicator": ttleave },
                        { "sick_leave-cal-indicator": ttsickleave },
                        { "cancel-cal-indicator": Highlight_cancel },
                    ],
                });
                dt = new Date(dt.setMonth(dt.getMonth() + 1));
            }

            if (NoofMonth.length !== 0) {
                return NoofMonth.map((item, index) => (
                    <div
                        className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 attendence-calendar-student"
                        key={index}
                    >

                        <div className="stud-attendance-view">
                            <DatePicker
                                selected={""}
                                highlightDates={item.HighlightWithRanges}
                                inline
                                minDate={new Date(item.StartDate)}
                                maxDate={new Date(item.EndDate)}
                                onChange={(date) => fnShowTeacherAttendanceDetailsByLecture(date, teacherAttendance.data)}
                            />
                        </div>

                    </div>
                ));
            }
        }
    }

    function saveteacherattendancedata(e) {
        e.preventDefault();
        let errors = {
            isin: "",
            inputimage: "",
        };

        let isValid = true;

        if (
            teacherAttendanceSave.inputimage === null ||
            teacherAttendanceSave.inputimage === undefined
        ) {
            errors.inputimage = "Image is required";
            isValid = false;
        } else {
            errors.inputimage = "";
        }

        if (
            teacherAttendanceSave.isin === null ||
            teacherAttendanceSave.isin === undefined
        ) {
            errors.isin = "Attendence type is required";
            isValid = false;
        } else {
            errors.isin = "";
        }

        setteacherAttendanceSave({
            ...teacherAttendanceSave,
            ["errors"]: errors,
        });

        if (isValid) {
            setsaveload(true);
            let formData = new FormData();
            formData.append("IsForIn", teacherAttendanceSave.isin);
            formData.append("FormFiles", teacherAttendanceSave.inputimage);
            formData.append("RequestFrom", "web");
            const reqteacherattendancesave = {
                method: "POST",
                enctype: "multipart/form-data",
                processData: false,
                contentType: false,
                headers: commonService.getHeadersFromData(),
                body: formData,
            };
            fetch(
                AppConfigData.APIBasePath +
                "v1/attendance/saveemployeeattendencedatabyphoto",
                reqteacherattendancesave
            )
                .then((response) => response.json())
                .then(function (json) {
                    setsaveload(false);
                    if (json.status_code === 1) {
                        setteacherAttendanceSave({
                            isin: true,
                            inputimage: null,
                            errors: {
                                isin: "",
                                inputimage: "",
                            },
                        });
                        document.getElementsByName("TeacherInOutFileSelection")[0].value =
                            "";

                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                    } else if (json.status_code === 0) {
                        alertService.alert({
                            type: AlertType.Error,
                            message: json.message,
                        });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        }
    }

    const [lecturedata, setlecturedata] = useState([]);

    const [periodselect, setperiodselect] = useState('');

    function changeperiodselect(e) {

        setattendence({ IsData: true, data: [] });
        setattendanceRegisterMaster({ IsData: true, data: [] });
        setperiodselect(e.value)
    }

    function getlecturedata() {

        setlecturedata([])
        if (SearchData.StdDiv.split("|")[0] !== null && SearchData.StdDiv.split("|")[1] !== null && txtStartDate !== null) {
            if (SearchData.StdDiv.split("|")[0] !== "" && SearchData.StdDiv.split("|")[1] !== "" && txtStartDate !== "") {
                const reqlecturedata = {
                    method: "POST",
                    headers: commonService.getHeaders(),
                    body: JSON.stringify({
                        standardID: SearchData.StdDiv.split("|")[0],
                        divisionID: SearchData.StdDiv.split("|")[1],
                        startDate: commonService.getDateInDBFormat(txtStartDate),
                        endDate: commonService.getDateInDBFormat(txtStartDate),
                        requestFrom: "web",
                    }),
                };
                fetch(
                    AppConfigData.APIBasePath + "v1/attendance/getteacherfromlogbookbydateandclass",
                    reqlecturedata
                )
                    .then((response) => response.json())
                    .then(function (json) {

                        if (json.status_code === 1) {
                            var a = json.data;
                            let options = [];
                            for (let i = 0; i < json.data.length; i++) {
                                options.push({
                                    value: json.data[i].TeacherLogBookID.toLocaleUpperCase(),
                                    label: `${json.data[i].TeacherName}`,
                                });
                            }
                            setlecturedata(options);
                        } else if (json.status_code === 0) {
                            setlecturedata([]);
                        } else if (json.status_code === 2) {
                            localStorage.clear();
                            history.push("/");
                        }
                    });
            }
        }
    }

    function checkgetlecturedata() {
        getlecturedata()
    }

    function BindTeacherAttendanceData() {
        setIsAttandanceListDateLodding(true);
        setattendence({ IsData: false, data: [] });
        setattendanceRegisterMaster({ IsData: false, data: [] });

        if (
            SearchData.StdDiv !== null &&
            SearchData.StdDiv !== "" &&
            txtStartDate !== null &&
            txtStartDate !== ""
        ) {
            const reqAttendanceData = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    standardID: SearchData.StdDiv.split("|")[0],
                    divisionID: SearchData.StdDiv.split("|")[1],
                    startDate: commonService.getDateInDBFormat(txtStartDate),
                    endDate: commonService.getDateInDBFormat(txtStartDate),
                    periodID: periodselect,
                    requestFrom: "Web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/attendance/getattendance_bylectureforteacher",
                reqAttendanceData
            )
                .then((response) => response.json())
                .then(function (json) {
                    setIsAttandanceListDateLodding(false);
                    if (json.status_code === 1) {
                        json.data.attendence.forEach(element => {
                            if (element[commonService.getDateInDBFormat(txtStartDate)] === null || element[commonService.getDateInDBFormat(txtStartDate)] === "") {
                                element[commonService.getDateInDBFormat(txtStartDate)] = "Present";
                            }
                        });
                        setattendence({ IsData: true, data: json.data.attendence });

                        setattendanceRegisterMaster({
                            IsData: true,
                            data: json.data.attendanceRegisterMaster,
                        });
                        if (json.data.attendanceRegisterMaster.length !== 0) {
                            let Options = [];
                            Options.push(SelectAttendanceFrom);
                            for (
                                let i = 0;
                                i < json.data.attendanceRegisterMaster.length;
                                i++
                            ) {
                                if (
                                    new Date(json.data.attendanceRegisterMaster[i].Date) <=
                                    new Date()
                                )
                                    Options.push({
                                        value: json.data.attendanceRegisterMaster[i].Date,
                                        label: commonService.getDateInFormat(
                                            json.data.attendanceRegisterMaster[i].Date
                                        ),
                                    });
                            }
                            setAttendanceFrom(Options);
                        }
                    } else if (json.status_code === 0) {
                        setattendence({ IsData: false, data: [] });
                        setattendanceRegisterMaster({ IsData: true, data: [] });
                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                });
        } else {
            setIsAttandanceListDateLodding(false);
            setattendence({ IsData: true, data: [] });
            setattendanceRegisterMaster({ IsData: true, data: [] });
        }
    }

    function SetPresentAll() {
        let _AttendanceFrom = SearchData.AttendanceFrom;
        let _AttendanceTo = SearchData.AttendanceTo !== null && SearchData.AttendanceTo !== "" ? SearchData.AttendanceTo : SearchData.AttendanceFrom;

        let temp = attendanceRegisterMaster.data;
        let temp_a = attendence.data;
        while (new Date(_AttendanceFrom) <= new Date(_AttendanceTo)) {
            var _tempDate = commonService.getDateInDBFormat(
                new Date(_AttendanceFrom)
            );
            if (temp.length !== 0) {
                for (let i = 0; i < temp.length; i++) {
                    if (temp[i].Date === _tempDate) {
                        temp[i].IsWorkingDay = true;
                        for (let j = 0; j < temp_a.length; j++) {
                            if (
                                temp_a[j][temp[i].Date] === null ||
                                temp_a[j][temp[i].Date] === ""
                            ) {
                                if (
                                    new Date(temp[i].Date) >=
                                    new Date(temp_a[j].DateofRegistration) &&
                                    new Date(temp[i].Date) <= new Date(temp_a[j].DateOfLeaving)
                                ) {
                                    temp_a[j][temp[i].Date] = "PRESENT";
                                }
                            }
                        }
                    }
                }
            }
            _AttendanceFrom = new Date(
                new Date(_AttendanceFrom).setDate(
                    new Date(_AttendanceFrom).getDate() + 1
                )
            );
        }
        setattendence({ IsData: true, data: temp_a });
        setattendanceRegisterMaster({ IsData: true, data: temp });
    }

    function SetAbsentAll() {
        let _AttendanceFrom = SearchData.AttendanceFrom;
        let _AttendanceTo = SearchData.AttendanceTo !== null && SearchData.AttendanceTo !== "" ? SearchData.AttendanceTo : SearchData.AttendanceFrom;

        let temp = attendanceRegisterMaster.data;
        let temp_a = attendence.data;
        while (new Date(_AttendanceFrom) <= new Date(_AttendanceTo)) {
            var _tempDate = commonService.getDateInDBFormat(
                new Date(_AttendanceFrom)
            );
            if (temp.length !== 0) {
                for (let i = 0; i < temp.length; i++) {
                    if (temp[i].Date === _tempDate) {
                        temp[i].IsWorkingDay = true;
                        for (let j = 0; j < temp_a.length; j++) {
                            if (
                                temp_a[j][temp[i].Date] === null ||
                                temp_a[j][temp[i].Date] === ""
                            ) {
                                if (
                                    new Date(temp[i].Date) >=
                                    new Date(temp_a[j].DateofRegistration) &&
                                    new Date(temp[i].Date) <= new Date(temp_a[j].DateOfLeaving)
                                ) {
                                    temp_a[j][temp[i].Date] = "ABSENT";
                                }
                            }
                        }
                    }
                }
            }
            _AttendanceFrom = new Date(
                new Date(_AttendanceFrom).setDate(
                    new Date(_AttendanceFrom).getDate() + 1
                )
            );
        }
        setattendence({ IsData: true, data: temp_a });
        setattendanceRegisterMaster({ IsData: true, data: temp });
    }

    return (
        <>
            <div className="d-flex my-4 align-items-center justify-content-between">
                <div>
                    <h1 className="page-header mr-1">{commonService.LabelDisplayText("AttendenceTitle")}</h1>
                </div>
                <div>
                    <span className="attendence-indicator attendence-cancel">
                        {commonService.LabelDisplayText("AttendenceCancel")}
                    </span>
                    <span className="attendence-indicator attendence-present">
                        {commonService.LabelDisplayText("AttendencePresent")}
                    </span>
                    <span className="attendence-indicator attendence-absent">
                        {commonService.LabelDisplayText("AttendenceAbsent")}
                    </span>
                    <span className="attendence-indicator attendence-Leave">
                        {commonService.LabelDisplayText("AttendenceLeave")}
                    </span>
                    <span className="attendence-indicator attendence-sickLeave mr-0">
                        {commonService.LabelDisplayText("AttendenceSickLeave")}
                    </span>
                </div>
            </div>

            {data[0].MemberType.toLowerCase() === "teacher" ?
                <>
                    <Tabs
                        defaultActiveKey="My Attendance"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 timetable-liveclass"
                    >
                        <Tab eventKey="My Attendance" title="My Attendance">
                            <>
                                {
                                    commonService.getLoginRoleData("Teacher Attendance", "IsCreate") &&
                                    <div className="background-theme-color sticky-top">
                                        <div className="search-top-section">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-4">
                                                    <div className="header-search-area mt-1">
                                                        <ButtonGroup
                                                            className={`${teacherAttendanceSave.errors.isin &&
                                                                "invalid"
                                                                }`}
                                                        >
                                                            <Button
                                                                variant={
                                                                    teacherAttendanceSave.isin === true
                                                                        ? "success"
                                                                        : "light"
                                                                }
                                                                onClick={(e) =>
                                                                    setteacherAttendanceSave({
                                                                        ...teacherAttendanceSave,
                                                                        isin: true,
                                                                    })
                                                                }
                                                            >
                                                                {commonService.LabelDisplayText("AttendenceForIn")}
                                                            </Button>
                                                            <Button
                                                                variant={
                                                                    teacherAttendanceSave.isin === false
                                                                        ? "success"
                                                                        : "light"
                                                                }
                                                                onClick={(e) =>
                                                                    setteacherAttendanceSave({
                                                                        ...teacherAttendanceSave,
                                                                        isin: false,
                                                                    })
                                                                }
                                                            >
                                                                {commonService.LabelDisplayText("AttendenceForOut")}
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-8 mt-3 mt-xl-0">
                                                    <div className="search-right-cal d-flex align-items-center">
                                                        <div className="w-100 mr-3">
                                                            <input
                                                                name="TeacherInOutFileSelection"
                                                                type="file"
                                                                className={`form-control ${teacherAttendanceSave.errors.inputimage &&
                                                                    "invalid"
                                                                    }`}
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) =>
                                                                    setteacherAttendanceSave({
                                                                        ...teacherAttendanceSave,
                                                                        inputimage: e.target.files[0],
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div className="right-icon-cal d-flex align-items-center">
                                                            {saveload === false ? (
                                                                <div
                                                                    className="search-icon-img pointer-cursor"
                                                                    onClick={(e) => {
                                                                        saveteacherattendancedata(e);
                                                                    }}
                                                                >
                                                                    <i className="fa-regular fa-plus"></i>
                                                                </div>
                                                            ) : (
                                                                <div className="search-icon-img pointer-cursor">
                                                                    <Spinner
                                                                        animation="border"
                                                                        size="sm"
                                                                        variant="primary"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <>
                                    {!teacherAttendance.IsData ? (
                                        <div className="row col-12">
                                            <_Skeleton
                                                type={_SkeletonType.Table}
                                                count={4}
                                                lineheight={30}
                                            />
                                        </div>
                                    ) : teacherAttendance.data.length === 0 ? (
                                        <div className="col-12">
                                            <_NoDataFound fontsize={"150px"} />
                                        </div>
                                    ) : (
                                        <div className="row">{bindTeadMonthCal()}</div>
                                    )}
                                </>
                            </>
                        </Tab>
                        <Tab eventKey="Student Attendance" title="Student Attendance">
                            {
                                data[0].AttendenceType_Term !== "" ?
                                    <>
                                        {
                                            data[0].AttendenceType_Term.toUpperCase() === "LECTURE WISE" ?
                                                <>
                                                    <div className="background-theme-color  notice-circular-event-searchbar attendence-header-section">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                                                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section  first-attendence-section">
                                                                    <div className="row align-items-center m-0 selection-attendence-row">
                                                                        <div className="col-12 p-0">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Select
                                                                                        className="attendence-select"
                                                                                        placeholder={commonService.LabelDisplayText("SelectStandardDivision")}
                                                                                        options={TeacherStdDiv}
                                                                                        onChange={(e) => { StandardDivisionChange(e) }}
                                                                                        value={TeacherStdDiv.find(
                                                                                            (obj) =>
                                                                                                obj.value.toLocaleUpperCase() ===
                                                                                                SearchData.StdDiv.toLocaleUpperCase()
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 pr-0">
                                                                                    <DatePicker
                                                                                        className="datepicker homework-input-date "
                                                                                        selected={txtStartDate}
                                                                                        dateFormat={data[0].DateFormat_Term}
                                                                                        onChange={(date) => setTxtStartDateforlecturedata(date)}
                                                                                        placeholderText={data[0].DateFormat_Term}
                                                                                        maxDate={new Date()}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section attendence-duration">
                                                                    <div className="row m-0">
                                                                        <div className="col-11 pl-0">
                                                                            <div className="form-group m-0">
                                                                                <Select
                                                                                    placeholder="Select Lecture"
                                                                                    options={lecturedata}
                                                                                    onChange={(e) => changeperiodselect(e)}
                                                                                    value={lecturedata.find(
                                                                                        (obj) =>
                                                                                            obj.value.toLocaleUpperCase() ===
                                                                                            periodselect.toLocaleUpperCase()
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-1">
                                                                            {IsAttandanceListDateLodding === false ? (
                                                                                <div
                                                                                    className="search-icon-img pointer-cursor attendence-serach-img"
                                                                                    onClick={() => BindTeacherAttendanceData()}
                                                                                >
                                                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="search-icon-img pointer-cursor attendence-serach-img">
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        variant="primary"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {!attendanceRegisterMaster.IsData ? (
                                                        <div className="row col-12">
                                                            <_Skeleton
                                                                type={_SkeletonType.Table}
                                                                count={4}
                                                                lineheight={30}
                                                            />
                                                        </div>
                                                    ) : attendanceRegisterMaster.data.length === 0 ? (
                                                        <div className="col-12 mt-5">
                                                            <_NoDataFound fontsize={"150px"} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Table responsive className="attendance mb-2">
                                                                <thead>
                                                                    {BindTableHeaders(attendanceRegisterMaster.data)}
                                                                </thead>
                                                                <tbody>
                                                                    {BindTableData(
                                                                        attendanceRegisterMaster.data,
                                                                        attendence.data
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                            {
                                                                commonService.getLoginRoleData("Attendance", "IsDownload") || commonService.getLoginRoleData("Attendance", "IsCreate") ?
                                                                    <div className="posting-section attendece-report-save-section d-flex justify-content-between mt-2">
                                                                        {data[0].AttendenceType_Term.toUpperCase() != "LECTURE WISE" ? <>
                                                                            {
                                                                                commonService.getLoginRoleData("Attendance", "IsDownload") &&
                                                                                <div className="generate-report">
                                                                                    <FormCheck
                                                                                        type={"radio"}
                                                                                        inline
                                                                                        defaultChecked={true}
                                                                                        className="header-radio"
                                                                                        label={"All"}
                                                                                        name={"GenerateReportType"}
                                                                                        id={"GenerateReportTypeAll"}
                                                                                        value={false}
                                                                                    />
                                                                                    <FormCheck
                                                                                        type={"radio"}
                                                                                        inline
                                                                                        className="header-radio"
                                                                                        label={"Only Working days"}
                                                                                        id={"GenerateReportTypeOnlyWokringDays"}
                                                                                        name={"GenerateReportType"}
                                                                                        value={true}
                                                                                    />
                                                                                    {IsGenerateReport === false ? (
                                                                                        <button
                                                                                            className="post-select-btn mr-2"
                                                                                            onClick={() => GenerateReport()}
                                                                                        >
                                                                                            {commonService.LabelDisplayText("GenerateReport")}
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            className="post-select-btn mr-2"
                                                                                            type="button"
                                                                                            disabled="disabled"
                                                                                        >
                                                                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("GeneratingReport")}
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            }

                                                                        </> : <div className="generate-report"></div>}
                                                                        {
                                                                            commonService.getLoginRoleData("Attendance", "IsCreate") &&
                                                                            <div>
                                                                                {IsAttendanceSaveing === false ? <>
                                                                                    {data[0].AttendenceType_Term.toUpperCase() === "LECTURE WISE" ? <>
                                                                                        <button
                                                                                            className="post-select-btn"
                                                                                            onClick={() => LectureAttendanceSave()}
                                                                                        >
                                                                                            {commonService.LabelDisplayText("Save")}
                                                                                        </button>
                                                                                    </> : <>
                                                                                        <button
                                                                                            className="post-select-btn"
                                                                                            onClick={() => AttendanceSave()}
                                                                                        >
                                                                                            {commonService.LabelDisplayText("Save")}
                                                                                        </button>
                                                                                    </>}
                                                                                </> : <>
                                                                                    <button
                                                                                        className="post-select-btn"
                                                                                        type="button"
                                                                                        disabled="disabled"
                                                                                    >
                                                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                                                    </button>
                                                                                </>}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    : <></>
                                                            }

                                                        </div>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    <div className="background-theme-color  notice-circular-event-searchbar attendence-header-section">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 mb-3">
                                                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section  first-attendence-section">
                                                                    <div className="row align-items-center m-0 selection-attendence-row">
                                                                        <div className="col-11 p-0">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Select
                                                                                        className="attendence-select"
                                                                                        placeholder={commonService.LabelDisplayText("SelectStandardDivision")}
                                                                                        options={TeacherStdDiv}
                                                                                        onChange={(e) => StandardDivisionChange(e)}
                                                                                        value={TeacherStdDiv.find(
                                                                                            (obj) =>
                                                                                                obj.value.toLocaleUpperCase() ===
                                                                                                SearchData.StdDiv.toLocaleUpperCase()
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-6 pr-0">
                                                                                    <Select
                                                                                        placeholder={commonService.LabelDisplayText("SelectMonthYear")}
                                                                                        options={MonthYear}
                                                                                        onChange={(e) => MonthYearChange(e)}
                                                                                        value={MonthYear.find(
                                                                                            (obj) =>
                                                                                                obj.value.toLocaleUpperCase() ===
                                                                                                SearchData.StartEndDate.toLocaleUpperCase()
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-1">
                                                                            {IsAttandanceListDateLodding === false ? (
                                                                                <div
                                                                                    className="search-icon-img pointer-cursor attendence-serach-img"
                                                                                    onClick={() => BindAttendanceData()}
                                                                                >
                                                                                    <i className="fa-regular fa-magnifying-glass"></i>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="search-icon-img pointer-cursor attendence-serach-img">
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        variant="primary"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section attendence-duration">
                                                                    <div className="row m-0">
                                                                        <div className="col-6 pl-0">
                                                                            <div className="form-group m-0">
                                                                                <Select
                                                                                    placeholder={commonService.LabelDisplayText("SelectAttendanceFrom")}
                                                                                    options={AttendanceFrom}
                                                                                    onChange={(e) => AttendanceFromChange(e)}
                                                                                    value={AttendanceFrom.find(
                                                                                        (obj) =>
                                                                                            obj.value.toLocaleUpperCase() ===
                                                                                            SearchData.AttendanceFrom.toLocaleUpperCase()
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 pr-0">
                                                                            <div className="form-group m-0">
                                                                                <Select
                                                                                    placeholder={commonService.LabelDisplayText("SelectAttendenceTo")}
                                                                                    options={AttendanceTo}
                                                                                    onChange={(e) => AttendanceToChange(e)}
                                                                                    value={AttendanceTo.find(
                                                                                        (obj) =>
                                                                                            obj.value.toLocaleUpperCase() ===
                                                                                            SearchData.AttendanceTo.toLocaleUpperCase()
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                <div className="posting-section  align-items-center justify-content-between sticky-top attendence-search-section  first-attendence-section">
                                                                    <button
                                                                        className="post-select-btn mr-2"
                                                                        onClick={() => SetPresentAll()}
                                                                    >
                                                                        All Present
                                                                    </button>
                                                                    <button
                                                                        className="post-select-btn mr-2"
                                                                        onClick={() => SetAbsentAll()}
                                                                    >
                                                                        All Absent
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!attendanceRegisterMaster.IsData ? (
                                                        <div className="row col-12">
                                                            <_Skeleton
                                                                type={_SkeletonType.Table}
                                                                count={4}
                                                                lineheight={30}
                                                            />
                                                        </div>
                                                    ) : attendanceRegisterMaster.data.length === 0 ? (
                                                        <div className="col-12 mt-5">
                                                            <_NoDataFound fontsize={"150px"} />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Table responsive className="attendance mb-2">
                                                                <thead>
                                                                    {BindTableHeaders(attendanceRegisterMaster.data)}
                                                                </thead>
                                                                <tbody>
                                                                    {BindTableData(
                                                                        attendanceRegisterMaster.data,
                                                                        attendence.data
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                            {
                                                                commonService.getLoginRoleData("Attendance", "IsDownload") || commonService.getLoginRoleData("Attendance", "IsCreate") ?
                                                                    <div className="posting-section attendece-report-save-section d-flex justify-content-between mt-2">
                                                                        {
                                                                            commonService.getLoginRoleData("Attendance", "IsDownload") &&
                                                                            <>
                                                                                <div className="generate-report width-50per">
                                                                                    <FormCheck
                                                                                        type={"radio"}
                                                                                        inline
                                                                                        defaultChecked={true}
                                                                                        className="header-radio"
                                                                                        label={"All"}
                                                                                        name={"GenerateReportType"}
                                                                                        id={"GenerateReportTypeAll"}
                                                                                        value={false}
                                                                                    />
                                                                                    <FormCheck
                                                                                        type={"radio"}
                                                                                        inline
                                                                                        className="header-radio"
                                                                                        label={"Only Working days"}
                                                                                        id={"GenerateReportTypeOnlyWokringDays"}
                                                                                        name={"GenerateReportType"}
                                                                                        value={true}
                                                                                    />

                                                                                    {IsGenerateReport === false ? (
                                                                                        <button
                                                                                            className="post-select-btn mr-2"
                                                                                            onClick={() => GenerateReport()}
                                                                                        >
                                                                                            {commonService.LabelDisplayText("GenerateReport")}
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            className="post-select-btn mr-2"
                                                                                            type="button"
                                                                                            disabled="disabled"
                                                                                        >
                                                                                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("GeneratingReport")}
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                                <div className="generate-report">
                                                                                    <FormCheck
                                                                                        type={"checkbox"}
                                                                                        inline
                                                                                        label={"Send To Father"}
                                                                                        id={"SendToFather"}
                                                                                        name={"SendToFather"}
                                                                                        className="header-checkbox right"
                                                                                        onChange={(e) => setIsSendFather(e.target.checked)}                                                                                               
                                                                                    />
                                                                                    <FormCheck
                                                                                        type={"checkbox"}
                                                                                        inline
                                                                                        label={"Send To Mother"}
                                                                                        id={"SendToMother"}
                                                                                        name={"SendToMother"}
                                                                                        className="header-checkbox right"
                                                                                        onChange={(e) => setIsSendMother(e.target.checked)}
                                                                                    />
                                                                                    <FormCheck
                                                                                        type={"checkbox"}
                                                                                        inline
                                                                                        label={"Send To Student"}
                                                                                        id={"SendToStudent"}
                                                                                        name={"SendToStudent"}
                                                                                        className="header-checkbox right"
                                                                                        onChange={(e) => setIsSendStudent(e.target.checked)}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            commonService.getLoginRoleData("Attendance", "IsCreate") &&
                                                                            <div>
                                                                                {IsAttendanceSaveing === false ? (
                                                                                    <button
                                                                                        className="post-select-btn"
                                                                                        onClick={() => AttendanceSave()}
                                                                                    >
                                                                                        {commonService.LabelDisplayText("Save")}
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="post-select-btn"
                                                                                        type="button"
                                                                                        disabled="disabled"
                                                                                    >
                                                                                        <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Saving")}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    )}
                                                </>
                                        }
                                    </>
                                    : <_NoDataFound fontsize={'150px'} />

                            }
                        </Tab>
                    </Tabs>
                </>
                : (
                    <>
                        {!attendence.IsData || !studentattendence.IsData ? (
                            <div className="row col-12">
                                <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                            </div>
                        ) : attendence.data.length === 0 && studentattendence.data.length === 0 ? (
                            <div className="col-12">
                                <_NoDataFound fontsize={"150px"} />
                            </div>
                        ) : (
                            <>{data[0].AttendenceType_Term.toUpperCase() === "LECTURE WISE" ? <>
                                <div className="row">{bindTeacherMonthCal(studentattendence.data)}</div>
                            </> : <><div className="row">{bindStudMonthCal(attendence.data)}</div></>} </>

                        )}
                    </>
                )}

            <Modal
                show={showAttendanceDetailsByLecturePopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setshowAttendanceDetailsByLecturePopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={"width-50per"}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("LectureWiseAttendanceDetails")} ({showAttendanceDetailsByLectureViewDate})</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setshowAttendanceDetailsByLecturePopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <Table responsive className="m-0">
                                <thead>
                                    <tr>
                                        <th>{commonService.LabelDisplayText("Subject")}</th>
                                        <th>{commonService.LabelDisplayText("Teacher")}</th>
                                        <th>{commonService.LabelDisplayText("Time")}</th>
                                        <th>{commonService.LabelDisplayText("Status")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        showAttendanceDetailsByLectureData.length !== 0 &&
                                        showAttendanceDetailsByLectureData.map(
                                            (item) =>
                                                <tr>
                                                    <td>{item.SubjectName}</td>
                                                    <td>{item.TeacherName}</td>
                                                    <td>{item.StartTime + " - " + item.EndTime}</td>
                                                    <td><label className={item.AttendenceType_Term.replaceAll(" ", "").toLowerCase() + "-label"}>{item.AttendenceType_Term}</label></td>
                                                </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                showTeacherAttendanceDetailsData &&
                <Modal
                    show={showTeacherAttendanceDetailsPopup}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setshowTeacherAttendanceDetailsPopup(false)}
                    className=""
                    contentClassName={""}
                    dialogClassName={"width-40per"}
                >
                    <Modal.Header className="">
                        <h5>{showTeacherAttendanceDetailsViewDate} ({showTeacherAttendanceDetailsData.AttendenceType_Term})</h5>
                        <button
                            className="poopup-close-button"
                            onClick={() => setshowTeacherAttendanceDetailsPopup(false)}
                        >
                            <i className="icon icon-fi-rr-cross"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-center mt-2">
                                <b>{commonService.LabelDisplayText("InTime")} : </b>{showTeacherAttendanceDetailsData.InTime}
                                <div className="col-12 text-center mt-2">
                                    {showTeacherAttendanceDetailsData.InPhoto !== "" && showTeacherAttendanceDetailsData.Inphoto !== null ? <img
                                        src={showTeacherAttendanceDetailsData.InPhoto}
                                        className="in-out-img"
                                    /> : <></>}
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-center mt-2">
                                <b>{commonService.LabelDisplayText("OutTime")} : </b> {showTeacherAttendanceDetailsData.OutTime}
                                <div className="col-12 text-center  mt-2">
                                    {showTeacherAttendanceDetailsData.OutPhoto !== "" && showTeacherAttendanceDetailsData.OutPhoto !== null ? <img
                                        src={showTeacherAttendanceDetailsData.OutPhoto}
                                        className="in-out-img"
                                    /> : <> </>}
                                </div>

                            </div>
                        </div>
                        {
                            showTeacherAttendanceDetailsData.Note !== null && showTeacherAttendanceDetailsData.Note !== "" &&
                            <div className="row mt-2">
                                <div className="col-2 text-center"> <b>{commonService.LabelDisplayText("Reason")}</b></div>
                                <div className="col-10">
                                    {showTeacherAttendanceDetailsData.Note}
                                </div>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
            }

        </>
    );
}

export default Attendance;
