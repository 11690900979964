import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { commonService, AppConfigData } from "../_services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import Skeleton from "react-loading-skeleton";

function ChildCustodyView() {

    const [profile, setprofile] = useState({ IsData: false, data: [] });

    function BindChildCustody() {
        const requestprofile = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                type: "CHILDCUSTODY"
            }),
        };

        fetch(AppConfigData.APIBasePath + "v1/user/getprofiledetailsbymember", requestprofile)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setprofile({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setprofile({ IsData: true, data: [] });
                }
            });
    }


    React.useEffect(() => {
        BindChildCustody();
    }, []);

    return (
        <>
            <div className="container classwork-popup-detail">
                <div className="col-lg-12 student-profile ">
                    {profile.IsData ?
                        (
                            <>
                                <div className="col-lg-12 my-2">
                                    <div className="col-lg-3 float-left">
                                        <input
                                            type="radio"
                                            className="mb-2 float-left"
                                            checked={profile.data[0].ChildCustody === "Mother" && true}
                                            name="b"
                                            id="Mother"
                                        />
                                        <label className="student-roll-no float-left" htmlFor="b">Mother</label>
                                    </div>
                                    <div className="col-lg-3 float-left">
                                        <input
                                            type="radio"
                                            className="mb-2 float-left"
                                            name="b"
                                            checked={profile.data[0].ChildCustody === "Father" && true}
                                            id="Father"
                                        />
                                        <label className="student-roll-no float-left">Father</label>
                                    </div>
                                    <div className="col-lg-3 float-left">
                                        <input
                                            type="radio"
                                            className="mb-2 float-left"
                                            name="b"
                                            checked={profile.data[0].ChildCustody === "Both" && true}
                                            id="Both"
                                        />
                                        <label className="student-roll-no float-left">Both</label>
                                    </div>
                                    <div className="col-lg-3 float-left">
                                        <input
                                            type="radio"
                                            className="mb-2 float-left"
                                            name="b"
                                            checked={profile.data[0].ChildCustody === "Other" && true}
                                            id="Other"
                                        />
                                        <label className="student-roll-no float-left">Other</label>
                                    </div>
                                </div>
                                <div className="col-lg-12 my-2">
                                    <div className="col-lg-12 float-left">
                                        <div className="col-lg-12 my-1 my-1 col-form-label-sm rounded-lg" style={{ height: "60px" }}>
                                            <div className="col-lg-1.5 float-left"><b className="student-roll-no mt-1">Remarks : </b></div>
                                            <div className="col-lg-10 float-left"><b className="student-roll-no mt-1">{profile.data[0].ChildRemark}</b></div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ) : (
                            <>
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                                <Skeleton count={1} height={30} />
                                <_Skeleton type={_SkeletonType.Table} />
                            </>
                        )}
                </div>
            </div>
        </>
    );
}

export default ChildCustodyView;
