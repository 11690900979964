import React from "react";
import _Header from "./_Header";
import _SideMenu from "./_SideMenu";
import { Route, useHistory } from "react-router-dom";
import Home from "./Home";
import Classwork from "./Classwork";
import Exam from "./Exam";
import Homework from "./Homework";
import Leave from "./Leave";
import NoticeCircularEvents from "./NoticeCircularEvents";
import Settings from "./Settings";
import TimetableLiveclass from "./TimetableLiveclass";
import Wall from "./Wall";
import Profile from "./Profile";
import ExamSyllabus from "./ExamSyllabus";
import ToDoTask from "./ToDoTask";
import OverallResult from "./OverallResult";
import Fees from "./Fees";
import Attendance from "./Attendance";
import SubjectListing from "./SubjectListing";
import SubjectDetails from "./SubjectDetails";
import ChapterDetails from "./ChapterDetails";
import Poll from "./Poll";
import Access from "./Access";
import UpdateInfo from "./UpdateInfo";
import HostelTransactionDetail from "./HostelTransactionDetail";
import { commonService, AppConfigData } from "../_services/common.service";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Communication from "./Communication";
import TeacherChargePatrak from "./TeacherChargePatrak";
import ComplainLogBook from "./ComplainLogBook";
import Holiday from "./Holiday";
import InstitutePage from "./InstitutePage";


function _Layout() {
    const history = useHistory();
    const location = useLocation();
    //function checkroledata() {
    //  if (!commonService.getLoginRoleDataByURL(location.pathname.slice(1))) {
    //    history.push("/access");
    //  }
    //}
    //useEffect(() => {
    //  checkroledata();
    //}, [location.pathname]);

    return (
        <div className="layout">
            <_SideMenu />
            <div className="container">
                <_Header />
                <Route path="/home" exact component={() => <Home />} />
                <Route path="/classwork" exact component={() => <Classwork />} />
                <Route path="/exam" exact component={() => <Exam />} />
                <Route path="/homework" exact component={() => <Homework />} />
                <Route path="/leave" exact component={() => <Leave />} />
                <Route
                    path="/notecircualreevents"
                    exact
                    component={() => <NoticeCircularEvents />}
                />
                <Route path="/setting" exact component={() => <Settings />} />
                <Route
                    path="/timetableliveclass"
                    exact
                    component={() => <TimetableLiveclass />}
                />
                <Route path="/wall" exact component={() => <Wall />} />
                <Route path="/profile" exact component={() => <Profile />} />
                <Route path="/examsyllabus" exact component={() => <ExamSyllabus />} />
                <Route path="/todotask" exact component={() => <ToDoTask />} />
                <Route
                    path="/overallresult"
                    exact
                    component={() => <OverallResult />}
                />
                <Route path="/fees" exact component={() => <Fees />} />
                <Route path="/attendance" exact component={() => <Attendance />} />
                <Route
                    path="/subjectlisting"
                    exact
                    component={() => <SubjectListing />}
                />
                <Route
                    path="/subjectdetails"
                    exact
                    component={() => <SubjectDetails />}
                />
                <Route
                    path="/chapterdetails"
                    exact
                    component={() => <ChapterDetails />}
                />
                <Route path="/access" exact component={() => <Access />} />
                <Route
                    path="/pocketmoney"
                    exact
                    component={() => <HostelTransactionDetail />}
                />
                <Route
                    path="/communication"
                    exact
                    component={() => <Communication />}
                />

                <Route path="/poll" exact component={() => <Poll />} />
                <Route path="/updateinfo" exact component={() => <UpdateInfo />} />
                <Route path="/TeacherChargePatrak" exact component={() => <TeacherChargePatrak />} />
                <Route path="/ComplainLogBook" exact component={() => <ComplainLogBook />} />
                <Route path="/Holiday" exact component={() => <Holiday />} />
                <Route path="/institutepage" exact component={() => <InstitutePage />} />
            </div>
        </div>
    );
}
export default _Layout;
